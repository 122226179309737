import styled, { css } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';

export const TestAnswerIcon = styled.span<{ $isTeal: boolean }>`
  position: relative;
  display: inline-block;
  width: 20px !important;
  height: 20px !important;
  background-color: ${STATIC_COLORS.base.white};
  border: 1.5px solid ${STATIC_COLORS.base.black};
  border-radius: 50%;

  &::before,
  &::after {
    position: absolute;
    content: '';
    background-color: ${STATIC_COLORS.base.black};
    border-radius: 3px;
  }

  &::before {
    top: 9.5px;
    left: 3.8px;
    width: 4.6px;
    height: 1.5px;
    transform: rotate(46deg);
  }

  &::after {
    top: 8px;
    left: 5.5px;
    width: 7.8px;
    height: 1.5px;
    transform: rotate(-55deg);
  }

  ${({ $isTeal }) =>
    $isTeal &&
    css`
      background-color: ${STATIC_COLORS.teal[600]};
      border: 1.5px solid ${STATIC_COLORS.base.white};

      &::before,
      &::after {
        background-color: ${STATIC_COLORS.base.white};
      }
    `}
`;

import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import {
  DESIGN_SET_VINHOOD_APP,
  DESIGN_SET_VINHOOD_EXPERIENCE,
  DESIGN_SET_VINHOOD_KIOSK,
} from '@lib/core/service/consts';
import { IColorVariants, IThemeVariants } from '@lib/tools/shared/helpers/interfaces';
import { useRetailerDesignSet } from '@lib/tools/views/hooks';

export const DEFAULT_THEMES = {
  [DESIGN_SET_VINHOOD_APP]: {
    light: { primaryColor: '#4091A0', secondaryColor: '#E0E2DA' },
  },
  [DESIGN_SET_VINHOOD_EXPERIENCE]: {
    light: { primaryColor: '#4091A0', secondaryColor: '#E0E2DA' },
  },
  [DESIGN_SET_VINHOOD_KIOSK]: {
    light: { primaryColor: '#4091A0', secondaryColor: '#E0E2DA' },
  },
};

const WHITE: number[] = [255, 255, 255];
const BLACK: number[] = [0, 0, 0];
const LIGHT_THEME = 'light';

const ADJUSTMENTS = {
  '-100': [WHITE, 0.64],
  '-200': [WHITE, 0.48],
  '-25': [WHITE, 0.96],
  '-300': [WHITE, 0.32],
  '-400': [WHITE, 0.16],
  '-50': [WHITE, 0.8],
  '-500': [WHITE, 0], // this is the base color
  '-600': [BLACK, 0.16],
  '-700': [BLACK, 0.32],
  '-800': [BLACK, 0.48],
  '-900': [BLACK, 0.64],
};

function mixColor(color: string, targetColor: number[], amount: number): string {
  const [r, g, b] = color.match(/\w\w/g).map(c => parseInt(c, 16));

  // Mixing the color with targetColor
  const mix = (channel: number, target: number) => Math.round(channel + (target - channel) * amount);

  return `#${mix(r, targetColor[0]).toString(16).padStart(2, '0')}${mix(g, targetColor[1])
    .toString(16)
    .padStart(2, '0')}${mix(b, targetColor[2]).toString(16).padStart(2, '0')}`.toUpperCase();
}

export const useTheme = (): IThemeVariants => {
  const { retailerDesignSet } = useRetailerDesignSet();
  const { customStyle } = useRetailerLocation();

  /**
   * @todo add possibility to use Dark theme here
   */

  const selectedTheme = LIGHT_THEME;
  const defaultColors =
    DEFAULT_THEMES[retailerDesignSet]?.[selectedTheme] || DEFAULT_THEMES[DESIGN_SET_VINHOOD_EXPERIENCE][selectedTheme];

  const primaryColor = customStyle?.primaryColor || defaultColors.primaryColor;
  const secondaryColor = customStyle?.secondaryColor || defaultColors.secondaryColor;

  const generateColorVariants = (baseColor: string): IColorVariants => {
    return Object.keys(ADJUSTMENTS).reduce((acc, key) => {
      const [targetColor, amount] = ADJUSTMENTS[key];
      acc[key] = mixColor(baseColor, targetColor, amount);
      return acc;
    }, {} as IColorVariants);
  };

  return {
    primary: generateColorVariants(primaryColor),
    secondary: generateColorVariants(secondaryColor),
    theme: selectedTheme,
  };
};

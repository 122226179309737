import { FC } from 'react';

import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import percentageIcon from '@components/web/src/assets/legacy/b2b/kiosk/icons/percentage_icon.svg';
import arrowIcon from '@components/web/src/assets/legacy/b2b/kiosk/icons/right_arrow_icon.svg';

interface IProps {
  handleClick: () => void;
}

const PromotionCard: FC<IProps> = ({ handleClick }) => {
  const { promotionCard } = localeKiosk.homePageCards;

  return (
    <button className="promotion-card-container" type="button" onClick={handleClick}>
      <img alt="percentage icon" src={percentageIcon} />
      <span>
        <LocaleFragment message={promotionCard.title} />
      </span>
      <img alt="arrow icon" src={arrowIcon} />
    </button>
  );
};

export default PromotionCard;

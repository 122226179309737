import styled, { css } from 'styled-components';

import { IButtonGroupItemPosition, IButtonGroupItemStyleProps } from '@components/web/src/atoms/ButtonsGroup/types';
import { STATIC_COLORS, styleMatcher } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

export const getBorderStyles = (position: IButtonGroupItemPosition = 'right') =>
  styleMatcher(position, {
    left: () => css`
      border: 1px solid;
      border-color: ${STATIC_COLORS.gray[300]};
      border-radius: 8px 0px 0px 8px;
    `,
    middle: () => css`
      border: 1px solid;
      border-color: ${STATIC_COLORS.gray[300]};
      border-right: none;
      border-left: none;
    `,
    right: () => css`
      border: 1px solid;
      border-color: ${STATIC_COLORS.gray[300]};
      border-radius: 0px 8px 8px 0px;
    `,
  });

export const ButtonGroupItem = styled.button<IButtonGroupItemStyleProps>`
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  max-width: fit-content;
  background: ${STATIC_COLORS.base.white};
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:disabled,
  &[disabled]:hover,
  &:focus,
  &:hover {
    text-decoration: none !important;
  }
  &:hover,
  &:focus {
    ${({ theme }) => {
      return `background-color: ${theme.colors.primaryColor['-50']}`;
    }}
  }
  &:hover {
    ${({ theme }) => `color: ${theme.colors.primaryColor['-900']}`}
  }
  &:disabled {
    background-color: ${STATIC_COLORS.base.white};
    ${({ theme }) => `color: ${theme.colors.primaryColor['-300']}`}
  }
  ${({ $position }) => getBorderStyles($position)};
  ${({ theme }) => `color: ${theme.colors.primaryColor['-700']}`}

  ${({ $isActive, theme }) =>
    $isActive && `color: ${theme.colors.primaryColor['-700']}; background: ${theme.colors.primaryColor['-50']}`}
`;

export const StyledText = styled(Text)<{ $isActive: boolean }>`
  ${({ $isActive, theme }) => $isActive && `font-weight: 500 !important; color: ${theme.colors.primaryColor['-700']}`}
`;

export const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const ButtonsGroup = styled.div`
  display: flex;
`;

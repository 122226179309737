import { styled } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';

export const FooterWidgetLoggedContainer = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  background-color: var(--color-primary-800);
`;

export const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 375px;
  padding: 9px 0 7px;
  margin: 0 auto;

  .footer-tooltip-content {
    max-width: 204px;
    top: -60px !important;
  }
`;

export const Link = styled.button<{ $isHidden: boolean; $isActive: boolean }>`
  all: unset;
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;

  svg,
  img {
    width: 24px;
    height: 24px;
  }

  img {
    object-fit: cover;
    border-radius: 50%;
  }

  svg path,
  span {
    fill: var(--color-primary-800);
    color: ${({ $isActive }) => ($isActive ? STATIC_COLORS.base.white : 'var(--color-primary-200)')};
    stroke: ${({ $isActive }) => ($isActive ? STATIC_COLORS.base.white : 'var(--color-primary-200)')};
  }
`;

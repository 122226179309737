import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { apiUrlGetQuizzes } from '@lib/core/quizzes/slices/urls';
import { TQuiz } from '@lib/core/quizzes/types';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';

export interface IQuizzesSlice {
  isLoading: boolean;
  data: TQuiz[];
  error: string;
}
const initialState: IQuizzesSlice = {
  data: undefined,
  error: '',
  isLoading: false,
};

export const actionGetQuizzes = createTypedAsyncThunk('quizzes/get', async () => await request(apiUrlGetQuizzes()));

export const quizzesSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetQuizzes.pending, state => {
      state.error = '';
      state.isLoading = true;
    });
    builder.addCase(actionGetQuizzes.fulfilled, (state, action: PayloadAction<TQuiz[]>) => {
      const { payload } = action;
      state.isLoading = false;
      state.data = payload;
    });
    builder.addCase(actionGetQuizzes.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
  initialState,
  name: 'quizzes',
  reducers: {},
});

export default quizzesSlice.reducer;

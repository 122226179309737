import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { TProductCategory } from '@lib/core/products/types';
import { resetPriceRange } from '@lib/tools/filterManager/slices/rangeFilterSlice';
import { changeSearchText } from '@lib/tools/filterManager/slices/searchTextFilterSlice';
import { updateShowOnlyFilterValue } from '@lib/tools/filterManager/slices/showOnlyFilterSlice';
import { updateFilterType } from '@lib/tools/filterManager/slices/sublistFilterSlice';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';
import {
  FILTER_TYPE_CHARACTER_TOGGLE,
  FILTER_TYPE_PRICE_RANGE,
  FILTER_TYPE_WISHLIST,
  PRODUCT_CATEGORY_WINE,
  VARIANT_SET_EMBEDDED,
  VARIANT_SET_FULLPAGE,
  VARIANT_SET_JOURNAL,
  VH_VARIANTS,
} from '@lib/tools/shared/helpers/consts';

import { ReactComponent as SearchIcon } from '@components/web/src/assets/legacy/icons/icon_search.svg';
import Badge from '@components/web/src/atoms/Badge/Badge';
import Button from '@components/web/src/atoms/Buttons/Button';
import InputField from '@components/web/src/components/Input/InputFields';
import * as S from '@components/web/src/organisms/Filter/styles';

export type TFilterSectionVariant =
  | typeof VARIANT_SET_FULLPAGE
  | typeof VARIANT_SET_EMBEDDED
  | typeof VARIANT_SET_JOURNAL;

type Props = {
  handleOpenFilter?: (string) => void;
  handleCloseFilter?: (string) => void;
  handleCancelCharacter?: (string) => void;
  handleApplyFilter: () => void;
  isProductsRequestLoading?: boolean;
  itemsCount?: number;
  filterTags?: any;
  isFilterReady?: boolean;
  isSearchEnabled?: boolean;
  searchTextValue: string;
  productCategory: TProductCategory;
  isFilterOpened?: boolean;
  variant?: TFilterSectionVariant;
};
const FilterSection: FC<Props> = ({
  isSearchEnabled = true,
  handleCancelCharacter,
  searchTextValue,
  isFilterReady = false,
  handleOpenFilter,
  handleCloseFilter,
  handleApplyFilter,
  filterTags,
  productCategory = PRODUCT_CATEGORY_WINE,
  isFilterOpened = false,
  variant = VARIANT_SET_FULLPAGE,
}) => {
  const dispatch = useDispatch();
  const { searchPlaceHolderPlural, productCatalog: productCatalogMessagesCommon } = localeCommon;
  const { publishedTerms } = LocaleUtils;

  const searchPlaceHolderTextCommon = publishedTerms[searchPlaceHolderPlural[productCategory]?.id];
  const isJournalVariant = variant === VARIANT_SET_JOURNAL;

  return (
    <S.FilterSection $isFilterOpened={isFilterOpened} $variant={variant}>
      <S.InputSearchWrapper $hideElement={!isSearchEnabled}>
        <InputField
          icon={!isJournalVariant && <SearchIcon />}
          name="search-items"
          placeholder={searchPlaceHolderTextCommon}
          value={searchTextValue}
          onChange={e => dispatch(changeSearchText(e.target.value))}
        />
      </S.InputSearchWrapper>
      <S.FilterButtonSection $variant={variant}>
        <Button
          disabled={isFilterOpened || !isFilterReady}
          handleClick={isFilterOpened ? handleCloseFilter : handleOpenFilter}
          iconVariant="filter"
          size="sm"
          text={productCatalogMessagesCommon.filterButtonText}
          variant={VH_VARIANTS.PRIMARY}
        />
        {!!filterTags.length && (
          <S.AppliedFiltersContainer $variant={variant}>
            {filterTags.map((tag, idx) => {
              const { name, filterType, value, icon } = tag;
              return (
                <Badge
                  key={idx}
                  icon={icon}
                  slug={name}
                  text={name}
                  handleCloseClick={() => {
                    switch (filterType) {
                      case FILTER_TYPE_CHARACTER_TOGGLE:
                        handleCancelCharacter(value);
                        break;
                      case FILTER_TYPE_WISHLIST:
                        dispatch(updateShowOnlyFilterValue(FILTER_TYPE_WISHLIST));
                        break;
                      case FILTER_TYPE_PRICE_RANGE:
                        dispatch(resetPriceRange());
                        break;
                      default:
                        dispatch(updateFilterType({ filterItem: tag }));
                        break;
                    }
                    handleApplyFilter();
                  }}
                />
              );
            })}
          </S.AppliedFiltersContainer>
        )}
      </S.FilterButtonSection>
    </S.FilterSection>
  );
};

export default FilterSection;

import { FC } from 'react';

import { localeWidget } from '@lib/tools/locale/source/web/widget';

import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/LegalPages/CookiePolicyPage/styles';

const CookiePolicyPage: FC = () => {
  const { cookiePolicyPage } = localeWidget;
  const {
    mainTitle,
    mainDescription,
    whatAreCookiesTitle,
    whatAreCookiesParagraph1,
    whatAreCookiesParagraph2,
    whatAreCookiesParagraph3,
    whatAreCookiesParagraph4,
    whatAreCookiesParagraph5,
    whatAreCookiesParagraph6,
    whatAreCookiesParagraph7,
    whatAreCookiesParagraph8,
    whatAreCookiesParagraph9,
    whatAreCookiesParagraph10,
    whatAreCookiesParagraph11,
    whatAreCookiesParagraph12,
    whatAreCookiesGoogleChrome,
    whatAreCookiesMozillaFirefox,
    whatAreCookiesInternetExplorer,
    whatAreCookiesOpera,
    whatAreCookiesSafari,
    cookiesUsedTitle,
    cookiesUsedParagraph1,
    cookiesUsedParagraph2,
    cookiesUsedParagraph3,
    cookiesUsedParagraph4,
    cookiesUsedParagraph5,
    cookiesUsedParagraph6,
    cookiesUsedParagraph7,
    cookiesUsedParagraph8,
    cookiesUsedParagraph9,
    cookiesUsedParagraph10,
    thirdPartyCookiesAnalytics,
    serviceName,
    purpose,
    persistence,
    purposeGoogleAnalytics,
    persistenceGoogleAnalytics,
    cookiesUsedParagraph11,
    cookiesUsedParagraph12,
    cookiesUsedParagraph13,
    cookiesUsedParagraph14,
    cookiesUsedParagraph15,
    // puerpose google
    purposeGoogleAds1,
    purposeGoogleAds2,
    persistenceGoogleAds,
    purposeBrainLead,
    persistenceBrainLead,
    purposeFacebook1,
    purposeFacebook2,
    persistenceFacebook,
    cookiesUsedParagraph16,
    cookiesUsedInstagram,
    cookiesUsedLinkedin,
    cookiesUsedYoutube,
    // ammendment
    amendmentsUpdatesTitle,
    amendmentsUpdatesParagraph1,
  } = cookiePolicyPage;

  return (
    <S.CookiePolicyPageWrapper>
      <S.MainTitle>
        <Text fontFamily="Fraunces" size="h5" text={mainTitle} weight="semibold" />
      </S.MainTitle>
      <S.MainDescription>
        <Text size="body2" text={mainDescription} />
      </S.MainDescription>

      <S.Section>
        <S.SectionTitle>
          <Text size="subtitle2" text={whatAreCookiesTitle} weight="medium" />
        </S.SectionTitle>
        <Text size="body2" text={whatAreCookiesParagraph1} />
        <Text size="body2" text={whatAreCookiesParagraph2} />
        <Text size="body2" text={whatAreCookiesParagraph3} />
        <Text size="body2" text={whatAreCookiesParagraph4} />
        <Text size="body2" text={whatAreCookiesParagraph5} />
        <Text size="body2" text={whatAreCookiesParagraph6} />
        <Text size="body2" text={whatAreCookiesParagraph7} />
        <Text size="body2" text={whatAreCookiesParagraph8} />
        <Text size="body2" text={whatAreCookiesParagraph9} />
        <Text size="body2" text={whatAreCookiesParagraph10} />
        <Text size="body2" text={whatAreCookiesParagraph11} />
        <Text size="body2" text={whatAreCookiesParagraph12} />
        <Text size="body2" text={whatAreCookiesGoogleChrome} />
        <Text size="body2" text={whatAreCookiesMozillaFirefox} />
        <Text size="body2" text={whatAreCookiesInternetExplorer} />
        <Text size="body2" text={whatAreCookiesOpera} />
        <Text size="body2" text={whatAreCookiesSafari} />
      </S.Section>

      <S.Section>
        <S.SectionTitle>
          <Text size="subtitle2" text={cookiesUsedTitle} weight="medium" />
        </S.SectionTitle>
        <Text size="body2" text={cookiesUsedParagraph1} />
        <Text size="body2" text={cookiesUsedParagraph2} />
        <Text size="body2" text={cookiesUsedParagraph3} />
        <Text size="body2" text={cookiesUsedParagraph4} />
        <Text size="body2" text={cookiesUsedParagraph5} />
        <Text size="body2" text={cookiesUsedParagraph6} />
        <Text size="body2" text={cookiesUsedParagraph7} />
        <Text size="body2" text={cookiesUsedParagraph8} />
        <Text size="body2" text={cookiesUsedParagraph9} />
        <Text size="body2" text={cookiesUsedParagraph10} />

        <S.TableWrapper>
          <S.Table>
            <S.TableMainTitle>
              <Text size="subtitle2" text={thirdPartyCookiesAnalytics} weight="medium" />
            </S.TableMainTitle>
            <S.TableTitle>
              <Text size="body2" text={serviceName} />
            </S.TableTitle>
            <S.TableDescription>
              <Text size="body2" text="Google Analytics" weight="bold" />
              <Text size="body2" text={` https://marketingplatform.google.com/about/analytics/terms/us/ `} />
            </S.TableDescription>
            <S.TableTitle>
              <Text size="body2" text={purpose} />
            </S.TableTitle>
            <S.TableDescription>
              <Text size="body2" text={purposeGoogleAnalytics} />
            </S.TableDescription>
            <S.TableTitle>
              <Text size="body2" text={persistence} />
            </S.TableTitle>
            <S.TableDescription>
              <Text size="body2" text={persistenceGoogleAnalytics} />
            </S.TableDescription>
          </S.Table>
        </S.TableWrapper>
        <Text size="body2" text={cookiesUsedParagraph11} />
        <Text size="body2" text={cookiesUsedParagraph12} />
        <Text size="body2" text={cookiesUsedParagraph13} />
        <Text size="body2" text={cookiesUsedParagraph14} />
        <Text size="body2" text={cookiesUsedParagraph15} />

        <S.TableWrapper>
          <S.Table>
            <S.TableTitle>
              <Text size="body2" text={serviceName} />
            </S.TableTitle>
            <S.TableDescription>
              <Text size="body2" text="Google Ads" weight="bold" />
              <Text size="body2" text={` https://policies.google.com/technologies/ads?hl=en-US`} />
            </S.TableDescription>
            <S.TableTitle>
              <Text size="body2" text={purpose} />
            </S.TableTitle>
            <S.TableDescription>
              <Text size="body2" text={purposeGoogleAds1} />
              <Text size="body2" text={purposeGoogleAds2} />
            </S.TableDescription>
            <S.TableTitle>
              <Text size="body2" text={persistence} />
            </S.TableTitle>
            <S.TableDescription>
              <Text size="body2" text={persistenceGoogleAds} />
            </S.TableDescription>
          </S.Table>
          <S.Table>
            <S.TableTitle>
              <Text size="body2" text={serviceName} />
            </S.TableTitle>
            <S.TableDescription>
              <Text size="body2" text="BrainLead" weight="bold" />
              <Text size="body2" text={` https://brainlead.it/utilizzo-dei-cookies.php`} />
            </S.TableDescription>
            <S.TableTitle>
              <Text size="body2" text={purpose} />
            </S.TableTitle>
            <S.TableDescription>
              <Text size="body2" text={purposeBrainLead} />
            </S.TableDescription>
            <S.TableTitle>
              <Text size="body2" text={persistence} />
            </S.TableTitle>
            <S.TableDescription>
              <Text size="body2" text={persistenceBrainLead} />
            </S.TableDescription>
          </S.Table>
          <S.Table>
            <S.TableTitle>
              <Text size="body2" text={serviceName} />
            </S.TableTitle>
            <S.TableDescription>
              <Text size="body2" text="Facebook" weight="bold" />
              <Text size="body2" text={` https://m.facebook.com/policy.php`} />
            </S.TableDescription>
            <S.TableTitle>
              <Text size="body2" text={purpose} />
            </S.TableTitle>
            <S.TableDescription>
              <Text size="body2" text={purposeFacebook1} />
              <Text size="body2" text={purposeFacebook2} />
            </S.TableDescription>
            <S.TableTitle>
              <Text size="body2" text={persistence} />
            </S.TableTitle>
            <S.TableDescription>
              <Text size="body2" text={persistenceFacebook} />
            </S.TableDescription>
          </S.Table>
        </S.TableWrapper>
        <Text size="body2" text={cookiesUsedParagraph16} />
        <Text size="body2" text={cookiesUsedInstagram} />
        <Text size="body2" text={cookiesUsedLinkedin} />
        <Text size="body2" text={cookiesUsedYoutube} />
      </S.Section>
      <S.Section>
        <S.SectionTitle>
          <Text size="subtitle2" text={amendmentsUpdatesTitle} weight="medium" />
        </S.SectionTitle>
        <Text size="body2" text={amendmentsUpdatesParagraph1} />
      </S.Section>
    </S.CookiePolicyPageWrapper>
  );
};

export default CookiePolicyPage;

import styled, { css } from 'styled-components';

import { Text } from '@components/web/src/foundations/Text/Text';

export const InterestModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 24px 16px;
  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      padding: 16px 40px 24px 40px;
    `}
  button {
    margin-left: auto;
  }
`;

export const Title = styled(Text)`
  margin-bottom: 4px;
`;

import { FC } from 'react';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import LoginForm from '@components/web/src/organisms/Authentication/KioskLoginForm/LoginForm';
import VinhoodLogo from '@components/web/src/shared/Logo';

interface KioskLoginComponentProps {
  onSubmit: (values: { email: string; password: string }) => void;
  isCaptchaDisabled: boolean;
  recaptchaRef: Record<string, any>;
  handleLogin: (recaptchaValue: string) => void;
}

const KioskLoginComponent: FC<KioskLoginComponentProps> = ({
  onSubmit,
  isCaptchaDisabled,
  recaptchaRef,
  handleLogin,
}) => {
  const handleSubmitForm = ({ ...rest }) => {
    onSubmit(
      rest as {
        email: string;
        password: string;
      },
    );
  };

  return (
    <Flexbox align="center" direction="column" gap={32} justify="center" padding="24px">
      <Flexbox isFullWidth>
        <VinhoodLogo />
      </Flexbox>

      <LoginForm
        handleLogin={handleLogin}
        isCaptchaDisabled={isCaptchaDisabled}
        recaptchaRef={recaptchaRef}
        onSubmit={handleSubmitForm}
      />
    </Flexbox>
  );
};

export default KioskLoginComponent;

import { FC, Fragment } from 'react';

import { IParsedServiceTerm } from '@lib/core/serviceTerms/types';

import { IModalOption } from '@components/web/src/app/Modals/modalTypes';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import ModalOptionItem from '@components/web/src/templates/Modals/ModalOptionItem/ModalOptionItem';

interface IProps {
  options: IModalOption[] | IParsedServiceTerm[];
  flexGap?: number;
  onToggle: (option: IModalOption) => void;
  hideToggleText?: boolean;
  isManageModalVariant?: boolean;
}

export const GeneralTermsCheckboxesComponent: FC<IProps> = ({
  options,
  isManageModalVariant,
  flexGap = 16,
  onToggle,
  hideToggleText,
}) => {
  return (
    <Flexbox align="flex-start" direction="column" gap={flexGap}>
      {options.map((option, idx) =>
        option ? (
          <Fragment key={idx}>
            <ModalOptionItem
              key={option.category}
              data={option}
              hideToggleText={hideToggleText}
              isManageModalVariant={isManageModalVariant}
              onToggle={onToggle}
            />
          </Fragment>
        ) : null,
      )}
    </Flexbox>
  );
};

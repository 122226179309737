import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@lib/core/service/types/appStateType';

const selectAuthState = (state: RootState) => state.users.auth;

/**
 * @returns is user's auth data loading
 */
export const selectIsAuthDataLoading = createSelector([selectAuthState], state => state?.isLoading);

/**
 * @returnsthe user's access token
 */
export const selectAuthToken = createSelector([selectAuthState], state => state?.data?.access_token);

/**
 * @returns the user's access error
 */
export const selectAuthError = createSelector([selectAuthState], state => state.error);

/**
 * @returns {string} the user's refresh token.
 */
export const selectUserRefreshToken = createSelector([selectAuthState], state => state.data?.refresh_token);

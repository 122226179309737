import { useSelector } from 'react-redux';

import {
  selectIsServiceInstanceFeatureEnabled,
  selectIsServiceInstanceRetailerDataLoading,
  selectServiceInstanceProductCategory,
  selectServiceInstanceRetailerLocationId,
  selectServiceInstanceRetailerLocationSlug,
  selectServiceInstanceRetailerLogo,
  selectServiceInstanceRetailerSlug,
} from '@lib/core/service/selectors/serviceInstance';

type IUseServiceInstance = {
  serviceInstanceProductCategory: string;
  serviceInstanceRetailerLocationId: string;
  serviceInstanceRetailerSlug: string;
  serviceInstanceRetailerLogo: string;
  serviceInstanceRetailerLocationSlug: string;
  isServiceInstanceRetailerDataLoading: boolean;
  isServiceInstanceFeatureEnabled: boolean;
};

export const useServiceInstance = (): IUseServiceInstance => ({
  isServiceInstanceFeatureEnabled: useSelector(selectIsServiceInstanceFeatureEnabled),
  isServiceInstanceRetailerDataLoading: useSelector(selectIsServiceInstanceRetailerDataLoading),
  serviceInstanceProductCategory: useSelector(selectServiceInstanceProductCategory),
  serviceInstanceRetailerLocationId: useSelector(selectServiceInstanceRetailerLocationId),
  serviceInstanceRetailerLocationSlug: useSelector(selectServiceInstanceRetailerLocationSlug),
  serviceInstanceRetailerLogo: useSelector(selectServiceInstanceRetailerLogo),
  serviceInstanceRetailerSlug: useSelector(selectServiceInstanceRetailerSlug),
});

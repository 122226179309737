import { TProductInstance } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';

interface IProps {
  productInstanceData: TProductInstance;
  isEnableVusionAddon: boolean;
  isShowProductLocationAddon: boolean;
  isEnableLocationMapAddon: boolean;
}

export const parseFindProduct = ({
  productInstanceData,
  isEnableVusionAddon,
  isShowProductLocationAddon,
  isEnableLocationMapAddon,
}: IProps) => {
  const { publishedTerms } = LocaleUtils;
  const { vusionAddonDefaultDescription } = localeCommon.commonMessages;

  const vusionAddonDefaultDescriptionText = publishedTerms[vusionAddonDefaultDescription.id];

  const { ecommerce_id: productEcommerceId, description, tags } = productInstanceData || {};
  const productLocationMap = tags?.image || '';
  const productLocationDescription =
    (isEnableVusionAddon && productEcommerceId ? description || vusionAddonDefaultDescriptionText : description) || '';

  const isFindProductFunctionalityEnable =
    isShowProductLocationAddon || isEnableLocationMapAddon || isEnableVusionAddon;

  const isFindProductButtonEnable =
    (isShowProductLocationAddon && !!productLocationDescription) ||
    (isEnableLocationMapAddon && productLocationMap) ||
    (isEnableVusionAddon && productEcommerceId);

  return {
    isFindProductButtonEnable,
    isFindProductFunctionalityEnable,
    productEcommerceId,
    productLocationDescription,
    productLocationMap,
  };
};

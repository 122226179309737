import { styled } from 'styled-components';

import { SCREEN_SIZE, STATIC_GRADIENTS } from '@components/web/src/foundations';

export const SplashScreenContainer = styled.div`
  position: absolute;
  inset: 0;
  z-index: 999999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
  height: 100vh !important;
  background: ${STATIC_GRADIENTS.primaryGradient};

  img {
    width: 100%;
    max-width: 251px;
    max-height: 63px;
    object-fit: contain;
  }

  @media (min-width: ${SCREEN_SIZE.vhApp}) {
    width: ${SCREEN_SIZE.vhApp};
    margin: auto;
    height: 86vh !important;
  }
`;

import { createSelector } from 'reselect';

import { PRODUCT_CATEGORY_NONE } from '@lib/core/service/consts';
import { RootState } from '@lib/core/service/types/appStateType';
import { selectUserEmail } from '@lib/core/users/selectors/user';
import { IUserSlice } from '@lib/core/users/slices/user';
import { USER_ROLE_KIOSK } from '@lib/core/users/utils/consts';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

/**
 * @returns the whole user state
 */
export const selectUserState = (state): IUserSlice => state.users.userInstance || {};

/**
 * @returns the kiosk user's retailer location id. Used for kiosks
 */

export const selectKioskUserRetailerLocationId = createSelector(
  [selectUserState],
  user => user?.data?.retailer_location?.identifier || '',
);

/**
 * @returns the kiosk user's retailer location slug. Used for kiosks
 */
export const selectKioskUserRetailerLocationSlug = createSelector(
  [selectUserState],
  user => user?.data?.retailer_location?.slug || '',
);

/**
 * ! Needs improvement
 * @returns the kiosk user's product category from the email address of the kiosk.
 */

export const selectKioskUserProductCategory = (state: RootState) => {
  const email = selectUserEmail(state);

  const kioskProductCategories = [PRODUCT_CATEGORY_WINE, PRODUCT_CATEGORY_COFFEE, PRODUCT_CATEGORY_BEER];
  return kioskProductCategories.find(pc => email.includes(`-${pc}`)) || PRODUCT_CATEGORY_NONE;
};

/**
 * @returns kiosk user retailer slug
 */
export const selectKioskUserRetailerSlug = createSelector(
  [selectUserState],
  user => user?.data?.initial_retailer?.slug || '',
);

/**
 * @returns if user is kiosk admin
 */

export const selectIsUserKioskAdmin = createSelector([selectUserState], user => user?.data?.role === USER_ROLE_KIOSK);

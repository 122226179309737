import { styled } from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const LanguageDropdownList = styled(Flexbox)`
  background: ${STATIC_COLORS.base.white};
  box-shadow: ${STATIC_SHADOWS.lg};
  position: absolute;
  top: 43px;
  left: 0;
  border-radius: 0 0 8px 8px;
  min-width: fit-content;
`;

export const LanguageListWrapper = styled(Flexbox)`
  padding: 6px 8px;
  cursor: pointer;
`;

import { createSlice } from '@reduxjs/toolkit';

import { apiUrlGetCharacters, apiUrlGetRetailerCharacter } from '@lib/core/characters/slices/urls';
import { TCharacter } from '@lib/core/characters/types';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';

export interface ICharactersSlice {
  data: TCharacter[];
  isLoading: boolean;
}

export const initialState: ICharactersSlice = {
  data: [],
  isLoading: false,
};

export const actionGetCharacters = createTypedAsyncThunk('characters/get', async () => {
  return await request(apiUrlGetCharacters());
});

export const actionGetRetailerCharacter = createTypedAsyncThunk(
  'characters/retailerCharacters/get',
  async ({ characterId }: { characterId: string }) => {
    return characterId ? request(apiUrlGetRetailerCharacter({ characterID: characterId })).then(json => json[0]) : {};
  },
);

export const index = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetCharacters.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(actionGetCharacters.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(actionGetCharacters.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
  },
  initialState,
  name: 'characters',
  reducers: {},
});

export default index.reducer;

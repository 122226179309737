import { FC } from 'react';

import * as S from '@components/web/src/atoms/TestAnswer/TestAnswerAnimationIcon/styles';

interface IProps {
  className?: string;
  isTeal?: boolean;
}

const TestAnswerAnimationIcon: FC<IProps> = ({ className, isTeal = false }) => {
  return <S.TestAnswerIcon $isTeal={isTeal} className={className} />;
};

export default TestAnswerAnimationIcon;

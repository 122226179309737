import { useSelector } from 'react-redux';

import { selectIsRecyclingLoading, selectRecyclingData } from '@lib/core/productAttributes/selectors';
import { TRecycling } from '@lib/core/productAttributes/types';

interface IProps {
  isRecyclingLoading: boolean;
  recyclingData: TRecycling[];
}

export const useRecycling = (): IProps => ({
  isRecyclingLoading: useSelector(selectIsRecyclingLoading),
  recyclingData: useSelector(selectRecyclingData),
});

import classNames from 'classnames';

import { ReactComponent as CheckCircle } from '@components/web/src/assets/icons/icon_check_circle_green.svg';
import { ReactComponent as WarningIcon } from '@components/web/src/assets/legacy/b2c/icons/warning_icon.svg';
import CloseButton from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';

type Props = {
  onCrossClick: () => void;
  hideLeftIcon?: boolean;
  icon?: 'check' | 'warning';
};

const AppModalHeader = ({ onCrossClick, hideLeftIcon = false, icon = 'check' }: Props) => {
  const icons = {
    check: <CheckCircle />,
    warning: <WarningIcon />,
  };
  return (
    <div className={classNames('app-modal-header-container', { crossOnly: hideLeftIcon })}>
      {!hideLeftIcon && <div>{icons[icon]}</div>}
      <div className="cursor-pointer">
        <CloseButton handleClick={onCrossClick} />
      </div>
    </div>
  );
};

export default AppModalHeader;

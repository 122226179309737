import { css, styled } from 'styled-components';

import { TTabIcon } from '@components/web/src/atoms/Tabs/TabButton/TabButton';
import { TTabsVariant } from '@components/web/src/atoms/Tabs/Tabs/Tabs';
import { DEFAULT_COLOR_THEME, STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

export const StyledTabButton = styled.button<{
  $isActive: boolean;
  $tabIcon: TTabIcon;
  $tabsVariant?: TTabsVariant;
}>`
  all: unset;
  z-index: 2;
  display: flex;
  align-items: center;
  max-width: fit-content;
  max-height: 38px;
  padding: 0 8px 12px;
  cursor: pointer;
  position: relative;
  ${({ $tabsVariant }) =>
    $tabsVariant === 'fullSize' &&
    css`
      flex-grow: 1;
      flex-basis: 0;
      justify-content: center;
      max-width: 100%;
    `}

  ${({ theme }) => css`
    padding: ${theme?.isKioskRootElementType ? '0 4px 16px' : '0 4px 12px'};
  `}

  ${({ $isActive, $tabIcon }) =>
    $isActive &&
    css`
      &::after {
        content: '';
        z-index: 2;
        position: absolute;
        bottom: 0;
        left: 0;
        min-height: 2px;
        width: 100%;
        background: ${STATIC_COLORS.productColors?.[$tabIcon]?.[500] || DEFAULT_COLOR_THEME.primaryColor[-700]};
      }
    `}
`;

export const Icon = styled.div<{ $isActive: boolean }>`
  width: 24px;
  height: 24px;
  margin-right: 8px;

  svg {
    width: 100%;
    height: 100%;

    path {
      stroke: ${({ $isActive }) => ($isActive ? DEFAULT_COLOR_THEME.primaryColor[-700] : 'inherit')};
    }
  }
`;

export const StyledText = styled(Text)<{ $isActive: boolean }>`
  text-transform: capitalize;
  color: ${({ $isActive }) => ($isActive ? DEFAULT_COLOR_THEME.primaryColor[-700] : STATIC_COLORS.gray['600'])};
  font-weight: ${({ $isActive }) => $isActive && 600};
`;

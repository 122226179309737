import { FC } from 'react';

import { FLAG_MEDIA_DOMAIN } from '@lib/tools/shared/helpers/consts';

import GlobeIcon from '@components/web/src/assets/icons/icon_globe.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/LanguageSwitcher/styles';
import CaretDown from '@components/web/src/shared/Icons/CaretDown';

interface IProps {
  languageCode: string;
  languageText: string;
  showLanguagesList?: boolean;
  isListItem?: boolean;
  handleSelectLanguage: (code: string) => void;
  handleToggleLanguagesList?: () => void;
}

const LanguageItem: FC<IProps> = ({
  languageCode,
  languageText,
  isListItem = false,
  showLanguagesList = false,
  handleSelectLanguage,
  handleToggleLanguagesList,
}) => {
  const flagCode = languageCode === 'en' ? 'GB' : languageCode;
  const flagSrc = !isListItem ? GlobeIcon : `${FLAG_MEDIA_DOMAIN}${flagCode.toUpperCase()}.png`;

  return (
    <S.LanguageItemContainer
      $isListItem={isListItem}
      $showLanguageList={showLanguagesList}
      align="center"
      gap={isListItem ? 10 : 8}
      justify="flex-start"
      onClick={() => {
        handleSelectLanguage(languageCode);
        if (handleToggleLanguagesList) handleToggleLanguagesList();
      }}
    >
      <Image alt="flag" height="20px" src={flagSrc} width="20px" />
      <Text color={STATIC_COLORS.gray['900']} size="body2" text={languageText || languageCode} weight="medium" />
      {!isListItem && <CaretDown fillColor={STATIC_COLORS.base.black} />}
    </S.LanguageItemContainer>
  );
};

export default LanguageItem;

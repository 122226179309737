import cn from 'classnames';
import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import { ReactComponent as SearchIcon } from '@components/web/src/assets/legacy/icons/icon_search.svg';
import InputField from '@components/web/src/components/Input/InputFields';

type Props = {
  searchHandler: (searchText: string) => void;
  productCategory: TProductCategory;
  placeholder: string;
  value: string;
  isNoRecipesFound: boolean;
  isApplicationKiosk: boolean;
};

const RecipeSearchHeader: FC<Props> = ({
  searchHandler,
  productCategory,
  placeholder,
  value,
  isNoRecipesFound,
  isApplicationKiosk,
}) => {
  const { recipeSearchHeader } = localeCommon;

  return (
    <div className="recipe-search-header-container">
      <div className={cn('content-container', { isLargeVariant: isApplicationKiosk })}>
        <p className="subheading">
          <LocaleFragment message={recipeSearchHeader[productCategory]} />
        </p>
        <p className="title">
          <LocaleFragment message={recipeSearchHeader.title} />
        </p>
      </div>
      <div className="search-container">
        <InputField
          icon={<SearchIcon />}
          name="search-recipe"
          placeholder={placeholder}
          value={value}
          onChange={e => searchHandler(e.target.value)}
        />
      </div>
      {isNoRecipesFound && (
        <p className="no-recipes-found-message">
          <LocaleFragment message={recipeSearchHeader.noRecipesFound} />
        </p>
      )}
    </div>
  );
};

export default RecipeSearchHeader;

import { FC, ReactElement, useRef, useState } from 'react';

import useOutsideClick from '@lib/tools/views/hooks/useOutsideClick';

type Props = {
  content: string[];
  children: ReactElement<any, any>;
};

const BadgesTooltip: FC<Props> = ({ children, content }) => {
  const ref = useRef();

  const [isToolTipActive, setIsToolTipActive] = useState(false);

  const hideTip = () => setIsToolTipActive(false);
  const toggleTip = () => setIsToolTipActive(current => !current);

  useOutsideClick(ref, hideTip);

  return (
    <div
      ref={ref}
      className="tags-tooltip-container"
      role="button"
      tabIndex={0}
      onClick={toggleTip}
      onFocus={toggleTip}
      onKeyDown={toggleTip}
    >
      {children}
      {isToolTipActive && (
        <div className="tagsToolTip">
          <div className="tooltip-content">
            {content.map((item, idx) => (
              <span key={`${item}${idx}`} className="content-item">
                {item}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BadgesTooltip;

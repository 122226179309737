import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import {
  GPRL_CHARACTER_QUERY,
  PRODUCT_REQUEST_FOR_HOME_PAGE,
  PRODUCT_REQUEST_FOR_RESULT_PAGE,
} from '@lib/core/service/consts';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import {
  CHARACTERISTICS,
  FILTER_TYPE_ORDERING,
  FORMAT,
  ORIGINS,
  PRICE,
  PRODUCT_ALL,
  PRODUCT_CATEGORY_QUERY,
  STYLE,
} from '@lib/tools/shared/helpers/consts';

export type ExtendedAllProductCategoryCode = TProductCategory | typeof PRODUCT_ALL;

export type ICatalogFilterDataKeys =
  | typeof FILTER_TYPE_ORDERING
  | typeof CHARACTERISTICS
  | typeof FORMAT
  | typeof PRICE
  | typeof ORIGINS
  | typeof STYLE;

export type IProductsResponse = {
  count: number;
  results: TProductInstance[];
  next: string;
  previous: string;
  stats: any;
  featuredProducts?: TProductInstance[];
};

export interface IFetchProductsOptions {
  params: {
    offset?: number;
    limit?: number;
    [FILTER_TYPE_ORDERING]?: string;
    [PRODUCT_CATEGORY_QUERY]?: TProductCategory;
  };
  isPagination?: boolean;
  shouldShuffleResponse?: boolean;
}

export interface IFetchProductsLimitedOptions {
  params: {
    [GPRL_CHARACTER_QUERY]: string;
  };
}

export type IProductsRequestMeta = {
  arg: IFetchProductsOptions;
};

export type IB2CProductsFetchedPage = typeof PRODUCT_REQUEST_FOR_HOME_PAGE | typeof PRODUCT_REQUEST_FOR_RESULT_PAGE;

export enum ThirdPartyConnectModalScenario {
  WISHLIST = 'wishlist',
  LOCATION = 'location',
  RATE5 = 'rate5',
  RATE4 = 'rate4',
}
export interface IThirdPartyNewsletterTexts {
  titleText: ILocaleText;
  descriptionText: string;
  acceptBtnText: ILocaleText;
  rejectBtnText: ILocaleText;
}

export type ThirdPartyConnectModalShown = {
  [key in ThirdPartyConnectModalScenario]: boolean;
};

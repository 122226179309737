import { FC, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { IThirdPartyNewsletterTexts } from '@lib/core/service/types/interface';

import ThirdPartyIcon from '@components/web/src/assets/icons/third_party_newsletter_icon.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/ThirdPartyNewsletter/styles';
import ThirdPartyNewsletterSticky from '@components/web/src/organisms/ThirdPartyNewsletter/ThirdPartyNewsletterSticky';

interface IProps {
  retailerName: string;
  withMarginTop?: boolean;
  handleThirdPartyNewsletter: (value: boolean) => void;
  thirdPartyNewsletterTexts: IThirdPartyNewsletterTexts;
}

const ThirdPartyNewsletter: FC<IProps> = ({
  handleThirdPartyNewsletter,
  retailerName,
  withMarginTop,
  thirdPartyNewsletterTexts,
}) => {
  const { titleText, acceptBtnText, rejectBtnText, descriptionText } = thirdPartyNewsletterTexts;

  const [isLeavingFromTop, setIsLeavingFromTop] = useState(false);
  const { ref: ThirdPartyComponentRef, inView } = useInView({
    delay: 100,
    onChange: (isInView, entry) => {
      setIsLeavingFromTop(!isInView && entry.boundingClientRect.top < 0);
    },
    rootMargin: '-48px',
    threshold: 0,
    trackVisibility: true,
  });

  const shouldShowStickyVariant = !inView && isLeavingFromTop;

  return (
    <>
      <S.ThirdPartyNewsletterContainer ref={ThirdPartyComponentRef} align="center" justify="center">
        <S.ThirdPartyNewsletterWrapper align="center" direction="column" gap={16} justify="center">
          <Image alt="Third party newsletter icon" height="57px" src={ThirdPartyIcon} width="55px" />

          <S.TitleTextWrapper>
            <Text
              color={STATIC_COLORS.base.white}
              localeOptions={{ retailerName }}
              size="subtitle2"
              text={titleText}
              weight="bold"
            />
          </S.TitleTextWrapper>

          <S.BtnWrapper>
            <S.AcceptBtn
              fontSize="subtitle2"
              size="md"
              text={acceptBtnText}
              textWeight="medium"
              variant="light"
              onClick={() => handleThirdPartyNewsletter(true)}
            />
            <S.RejectBtn
              fontSize="subtitle2"
              size="md"
              text={rejectBtnText}
              textWeight="medium"
              variant="primary"
              onClick={() => handleThirdPartyNewsletter(false)}
            />
          </S.BtnWrapper>

          <Text color={STATIC_COLORS.base.white} size="body3" text={descriptionText} />
        </S.ThirdPartyNewsletterWrapper>
      </S.ThirdPartyNewsletterContainer>
      {shouldShowStickyVariant && (
        <ThirdPartyNewsletterSticky
          handleThirdPartyNewsletter={handleThirdPartyNewsletter}
          retailerName={retailerName}
          thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
          withMarginTop={withMarginTop}
        />
      )}
    </>
  );
};

export default ThirdPartyNewsletter;

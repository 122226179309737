import { createSelector } from 'reselect';

import { selectCharactersByIdentifiers } from '@lib/core/characters/selectors';
import { TCharacter } from '@lib/core/characters/types';
import { RootState } from '@lib/core/service/types/appStateType';
import { IUserCharacterSlice } from '@lib/core/users/slices/characters';
import RouteUtils from '@lib/tools/routes';

/**
 * @returns the whole user characters state
 */
export const selectUserCharactersState = (state): IUserCharacterSlice => state.users.characters;

/**
 * @returns user characters extended with full character data
 */
export const selectUserCharacters = createSelector(
  [selectUserCharactersState, selectCharactersByIdentifiers],
  (userCharactersState, charactersByIdentifiers) => {
    return userCharactersState?.data.map(({ character }) => ({
      ...character,
      ...charactersByIdentifiers[character?.identifier],
    }));
  },
);

/**
 * @returns array of all user characters Ids
 */
export const selectUserCharactersIds = createSelector([selectUserCharactersState], userCharactersState =>
  userCharactersState?.data.map(({ character }) => character?.identifier),
);

/**
 * @returns user characters for category
 */
export const selectUserCharactersByCategory = createSelector([selectUserCharacters], userCharactersArray => {
  const userCharacters = {};
  userCharactersArray.forEach(characterData => {
    const { product_category: pc = '' } = characterData || {};
    if (userCharacters[pc]) {
      userCharacters[pc].push(characterData);
    } else {
      userCharacters[pc] = [characterData];
    }
  });
  return userCharacters || {};
});

/**
 * @returns user best matches character for category
 */
export const selectUserBestMatchCharacterByProductCategory = createSelector(
  [selectUserCharactersByCategory],
  userCharactersByCategory => {
    const userCharacters: Record<string, TCharacter> = {};
    Object.keys(userCharactersByCategory).forEach(pc => {
      userCharacters[pc] = userCharactersByCategory[pc]?.[userCharactersByCategory[pc].length - 1];
    });
    return userCharacters;
  },
);

/**
 * @returns if user has any character (completed at least 1 test)
 */
export const selectIsUserHasAnyCharacter = createSelector(
  [selectUserCharacters],
  userCharacters => !!userCharacters.length,
);

/**
 * @returns if user has character for retailer where we have only 1 product type
 */
export const selectIsUserHasCharacterForCurrentProductCategory = createSelector(
  [
    selectUserBestMatchCharacterByProductCategory,
    (state: RootState) => state?.service?.productCategory || RouteUtils.getProductCategory(),
  ],
  (userCharacters, productCategory) => {
    return !!userCharacters[productCategory] || false;
  },
);

/**
 * @returns if user characters are loading
 */
export const selectIsCharactersAreLoading = createSelector(
  [selectUserCharactersState],
  userCharactersState => !!userCharactersState.isLoading,
);

/**
 * @returns user characters for current product category
 */
export const selectBestMatchCharacterForServiceProductCategory = createSelector(
  [
    selectUserBestMatchCharacterByProductCategory,
    (state: RootState) => state?.service?.productCategory || RouteUtils.getProductCategory(),
  ],
  (userBestMatchCharacterByCategory, productCategory) => userBestMatchCharacterByCategory[productCategory],
);

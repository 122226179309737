import { useRef } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

interface IDataChangedEffect {
  /** Data that must be passed to the tracking method */
  data: any;
  /** Condition that must be `true` for the tracking method to trigger */
  trackCondition: boolean;
  /** The tracking method that needs to trigger */
  effect: () => void;
  /** Dependency array as if it was a normal `useEffect` call */
  deps: any[];
}

/**
 * Triggers `effect` only when `data` actually changes.
 *
 * Use this hook when you want to trigger a tracking method only when `<some-data>Loading` is `false`,
 * but avoid unnecessary triggers when `data` doesn't actually change.
 */
export const useDataChangedEffect = ({ data, trackCondition, effect, deps }: IDataChangedEffect) => {
  const dataChanged = useRef(false);

  useDeepCompareEffect(() => {
    dataChanged.current = true;
  }, [data]);

  useDeepCompareEffect(() => {
    if (trackCondition && dataChanged.current) {
      effect();
      dataChanged.current = false;
    }
  }, [trackCondition, effect, ...deps]);
};

import { FC, ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { prependBasename } from '@lib/core/service/utils';
import { SERVICE_TERMS_CATEGORIES } from '@lib/core/serviceTerms/consts';
import { useServiceTerms } from '@lib/core/serviceTerms/hooks/useServiceTerms';
import { useUser } from '@lib/core/users/hooks';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { useModals } from '@lib/tools/modals/hooks';
import { MODALS } from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import { BasicServiceTermsModal } from '@components/web/src/templates/Modals/BasicServiceTermsModal/BasicServiceTermsModal';
import FoodPreferencesModal from '@components/web/src/templates/Modals/FoodPreferencesModal/FoodPreferencesModal';
import InterestsModal from '@components/web/src/templates/Modals/InterestsModal/InterestsModal';
import ManageModal from '@components/web/src/templates/Modals/ManageModal/ManageModal';

type Props = {
  children: ReactElement;
};

export const ModalsHOC: FC<Props> = ({ children }) => {
  const {
    closeAllModals,
    openModal,
    isBasicServiceTermsModalOpened,
    isFoodPreferencesModalOpened,
    isInterestsModalOpened,
    isManageModalOpened,
  } = useModals();

  const navigate = useNavigate();

  const {
    updateServiceTerms,
    serviceTermsByCategory: { cookie: { is_selected: isUserAllowedUseLocalStorage } = {} },
  } = useServiceTerms();
  const { userId } = useUser();

  const {
    b2cModalsHoc: { deactivateAccountTitle, deactivateAccountDesc, deleteAccountTitle, deleteAccountDesc },
  } = localeApp;

  const accountOptions = {
    deactivateaccount: {
      category: 'deactivateaccount',
      is_always_active: false,
      is_selected: false,
      text: deactivateAccountDesc,
      title: deactivateAccountTitle,
    },
    deleteaccount: {
      category: 'deleteaccount',
      is_always_active: false,
      is_selected: false,
      text: deleteAccountDesc,
      title: deleteAccountTitle,
    },
  };

  useEffect(() => {
    if (!isUserAllowedUseLocalStorage && !userId) {
      openModal(MODALS.TERMS_OF_SERVICE_BASIC_MODAL);
    }
  }, []);

  const handleRejectOptionalBasicServiceTerms = () => {
    updateServiceTerms([{ category: SERVICE_TERMS_CATEGORIES.cookie, is_selected: true }]);
  };

  const handleOpenGeneralServiceTermsPage = () => {
    navigate(prependBasename(PAGES.vinhood.legal.generalServiceTerms));
    closeAllModals();
  };
  const handleAcceptAllBasicServiceTerms = () => {
    updateServiceTerms([
      { category: SERVICE_TERMS_CATEGORIES.cookie, is_selected: true },
      { category: SERVICE_TERMS_CATEGORIES.profiling, is_selected: true },
    ]).then(() => closeAllModals());
  };
  const handleManageModalConfirmButton = values => {
    console.info(values);
  };
  return (
    <>
      <>
        {isBasicServiceTermsModalOpened && (
          <BasicServiceTermsModal
            handleAcceptAllBasicServiceTerms={handleAcceptAllBasicServiceTerms}
            handleOpenGeneralServiceTermsPage={handleOpenGeneralServiceTermsPage}
            handleRejectOptionalBasicServiceTerms={handleRejectOptionalBasicServiceTerms}
            isModalOpen={isBasicServiceTermsModalOpened}
          />
        )}
        {isFoodPreferencesModalOpened && (
          <FoodPreferencesModal hideModal={closeAllModals} isModalOpen={isFoodPreferencesModalOpened} />
        )}
        {isInterestsModalOpened && <InterestsModal hideModal={closeAllModals} isModalOpen={isInterestsModalOpened} />}
        {isManageModalOpened && (
          <ManageModal
            accountOptions={accountOptions}
            handleCancelButton={closeAllModals}
            handleConfirmButton={handleManageModalConfirmButton}
            hideModal={closeAllModals}
            isModalOpen={isManageModalOpened}
          />
        )}
      </>
      <>{children}</>
    </>
  );
};

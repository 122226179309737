import { useDispatch, useSelector } from 'react-redux';

import { isApplicationKiosk } from '@lib/core/service/consts';
import { selectIsThirdPartyConnectModalShown } from '@lib/core/service/selectors';
import { setThirdPartyConnectModalOpenScenario } from '@lib/core/service/slices';
import { ThirdPartyConnectModalScenario } from '@lib/core/service/types/interface';
import {
  selectWishlistProductInstanceData,
  selectWishlistProductInstanceIds,
  selectWishlistProductListLoading,
} from '@lib/core/users/selectors/productList';
import { actionUpdateProductList } from '@lib/core/users/slices/productList';
import { useModals } from '@lib/tools/modals/hooks';
import { MODALS, PRODUCT_LIST_WISHLIST } from '@lib/tools/shared/helpers/consts';

export const useProductList = () => {
  const dispatch = useDispatch();
  const { openModal } = useModals();

  const isWishlistProductListLoading = useSelector(selectWishlistProductListLoading);
  const wishlistProductInstanceData = useSelector(selectWishlistProductInstanceData);
  const wishlistProductInstanceIds = useSelector(selectWishlistProductInstanceIds);
  const isThirdPartyConnectModalShown = useSelector(selectIsThirdPartyConnectModalShown);

  const handleUpdateWishlistProductList = (productId, productName = '') => {
    const shouldShowThirdPartyConnectModal =
      !wishlistProductInstanceIds.length && !isThirdPartyConnectModalShown.wishlist && !isApplicationKiosk;

    if (shouldShowThirdPartyConnectModal) {
      dispatch(
        setThirdPartyConnectModalOpenScenario({
          productName,
          scenario: ThirdPartyConnectModalScenario.WISHLIST,
        }),
      );
      openModal(MODALS.THIRD_PARTY_CONNECT_MODAL);
    }

    dispatch(
      actionUpdateProductList({
        listName: PRODUCT_LIST_WISHLIST,
        productId,
      }),
    );
  };

  return {
    handleUpdateWishlistProductList,
    isWishlistProductListLoading,
    wishlistProductInstanceData,
    wishlistProductInstanceIds,
  };
};

import React from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import BottomOverlay from '@components/web/src/app/OverlayBackground/BottomOverlay';
import Button from '@components/web/src/atoms/Buttons/Button';
import CloseButton from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

interface Props {
  isModalOpen: boolean;
  onHandleExit: () => void;
  retailerName: string;
  onClose: () => void;
  hideModal?: () => void;
  backgroundImage: string;
}

const ExitModalComponent: React.FC<Props> = ({
  isModalOpen,
  hideModal,
  onHandleExit,
  retailerName,
  onClose,
  backgroundImage,
}) => {
  const { exitAndGoToTheAppModal } = localeCommon;

  return (
    <ModalWrapper hideModal={hideModal} isOpen={isModalOpen} modalClassName="exit-modal-wrapper">
      <div className="exit-modal-container">
        <span aria-hidden className="close-icon">
          <CloseButton handleClick={onClose} variant="light" />
        </span>
        <BottomOverlay backgroundImage={backgroundImage} className="white-mask" />

        <div className="contents">
          <p className="subtitle">
            <LocaleFragment message={exitAndGoToTheAppModal.title} options={{ retailerName }} />
          </p>
          <p className="body-text">
            <LocaleFragment message={exitAndGoToTheAppModal.subtitle} />
          </p>
        </div>
        <div className="btn-wrapper">
          <Button
            className="btn cancel-btn"
            disabled={false}
            size="sm"
            text={exitAndGoToTheAppModal.cancelBtn}
            variant="light"
            onClick={onClose}
          />

          <Button
            className="btn exit-btn"
            disabled={false}
            size="sm"
            text={exitAndGoToTheAppModal.exitBtn}
            onClick={onHandleExit}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};
export const ExitModal = ExitModalComponent;

import { createSelector } from 'reselect';

import { RootState } from '@lib/core/service/types/appStateType';
import { IUserSlice } from '@lib/core/users/slices/user';
import { TUser, TUserGroup, TUserRole } from '@lib/core/users/types';
import { USER_ROLE_ANONYMOUS } from '@lib/core/users/utils/consts';

/**
 * @returns the whole user state
 */
export const selectUserState = (state): IUserSlice => state.users.userInstance || {};

/**
 * @returns whether the user is `identified` with Mixpanel or not.
 */
export const selectMixpanelUserDidIdentify = (state: RootState, defaultValue = false): boolean =>
  state?.tracking.mixPanelDidIdentify || defaultValue;

/**
 * @returns the user's role. `null` for anonymous user.
 */
export const selectUserRole: (state) => TUserRole = createSelector(
  [selectUserState],
  user => user?.data?.role || USER_ROLE_ANONYMOUS,
);

/**
 * @returns The user's groups in django admin. `[]` for user without groups.
 */
export const selectUserGroups: (state) => TUserGroup[] = createSelector(
  [selectUserState],
  user => user?.data?.groups || [],
);

/**
 * @returns user's email address
 */
export const selectUserEmail: (state) => string = createSelector([selectUserState], user => user?.data?.email || '');

/**
 * @returns user's first name
 */
export const selectUserFirstName: (state) => string = createSelector(
  [selectUserState],
  user => user?.data?.first_name || '',
);

/**
 * @returns user's last name
 */
export const selectUserLastName: (state) => string = createSelector(
  [selectUserState],
  user => user?.data?.last_name || '',
);

/**
 * @returns user's primary key
 */
export const selectUserId: (state) => number | null = createSelector([selectUserState], user => user?.data?.pk || null);

/**
 * @returns user session id
 */
export const selectUserSessionId: (state) => string = createSelector(
  [selectUserState],
  user => user?.data?.user_session_id || '',
);

/**
 * @returns value to be sure that both user data requests are done
 */
export const selectIsUserFullUserDataLoadedTemporaryHint: (state) => boolean = createSelector(
  [selectUserState],
  user => user?.isFullUserDataLoadedTemporaryHint || false,
);

/**
 * @returns boolean value is user data loading
 */
export const selectIsUserDataLoading: (state) => boolean = createSelector(
  [selectUserState],
  user => user?.isLoading || false,
);

/**
 * @returns user raw data
 */
export const selectUserDataRaw: (state) => TUser | Record<string, never> = createSelector(
  [selectUserState],
  user => user?.data || {},
);

/**
 * @returns user image
 */
export const selectUserImage: (state) => string = createSelector(
  [selectUserState],
  user => user?.data?.profile_image || '',
);

/**
 * @returns upload state of user profile
 */
export const selectIsUploadingProfilePicture: (state) => boolean = createSelector(
  [selectUserState],
  user => user?.isUploadingProfilePicture || false,
);

import { FC } from 'react';

import { localeApp } from '@lib/tools/locale/source/web/app';

import loadingGIF from '@components/web/src/assets/gif/loading.gif';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/Loading/LoadingSpinner/styles';

interface IProps {
  variant?: 'login' | 'default' | 'spinner';
}

const LoadingSpinner: FC<IProps> = ({ variant = 'default' }) => {
  const { textOne, textTwo } = localeApp.loadingScreen;
  const isDefaultVariant = variant === 'default';
  const isSpinnerVariant = variant === 'spinner';

  return (
    <S.LoadingSpinnerContainer>
      <img alt="loading" src={loadingGIF} />
      {!isSpinnerVariant && (
        <S.TextContainer>
          <Text size="subtitle1" text={textOne} />
          {isDefaultVariant && <Text size="subtitle1" text={textTwo} weight="semibold" />}
        </S.TextContainer>
      )}
    </S.LoadingSpinnerContainer>
  );
};

export default LoadingSpinner;

import { TProductInstance } from '@lib/core/products/types';
import { getVinhoodTagsFromCharacteristics } from '@lib/core/service/utils';
import { LocaleUtils } from '@lib/tools/locale/utils';
import {
  DIETARY_PREFERENCE_EXPOSURE,
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
} from '@lib/tools/shared/helpers/consts';

/**
 * @param locale uses for productPerfectFor and productDescription localization
 */

interface IProps {
  productInstanceData: TProductInstance;
  locale?: string;
}

export const parseProductInstance = ({ productInstanceData, locale }: IProps) => {
  const {
    product,
    promotions,
    price,
    identifier,
    preferences,
    url,
    character,
    discount,
    taste_match: tasteMatch,
  } = productInstanceData || {};

  const {
    name,
    image,
    attributes,
    producer,
    characteristics,
    category,
    product_category: pc,
    description = '',
    format,
    character: productCharacter,
    region,
    regions,
  } = product || {};

  /** WARN. These Ids use for different purposes:
   * productId uses only for wishlist, productList and feedback
   * productInstanceId uses as basic gprl.identifier that we use before
   */
  const productId = product?.identifier || '';
  const productInstanceId = identifier || '';

  const productCategory = category || pc || '';
  const productCharacterName = character?.name || productCharacter?.name || '';
  const productCharacterId = character?.identifier || '';
  const productCharacterTechnicalCaption = character?.techCaption || productCharacter?.technical_caption || '';
  const productProducerName = producer?.name || '';
  const productFormatName = format?.name || '';
  const productFormatIdentifier = format?.identifier || '';
  const productFormatSize = attributes?.quantity || '';
  const productRegionName = (!!region?.length && region[0]?.name) || (!!regions?.length && regions[0]?.name) || '';
  const productRegionCountry =
    (!!region?.length && region[0]?.country) || (!!regions?.length && regions[0]?.country) || '';
  const productProducerUrl = url || '';
  const productStyle = [PRODUCT_CATEGORY_BEER, PRODUCT_CATEGORY_COFFEE].includes(productCategory) ? description : '';
  const productTasteMatchValue = typeof tasteMatch === 'number' ? tasteMatch : null;

  const productPerfectFor = (locale && character?.attributes[`when_to_drink_${locale}`]) || '';
  const productDescription = (locale && product?.attributes[`story_${locale}`]) || '';

  const productCurrencySymbol = LocaleUtils.getCurrencySymbolFromCode(price?.currency || '');
  const productOriginalPrice = Number(price?.value) || 0;
  const productDiscountPrice = Number(discount?.price?.value) || 0;
  const showedProductOriginalPrice = productOriginalPrice ? `${productOriginalPrice} ${productCurrencySymbol}` : null;
  const showedProductDiscountPrice = productDiscountPrice ? `${productDiscountPrice} ${productCurrencySymbol}` : null;

  const productTags = getVinhoodTagsFromCharacteristics(characteristics || []);
  const productBadges = preferences
    ? preferences.filter(item => item.exposure === DIETARY_PREFERENCE_EXPOSURE.TAGS).map(tag => tag.name)
    : [];

  return {
    productBadges,
    productCategory,
    productCharacterId,
    productCharacterName,
    productCharacterTechnicalCaption,
    productCurrencySymbol,
    productDescription,
    productDiscountPrice,
    productFormat: format || '',
    productFormatIdentifier,
    productFormatName,
    productFormatSize,
    productId,
    productImage: image || '',
    productInstanceId,
    productName: name || '',
    productOriginalPrice,
    productPerfectFor,
    productProducerName,
    productProducerUrl,
    productRegionCountry,
    productRegionName,
    productStyle,
    productTags,
    productTasteMatchValue,
    promotions,
    showedProductDiscountPrice,
    showedProductOriginalPrice,
  };
};

import { FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';

import { ReactComponent as CrossIcon } from '@components/web/src/assets/icons/cross_icon.svg';
import { ReactComponent as VinhoodCircleLogo } from '@components/web/src/assets/svg/vh_circle_logo.svg';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Cards/DownloadAppCard/styles';

interface IProps {
  handleClick: () => void;
  handleCloseClick: () => void;
}
const DownloadAppCard: FC<IProps> = ({ handleClick, handleCloseClick }) => {
  const { vinhood, vinhoodTaste } = localeCommon.commonMessages;

  return (
    <S.DownloadAppCardWrapper align="center" gap={10}>
      <S.CrossIconWrapper>
        <CrossIcon onClick={handleCloseClick} />
      </S.CrossIconWrapper>
      <S.LogoWrapper align="center" justify="center">
        <VinhoodCircleLogo />
      </S.LogoWrapper>
      <S.VinhoodTextWrapper direction="column" gap={0}>
        <Text size="body1" text={vinhood} weight="semibold" />
        <Text size="body2" text={vinhoodTaste} />
      </S.VinhoodTextWrapper>
      <S.GradientText size="body1" text="Download App" weight="medium" onClick={handleClick} />
    </S.DownloadAppCardWrapper>
  );
};

export default DownloadAppCard;

import { styled } from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const HeaderKioskContainer = styled(Flexbox)`
  position: sticky;
  top: 0;
  z-index: 9999;
  width: 1080px;
  min-height: 60px;
  max-height: 60px;
  background-color: var(--color-secondary-100);
`;

export const LogoWrapper = styled.button`
  all: unset;
  height: 30.8px;
`;

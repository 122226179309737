import { createSelector } from '@reduxjs/toolkit';

import { TProductInstance } from '@lib/core/products/types';
import { RootState } from '@lib/core/service/types/appStateType';
import { IProductListState } from '@lib/core/users/slices/productList';
import { IProductList } from '@lib/core/users/types';
import { PRODUCT_LIST_WISHLIST } from '@lib/tools/shared/helpers/consts';

const selectProductList = (state: RootState) => state.users.productList;

export const selectWishlistProductListLoading = createSelector(
  [selectProductList],
  (state): boolean => state.isLoading || false,
);

export const selectWishlistProductListData = createSelector(
  [selectProductList],
  (state: IProductListState): IProductList[] =>
    state.data.filter(list => list[0]?.list_name === PRODUCT_LIST_WISHLIST).flat(),
);

export const selectWishlistProductInstanceData = createSelector(
  [selectWishlistProductListData],
  (state: IProductList[]): TProductInstance[] => state.map(item => item?.product),
);

export const selectWishlistProductInstanceIds = createSelector([selectWishlistProductInstanceData], (state): string[] =>
  state.map(item => item?.product?.identifier),
);

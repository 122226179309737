import { css, styled } from 'styled-components';

import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const SublistWrapper = styled(Flexbox)`
  border-bottom: 1px solid ${STATIC_COLORS.warmGray['300']};
`;

export const SublistContainer = styled(Flexbox)<{ $isOpened: boolean }>`
  padding: 16px;
  cursor: pointer;
  background: ${STATIC_COLORS.warmGray['100']};
  ${({ $isOpened }) =>
    $isOpened &&
    css`
      background: ${STATIC_GRADIENTS.warmGradient};
    `}
`;

export const Icon = styled(Flexbox)<{ $isChecked: boolean }>`
  width: 40px;
  height: 40px;
  background-color: ${STATIC_COLORS.base.white};
  border: none;
  border-radius: 50%;
  ${({ $isChecked }) =>
    $isChecked &&
    css`
      background-color: ${STATIC_COLORS.teal['300']};
      svg {
        path {
          stroke: ${STATIC_COLORS.base.white};
        }
      }
    `}
`;

export const SublistButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 1px solid ${STATIC_COLORS.teal['600']};
  border-radius: 50%;
`;

export const SublistLists = styled(Flexbox)`
  width: 100%;
  gap: 0;
  flex-direction: column;
  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      padding: 16px;
      gap: 16px;
      flex-direction: row;
      flex-wrap: wrap;
    `};
`;

import { FC, useMemo } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  FILTER_TYPE_CHARACTERISTICS,
  FILTER_TYPE_CHARACTER_TOGGLE,
  FILTER_TYPE_FORMAT,
  FILTER_TYPE_LOCATION,
  FILTER_TYPE_MOOD,
  FILTER_TYPE_ORIGIN,
  FILTER_TYPE_PRICE_RANGE,
  FILTER_TYPE_PRODUCT_CATEGORY,
  FILTER_TYPE_PROMOTION_TOGGLE,
  FILTER_TYPE_RATING,
  FILTER_TYPE_SHOW_FAVORITES,
  FILTER_TYPE_STYLE,
  FILTER_TYPE_WISHLIST,
  VH_VARIANTS,
} from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import ShowOnlyFilter from '@components/web/src/atoms/Filters/ShowOnlyFilter/ShowOnlyFilter';
import Sublist from '@components/web/src/atoms/Filters/Sublist/Sublist';
import ToggleFilter from '@components/web/src/atoms/Filters/ToggleFilter/ToggleFilter';
import { TFilterData } from '@components/web/src/atoms/Filters/types';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import * as S from '@components/web/src/organisms/Filter/FilterComponent/styles';

interface Props {
  data: TFilterData[];
  isJournalVariant?: boolean;
  handleClose: () => void;
  handleApply: ({ isPagination }: { isPagination?: boolean }) => void;
  handleUpdatePromotionToggleActive?: (value: boolean) => void;
  handleUpdateCharactersToggleActive?: (value: boolean) => void;
}

const FilterComponent: FC<Props> = ({
  handleClose,
  data = [],
  handleApply,
  isJournalVariant = false,
  handleUpdatePromotionToggleActive,
  handleUpdateCharactersToggleActive,
}) => {
  const { filterComponent, commonMessages } = localeCommon;

  const filters: JSX.Element[] = useMemo(
    () =>
      data.map((filterSectionData, index) => {
        const { filterType, listContent } = filterSectionData;

        const title = {
          [FILTER_TYPE_CHARACTERISTICS]: filterComponent.characteristicsTitle,
          [FILTER_TYPE_CHARACTER_TOGGLE]: filterComponent.toggleTitle,
          [FILTER_TYPE_FORMAT]: filterComponent.formatTitle,
          [FILTER_TYPE_LOCATION]: filterComponent.locationTitle,
          [FILTER_TYPE_MOOD]: filterComponent.moodTitle,
          [FILTER_TYPE_ORIGIN]: filterComponent.originTitle,
          [FILTER_TYPE_PRICE_RANGE]: filterComponent.priceRangeTitle,
          [FILTER_TYPE_PRODUCT_CATEGORY]: filterComponent.productCategoryTitle,
          [FILTER_TYPE_PROMOTION_TOGGLE]: filterComponent.togglePromotionTitle,
          [FILTER_TYPE_RATING]: filterComponent.ratingTitle,
          [FILTER_TYPE_SHOW_FAVORITES]: filterComponent.wishlistTitle,
          [FILTER_TYPE_STYLE]: filterComponent.styleTitle,
          [FILTER_TYPE_WISHLIST]: filterComponent.wishlistTitle,
        }[filterType];

        switch (filterType) {
          case FILTER_TYPE_MOOD:
          case FILTER_TYPE_LOCATION:
          case FILTER_TYPE_FORMAT:
          case FILTER_TYPE_PRODUCT_CATEGORY:
          case FILTER_TYPE_STYLE:
          case FILTER_TYPE_ORIGIN:
          case FILTER_TYPE_CHARACTERISTICS:
            return <Sublist key={index} filterType={filterType} listContent={listContent} title={title} />;
          case FILTER_TYPE_CHARACTER_TOGGLE:
            return (
              <ToggleFilter
                key={index}
                handleUpdateCharactersToggleActive={handleUpdateCharactersToggleActive}
                handleUpdatePromotionToggleActive={handleUpdatePromotionToggleActive}
                inactiveSubtitle={filterComponent.toggleInactiveSubtitle}
                subtitle={filterComponent.toggleActiveSubtitle}
                title={title}
                toggleType={FILTER_TYPE_CHARACTER_TOGGLE}
              />
            );
          case FILTER_TYPE_PROMOTION_TOGGLE:
            return (
              <ToggleFilter
                key={index}
                handleUpdateCharactersToggleActive={handleUpdateCharactersToggleActive}
                handleUpdatePromotionToggleActive={handleUpdatePromotionToggleActive}
                title={title}
                toggleType={FILTER_TYPE_PROMOTION_TOGGLE}
              />
            );

          case FILTER_TYPE_SHOW_FAVORITES:
            return <ShowOnlyFilter key={index} filterType={FILTER_TYPE_WISHLIST} title={title} />;
          case FILTER_TYPE_RATING:
            return <Sublist key={index} filterType={FILTER_TYPE_RATING} listContent={listContent} title={title} />;
          case FILTER_TYPE_PRICE_RANGE:
            return isJournalVariant ? null : <Sublist key={index} filterType={FILTER_TYPE_PRICE_RANGE} title={title} />;

          default:
            return null;
        }
      }),
    [data],
  );

  // TODO: className to be removed once product catalog is changed to styled
  return (
    <Flexbox isFullWidth className="filter-component-wrapper" direction="column" padding="0px">
      <S.FilterPageComponent>
        <div>{filters}</div>
        <S.FilterPageButtonWrapper isFullWidth align="center" gap={16} justify="flex-end">
          <Button size="sm" text={commonMessages.cancelBtn} variant={VH_VARIANTS.LIGHT} onClick={handleClose} />
          <Button size="sm" text={filterComponent.applyBtn} onClick={() => handleApply({ isPagination: false })} />
        </S.FilterPageButtonWrapper>
      </S.FilterPageComponent>
    </Flexbox>
  );
};

export default FilterComponent;

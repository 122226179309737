import { styled } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';

export const LogoutModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 24px;
  margin: 0 auto;
  background: ${STATIC_COLORS.base.white};
  border-radius: 8px;

  & > button {
    margin-left: auto;
  }
`;

export const Icon = styled.img`
  width: 56px;
  height: 56px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

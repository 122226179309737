import styled from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const CharacterDetailsContainer = styled(Flexbox)`
  width: 100%;
  padding: 20px 140px 0 40px;
  background-color: ${STATIC_COLORS.base.white};
  box-shadow: ${STATIC_SHADOWS.lg};
`;

export const CharacterImage = styled.div<{ $characterImage: string }>`
  width: 440px;
  height: 440px;
  margin-bottom: 0;
  background-repeat: no-repeat;
  background-position-x: -10%;
  background-size: cover;
  background-image: ${({ $characterImage }) => `url('${$characterImage}')`};
`;

export const CharacterSummary = styled(Flexbox)`
  width: 500px;
  padding: 66px 0 40px;
`;

export const CharacterEssenceGroup = styled(Flexbox)`
  width: 100%;
  margin: 24px 0;
`;

export const CharacterTitle = styled(Text)`
  margin-bottom: 4px;
`;

export const CharacterName = styled(Text)`
  margin-bottom: 4px;
`;

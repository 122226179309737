import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@lib/core/service/types/appStateType';

/**
 * @returns the whole locationList state
 */
const selectLocationList = (state: RootState) => state.users.locationList;

/**
 * @returns {boolean} status of loading locationList state
 */
export const selectLocationListLoading = createSelector([selectLocationList], state => state?.isLoading);

/**
 * @returns {ILocation[]} locationList data
 */
export const selectLocationListData = createSelector([selectLocationList], state => state?.data || []);

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { selectQuizData, selectQuizPath } from '@lib/core/quizzes/selectors/quiz';
import { apiUrlGetQuiz } from '@lib/core/quizzes/slices/urls';
import { TQuizDetail, TQuizPath } from '@lib/core/quizzes/types';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';

export interface IQuizSlice {
  isLoading: boolean;
  data: TQuizDetail;
  error: string;
}
const initialState: IQuizSlice = {
  data: undefined,
  error: '',
  isLoading: false,
};

export const actionGetQuiz = createTypedAsyncThunk('quizzes/quiz/get', async ({ quizSlug }: { quizSlug: string }) => {
  return await request(apiUrlGetQuiz(quizSlug)).then(async (quizDetail: TQuizDetail) => {
    // Obtain pregenerated gzipped file from S3, if supplied.
    if (quizDetail.quiz_test_data_id) {
      await request(quizDetail.quiz_path, { ignoreProfileHeaders: true }).then((response: TQuizPath) => {
        quizDetail.quiz_path = response;
      });
    }

    const preparedQuizPath = { ...quizDetail.quiz_path };
    const questionIds = Object.keys(preparedQuizPath);

    questionIds.forEach(quizPathKey => {
      const answerIds = Object.keys(preparedQuizPath[quizPathKey].answers);
      answerIds.forEach(answerId => {
        const questionPool = preparedQuizPath[quizPathKey].answers[answerId].question_pool;

        // Randomly reduce one of the questions in the question_pool.
        if (questionPool?.length) {
          preparedQuizPath[quizPathKey].answers[answerId].question_pool = [
            questionPool[Math.floor(Math.random() * questionPool.length)],
          ];
        }
      });
    });

    return {
      ...quizDetail,
      quiz_path: preparedQuizPath,
    };
  });
});

export const updateQuizPathAndData = createTypedAsyncThunk(
  'quiz/updatePathAndData',
  async (changedNodes: object, { getState }) => {
    const state = getState();
    const quizPath = JSON.parse(JSON.stringify(selectQuizPath(state)));
    const quizData = JSON.parse(JSON.stringify(selectQuizData(state)));
    const changedNewAnswerNodes = {};
    if (changedNodes) {
      Object.keys(changedNodes).forEach(changedNodeID => {
        const changedNode = changedNodes[changedNodeID];
        if (changedNodeID.startsWith('QA')) {
          const foundKey = Object.keys(quizPath).find(key => {
            const answersObj = quizPath[key].answers;
            const answerArray = Object.keys(answersObj);
            return answerArray.includes(changedNodeID);
          });
          // means it is a not new node so it's already in the quizPath and quizData
          if (foundKey) {
            if (changedNode.addedChildren || changedNode.removedChildren) {
              if (changedNode.removedChildren && changedNode.removedChildren.length) {
                quizPath[foundKey].answers[changedNodeID].question_pool = quizPath[foundKey].answers[
                  changedNodeID
                ].question_pool.filter(item => !changedNode.removedChildren.includes(item));
              }
              if (changedNode.addedChildren && changedNode.addedChildren.length) {
                changedNode.addedChildren.forEach(addedChild => {
                  quizPath[foundKey].answers[changedNodeID].question_pool.push(addedChild);
                });
              }
            }
            if (changedNode.text) {
              const answersArray = quizData[foundKey].answers;
              answersArray.some((answerObj, i) => {
                if (Object.keys(answerObj)[0] === changedNodeID) {
                  answersArray[i][changedNodeID].text = changedNode.text;
                  return true;
                }
                return false;
              });
              quizData[foundKey].answers = answersArray;
            }
          } else {
            changedNewAnswerNodes[changedNodeID] = changedNode.addedChildren;
          }
        }
      });
      Object.keys(changedNodes).forEach(changedNodeID => {
        const changedNode = changedNodes[changedNodeID];
        if (changedNodeID.startsWith('QQ') && changedNode.addedChildren && changedNode.addedChildren.length) {
          changedNode.addedChildren.forEach(addedChild => {
            const foundKey = Object.keys(quizPath).find(key => {
              const answersObj = quizPath[key].answers;
              const answerArray = Object.keys(answersObj);
              return answerArray.includes(addedChild);
            });
            let nextQuestionsArray;
            let answerInfo;
            // means the answer node to be added is not a new node but already in the path
            if (foundKey) {
              nextQuestionsArray = quizPath[foundKey].answers[addedChild];
              // quizPath[changedNodeID].answers[addedChild] = nextQuestionsArray;
              const answersArray = quizData[foundKey].answers;
              let index;
              answersArray.some((answerObj, i) => {
                if (Object.keys(answerObj)[0] === addedChild) {
                  index = i;
                  return true;
                }
                return false;
              });
              answerInfo = quizData[foundKey].answers[index];
              // quizData[changedNodeID].answers.push(answerInfo);
            }
            // means that the addedNode is a new node
            else if (!foundKey) {
              nextQuestionsArray = { question_pool: changedNewAnswerNodes[addedChild] };
              answerInfo = { [addedChild]: { text: changedNodes[addedChild].text } };
              // means it is the first time that we're adding this
            }
            // means that the changed node is a new node and doesn't exist in the quizPath

            if (!quizPath[changedNodeID]) {
              quizPath[changedNodeID] = { answers: { [addedChild]: nextQuestionsArray } };
              quizData[changedNodeID] = { answers: [answerInfo], text: changedNodes[changedNodeID].text };
            } else {
              quizData[changedNodeID].answers.push(answerInfo);
              quizPath[changedNodeID].answers[addedChild] = nextQuestionsArray;
            }
          });
        }
      });
      Object.keys(changedNodes).forEach(changedNodeID => {
        const changedNode = changedNodes[changedNodeID];
        if (changedNodeID.startsWith('QQ') && changedNode.removedChildren && changedNode.removedChildren.length) {
          changedNode.removedChildren.forEach(removedChild => {
            delete quizPath[changedNodeID].answers[removedChild];
            const answersArray = [...quizData[changedNodeID].answers];
            const answersArrayCopy = [];
            answersArray.forEach(answerObj => {
              if (Object.keys(answerObj)[0] !== removedChild) {
                answersArrayCopy.push(answerObj);
              }
            });
            quizData[changedNodeID].answers = answersArrayCopy;
          });
        }
      });
    }
    return { quizData, quizPath };
  },
);

export const quizSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetQuiz.pending, state => {
      state.error = '';
      state.isLoading = true;
    });
    builder.addCase(actionGetQuiz.fulfilled, (state, action: PayloadAction<TQuizDetail>) => {
      const { payload } = action;
      state.isLoading = false;
      state.data = payload;
    });
    builder.addCase(actionGetQuiz.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateQuizPathAndData.fulfilled, (state, action) => {
      const { quizData, quizPath } = action.payload;
      state.isLoading = false;

      state.data.quiz_path = quizPath;
      state.data.quiz_data = quizData;
    });
    builder.addCase(updateQuizPathAndData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
  initialState,
  name: 'quiz',
  reducers: {},
});

export default quizSlice.reducer;

import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { GPRL_CHARACTER_QUERY } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import { useUser } from '@lib/core/users/hooks/useUser';
import { useProductTip } from '@lib/tools/productTip/hooks/useProductTip';
import {
  fetchAndCreateNewProductTip,
  fetchProductTipData,
  resetCurrentProductTip,
} from '@lib/tools/productTip/slices/';
import { GPRL_IDENTIFIER_QUERY, PRODUCT_CATEGORY_QUERY } from '@lib/tools/shared/helpers/consts';
import { useAddons, useEffectSkipFirst } from '@lib/tools/views/hooks';

const useProductTipData = () => {
  const dispatch = useDispatch();

  const { isEnableKioskFidelityScanAddon } = useAddons();
  const { userBestMatchCharacterForCurrentServiceProductCategory } = useUser();
  const { fidelityCardId } = useFidelityCard();
  const { locale, productCategory } = useApp();

  const { currentProductTipData, isCurrentProductTipLoading, usersProductTipsStorage } = useProductTip();

  const currentDate = moment().format('DD.MM.YYYY');
  const savedProductTipInUsersStorage = usersProductTipsStorage[fidelityCardId];
  const { lastProductTipDate = '', productTipCharacter = '', productTipId = '' } = savedProductTipInUsersStorage || {};

  const currentUserCharacterId = userBestMatchCharacterForCurrentServiceProductCategory?.identifier;
  const isProductTipFeatureActive = isEnableKioskFidelityScanAddon && fidelityCardId;
  const isLastProductTipDatePassed = savedProductTipInUsersStorage && lastProductTipDate !== currentDate;
  const isUserChangedCharacter = savedProductTipInUsersStorage && productTipCharacter !== currentUserCharacterId;
  const isCurrentProductTipDataNotLoaded =
    savedProductTipInUsersStorage && currentProductTipData?.identifier !== productTipId;

  useEffect(() => {
    if (isProductTipFeatureActive) {
      if (!currentUserCharacterId) {
        /** Reset for a user without a character, not to show the Product Tip of the previous user */
        dispatch(resetCurrentProductTip());
        return;
      }

      if (!savedProductTipInUsersStorage || isLastProductTipDatePassed || isUserChangedCharacter) {
        /** Fetch data and create a new Product Tip for user:
         * 1. If user hasn't got any Product Tip yet
         * 2. If user has Product Tip in storage but:
         * - user updated character
         * - last tip date is passed (every day a new tip)
         */
        dispatch(
          fetchAndCreateNewProductTip({
            [GPRL_CHARACTER_QUERY]: currentUserCharacterId,
            [PRODUCT_CATEGORY_QUERY]: productCategory,
            offset: 0,
          }),
        );
      }

      if (isCurrentProductTipDataNotLoaded) {
        /** Fetch data for saved Product Tip in storage */
        dispatch(fetchProductTipData(productTipId));
      }
    }
  }, [fidelityCardId]);

  useEffectSkipFirst(() => {
    if (isProductTipFeatureActive) {
      dispatch(
        fetchAndCreateNewProductTip({
          ...(productTipId && { [GPRL_IDENTIFIER_QUERY]: productTipId }),
          [GPRL_CHARACTER_QUERY]: currentUserCharacterId,
          [PRODUCT_CATEGORY_QUERY]: productCategory,
          offset: 0,
        }),
      );
    }
  }, [locale]);

  return { currentProductTipData, isCurrentProductTipLoading };
};

export default useProductTipData;

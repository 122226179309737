import { Button as BaseButton } from '@mui/base/Button';
import { styled } from 'styled-components';

import { themeDevTools } from '@lib/tools/devtools/components/theme';

export const Button = styled(BaseButton)(
  () => `
  width: 80px;
  height: 24px;
  padding: 2px 0;
  margin: 5px 5px 5px 0;
  font-size: ${themeDevTools.font.small};
  color: ${themeDevTools.color.white};
  cursor: pointer;
  background-color: ${themeDevTools.color.black};
  border: ${themeDevTools.color.white} 1px solid;
  transition-duration: 250ms;

  &:hover {
    color: ${themeDevTools.color.black};
    background-color: ${themeDevTools.color.white};
  }

  &:active {
    background-color: ${themeDevTools.color.white};
    box-shadow: none;
    transform: scale(0.99);
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${themeDevTools.color.white};
    outline: none;
  }

   &.base--disabled {
    background-color: ${themeDevTools.color.black}; 
    color: ${themeDevTools.color.white}; 
    border: ${themeDevTools.color.white} 
    cursor: not-allowed; 
    opacity: 0.6; 
    pointer-events: none; 
  }
`,
);

import { css, styled } from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const FeedbackModalContainer = styled.div`
  padding: 16px;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      min-width: 706px;
    `}

  .close-button {
    margin-left: auto;
  }
`;

export const FeedbackContentWrapper = styled(Flexbox)`
  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      flex-direction: row;
      gap: 40px;
      padding-inline: 24px;
    `}
`;

export const StyledTitleWrapper = styled(Flexbox)`
  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      max-width: 240px;
    `}
`;

import { useSelector } from 'react-redux';

import { TProductInstance } from '@lib/core/products/types';
import { actionResetUserQuiz } from '@lib/core/quizzes/slices';
import { useRetailer } from '@lib/core/retailers/hooks';
import { actionGetRetailerDetail } from '@lib/core/retailers/slices';
import {
  selectKioskIsAnonymousPreference,
  selectKioskProductQuantity,
  selectKioskProducts,
  selectKioskProductsIsLoading,
} from '@lib/core/service/selectors/kioskRootSelector';
import { resetKioskHomePage } from '@lib/core/service/slices/kioskRootSlice';
import { prependBasename } from '@lib/core/service/utils';
import { actionResetUserCharactersSlice } from '@lib/core/users/slices/characters';
import { actionResetDietaryPreferenceListData } from '@lib/core/users/slices/dietaryPreference';
import { detachFidelityCard } from '@lib/core/users/slices/fidelityCard';
import { actionResetProductList } from '@lib/core/users/slices/productList';
import { actionResetUserProfile } from '@lib/core/users/slices/profile';
import { resetPromotionProducts } from '@lib/tools/promotionProducts/slices';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

export const useKioskRoot = (): {
  handleResetKioskUserSession: (params: { showFidelityMessage?: boolean }) => void;
  products: TProductInstance[][];
  isProductsLoading: boolean;
  isAnonymousPreference: boolean;
  productQuantity: number;
} => {
  const dispatch = useTypedDispatch();

  const { retailerSlug } = useRetailer();

  const handleResetKioskUserSession = async ({ showFidelityMessage = false }: { showFidelityMessage?: boolean }) => {
    try {
      await Promise.all([
        dispatch(detachFidelityCard({ displayMessage: showFidelityMessage })),
        dispatch(actionResetUserQuiz()),
        dispatch(resetKioskHomePage()),
        dispatch(resetPromotionProducts()),
        dispatch(actionResetProductList()),
        dispatch(actionResetDietaryPreferenceListData()),
        dispatch(actionResetUserProfile()),
        dispatch(actionGetRetailerDetail({ retailerSlug })),
        dispatch(actionResetUserCharactersSlice()),
      ]);
      window.location.href = prependBasename(PAGES.vinhood.welcome);
    } catch (error) {
      console.error('Error during reset actions:', error);
    }
  };

  return {
    handleResetKioskUserSession,
    isAnonymousPreference: useSelector(selectKioskIsAnonymousPreference),
    isProductsLoading: useSelector(selectKioskProductsIsLoading),
    productQuantity: useSelector(selectKioskProductQuantity),
    products: useSelector(selectKioskProducts),
  };
};

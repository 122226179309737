import cn from 'classnames';
import { FC } from 'react';

import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { ReactComponent as LeftArrowIcon } from '@components/web/src/assets/legacy/b2c/icons/left-arrow-icon.svg';

type IconVariantType = 'none' | 'leftarrow';

type IconVariants = {
  [key in IconVariantType]: JSX.Element | null;
};

type ButtonProps = {
  btnText: ILocaleText;
  btnIcon?: IconVariantType;
  className?: string;
  handleBtnClick?: () => void;
};

const CTAButton: FC<ButtonProps> = ({ btnIcon, className, btnText, handleBtnClick }: ButtonProps) => {
  const icons: IconVariants = {
    leftarrow: <LeftArrowIcon />,
    none: null,
  };
  const renderedIcon = icons[btnIcon];
  return (
    <button className={cn('app-cta-btn btn', className)} onClick={handleBtnClick}>
      {renderedIcon}
      <span className="btn-text">
        <LocaleFragment message={btnText} />
      </span>
    </button>
  );
};

export default CTAButton;

import { createSelector } from '@reduxjs/toolkit';

import { TProductInstance } from '@lib/core/products/types';
import { RootState } from '@lib/core/service/types/appStateType';

const selectDiscoveryQuiz = (state: RootState) => state?.discoveryQuiz;

const selectDiscoveryQuizProductInstance = createSelector(
  [selectDiscoveryQuiz],
  (discoveryQuiz): TProductInstance => discoveryQuiz?.discoveryQuizProductInstance || undefined,
);

const selectDiscoveryQuizProducts = createSelector(
  [selectDiscoveryQuiz],
  (discoveryQuiz): TProductInstance[] => discoveryQuiz?.data?.discoveryQuizProducts || undefined,
);

const selectIsLoadingScreenOpen = createSelector(
  [selectDiscoveryQuiz],
  (discoveryQuiz): boolean => discoveryQuiz?.isLoadingScreenOpen || false,
);

export { selectDiscoveryQuizProductInstance, selectDiscoveryQuizProducts, selectIsLoadingScreenOpen };

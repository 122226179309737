import { createSlice } from '@reduxjs/toolkit';

export interface IRangeFilterState {
  appMinPriceRangeValue: number;
  appMaxPriceRangeValue: number;
  userLowerPriceRangeValue: number;
  lastChangeWasManual?: boolean;
  userUpperPriceRangeValue: number;
}

export interface ISetPriceRangeValues {
  appMinPriceRangeValue?: number;
  appMaxPriceRangeValue?: number;
  userLowerPriceRangeValue?: number;
  userUpperPriceRangeValue?: number;
  isManual?: boolean;
}

interface ISetPriceRangeValuesPayload {
  payload: ISetPriceRangeValues;
}

const initialState: IRangeFilterState = {
  appMaxPriceRangeValue: 0,
  appMinPriceRangeValue: 0,
  lastChangeWasManual: false,
  userLowerPriceRangeValue: 0,
  userUpperPriceRangeValue: 0,
};

const rangeFilterSlice = createSlice({
  initialState,
  name: 'rangeFilter',
  reducers: {
    resetPriceRange: () => initialState,
    setPriceRangeValues: (state, action: ISetPriceRangeValuesPayload) => {
      const {
        payload: {
          appMinPriceRangeValue = state.appMinPriceRangeValue,
          appMaxPriceRangeValue = state.appMaxPriceRangeValue,
          userLowerPriceRangeValue = state.userLowerPriceRangeValue,
          userUpperPriceRangeValue = state.userUpperPriceRangeValue,
          isManual = false,
        } = {},
      } = action;

      state.appMinPriceRangeValue = appMinPriceRangeValue;
      state.appMaxPriceRangeValue = appMaxPriceRangeValue;
      state.userLowerPriceRangeValue = userLowerPriceRangeValue;
      state.userUpperPriceRangeValue = userUpperPriceRangeValue;
      state.lastChangeWasManual = isManual;
    },
  },
});

export default rangeFilterSlice.reducer;
export const { resetPriceRange, setPriceRangeValues } = rangeFilterSlice.actions;

import { FC } from 'react';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { FILTER_TYPE_CHARACTER_TOGGLE, FILTER_TYPE_PROMOTION_TOGGLE } from '@lib/tools/shared/helpers/consts';
import { useTypedSelector } from '@lib/tools/views/hooks';

import * as S from '@components/web/src/atoms/Filters/ToggleFilter/styles';
import ToggleButton from '@components/web/src/atoms/ToggleButton/ToggleButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

type ToggleType = typeof FILTER_TYPE_CHARACTER_TOGGLE | typeof FILTER_TYPE_PROMOTION_TOGGLE;

type Props = {
  title: ILocaleText;
  subtitle?: ILocaleText;
  inactiveSubtitle?: ILocaleText;
  toggleType: ToggleType;
  handleUpdatePromotionToggleActive: (value: boolean) => void;
  handleUpdateCharactersToggleActive: (value: boolean) => void;
};

const ToggleFilter: FC<Props> = ({
  title,
  subtitle,
  inactiveSubtitle,
  toggleType,
  handleUpdatePromotionToggleActive,
  handleUpdateCharactersToggleActive,
}) => {
  const { isCharacterToggleActive, isPromotionToggleActive } = useTypedSelector(state => state.productFilters.toggle);

  const isActive = toggleType === FILTER_TYPE_CHARACTER_TOGGLE ? isCharacterToggleActive : isPromotionToggleActive;

  const updateToggleActive =
    toggleType === FILTER_TYPE_CHARACTER_TOGGLE
      ? (value: boolean) => handleUpdateCharactersToggleActive(value)
      : (value: boolean) => handleUpdatePromotionToggleActive(value);

  return (
    <S.FilterToggleContainer $isActive={isActive} align="center" gap={0}>
      <ToggleButton isToggled={isActive} onToggle={() => updateToggleActive(!isActive)} />
      <Flexbox direction="column" gap={0}>
        <Text size="body1" text={title} weight="medium" />
        {subtitle && (
          <Text
            color={isActive ? STATIC_COLORS.teal[800] : STATIC_COLORS.gray[700]}
            size="body2"
            text={isActive ? subtitle : inactiveSubtitle}
            weight="medium"
          />
        )}
      </Flexbox>
    </S.FilterToggleContainer>
  );
};

export default ToggleFilter;

import QRCode from 'react-qr-code';
import styled from 'styled-components';

import { TOrientation } from '@components/web/src/atoms/ScanCTA/ScanCTA';
import Circle from '@components/web/src/shared/Icons/CircleIcon';

export const ScanCtaContainer = styled.div<{ $orientation: TOrientation }>`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: ${({ $orientation }) => ($orientation === 'left' ? 'row' : 'row-reverse')};
`;

export const ImageWrapper = styled.div<{ $orientation: TOrientation }>`
  position: relative;
  width: 238px;
  height: 250px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  ${({ $orientation }) => $orientation === 'right' && 'transform: scaleX(-1)'};
`;

export const CircleIcon = styled(Circle)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
`;

export const ArrowIcon = styled.img`
  position: absolute;
  right: -25px;
  bottom: -45px;
  transform: scaleX(-1);
`;

export const Icon = styled.img`
  position: absolute;
  top: 18%;
  width: 150px;
  height: 150px;
  left: 14%;
  transform: scaleX(-1);
`;

export const QrIcon = styled(QRCode)`
  position: absolute;
  top: 18%;
  width: 150px;
  height: 150px;
  left: 15%;
`;

export const TextWrapper = styled.div<{ $orientation: TOrientation }>`
  display: flex;
  flex-direction: column;
  width: 578px;
  ${({ $orientation }) => $orientation === 'right' && 'text-align: right'};
`;

import { css, styled } from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';

export const LanguageSwitcherContainer = styled.div`
  position: relative;
  min-width: 55px;
  max-width: fit-content;
  cursor: pointer;
`;

export const IconContainer = styled(Flexbox)`
  min-width: 72px;
`;

export const LanguageSwitcherButton = styled.button<{ $isSelected: boolean }>`
  all: unset;
  cursor: pointer;
  ${({ $isSelected }) =>
    !$isSelected &&
    css`
      background-color: inherit;
    `}
`;

export const IconImage = styled(Image)<{ $isSelected: boolean }>`
  ${({ $isSelected }) =>
    !$isSelected &&
    css`
      opacity: 0.3;
    `}
`;

export const LanguageItemContainer = styled(Flexbox)<{ $isListItem: boolean; $showLanguageList: boolean }>`
  width: 100%;
  padding: ${({ $isListItem }) => ($isListItem ? `9px 8px` : `14px 4px`)};
  border-radius: 0px 0px 4px 4px;

  ${({ $showLanguageList }) =>
    $showLanguageList &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

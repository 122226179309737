import { FC } from 'react';

import KioskContainer from '@components/web/src/kiosk/KioskContainer/KioskContainer';

type IResultPageSkeletonProps = {
  isFromSituationTest: boolean;
};

const ResultPageSkeleton: FC<IResultPageSkeletonProps> = ({ isFromSituationTest }) => {
  return (
    <KioskContainer>
      <div className="result-page-skeleton-container">
        {isFromSituationTest ? (
          <div className="situational-page-header-skeleton" />
        ) : (
          <div className="character-detail-skeleton">
            <div className="left">
              <div className="rectangle-one" />
            </div>
            <div className="right">
              <div className="rectangle-bar-one" />
              <div className="input-bar-one" />
              <div className="rectangle-bar-two" />
              <div className="cross-section one" />
              <div className="cross-section" />
            </div>
          </div>
        )}
        <div className="skeleton-body-wrapper">
          <div className="title-page-skeleton" />
          <div className="skeleton-body">
            <div className="product-card-skeleton" />
            <div className="product-card-skeleton" />
            <div className="product-card-skeleton" />
          </div>
        </div>
        <div className="button-skeleton" />
      </div>
    </KioskContainer>
  );
};

export default ResultPageSkeleton;

/* eslint-disable sort-keys-fix/sort-keys-fix */
import { TProductInstance } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import AlcoholIcon from '@components/web/src/assets/icons/largeProductPage/alcohol.svg';
import AromaIcon from '@components/web/src/assets/icons/largeProductPage/aroma.svg';
import FromIcon from '@components/web/src/assets/icons/largeProductPage/from.svg';
import GrapeIcon from '@components/web/src/assets/icons/largeProductPage/grape.svg';
import StyleBeerIcon from '@components/web/src/assets/icons/largeProductPage/styleBeer.svg';
import StyleCoffeeIcon from '@components/web/src/assets/icons/largeProductPage/styleCoffee.svg';
import TasteIcon from '@components/web/src/assets/icons/largeProductPage/taste.svg';

type IPageIdentitiesProps = {
  productInstanceData: TProductInstance;
  locale: string;
};

export interface IIdentity {
  title: ILocaleText;
  description: string;
  icon: string;
}

export const parseProductIdentitiesForKiosk = ({ productInstanceData, locale }: IPageIdentitiesProps) => {
  const { product: { region, category, description: productDescription, attributes, attributes: { alcohol } } = {} } =
    productInstanceData;

  const {
    productIdentitiesCard: {
      styleTitleText,
      fromTitleText,
      grapeTitleText,
      alcoholTitleText,
      aromasTitleText,
      tasteTitleText,
    },
  } = localeCommon;

  const productRegion = (region && region[0]?.name) || '';
  const productCountry = (region && region[0]?.country) || '';
  let productIdentities: IIdentity[] = [];

  if (category === PRODUCT_CATEGORY_WINE) {
    productIdentities = [
      { icon: FromIcon, title: fromTitleText, description: `${productRegion}, ${productCountry}` },
      { icon: GrapeIcon, title: grapeTitleText, description: attributes?.[`type_${locale}`] },
      { icon: AlcoholIcon, title: alcoholTitleText, description: alcohol ? `${alcohol}%` : '' },
      {
        icon: AromaIcon,
        title: aromasTitleText,
        description: attributes?.[`aromas_${locale}`] || attributes?.[`aroma_${locale}`],
      },
    ];
  }

  if (category === PRODUCT_CATEGORY_BEER) {
    productIdentities = [
      { icon: FromIcon, title: fromTitleText, description: `${productRegion}, ${productCountry}` },
      {
        icon: StyleBeerIcon,
        title: styleTitleText,
        description: attributes?.[`style_${locale}`] || productDescription,
      },
      { icon: AlcoholIcon, title: alcoholTitleText, description: alcohol ? `${alcohol}%` : '' },
      {
        icon: AromaIcon,
        title: aromasTitleText,
        description: attributes?.[`aromas_${locale}`] || attributes?.[`aroma_${locale}`],
      },
    ];
  }

  if (category === PRODUCT_CATEGORY_COFFEE) {
    productIdentities = [
      { icon: FromIcon, title: fromTitleText, description: `${productRegion}, ${productCountry}` },
      {
        icon: StyleCoffeeIcon,
        title: styleTitleText,
        description: attributes?.[`style_${locale}`] || productDescription,
      },
      { icon: TasteIcon, title: tasteTitleText, description: attributes?.[`taste_${locale}`] },
      {
        icon: AromaIcon,
        title: aromasTitleText,
        description: attributes?.[`aromas_${locale}`] || attributes?.[`aroma_${locale}`],
      },
    ];
  }

  return productIdentities;
};

import ReactPixel from 'react-facebook-pixel';

import { selectRetailerSlug } from '@lib/core/retailers/selectors/retailer';
import { ENV_FACEBOOK_PIXEL_ID } from '@lib/core/service/consts';
import { store } from '@lib/core/service/store';
import { AppStateType } from '@lib/core/service/types/appStateType';
import { actionFacebookPixelStarting } from '@lib/tools/dat/slices';
import { VH_RETAILER_URL_SLUG_APP } from '@lib/tools/shared/helpers/consts';

const initFacebookPixel = () => {
  const state = store.getState();
  const { tracking: trackingState }: AppStateType = state;

  const retailerSlug = selectRetailerSlug(state);
  if (!trackingState.facebookPixelState && ENV_FACEBOOK_PIXEL_ID && retailerSlug === VH_RETAILER_URL_SLUG_APP) {
    ReactPixel.init(ENV_FACEBOOK_PIXEL_ID);
    store.dispatch(actionFacebookPixelStarting());
  }
};

export default initFacebookPixel;

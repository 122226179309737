import { selectRetailerLocationId } from '@lib/core/retailers/selectors/retailerLocation';
import { useAddons } from '@lib/core/service/hooks/useAddons';
import { store } from '@lib/core/service/store';
import { ICustomStyles, IRetailerCustomStyles } from '@lib/tools/shared/helpers/interfaces';

import { WIDGET_COLORS } from '@components/web/src/widget/consts';

export const useCustomStyles = (): ICustomStyles => {
  const {
    retailer: { data: retailerData },
  } = store.getState();
  const retailerLocationID = selectRetailerLocationId(store.getState());
  const { isApplyCustomStyle } = useAddons();
  const { darkTheme } = WIDGET_COLORS;

  const retailerCustomStyles: IRetailerCustomStyles | undefined = retailerData?.custom_style?.[retailerLocationID];

  const customStyles = {
    customGlobalBackgroundColor: isApplyCustomStyle ? retailerCustomStyles?.global?.backgroundColor || '' : '',
    customHeaderBackgroundColor: isApplyCustomStyle ? retailerCustomStyles?.header?.backgroundColor || '' : '',
    customHeaderLogo: isApplyCustomStyle ? retailerCustomStyles?.headerLogoImage?.backgroundImage || '' : '',
    customPrimaryColor: isApplyCustomStyle ? retailerCustomStyles?.primaryColor || '' : '',
    customQuizBackgroundColor: isApplyCustomStyle ? retailerCustomStyles?.quiz?.backgroundColor || '' : '',
    customSecondaryColor: isApplyCustomStyle ? retailerCustomStyles?.secondaryColor || '' : '',
    customTextColor: isApplyCustomStyle && retailerCustomStyles?.theme === darkTheme.name ? darkTheme.color : '',
    customTheme: isApplyCustomStyle ? retailerCustomStyles?.theme || '' : '',
  };

  return customStyles;
};

import styled from 'styled-components';

export const ModalOptionContainer = styled.div`
  width: 100%;
  padding: 8px 0;
  a {
    text-decoration: underline;
    line-height: 18px;
    margin-bottom: 0;
    color: #454c5a;
  }
`;

export const ToggleWrapper = styled.div`
  margin: 0 0 8px auto;
`;

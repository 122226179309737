import styled, { css } from 'styled-components';

import { DEFAULT_COLOR_THEME, STATIC_COLORS } from '@components/web/src/foundations';
import { TYPOGRAPHY_SIZES } from '@components/web/src/foundations/Text/Text.styles';

export const HeroSectionContainer = styled.div<{ $shouldDisplaySmallVariant }>`
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0 auto;
  padding: 32px 64px 0;

  ${({ $shouldDisplaySmallVariant }) =>
    !$shouldDisplaySmallVariant &&
    css`
      height: 608px;
      padding: 167px 64px 142px;
    `};

  .kiosk_start_page-footer-wave {
    position: absolute;
    right: 0;
    bottom: -50px;
    left: 0;
    z-index: 1;
    margin-bottom: 24px;

    path:nth-child(1) {
      fill: ${STATIC_COLORS.base.white} !important;
      opacity: 0.6;
    }

    path:nth-child(2) {
      fill: ${({ theme }) =>
        theme?.colors?.secondaryColor['-100'] || DEFAULT_COLOR_THEME.secondaryColor['-100']} !important;
    }
  }
`;

export const Background = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: ${STATIC_COLORS.teal['900']};
    opacity: 0.5;
  }
`;

export const TextWrapper = styled.div<{ $shouldDisplaySmallVariant }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  z-index: 3;

  span {
    ${({ $shouldDisplaySmallVariant }) =>
      $shouldDisplaySmallVariant
        ? css`
            color: ${STATIC_COLORS.base.black};
          `
        : css`
            text-shadow:
              0px 4px 8px rgba(16, 24, 40, 0.1),
              0px 2px 4px rgba(16, 24, 40, 0.06);
            color: ${STATIC_COLORS.base.white};
          `};
  }
`;

export const PreTitleWrapper = styled.div`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: ${TYPOGRAPHY_SIZES.h6.xl['font-size']};
  line-height: ${TYPOGRAPHY_SIZES.h6.xl['line-height']};
  letter-spacing: ${TYPOGRAPHY_SIZES.h6.xl?.['letter-spacing'] || 'normal'};
  display: flex;
  align-items: center;
  gap: 4px;
  height: 28px;
  text-transform: lowercase;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { B2C_MODALS } from '@lib/core/service/consts';
import { MODALS } from '@lib/tools/shared/helpers/consts';

type IModalKeys = keyof typeof B2C_MODALS | keyof typeof MODALS;
type AllModals = typeof B2C_MODALS & typeof MODALS;
export type IModalTypes = AllModals[IModalKeys];
type IState = Record<'modalState', Record<IModalTypes, boolean>>;

export const initialState: IState = {
  modalState: {
    [MODALS.TERMS_OF_SERVICE_BASIC_MODAL]: false,
    [MODALS.DOWNLOAD_APP_MODAL]: false,
    [B2C_MODALS.FOOD_PREFERENCES_MODAL]: false,
    [B2C_MODALS.INTERESTS_MODAL]: false,
    [B2C_MODALS.MANAGE_MODAL]: false,
    [MODALS.THIRD_PARTY_CONNECT_MODAL]: false,
  },
};

export const modalsSlice = createSlice({
  initialState,
  name: 'modals',
  reducers: {
    actionModalClose: state => {
      state.modalState = initialState.modalState;
    },
    actionModalOpen: (state, action: PayloadAction<{ modalName: IModalTypes }>) => {
      state.modalState[action.payload.modalName] = true;
    },
  },
});

export default modalsSlice.reducer;
export const { actionModalOpen, actionModalClose } = modalsSlice.actions;

import { useEffect, useState } from 'react';

import { useQuizView, useUserQuiz } from '@lib/core/quizzes/hooks';
import QuizUtils from '@lib/core/quizzes/utils';
import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import { SITUATIONAL_PRESELECTED_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

export const useSituationalQuiz = () => {
  const { route } = useApp();
  const { quizViewQuestionId } = useQuizView();
  const { userQuizId, userQuizType } = useUserQuiz();
  const preselectedAnswers = QuizUtils.getPreselectedAnswerIds();

  const recordedPathname =
    route.includes(SITUATIONAL_PRESELECTED_URL_PARAM) || route.includes(PAGES.vinhood.quiz.chooseTaste)
      ? prependBasename(PAGES.vinhood.home)
      : route;

  const [situationalQuizSourcePage, setSituationalQuizSourcePage] = useState(recordedPathname);
  const [situationalQuizDropQuestionId, setSituationalQuizDropQuestionId] = useState('');

  const isSituationalQuizDropQuestion = quizViewQuestionId === situationalQuizDropQuestionId;

  useEffect(() => {
    if (preselectedAnswers.length && !situationalQuizSourcePage) {
      setSituationalQuizSourcePage(recordedPathname);
    } else if (!situationalQuizDropQuestionId && !preselectedAnswers.length) {
      setSituationalQuizDropQuestionId(quizViewQuestionId);
    }
  }, [userQuizId, quizViewQuestionId, userQuizType]);

  return {
    isSituationalQuizDropQuestion,
    situationalQuizSourcePage,
  };
};

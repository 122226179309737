import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectAllServiceTermsOptions,
  selectServiceTermListIsLoading,
  selectServiceTermsInitialDataLoading,
  selectServiceTermsList,
} from '@lib/core/serviceTerms/selectors';
import { actionUpdateServiceTerms } from '@lib/core/serviceTerms/slices';
import { IParsedServiceTerm, IServiceTermCategory } from '@lib/core/serviceTerms/types';
import { findServiceTermsByCategory, findServiceTermsById } from '@lib/core/serviceTerms/utils/filters';

export const useServiceTerms = () => {
  const dispatch = useDispatch();
  const allAppServiceTerms = useSelector(selectAllServiceTermsOptions);
  const serviceTermsList = useSelector(selectServiceTermsList);
  const serviceTermsListUpdateIsLoading = useSelector(selectServiceTermListIsLoading);
  const isTermsInitialDataLoading = useSelector(selectServiceTermsInitialDataLoading);

  const updateServiceTerms = async (terms: IParsedServiceTerm[]) => {
    const parsedTermsDataForApiPayload = terms.map(termFromArg => ({
      ...termFromArg,
      term: findServiceTermsByCategory(allAppServiceTerms, termFromArg.category)?.identifier,
    }));
    return dispatch(actionUpdateServiceTerms(parsedTermsDataForApiPayload));
  };

  const serviceTermsByCategory = useMemo(() => {
    const serviceTermsObject: Partial<Record<IServiceTermCategory, IParsedServiceTerm>> = {};
    allAppServiceTerms.forEach(term => {
      serviceTermsObject[term.category] = {
        ...term,
        is_selected: findServiceTermsById(serviceTermsList.data.results, term.identifier)?.is_selected,
      };
    });
    return serviceTermsObject;
  }, [allAppServiceTerms, serviceTermsList]);

  return {
    allAppServiceTerms,
    isTermsInitialDataLoading,
    serviceTermsByCategory,
    serviceTermsListUpdateIsLoading,
    updateServiceTerms,
  };
};

import { apiUrlGetRecipes } from '@lib/core/productAttributes/slices/urls';
import { TProductCategory } from '@lib/core/products/types';
import request from '@lib/core/service/requests/request';
import {
  CHARACTER_QUERY,
  PAGE_SIZE_QUERY,
  PRODUCT_CATEGORY_QUERY,
  RANDOM_QUERY,
  RECIPE_TYPE_QUERY,
} from '@lib/tools/shared/helpers/consts';

export const getRecipesData = (recipeType: string, productCharacterId: string, productCategory: TProductCategory) =>
  request(apiUrlGetRecipes(), {
    params: {
      [CHARACTER_QUERY]: productCharacterId,
      [PAGE_SIZE_QUERY]: 1,
      [PRODUCT_CATEGORY_QUERY]: productCategory,
      [RANDOM_QUERY]: true,
      [RECIPE_TYPE_QUERY]: recipeType,
    },
  })
    .then(data => data.results)
    .catch(error => {
      throw new Error(error);
    });

import { retailerServiceTermsCategories } from '@lib/core/serviceTerms/consts';
import { useServiceTerms } from '@lib/core/serviceTerms/hooks/useServiceTerms';
import { useUser } from '@lib/core/users/hooks';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { actionAddToastMessage } from '@lib/tools/toastMessage/slices';
import { ToastMessageTypes } from '@lib/tools/toastMessage/utils/consts';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

export const useThirdPartyNewsletter = () => {
  const dispatch = useTypedDispatch();
  const { isUserAuthenticated } = useUser();
  const { ToastMessages } = localeCommon;
  const { titleText, acceptBtnText, rejectBtnText } = localeCommon.ThirdPartyNewsletter;
  const {
    isTermsInitialDataLoading,
    serviceTermsByCategory: { [retailerServiceTermsCategories.thirdParty]: thirdParty },
    updateServiceTerms,
  } = useServiceTerms();
  const descriptionText = thirdParty?.text || '';

  const handleThirdPartyNewsletter = (isAccepted: boolean) => {
    updateServiceTerms([{ category: retailerServiceTermsCategories.thirdParty, is_selected: isAccepted }])
      .then(() => {
        dispatch(
          actionAddToastMessage({
            title: isAccepted ? ToastMessages.thirdPartyAgreementAccepted : ToastMessages.thirdPartyAgreementRejected,
            type: ToastMessageTypes.success,
          }),
        );
      })
      .catch(() => {
        dispatch(
          actionAddToastMessage({
            message: ToastMessages.userDataFailed,
            type: ToastMessageTypes.error,
          }),
        );
      });
  };

  return {
    handleThirdPartyNewsletter,
    isShowThirdPartyNewsletter:
      isUserAuthenticated && thirdParty && thirdParty.is_selected === undefined && !isTermsInitialDataLoading,
    thirdPartyNewsletterTexts: {
      acceptBtnText,
      descriptionText,
      rejectBtnText,
      titleText,
    },
  };
};

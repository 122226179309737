import { FC } from 'react';

import * as S from '@components/web/src/atoms/Wishlist/WishlistAnimation/styles';
import WishlistIcon from '@components/web/src/atoms/Wishlist/WishlistIcon/WishlistIcon';

export type TWishlistVariant = 'default' | 'light';
interface IProps {
  isActive: boolean;
  isAnimationEnded?: boolean;
  isAnimationNotStarted?: boolean;
  variant?: TWishlistVariant;
  isLargeSize?: boolean;
}

const WishlistAnimation: FC<IProps> = ({
  isActive,
  variant = 'default',
  isAnimationEnded = false,
  isAnimationNotStarted = false,
  isLargeSize = false,
}) => {
  return (
    <S.WishlistAnimationContainer>
      {isAnimationNotStarted && (
        <S.RotateScale $isActive={false}>
          <WishlistIcon isLargeSize={isLargeSize} status="start" variant={variant} />
        </S.RotateScale>
      )}
      {isActive && (
        <>
          <S.RotateScale $isActive={isActive}>
            <WishlistIcon isLargeSize={isLargeSize} status="start" variant={variant} />
          </S.RotateScale>

          <S.FadeIn $isActive={isActive}>
            <WishlistIcon isLargeSize={isLargeSize} status="end" variant={variant} />
          </S.FadeIn>

          <S.ScaleAndSplash $isActive={isActive}>
            <WishlistIcon iconVariant="splash" isLargeSize={isLargeSize} variant={variant} />
          </S.ScaleAndSplash>
        </>
      )}
      {isAnimationEnded && (
        <S.FadeIn $isActive={false}>
          <WishlistIcon isLargeSize={isLargeSize} status="end" variant={variant} />
        </S.FadeIn>
      )}
    </S.WishlistAnimationContainer>
  );
};

export default WishlistAnimation;

import { FC } from 'react';

type ISkeletonProps = {
  styles?: Record<string, string>;
  isRound?: boolean;
  className?: string;
};

const Skeleton: FC<ISkeletonProps> = ({ styles, isRound = false, className }) => {
  return <div className={`vh-common-skeleton ${isRound ? 'circular' : ''} ${className}`} style={styles} />;
};

export default Skeleton;

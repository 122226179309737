import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  FILTER_TYPE_CHARACTERISTICS,
  FILTER_TYPE_FORMAT,
  FILTER_TYPE_LOCATION,
  FILTER_TYPE_MOOD,
  FILTER_TYPE_ORIGIN,
  FILTER_TYPE_PRODUCT_CATEGORY,
  FILTER_TYPE_RATING,
  FILTER_TYPE_STYLE,
  HARDCODED_RATING_FILTER,
} from '@lib/tools/shared/helpers/consts';

export interface IFilterItem {
  filterType: string;
  name: string;
  isActive: boolean;
  value: string;
  icon?: string;
}

export interface IFilterChangeAction {
  filterItem: IFilterItem;
}

export interface ISublistFilterState {
  [FILTER_TYPE_ORIGIN]?: Record<string, IFilterItem>;
  [FILTER_TYPE_LOCATION]?: Record<string, IFilterItem>;
  [FILTER_TYPE_CHARACTERISTICS]?: Record<string, IFilterItem>;
  [FILTER_TYPE_FORMAT]?: Record<string, IFilterItem>;
  [FILTER_TYPE_STYLE]?: Record<string, IFilterItem>;
  [FILTER_TYPE_MOOD]?: Record<string, IFilterItem>;
  [FILTER_TYPE_PRODUCT_CATEGORY]?: Record<string, IFilterItem>;
  [FILTER_TYPE_RATING]: Record<string, IFilterItem>;
}

const initialState: ISublistFilterState = {
  [FILTER_TYPE_RATING]: HARDCODED_RATING_FILTER,
};

const sublistFilterSlice = createSlice({
  initialState,
  name: 'sublistFilter',
  reducers: {
    updateFilterType: (state, action: PayloadAction<IFilterChangeAction>) => {
      const { filterItem } = action.payload;
      const { name, filterType } = filterItem;
      const currentFilterData: Record<string, any> = state[filterType] || {};
      const currentData = currentFilterData[name];
      state[filterType] = {
        ...currentFilterData,
        [name]: currentData
          ? {
              ...currentData,
              isActive: !currentData.isActive,
            }
          : {
              isActive: true,
              ...filterItem,
            },
      };
    },
  },
});

export default sublistFilterSlice.reducer;
export const { updateFilterType } = sublistFilterSlice.actions;

import { TASTE_MATCH_LEVELS, TASTE_MATCH_LEVEL_VALUES, TTasteMatchLevels } from '@lib/core/service/consts';

export const parseTasteMatchLevel = (level: number | null, isUserHasAnyCharacter: boolean): TTasteMatchLevels => {
  const { levelOne, levelTwo, levelThree } = TASTE_MATCH_LEVEL_VALUES;

  if (level === null || level === undefined || !isUserHasAnyCharacter) return TASTE_MATCH_LEVELS.NONE;
  if (level >= levelOne.min && level <= levelOne.max) return TASTE_MATCH_LEVELS.ONE;
  if (level >= levelTwo.min && level <= levelTwo.max) return TASTE_MATCH_LEVELS.TWO;
  if (level >= levelThree.min && level <= levelThree.max) return TASTE_MATCH_LEVELS.THREE;

  return TASTE_MATCH_LEVELS.FOUR;
};

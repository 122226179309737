import { useEffect, useMemo, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hotjar } from 'react-hotjar';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { ENV_IS_BUILD } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useKioskRoot } from '@lib/core/service/hooks/useKioskRoot';
import { useTechnical } from '@lib/core/service/hooks/useTechnical';
import { setServiceLocale } from '@lib/core/service/slices';
import { store } from '@lib/core/service/store';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import { useUserKiosk } from '@lib/core/users/hooks/useUserKiosk';
import { MixpanelExtras } from '@lib/tools/dat/mixpanel';
import RouteUtils from '@lib/tools/routes';
import { useTypedSelector } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

export const KioskSessionManager = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isFetchingEan = useTypedSelector(state => state.scanner?.isScanningLoading || false);
  const { fidelityCardId } = useFidelityCard();
  const { isAnonymousPreference, handleResetKioskUserSession } = useKioskRoot();
  const { isDevToolsEnabled } = useApp();
  const { isUserKioskAdmin } = useUserKiosk();
  const { retailerDefaultLanguage, retailerSlug } = useRetailer();

  const kioskTimeoutSeconds = 60;
  const sessionTimer = useRef(null);
  const [timer, setTimer] = useState(kioskTimeoutSeconds);

  const domEvents = ['load', 'mousemove', 'mousedown', 'click', 'keypress', 'touchstart'];

  const resetCountdown = () => {
    setTimer(kioskTimeoutSeconds);
  };

  useEffect(() => {
    if (!timer) {
      resetCountdown();
      const page = RouteUtils.getPage();
      const isNotWelcomePage = !page.includes(PAGES.vinhood.welcome);

      if (isNotWelcomePage || isAnonymousPreference || fidelityCardId) {
        handleResetKioskUserSession({ showFidelityMessage: false });
        MixpanelExtras.handleKioskTimeout();
      }
    }
  }, [timer, fidelityCardId, isAnonymousPreference]);

  useEffect(() => {
    // TODO fix this in future
    // So what happens when we press F5 with scanned card :
    // Fidelity card slice isn't persisted (logically) and it is cleaned up
    // Profile-id from fidelity card scanning is still connected to user slice
    // resetVinhoodUserSession({ dispatch, showFidelityMessage: false });

    domEvents.forEach(event => {
      window.addEventListener(event, resetCountdown);
    });

    if (ENV_IS_BUILD && !isDevToolsEnabled) {
      sessionTimer.current = setInterval(() => setTimer(d => d - 1), 1000);
    }

    return () => {
      domEvents.forEach(event => {
        window.removeEventListener(event, resetCountdown);
      });
      if (sessionTimer.current) {
        clearInterval(sessionTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (fidelityCardId) {
      resetCountdown();
    }
  }, [fidelityCardId]);

  useEffect(() => {
    if (isUserKioskAdmin) {
      dispatch(setServiceLocale(retailerDefaultLanguage));
    }
  }, [isUserKioskAdmin]);

  const { isClientIpBlocked } = useTechnical();

  useMemo(() => {
    const isBlackListClientIp = isClientIpBlocked;
    if (!isBlackListClientIp && retailerSlug) {
      const { tracking: trackingState } = store.getState();
      const currentLocation = `${location.pathname}${location.search}`;

      // track on hotjar the current location
      if (trackingState.hotjarState) {
        hotjar.stateChange(currentLocation);
      }
    }

    if (!isFetchingEan) {
      resetCountdown();
    }
  }, [location]);

  return <>{children}</>;
};

import { store } from '@lib/core/service/store';

const requestErrorHandler = ({ error, func, url }) => {
  if (error.name === 'AbortError') {
    return store.dispatch(func('abort request'));
  }
  if (error?.cause?.status === 401) {
    return store.dispatch(func('unauth'));
  }
  if (error?.cause) {
    try {
      return store.dispatch(func(error?.cause?.data));
    } catch {
      return store.dispatch(func(`unexpected request error, url: ${url}`));
    }
  }
  return store.dispatch(func(`unexpected request error, url: ${url}`));
};

export default requestErrorHandler;

import React from 'react';

import confetti from '@components/web/src/assets/gif/confetti.gif';
import * as S from '@components/web/src/atoms/Confetti/styles';

interface IProps {
  isVisible?: boolean;
}

const Confetti: React.FC<IProps> = ({ isVisible = true }) => {
  if (!isVisible) return null;

  return (
    <S.ConfettiContainer>
      <S.ImageWrapper alt="Confetti" src={confetti} />
    </S.ConfettiContainer>
  );
};

export default Confetti;

import { FC } from 'react';

import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import fidelityCardIcon from '@components/web/src/assets/legacy/b2b/kiosk/icons/detach_fidelity_icon.svg';

type Props = {
  onClick: () => void;
};
const FidelityDetachButton: FC<Props> = ({ onClick }) => {
  const { fidelityDetachButton } = localeKiosk;
  return (
    <button className="detach-fidelity-card-button" onClick={onClick}>
      <img alt="detach icon" src={fidelityCardIcon} />
      <LocaleFragment message={fidelityDetachButton.fidelityCardLabel} />
    </button>
  );
};

export default FidelityDetachButton;

import { TProductCategory } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { PRODUCT_CATEGORY_WINE } from '@lib/tools/shared/helpers/consts';

import PushButton from '@components/web/src/assets/legacy/b2b/kiosk/push_button_bg.png';
import WelcomePageVector from '@components/web/src/assets/legacy/b2b/kiosk/welcome_vector.png';
import VideoPlaylist from '@components/web/src/components/VideoPlaylist/VideoPlaylist';
import FooterWave from '@components/web/src/shared/Footer/FooterWave';

type WelcomePageProps = {
  mediaList: string | string[];
  handlePushButtonClick?: () => void;
  productCategory?: TProductCategory;
};

const WelcomePage = ({
  mediaList = null,
  handlePushButtonClick,
  productCategory = PRODUCT_CATEGORY_WINE,
}: WelcomePageProps) => {
  const { pageInfo, pageTitle, ctaBtn } = localeKiosk.welcomePageText;
  const { productCategories } = localeCommon;

  return (
    <div className="vh-welcomepage-container">
      <div className="upper-content">
        <div className="media-wrapper">
          <VideoPlaylist videos={mediaList} />
        </div>
        <FooterWave className="welcomepage-footer-wave" />
      </div>
      <div className="bottom-content">
        <div className="vector-wrapper" style={{ background: `url(${WelcomePageVector})` }} />
        <div className="contents-wrapper">
          <p className="page-title">
            <LocaleFragment
              message={pageTitle}
              variables={{ productCategoryText: productCategories[productCategory] }}
              options={{
                productCategory,
              }}
            />
          </p>
          <div aria-hidden className="cta-wrapper" onClick={handlePushButtonClick}>
            <div className="bg" style={{ background: `url(${PushButton})` }}>
              <div className="welcome-cta-text">
                <LocaleFragment message={ctaBtn} />
              </div>
            </div>
          </div>
          <p className="info-badge">
            <LocaleFragment message={pageInfo} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;

import { useSelector } from 'react-redux';

import { selectUserEmail } from '@lib/core/users/selectors/user';
import {
  selectIsUserKioskAdmin,
  selectKioskUserProductCategory,
  selectKioskUserRetailerLocationId,
  selectKioskUserRetailerSlug,
} from '@lib/core/users/selectors/userKiosk';

export const useUserKiosk = () => {
  return {
    isUserKioskAdmin: useSelector(selectIsUserKioskAdmin),
    kioskUserProductCategory: useSelector(selectKioskUserProductCategory),
    kioskUserRetailerLocationId: useSelector(selectKioskUserRetailerLocationId),
    kioskUserRetailerSlug: useSelector(selectKioskUserRetailerSlug),
    userEmail: useSelector(selectUserEmail),
  };
};

import styled, { css } from 'styled-components';

import { TProductCategory } from '@lib/core/products/types';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const ExtendedFlexbox = styled(Flexbox)<{
  $productCategory?: TProductCategory;
  $isTransparentVariant?: boolean;
}>`
  padding: 16px 24px 16px;
  width: 312px;
  height: 100%;
  border-radius: 8px;
  box-shadow: ${({ $isTransparentVariant }) => !$isTransparentVariant && STATIC_SHADOWS.lg};

  ${({ $isTransparentVariant, $productCategory }) =>
    !$isTransparentVariant &&
    css`
      background-color: ${$productCategory
        ? STATIC_COLORS.productColors[$productCategory]['500']
        : STATIC_COLORS.base.white};
    `}

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      gap: 12px;
      width: 397px;
      padding: 0;
    `};
`;

export const MoreText = styled(Text)`
  text-decoration: underline;
  text-transform: lowercase;
  cursor: pointer;
`;

import { createSelector } from '@reduxjs/toolkit';

import { IPromotionProducts } from '@lib/tools/promotionProducts/types/';

/**
 * Selects the entire promotion products state from the Redux store.
 */
export const selectPromotionProductsState = (state): IPromotionProducts => state.promotionProducts || {};

/**
 * Selects whether promotion products are available.
 */
export const selectIsPromotionProductsAvailable = createSelector(
  [selectPromotionProductsState],
  promotionProducts => promotionProducts.isPromotionProductsAvailable,
);

/**
 * Selects the loading state for promotion products.
 */
export const selectIsPromotionProductsLoading = createSelector(
  [selectPromotionProductsState],
  promotionProducts => promotionProducts.isPromotionProductsLoading,
);

import { useSelector } from 'react-redux';

import {
  selectActiveFidelityModalCode,
  selectFidelityCardData,
  selectIsFidelityCardScanLoading,
} from '@lib/core/users/selectors/fidelityCard';

const useFidelityCard = () => {
  const isFidelityCardLoading = useSelector(selectIsFidelityCardScanLoading);
  const fidelityCardData = useSelector(selectFidelityCardData);
  const activeFidelityModalCode = useSelector(selectActiveFidelityModalCode);
  const { card_identifier: fidelityCardId } = fidelityCardData || {};

  return {
    activeFidelityModalCode,
    fidelityCardData,
    fidelityCardId,
    isFidelityCardLoading,
    isFidelityCardScanned: !!fidelityCardId,
  };
};

export { useFidelityCard };

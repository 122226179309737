import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ITechnicalSlice {
  hasRegisteredUserNotFinishedOnboarding?: boolean;
}

const initialState: ITechnicalSlice = {
  hasRegisteredUserNotFinishedOnboarding: false,
};
export const technicalSlice = createSlice({
  initialState,
  name: 'technicalSlice',
  reducers: {
    actionSetRegisteredUserNotFinishedOnboarding: (_, action: PayloadAction<boolean>) => ({
      hasRegisteredUserNotFinishedOnboarding: action.payload,
    }),
  },
});

export default technicalSlice.reducer;

export const { actionSetRegisteredUserNotFinishedOnboarding } = technicalSlice.actions;

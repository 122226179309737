import { useMemo } from 'react';

import { useRecycling, useWellness } from '@lib/core/productAttributes/hooks';
import {
  isRecyclingForProductCategory,
  isRecyclingForProductFormat,
  isWellnessForProductCategory,
} from '@lib/core/productAttributes/utils/filters';
import { TProductCategory } from '@lib/core/products/types';
import { getMultipleUniqueRandomItemsFromArray } from '@lib/core/service/utils';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import { IReadMoreCardProps } from '@components/web/src/organisms/Cards/ReadMoreCard/ReadMoreCard';

export const useSustainability = (productCategory: TProductCategory, productFormatIdentifier: string) => {
  const { sustainabilityWellness, sustainabilityRecycling } = localeCommon.productPage;

  const { recyclingData, isRecyclingLoading } = useRecycling();
  const { wellnessData, isWellnessLoading } = useWellness();

  const sustainabilityData: IReadMoreCardProps[] = useMemo(() => {
    if (!recyclingData.length || !wellnessData.length || !productCategory) return [];

    const filteredWellnessData = getMultipleUniqueRandomItemsFromArray(
      wellnessData.filter(wellness => isWellnessForProductCategory(wellness, productCategory)) || [],
      1,
    );

    const filteredRecyclingData = getMultipleUniqueRandomItemsFromArray(
      recyclingData
        .filter(recycling => isRecyclingForProductCategory(recycling, productCategory))
        .filter(recycling => isRecyclingForProductFormat(recycling, productFormatIdentifier)) || [],
      1,
    );

    const wellnessDescription = filteredWellnessData[0]?.description || '';
    const recyclingDescription = filteredRecyclingData[0]?.description || '';

    return [
      wellnessDescription && {
        cardTitle: sustainabilityWellness,
        description: wellnessDescription,
      },
      recyclingDescription && {
        cardTitle: sustainabilityRecycling,
        description: recyclingDescription,
      },
    ].filter(item => item.cardTitle && item.description);
  }, [recyclingData, wellnessData, productCategory, productFormatIdentifier]);

  return { isSustainabilityDataLoading: isRecyclingLoading || isWellnessLoading, sustainabilityData };
};

export default useSustainability;

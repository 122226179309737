import { FC, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TLanguage } from '@lib/core/retailers/types';
import { setServiceLocale } from '@lib/core/service/slices';
import { languages } from '@lib/tools/locale/utils/consts';
import useOutsideClick from '@lib/tools/views/hooks/useOutsideClick';

import GBicon from '@components/web/src/assets/legacy/b2b/kiosk/icons/GB.svg';
import ITicon from '@components/web/src/assets/legacy/b2b/kiosk/icons/IT.svg';
import LanguageItem from '@components/web/src/organisms/LanguageSwitcher/LanguageItem';
import LanguageList from '@components/web/src/organisms/LanguageSwitcher/LanguageList/LanguageList';
import * as S from '@components/web/src/organisms/LanguageSwitcher/styles';

export type ILanguageSwitcherType = 'select' | 'icon';

interface IProps {
  languageSwitcherType: ILanguageSwitcherType;
  retailerLanguages: TLanguage[];
  locale: string;
}

const LanguageSwitcher: FC<IProps> = ({ retailerLanguages, languageSwitcherType, locale }) => {
  const dispatch = useDispatch();

  const [showLanguagesList, setShowLanguagesList] = useState(false);

  const handleOutsideClick = () => setShowLanguagesList(false);
  const handleToggleLanguagesList = () => setShowLanguagesList(!showLanguagesList);

  const ref = useRef();
  useOutsideClick(showLanguagesList ? ref : null, handleOutsideClick);

  const handleSelectLanguage = selectedLanguageCode => {
    if (selectedLanguageCode !== locale) {
      dispatch(setServiceLocale(selectedLanguageCode));
    }
    setShowLanguagesList(false);
  };

  const currentLanguage = useMemo(() => retailerLanguages.find(({ code }) => code === locale), [locale]);
  const filteredLanguagesList = useMemo(() => retailerLanguages.filter(({ code }) => code !== locale), [locale]);

  if (!retailerLanguages || retailerLanguages?.length < 2) return null;
  return (
    <>
      {languageSwitcherType === 'select' ? (
        <S.LanguageSwitcherContainer ref={ref}>
          <LanguageItem
            handleSelectLanguage={handleSelectLanguage}
            handleToggleLanguagesList={handleToggleLanguagesList}
            languageCode={currentLanguage?.code || locale}
            languageText={currentLanguage?.language || ''}
            showLanguagesList={showLanguagesList}
          />
          {showLanguagesList && (
            <LanguageList filteredLanguagesList={filteredLanguagesList} handleSelectLanguage={handleSelectLanguage} />
          )}
        </S.LanguageSwitcherContainer>
      ) : (
        <S.IconContainer align="flex-start" gap={8} justify="flex-start">
          {retailerLanguages?.map(({ code }) => {
            const isSelected = locale === code;
            const icon = code === languages.ITALIAN ? ITicon : GBicon;

            return (
              <S.LanguageSwitcherButton key={code} $isSelected={isSelected} onClick={() => handleSelectLanguage(code)}>
                <S.IconImage $isSelected={isSelected} alt={`${code} icon`} src={icon} />
              </S.LanguageSwitcherButton>
            );
          })}
        </S.IconContainer>
      )}
    </>
  );
};

export default LanguageSwitcher;

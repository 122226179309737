const CircleIcon = ({ backgroundColor, className = '' }) => {
  return (
    <svg className={className} fill="none" height="251" viewBox="0 0 238 251" width="238">
      <path
        d="M73.1154 1.73233C183.27 -7.56237 248.21 35.7349 235.75 145.544C227.236 201.847 157.882 263.076 85.096 248.265C35.0674 243.885 -12.1862 201.736 -22.7626 162.662C-33.6175 122.519 -20.6451 70.2278 -10.782 51.3821C3.02629 25.0359 34.1535 5.90046 73.1154 1.73233Z"
        fill="white"
      />
      <path
        d="M110.561 10.882C170.731 6.13435 208.846 43.915 216.458 98.8919C224.293 155.518 203.006 201.602 161.905 217.305C91.2919 244.297 11.6742 201.635 -1.75513 145.298C-12.2534 101.277 9.1222 52.463 25.5161 38.0863C45.0639 20.9345 71.4433 13.9691 110.561 10.882Z"
        fill={backgroundColor}
      />
    </svg>
  );
};

export default CircleIcon;

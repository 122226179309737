import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ZodType, z } from 'zod';

import { ENV_RECAPTCHA_SITE_KEY } from '@lib/core/service/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';
import ReCaptchaContainer from '@lib/tools/views/web/containers/reCaptchaContainer';

import InputField from '@components/web/src/components/Input/InputFields';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Authentication/KioskLoginForm/styles';

export interface IFormData {
  email: string;
  password: string;
}

interface LoginFormProps {
  onSubmit: (values: { email: string; password: string }) => void;
  handleLogin: (recaptchaValue: string) => void;
  isCaptchaDisabled: boolean;
  recaptchaRef: Record<string, any>;
}

const LoginForm: FC<LoginFormProps> = ({ onSubmit, handleLogin, isCaptchaDisabled, recaptchaRef }) => {
  const [isBtnDisabled, setIsBtnDisabled] = useState(ENV_RECAPTCHA_SITE_KEY && !isCaptchaDisabled);

  const { email, login, password, emailValidationText, passwordValidationText } = localeCommon.loginForms;

  const { publishedTerms } = LocaleUtils;

  const placeholders = {
    email: publishedTerms[email.id],
    emailValidationText: publishedTerms[emailValidationText.id],
    password: publishedTerms[password.id],
    passwordValidationText: passwordValidationText[password.id],
  };

  const validationSchema: ZodType = useMemo(
    () =>
      z.object({
        email: z
          .string()
          .min(1, placeholders.emailValidationText)
          .nonempty(placeholders.emailValidationText)
          .email({ message: placeholders.emailValidationText }),
        password: z.string().min(1, placeholders.passwordValidationText).nonempty(placeholders.passwordValidationText),
      }),
    [],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormData>({
    defaultValues: {},
    mode: 'onBlur',
    resolver: zodResolver(validationSchema),
  });

  const submitHandler = (formData: IFormData) => onSubmit(formData);

  return (
    <S.LoginForm noValidate autoComplete="off" onSubmit={handleSubmit(submitHandler)}>
      <InputField label={placeholders.email} {...register('email')} errors={errors} type="email" />
      <InputField label={placeholders.password} {...register('password')} errors={errors} type="password" />
      <ReCaptchaContainer
        handleValue={handleLogin}
        isDisabled={!!(ENV_RECAPTCHA_SITE_KEY && !isCaptchaDisabled)}
        recaptchaRef={recaptchaRef}
        setIsBtnDisabled={setIsBtnDisabled}
      />
      <S.Button disabled={isBtnDisabled} type="submit">
        <Text color={STATIC_COLORS.base.white} size="h4" text={login} weight="medium" />
      </S.Button>
    </S.LoginForm>
  );
};

export default LoginForm;

import { useDispatch } from 'react-redux';

import { useRetailer, useRetailerLocation } from '@lib/core/retailers/hooks';
import { useApp } from '@lib/core/service/hooks';
import { useThirdPartyNewsletter } from '@lib/core/service/hooks/useThirdPartyNewsletter';
import { setIsThirdPartyConnectShown } from '@lib/core/service/slices';
import { ThirdPartyConnectModalScenario } from '@lib/core/service/types/interface';
import { useModals } from '@lib/tools/modals/hooks';

import ThirdPartyConnectModal from '@components/web/src/templates/Modals/ThirdPartyConnectModal/ThirdPartyConnectModal';

const ThirdPartyConnectModalContainer = () => {
  const dispatch = useDispatch();
  const { isThirdPartyConnectModalOpened, closeAllModals } = useModals();
  const { retailerTags } = useRetailer();

  const { retailerLocationName, retailerLocationSlug } = useRetailerLocation();
  const { scenarioForThirdPartyConnectModalOpened, productCategory } = useApp();
  const retailerLocationNameText = retailerLocationName || retailerLocationSlug || '';
  const { thirdPartyNewsletterTexts, handleThirdPartyNewsletter } = useThirdPartyNewsletter();

  const closeModalWithScenario = () => {
    if (scenarioForThirdPartyConnectModalOpened.scenario) {
      dispatch(setIsThirdPartyConnectShown(scenarioForThirdPartyConnectModalOpened.scenario));
    }
    closeAllModals();
  };

  const handleBtnClick = (acceptance: boolean) => {
    handleThirdPartyNewsletter(acceptance);
    closeModalWithScenario();
  };

  return (
    <ThirdPartyConnectModal
      handleBtnClick={handleBtnClick}
      hideModal={() => closeModalWithScenario()}
      isOpen={isThirdPartyConnectModalOpened}
      productCategory={productCategory}
      productName={scenarioForThirdPartyConnectModalOpened.productName}
      retailerLocationNameText={retailerLocationNameText}
      retailerLogo={retailerTags?.headerLogo}
      scenario={scenarioForThirdPartyConnectModalOpened.scenario as ThirdPartyConnectModalScenario}
      thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
    />
  );
};

export default ThirdPartyConnectModalContainer;

import { css, styled } from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';

export const FeedbackListItemContainer = styled.button`
  all: unset;
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

export const FeedbackIcon = styled.span<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: ${STATIC_COLORS.base.white};
  border-radius: 8px;
  box-shadow: ${STATIC_SHADOWS.md};

  ${({ $isActive }) =>
    $isActive &&
    css`
      box-shadow: none;
      border: 1px solid ${STATIC_COLORS.warmGray[500]};
      background-color: ${STATIC_COLORS.warmGray[100]};
    `}
`;

import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { LEGAL_TAB_SLUG_REPLACEMENT } from '@lib/core/service/consts';
import { prependBasename } from '@lib/core/service/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  LEGAL_PAGE_COOKIE_POLICY_PAGE,
  LEGAL_PAGE_PRIVACY_POLICY_PAGE,
  LEGAL_PAGE_TERMS_AND_CONDITIONS_PAGE,
} from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import { ReactComponent as GearIcon } from '@components/web/src/assets/icons/footer/icon_footer_gear.svg';
import * as S from '@components/web/src/atoms/Footers/FooterWidgetAnonymous/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

interface IProps {
  isHideGearIcon: boolean;
}

const FooterWidgetAnonymous: FC<IProps> = ({ isHideGearIcon = true }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { commonMessages } = localeCommon;

  const onLinkClick = (pageSlug, pageName) => {
    if (location.pathname.includes(pageSlug)) return;
    MixpanelTracker.events.footerNavigationClick({ pageName });
    navigate(prependBasename(PAGES.vinhood.legal.tabs.replace(LEGAL_TAB_SLUG_REPLACEMENT, pageSlug)));
  };

  const footerMenu = [
    {
      pageName: 'Legal',
      pageSlug: LEGAL_PAGE_TERMS_AND_CONDITIONS_PAGE,
      text: commonMessages.termsAndConditionsTitle,
    },
    {
      pageName: 'Privacy policy',
      pageSlug: LEGAL_PAGE_PRIVACY_POLICY_PAGE,
      text: commonMessages.privacyPolicyTitle,
    },
    {
      pageName: 'Cookie policy',
      pageSlug: LEGAL_PAGE_COOKIE_POLICY_PAGE,
      text: commonMessages.cookiePolicyTitle,
    },
  ];

  return (
    <S.FooterWidgetAnonymousContainer>
      {footerMenu.map(({ text, pageName, pageSlug }, idx) => (
        <S.Link key={idx} onClick={() => onLinkClick(pageSlug, pageName)}>
          <Text color={STATIC_COLORS.base.white} size="body2" text={text} />
        </S.Link>
      ))}
      <S.Link
        key="gear"
        $isHidden={isHideGearIcon}
        onClick={() => navigate(prependBasename(PAGES.vinhood.legal.generalServiceTerms))}
      >
        <GearIcon />
      </S.Link>
    </S.FooterWidgetAnonymousContainer>
  );
};

export default FooterWidgetAnonymous;

import { useSelector } from 'react-redux';

import {
  selectFeaturedProducts,
  selectFiltersData,
  selectIsAnyProductsDataLimitedAvailable,
  selectIsFeaturedProductsLoading,
  selectIsProductsRequestLoading,
  selectLastDataLoadedTime,
  selectLastFeaturedProductsLoadedTime,
  selectProductsDataCount,
  selectProductsDataLimitedFirstPriority,
  selectProductsDataLimitedSecondPriority,
  selectProductsDataLimitedThirdPriority,
  selectProductsDataResults,
  selectStaticMatchedProducts,
} from '@lib/tools/productCatalog/selectors';

export const useProductCatalog = () => ({
  featuredProducts: useSelector(selectFeaturedProducts),
  filtersData: useSelector(selectFiltersData),
  firstPriorityProducts: useSelector(selectProductsDataLimitedFirstPriority),
  isAnyProductsDataLimitedAvailable: useSelector(selectIsAnyProductsDataLimitedAvailable),
  isFeaturedProductsLoading: useSelector(selectIsFeaturedProductsLoading),
  isProductsRequestLoading: useSelector(selectIsProductsRequestLoading),
  itemsCount: useSelector(selectProductsDataCount),
  lastDataLoadedTime: useSelector(selectLastDataLoadedTime),
  lastFeaturedProductsLoadedTime: useSelector(selectLastFeaturedProductsLoadedTime),
  products: useSelector(selectProductsDataResults),
  secondPriorityProducts: useSelector(selectProductsDataLimitedSecondPriority),
  staticMatchedProducts: useSelector(selectStaticMatchedProducts),
  thirdPriorityProducts: useSelector(selectProductsDataLimitedThirdPriority),
});

import { Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider, useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

// eslint-disable-next-line import/order
import { store } from '@lib/core/service/store';
import { QA_URL_PARAM_CURRENT_DATE } from '@lib/core/service/consts';
import ServiceLauncher from '@lib/core/service/providers/ServiceLauncher';
import { updateCurrentDateParams } from '@lib/core/service/slices/qaParameterSlice';
import { Navigator } from '@lib/core/service/utils/Navigator';
import UserProvider from '@lib/core/users/providers/UserProvider';
import DatProvider from '@lib/tools/dat/views/DatProvider';
import { DevTools } from '@lib/tools/devtools';
import LocaleProvider from '@lib/tools/locale/providers/LocaleProvider';
import ScannerHOC from '@lib/tools/scanner/providers/ScannerProvider';
import { DataProvider } from '@lib/tools/shared/views/providers/DataProvider';
import HocWrapper from '@lib/tools/shared/views/providers/HocWrapper';
import { ThemeProvider } from '@lib/tools/shared/views/providers/ThemeProvider';

import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';

import { KioskSessionManager } from 'containers/KioskSessionManager';
import AsyncRouterKiosk from 'routes/RouterKiosk';

// Styles
import 'rc-slider/assets/index.css';
import '@components/web/src/styles/_index.scss';

const persistor = persistStore(store);

const View = () => {
  const { location } = window;

  const dispatch = useDispatch();

  useEffect(() => {
    // INFO: This part is done for QA accessibility so that we can change the params part
    // to check different unique items per day passing the date as current date on params
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const qaCurrentDate = searchParams.get(QA_URL_PARAM_CURRENT_DATE);

      if (qaCurrentDate) {
        dispatch(updateCurrentDateParams(qaCurrentDate));
      }
    }

    return () => {
      new AbortController().abort();
    };
  }, []);

  return (
    <LocaleProvider>
      <BrowserRouter>
        <Navigator />
        <DevTools />

        <Suspense fallback={<LoadingSpinner variant="spinner" />}>
          <DatProvider>
            <UserProvider>
              <DataProvider>
                <ThemeProvider>
                  <HocWrapper>
                    <KioskSessionManager>
                      <ScannerHOC>
                        <AsyncRouterKiosk />
                      </ScannerHOC>
                    </KioskSessionManager>
                  </HocWrapper>
                </ThemeProvider>
              </DataProvider>
            </UserProvider>
          </DatProvider>
        </Suspense>
      </BrowserRouter>
    </LocaleProvider>
  );
};

const App = ServiceLauncher(View);

createRoot(document.getElementById('root')!).render(
  <PersistGate loading={null} persistor={persistor}>
    <ReduxProvider store={store}>
      <App key="kiosk" props={undefined} type={undefined} />
    </ReduxProvider>
  </PersistGate>,
);

import { css, styled } from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const TasteMatchModalWrapper = styled(Flexbox)`
  position: relative;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      padding: 40px 40px 24px;
    `}
`;

export const TasteMatchContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
  align-items: center;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      gap: 8px;
    `}
`;

export const TasteMatchActions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      margin-top: 16px;
      gap: 10px;
    `}
`;

export const Icon = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-left: auto;
  cursor: pointer;
  top: 16px;
  right: 16px;

  svg {
    stroke: black;
  }
`;

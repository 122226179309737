import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getHeaderAppLogoUrl } from '@app/native/src/helpers/headerApp';
/* eslint-disable import/no-extraneous-dependencies */
import ThirdPartyConnectModalContainer from '@app/web/src/containers/ThirdPartyConnectModalContainer';
import { useHomeData } from '@app/web/src/hooks/useHomeData';

import { catalogDownloadAppBanner } from '@lib/core/banners/hooks';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { GPRL_CHARACTER_QUERY, GPRL_PROMOTIONS_QUERY, isApplicationKiosk } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useKioskRoot } from '@lib/core/service/hooks/useKioskRoot';
import { useScrollToTop } from '@lib/core/service/hooks/useScrollToTop';
import { useServiceInstance } from '@lib/core/service/hooks/useServiceInstance';
import { useThirdPartyNewsletter } from '@lib/core/service/hooks/useThirdPartyNewsletter';
import {
  setIsTasteIdGuideShown,
  setServiceLocale,
  setShouldHideDownloadAppCard,
  setSplashScreenPageShown,
} from '@lib/core/service/slices';
import { prependBasename } from '@lib/core/service/utils';
import { useProductFeedback, useProductList, useUser } from '@lib/core/users/hooks';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import { useUserKiosk } from '@lib/core/users/hooks/useUserKiosk';
import { USER_ROLE_ANONYMOUS } from '@lib/core/users/utils/consts';
import UserUtils from '@lib/core/users/utils/users';
import { isAppInIframe } from '@lib/tools/comms/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { useExploreLocations } from '@lib/tools/explore/hooks';
import { useModals } from '@lib/tools/modals/hooks';
import { fetchPromotionProducts, resetPromotionProducts } from '@lib/tools/promotionProducts/slices';
import RouteUtils from '@lib/tools/routes';
import { MODALS, PRODUCT_CATEGORY_QUERY, PROMOTION_LABEL_SLUG } from '@lib/tools/shared/helpers/consts';
import { useToastMessage } from '@lib/tools/toastMessage/hooks';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import { HeaderAppProps } from '@components/web/src/app/HeaderApp/HeaderApp';
import { IFooterAppLoggedProps } from '@components/web/src/atoms/Footers/FooterAppLogged/FooterAppLogged';
import { HeaderFooterApp, HeaderFooterKiosk, HeaderFooterWidget } from '@components/web/src/atoms/HeaderFooter';
import RootElement from '@components/web/src/atoms/RootElement/rootElement';

type ISendHocProps = {
  footerProps: IFooterAppLoggedProps & { showGearIcon?: boolean; shouldHideFooter?: boolean };
  headerProps: HeaderAppProps;
  isAuthenticated: boolean;
  isAuthFooter?: boolean;
  isSplashScreenPageShown: boolean;
};

// ? Separate view (header/footer) logic and api-store logic
const HocWrapper = ({
  children,
  isHeaderShown = true,
  showSearch = true,
  showNotification = false,
  showGearIcon = true,
}) => {
  useScrollToTop();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { retailerLanguages, isAnonymousRetailerAccess, retailerName } = useRetailer();
  const { retailerLocationProducerId, retailerLocationName } = useRetailerLocation();
  const { handleResetKioskUserSession } = useKioskRoot();

  const { openModal } = useModals();
  const { isDisableHomeAddon, isExternalCharacterPage, isEnableAppBanner } = useAddons();

  const {
    userImage,
    userRole,
    isUserAuthenticated,
    userBestMatchCharacterForCurrentServiceProductCategory,
    isUserHasCharacterForCurrentPC,
    isUserHasAnyCharacter,
  } = useUser();

  const { isUserKioskAdmin } = useUserKiosk();
  const { isShowThirdPartyNewsletter } = useThirdPartyNewsletter();
  const { toastMessages, handleClearToastMessage } = useToastMessage();

  const { isDesignSetVinhoodApp, isDesignSetVinhoodExperience } = useRetailerDesignSet();
  const {
    productCategory,
    isSplashScreenPageShown,
    locale,
    isTasteIdGuideShown,
    shouldHideDownloadAppCard,
    shouldHideLoginPrompt,
    shouldShowLanguageSwitcher,
  } = useApp();
  const { fidelityCardId } = useFidelityCard();
  const { isServiceInstanceFeatureEnabled } = useServiceInstance();
  const { isLoading: isHomePageLoading } = useHomeData();
  const currentUserCharacterId = userBestMatchCharacterForCurrentServiceProductCategory?.identifier;

  const isHomePage = RouteUtils.getPage().includes(PAGES.vinhood.home);
  const isWelcomePage = RouteUtils.getPage().includes(PAGES.vinhood.welcome);
  const isTestResultPage = RouteUtils.getPage().includes(PAGES.vinhood.quizResults.result);
  const isRedirectPage = RouteUtils.getPage().includes(PAGES.vinhood.redirect);
  const isCatalogPage = RouteUtils.getPage().includes(PAGES.vinhood.catalog);
  const isProductPage = RouteUtils.getPage().includes(PAGES.vinhood.product);
  const isRegistrationPage = RouteUtils.getPage().includes(PAGES.vinhood.registration);
  const isJournalPage = [PAGES.vinhood.tasteId.tasteIdProductsJournal, PAGES.vinhood.tasteId.tasteIdPlacesJournal].some(
    page => RouteUtils.getPage().includes(page),
  );

  const { feedbackData, isFeedbackLoading } = useProductFeedback();
  const producerName = retailerLocationName;
  const { exploreLocationList } = useExploreLocations();
  const { wishlistProductInstanceIds, isWishlistProductListLoading } = useProductList();

  useEffect(() => {
    /** If Service Instance (SI) feature is enabled:
     * Anon user:
     * - with character for SI PC navigate to App Registration page
     * - without character for SI PC navigate to App Start page
     * Auth user:
     * - with character for SI PC navigate to Widget Policy Acceptance page
     * - without character for SI PC navigate to Widget Home page
     */

    if (!isServiceInstanceFeatureEnabled) return;

    if (!isUserAuthenticated && currentUserCharacterId) {
      navigate(prependBasename(PAGES.vinhood.registration));
    } else if (isUserAuthenticated) {
      RouteUtils.navigateToWidgetServiceInstance(currentUserCharacterId ? PAGES.vinhood.catalog : PAGES.vinhood.home);
    }
  }, [isServiceInstanceFeatureEnabled]);

  useEffect(() => {
    if (isApplicationKiosk && fidelityCardId) {
      /** For kiosk users with fidelity cards:
       * Changing loyalty card updates data
       * Changing user character checks availability of promotional products
       * Feature only works if promotional products are available for user's current character */
      dispatch(resetPromotionProducts());

      if (currentUserCharacterId) {
        dispatch(
          fetchPromotionProducts({
            [GPRL_CHARACTER_QUERY]: currentUserCharacterId,
            [GPRL_PROMOTIONS_QUERY]: PROMOTION_LABEL_SLUG,
            [PRODUCT_CATEGORY_QUERY]: productCategory,
            offset: 0,
          }),
        );
      }
    }
  }, [fidelityCardId, currentUserCharacterId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setSplashScreenPageShown());
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const shouldShowTasteIdGuide =
    isUserAuthenticated &&
    !isTasteIdGuideShown &&
    [...feedbackData, ...wishlistProductInstanceIds].length > 0 &&
    (!isWishlistProductListLoading || !isFeedbackLoading);

  const handleSetTasteIdPageShown = () =>
    [...feedbackData, ...wishlistProductInstanceIds].length > 0 &&
    (!isWishlistProductListLoading || !isFeedbackLoading) &&
    dispatch(setIsTasteIdGuideShown(true));

  useEffect(() => {
    let timer;
    if (shouldShowTasteIdGuide) {
      timer = setTimeout(handleSetTasteIdPageShown, 10000);
    }
    return () => clearTimeout(timer);
  }, [shouldShowTasteIdGuide]);

  if (isDesignSetVinhoodApp) {
    const onHeaderIconClick = () => navigate(getHeaderAppLogoUrl());

    const showLanguageSwitcher = !isTestResultPage && userRole === USER_ROLE_ANONYMOUS;

    const sendHocProps: ISendHocProps = {
      footerProps: {
        isFooterDisabled: isDesignSetVinhoodApp ? !isUserHasAnyCharacter : !isUserHasCharacterForCurrentPC,
        profileImageUrl: userImage,
        shouldHideFooter: (isDesignSetVinhoodApp && isTestResultPage && !isUserAuthenticated) || isJournalPage,
        showGearIcon,
      },
      headerProps: {
        // check role and refactor again later if needed
        isHeaderShown: isHeaderShown && !isJournalPage,
        is_notification_enabled: !!userRole && showNotification,
        is_search_enabled: !!userRole && showSearch,
        is_show_language: showLanguageSwitcher,
        locale,
        onHeaderIconClick,
        retailerLanguages,
      },
      isAuthFooter: isUserAuthenticated && !isJournalPage,
      isAuthenticated: !!userRole,
      isSplashScreenPageShown,
    };

    return (
      <RootElement>
        <HeaderFooterApp {...sendHocProps}>{children}</HeaderFooterApp>
      </RootElement>
    );
  }

  if (isDesignSetVinhoodExperience) {
    const shouldHideAnonymousFooter = isUserAuthenticated;
    const shouldHideLoggedFooter = !isUserAuthenticated || isAnonymousRetailerAccess;
    const shouldShowDownloadAppCard = !shouldHideDownloadAppCard && isEnableAppBanner && isCatalogPage;
    const isRegistrationPromptShown =
      !shouldHideLoginPrompt && !isUserAuthenticated && (isCatalogPage || isProductPage);
    const isToShowThirdPartyModal = isUserAuthenticated && isShowThirdPartyNewsletter;

    const handleLogoClick = () => {
      const shouldNavigateUserToTest = isDisableHomeAddon && isUserAuthenticated && isCatalogPage;

      const url = shouldNavigateUserToTest ? PAGES.vinhood.quiz.chooseExpert : PAGES.vinhood.home;

      navigate(prependBasename(url));
    };

    const handleSettingPageNavigation = () => navigate(prependBasename(PAGES.vinhood.tasteId.tasteIdSettings));

    const handleHideDownloadAppClick = () => dispatch(setShouldHideDownloadAppCard());

    const handleDownloadAppClick = () => {
      const { identifier, text, link, positions } = catalogDownloadAppBanner;
      MixpanelTracker.events.bannerClick(identifier, text, link, positions[0]);
      openModal(MODALS.DOWNLOAD_APP_MODAL);
    };

    const handleExitNavigation = () => {
      let page = '';
      if (!UserUtils.isUserHasCharactersForEachRetailerLocationCategories()) {
        page = PAGES.vinhood.start;
      } else if (UserUtils.isUserHasCharactersForEachRetailerLocationCategories() && !isUserAuthenticated) {
        page = PAGES.vinhood.registration;
      } else {
        page = PAGES.vinhood.home;
      }

      RouteUtils.navigateToAppServiceInstance(page);
    };

    const handleSelectLanguage = (selectedLanguageCode: string) => dispatch(setServiceLocale(selectedLanguageCode));

    return isRedirectPage ? (
      <>{children}</>
    ) : (
      <RootElement>
        <HeaderFooterWidget
          handleClearToastMessage={handleClearToastMessage}
          handleDownloadAppClick={handleDownloadAppClick}
          handleExitNavigation={handleExitNavigation}
          handleHideDownloadAppClick={handleHideDownloadAppClick}
          handleLogoClick={handleLogoClick}
          handleSelectLanguage={handleSelectLanguage}
          handleSetTasteIdPageShown={handleSetTasteIdPageShown}
          handleSettingPageNavigation={handleSettingPageNavigation}
          isEnableExitNavigation={false}
          isEnableLanguageSwitcher={shouldShowLanguageSwitcher}
          isEnableSettingsNavigation={isUserAuthenticated}
          isFixedBody={isJournalPage}
          isHideGearIcon={isAppInIframe}
          isHomePage={isHomePage}
          isHomePageLoading={isHomePageLoading}
          isMultipleProducer={exploreLocationList.length > 1}
          isRegistrationPromptShown={isRegistrationPromptShown}
          locale={locale}
          producerName={producerName}
          profileImageUrl={userImage}
          retailerLanguages={retailerLanguages}
          retailerName={retailerName}
          shouldHideAnonymousFooter={shouldHideAnonymousFooter}
          shouldHideCatalogLink={isExternalCharacterPage}
          shouldHideExploreLink={!retailerLocationProducerId}
          shouldHideHeader={!isUserAuthenticated && isRegistrationPage}
          shouldHideHomeLink={isDisableHomeAddon}
          shouldHideLoggedFooter={shouldHideLoggedFooter}
          shouldHideTasteIdLink={!isUserHasCharacterForCurrentPC}
          shouldShowDownloadAppCard={shouldShowDownloadAppCard}
          shouldShowHeaderDropDownMenu={false}
          shouldShowTasteIdGuide={shouldShowTasteIdGuide}
          toastMessages={toastMessages}
        >
          {children}
          {isToShowThirdPartyModal && <ThirdPartyConnectModalContainer />}
        </HeaderFooterWidget>
      </RootElement>
    );
  }

  if (isApplicationKiosk) {
    return isWelcomePage || !isUserKioskAdmin ? (
      <>{children}</>
    ) : (
      <RootElement>
        <HeaderFooterKiosk
          handleDetachButtonClick={() => handleResetKioskUserSession({ showFidelityMessage: true })}
          handleLogoClick={() => navigate(prependBasename(PAGES.vinhood.home))}
          isHomePage={isHomePage}
          isShowDetachFidelityCard={!!fidelityCardId}
          isShowLanguageSwitcher={isHomePage}
          locale={locale}
          retailerLanguages={retailerLanguages}
        >
          {children}
        </HeaderFooterKiosk>
      </RootElement>
    );
  }

  return <>{children}</>;
};

export default HocWrapper;

const ArrowIcon = ({ fillColor = '#367A86', size = 'long' }: { fillColor?: string; size?: 'long' | 'short' }) => {
  if (size === 'short') {
    return (
      <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.8334 10.0001H4.16669M4.16669 10.0001L10 15.8334M4.16669 10.0001L10 4.16675"
          stroke={fillColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.6"
        />
      </svg>
    );
  }
  return (
    <svg className="icon" fill="none" height="16" viewBox="0 0 32 16" width="32">
      <path
        d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM31 9C31.5523 9 32 8.55228 32 8C32 7.44772 31.5523 7 31 7V9ZM1 9H31V7H1V9Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default ArrowIcon;

import { createSelector } from 'reselect';

import { IRetailerSlice } from '@lib/core/retailers/slices/retailer';
import { STORE } from '@lib/core/service/consts';
import { languages } from '@lib/tools/locale/utils/consts';
import {
  ANONYMOUS_RETAILER_ACCESS,
  AUTHENTICATED_RETAILER_ACCESS,
  DISABLED_RETAILER_ACCESS,
} from '@lib/tools/shared/helpers/consts';

import { THIRD_PARTY_CATEGORY } from '@components/web/src/widget/consts';

/**
 * @returns the whole retailer state
 */
const selectRetailerState = (state): IRetailerSlice => state?.retailer || {};

/**
 * @returns the retailer's data
 */
export const selectRetailerData = createSelector([selectRetailerState], retailerState => retailerState?.data || {});

export const selectRetailerSlug = createSelector([selectRetailerData], retailerData => retailerData?.slug || '');

export const selectRetailerPrivacyUrl = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.privacy_url || '',
);
/**
 * Contains custom configs for the retailer.
 * @returns the retailer's tags
 */
export const selectRetailerTags = createSelector([selectRetailerData], retailerData => retailerData?.tags || {});

/**
 * @returns the url for retailer's Logo
 */
export const selectRetailerLogo = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.tags?.headerLogo || '',
);

/**
 * @returns the retailer's name
 */
export const selectRetailerName = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.name || retailerData?.slug || '',
);
/**
 * @returns the retailer's id
 */
export const selectRetailerId = createSelector([selectRetailerData], retailerData => retailerData?.identifier || '');

/**
 * @returns the retailer's languages
 */
export const selectRetailerLanguages = createSelector(
  [selectRetailerState],
  retailer => retailer?.data?.supported_languages || [],
);
/**
 * @returns the retailer's page types
 */
export const selectRetailerPageType = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.page_type || STORE,
);

/**
 * @returns the retailer's privacy option where category is marketing
 */
export const selectRetailer3dPartyAgreement = createSelector([selectRetailerData], retailerData =>
  retailerData?.privacy_options.find(privacy => privacy.category === THIRD_PARTY_CATEGORY),
);

/**
 * @returns the retailer's default language
 */
export const selectRetailerDefaultLanguage = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.language || languages.ENGLISH,
);

/**
 * @returns the retailer's error
 */

export const selectRetailerError = createSelector([selectRetailerState], retailer => retailer?.error || '');

/**
 * @returns the retailer loader.
 */
export const selectRetailerLoading = createSelector([selectRetailerState], retailer => retailer?.isLoading || false);

/**
 * @returns the retailer widget access
 */
export const selectRetailerWidgetAccess = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.widget || DISABLED_RETAILER_ACCESS,
);

/**
 * @returns state of a retailer access
 */
export const selectIsAuthenticatedRetailerAccess = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.widget === AUTHENTICATED_RETAILER_ACCESS,
);

/**
 * @returns state of a retailer access
 */
export const selectIsAnonymousRetailerAccess = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.widget === ANONYMOUS_RETAILER_ACCESS,
);

/**
 * @returns the retailer custom style
 */
export const selectRetailerCustomStyle = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.custom_style || null,
);

/**
 * @returns the retailer's custom fidelity card image
 */
export const selectRetailerFidelityCardImage = createSelector(
  [selectRetailerData],
  retailerData => retailerData?.custom_style?.fidelityCardLogo || '',
);

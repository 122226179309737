import { css, styled } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const FilterPageButtonWrapper = styled(Flexbox)`
  padding: 24px 16px;

  button {
    min-width: 110px;
  }
`;

export const FilterPageComponent = styled.div`
  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      width: 100%;
      padding: 0;
      padding: 0 32px;
      background-color: ${STATIC_COLORS.base.white};
      border-radius: 0 0 20px 20px;
    `};
`;

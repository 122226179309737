import { createSlice } from '@reduxjs/toolkit';

import { actionPatchUserQuizComplete } from '@lib/core/quizzes/slices';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import { requestWithKeysAsOptions } from '@lib/core/service/requests/request';
import { getProfileCharactersApiUrlCreator } from '@lib/core/users/slices/urls';
import { IUserCharacter } from '@lib/core/users/types';

export interface IUserCharacterSlice {
  data: IUserCharacter[];
  isLoading: boolean;
  error: string;
}

const initialState: IUserCharacterSlice = {
  data: [],
  error: '',
  isLoading: false,
};

export const actionGetUserCharacters = createTypedAsyncThunk(
  'user/actionGetUserCharacters',
  async () =>
    await requestWithKeysAsOptions({
      apiPath: getProfileCharactersApiUrlCreator(),
      withErrorReturn: true,
    }),
);
export const charactersSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetUserCharacters.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(actionGetUserCharacters.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(actionGetUserCharacters.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    // This is the new basic approach to immediately update character after the quiz is completed, may be improved
    builder.addCase(actionPatchUserQuizComplete.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.itWillUpdateUserCharacter) {
        state.data = [...state.data, ...action.payload.characters.map(character => ({ character }))];
      }
    });
  },
  initialState,
  name: 'userCharacters',
  reducers: {
    actionResetUserCharactersSlice: () => initialState,
  },
});

export default charactersSlice.reducer;

export const { actionResetUserCharactersSlice } = charactersSlice.actions;

import cn from 'classnames';
import { FC } from 'react';

import { ReactComponent as BottomMask } from '@components/web/src/assets/svg/masks/bottom_wave.svg';

type Props = {
  backgroundImage: string;
  className?: string;
};

const BottomOverlay: FC<Props> = ({ backgroundImage, className }) => {
  return (
    <div className={cn('bottom-overlay-mask-container', className)}>
      <div
        className="image-container"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      />
      <div className="mask-container">
        <BottomMask />
      </div>
    </div>
  );
};

export default BottomOverlay;

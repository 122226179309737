import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@lib/core/service/types/appStateType';
import { IModalTypes } from '@lib/tools/modals/slice';

const selectModalState = (modalName: IModalTypes) =>
  createSelector(
    (state: RootState) => state?.modals?.modalState,
    modalState => modalState[modalName] || false,
  );

export { selectModalState };

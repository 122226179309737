import { FC } from 'react';

import { TLanguage } from '@lib/core/retailers/types';
import MixpanelTracker from '@lib/tools/dat/mixpanel';

import * as S from '@components/web/src/atoms/Headers/HeaderKiosk/styles';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import FidelityDetachButton from '@components/web/src/kiosk/FidelityDetachButton/FidelityDetachButton';
import LanguageSwitcher from '@components/web/src/organisms/LanguageSwitcher/LanguageSwitcher';
import VinhoodLogo from '@components/web/src/shared/Logo';

interface IProps {
  isShowLanguageSwitcher: boolean;
  isShowDetachFidelityCard?: boolean;
  retailerLanguages: TLanguage[];
  locale: string;
  handleLogoClick: () => void;
  handleDetachButtonClick: () => void;
}

export const HeaderKiosk: FC<IProps> = ({
  isShowLanguageSwitcher,
  isShowDetachFidelityCard,
  retailerLanguages,
  locale,
  handleLogoClick,
  handleDetachButtonClick,
}) => {
  return (
    <S.HeaderKioskContainer align="center" justify="space-between" padding="0 32px">
      <S.LogoWrapper onClick={handleLogoClick}>
        <VinhoodLogo isLargeVariant />
      </S.LogoWrapper>
      <Flexbox align="center" gap={24}>
        {isShowDetachFidelityCard && (
          <FidelityDetachButton
            onClick={() => {
              handleDetachButtonClick();
              MixpanelTracker.events.detachFidelityCard();
            }}
          />
        )}
        {isShowLanguageSwitcher && (
          <LanguageSwitcher languageSwitcherType="icon" locale={locale} retailerLanguages={retailerLanguages} />
        )}
      </Flexbox>
    </S.HeaderKioskContainer>
  );
};

import React from 'react';

import { TWishlistVariant } from '@components/web/src/atoms/Wishlist/WishlistAnimation/WishlistAnimation';
import { STATIC_COLORS } from '@components/web/src/foundations';

interface WishlistIconProps {
  iconVariant?: 'default' | 'splash';
  variant?: TWishlistVariant;
  status?: 'start' | 'end';
  isLargeSize?: boolean;
}

const WishlistIcon: React.FC<WishlistIconProps> = ({
  iconVariant = 'default',
  variant = 'default',
  status = 'start',
  isLargeSize = false,
}) => {
  const isStartStatus = status === 'start';
  const isSplashIconVariant = iconVariant === 'splash';
  const isLightVariant = variant === 'light';

  const getFillColor = () => {
    if (isLightVariant) return isStartStatus ? STATIC_COLORS.teal[25] : STATIC_COLORS.teal[600];
    return isStartStatus ? 'none' : STATIC_COLORS.teal[600];
  };

  const getStrokeColor = () => {
    if (isLightVariant) return isStartStatus ? STATIC_COLORS.teal[600] : STATIC_COLORS.teal[25];
    return STATIC_COLORS.teal[600];
  };

  if (isSplashIconVariant) {
    return (
      <svg fill="none" height="45" viewBox="0 0 48 45" width="48">
        <g filter="url(#filter0_d_10358_28575)" id="Property 1=Variant10">
          {[
            { cx: '35', cy: '10', fillOpacity: '0.5', r: '2' },
            { cx: '36', cy: '22', fillOpacity: '0.8', r: '2' },
            { cx: '17', cy: '29', fillOpacity: '0.8', r: '2' },
            { cx: '11', cy: '24', fillOpacity: '0.5', r: '1' },
            { cx: '35', cy: '30', fillOpacity: '0.5', r: '1' },
            { cx: '15', cy: '12', fillOpacity: '0.5', r: '1' },
            { cx: '22', cy: '13', fillOpacity: '0.8', r: '2' },
          ].map((circleProps, idx) => (
            <circle
              key={idx}
              cx={circleProps.cx}
              cy={circleProps.cy}
              fill={STATIC_COLORS.teal[600]}
              fillOpacity={circleProps.fillOpacity}
              id={`Ellipse ${idx + 49}`}
              r={circleProps.r}
            />
          ))}
          <path
            d="M30.593 14.3221C31.693 14.4501 32.5 15.3991 32.5 16.5071V32.0001L25 28.2501L17.5 32.0001V16.5071C17.5 15.3991 18.306 14.4501 19.407 14.3221C23.1232 13.8907 26.8768 13.8907 30.593 14.3221Z"
            fill={STATIC_COLORS.teal[600]}
            id="Vector"
            stroke={STATIC_COLORS.teal[600]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
        <defs>
          <filter
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
            height="47"
            id="filter0_d_10358_28575"
            width="48"
            x="0"
            y="0"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_10358_28575" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow_10358_28575" mode="normal" result="shape" />
          </filter>
        </defs>
      </svg>
    );
  }

  return (
    <svg fill="none" height={isLargeSize ? '44' : '24'} viewBox="0 0 24 24" width={isLargeSize ? '44' : '24'}>
      <path
        d="M17.593 3.32206C18.693 3.45006 19.5 4.39906 19.5 5.50706V21.0001L12 17.2501L4.5 21.0001V5.50706C4.5 4.39906 5.306 3.45006 6.407 3.32206C10.1232 2.89069 13.8768 2.89069 17.593 3.32206Z"
        fill={getFillColor()}
        stroke={getStrokeColor()}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default WishlistIcon;

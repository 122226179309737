import cn from 'classnames';
import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';

import Banner, { TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';
import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import { IB2CRecipe } from '@components/web/src/components/Recipe/RecipeItem/RecipeItem';
import RecipeResult from '@components/web/src/components/Recipe/RecipeResult/RecipeResult';
import RecipeSearchHeader from '@components/web/src/components/Recipe/RecipeSearchHeader/RecipeSearchHeader';
import KioskCirclesBackground from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';

type Props = {
  productCategory: TProductCategory;
  isRecipesLoading: boolean;
  recipes: IB2CRecipe[];
  recipePageBanner: TBannerCard;
  value: string;
  isNoRecipesFound: boolean;
  isDesignSetVinhoodApp: boolean;
  isApplicationKiosk?: boolean;
  handleBannerClick?: (route?: string, linkParams?: string) => void;
  searchHandler: (searchText: string) => void;
  redirectToPreviousPage: () => void;
};

const RecipePage: FC<Props> = ({
  productCategory,
  isRecipesLoading,
  recipes,
  recipePageBanner,
  value,
  isNoRecipesFound,
  isDesignSetVinhoodApp,
  isApplicationKiosk,
  handleBannerClick,
  searchHandler,
  redirectToPreviousPage,
}) => {
  const { recipePage } = localeCommon;

  const { publishedTerms } = LocaleUtils;

  const searchPlaceholderText = publishedTerms[recipePage.searchPlaceholder?.id];

  return (
    <div className={cn('recipe-search-page-container', { isLargeVariant: isApplicationKiosk })}>
      {isApplicationKiosk && <KioskCirclesBackground />}
      <RecipeSearchHeader
        isApplicationKiosk={isApplicationKiosk}
        isNoRecipesFound={isNoRecipesFound}
        placeholder={searchPlaceholderText}
        productCategory={productCategory}
        searchHandler={searchHandler}
        value={value}
      />
      <RecipeResult
        isApplicationKiosk={isApplicationKiosk}
        isDesignSetVinhoodApp={isDesignSetVinhoodApp}
        isRecipesLoading={isRecipesLoading}
        productCategory={productCategory}
        recipes={recipes}
      />
      <Banner {...recipePageBanner} onClickHandler={handleBannerClick} />
      <BackButton handleClick={redirectToPreviousPage} variant="long" />
    </div>
  );
};

export default RecipePage;

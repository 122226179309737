import { css, styled } from 'styled-components';

import { STATIC_COLORS, styleMatcher } from '@components/web/src/foundations';

export type TVariant = 'dark' | 'light';
export type TType = 'regular' | 'bold';
export type TPosition = 'left' | 'right';

export const getVariantStyles = (variant: TVariant) =>
  styleMatcher(variant, {
    dark: () => css`
      border: 1px solid ${STATIC_COLORS.base.black};

      &::after,
      &::before {
        background-color: ${STATIC_COLORS.base.black};
      }
    `,
    light: () => css`
      border: 1px solid ${STATIC_COLORS.base.white};

      &::after,
      &::before {
        background-color: ${STATIC_COLORS.base.white};
      }
    `,
  });

export const CloseButtonContainer = styled.button<{ $type: TType; $variant: TVariant; $position: TPosition }>`
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  border-radius: 50%;

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 9px;
    height: 1px;
    content: '';
    border-radius: 8px;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 9px;
    height: 1px;
    content: '';
    border-radius: 8px;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  ${({ $type }) =>
    $type === 'bold' &&
    css`
      border-width: 1.5px;

      &::after,
      &::before {
        height: 1.5px;
      }
    `}

  ${({ $position }) =>
    $position === 'right' &&
    css`
      margin-left: auto;
    `}

  ${({ $variant }) => getVariantStyles($variant)}
`;

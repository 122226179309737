import { FC } from 'react';

import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';

import pairings1 from '@components/web/src/assets/icons/largeProductPage/pairings1.svg';
import pairings2 from '@components/web/src/assets/icons/largeProductPage/pairings2.svg';
import pairings3 from '@components/web/src/assets/icons/largeProductPage/pairings3.svg';
import { Text } from '@components/web/src/foundations/Text/Text';
import { IRecipe } from '@components/web/src/organisms/Cards/ChefRecommendsCard/ChefRecommendsCard';
import * as S from '@components/web/src/templates/Modals/ChefRecommendedPairingsModal/styles';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

interface Props {
  onClose: () => void;
  recipesData: IRecipe[];
  isChefRecommendedPairingsModalOpen: boolean;
}

const ChefRecommendedPairingsModal: FC<Props> = ({ onClose, recipesData, isChefRecommendedPairingsModalOpen }) => {
  const { productPage } = localeKiosk;
  const selectIcon = (order: number) => {
    let icon = '';
    switch (order) {
      case 0:
        icon = pairings1;
        break;
      case 1:
        icon = pairings2;
        break;
      case 2:
        icon = pairings3;
        break;
      default:
        icon = pairings1;
    }
    return icon;
  };

  return (
    <ModalWrapper maxContentHeight hideModal={onClose} isOpen={isChefRecommendedPairingsModalOpen}>
      <S.ChefRecommendedPairingsModalContainer>
        <S.StyledCloseButton handleClick={onClose} variant="dark" />
        <S.TitleTextWrapper>
          <Text size="h4" text={productPage.chefRecommendedPairingsTitle} weight="semibold" />
        </S.TitleTextWrapper>
        {recipesData.map(({ title, subtitle, image }, index) => (
          <S.RecipeCard key={index}>
            <S.RecipeCardImage alt="image" height="236px" src={image} width="290px" />
            <S.RecipeCardTextPart>
              <S.RecipeCardTitleWrapper>
                <S.RecipeCardTitleImage alt="icon" src={selectIcon(index)} />
                <Text size="h6" text={subtitle} weight="medium" />
              </S.RecipeCardTitleWrapper>
              <Text size="h6" text={title} weight="semibold" />
            </S.RecipeCardTextPart>
          </S.RecipeCard>
        ))}
      </S.ChefRecommendedPairingsModalContainer>
    </ModalWrapper>
  );
};
export default ChefRecommendedPairingsModal;

import React, { FC, useState } from 'react';

import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useTechnical } from '@lib/core/service/hooks/useTechnical';
import { actionLogin } from '@lib/core/users/slices/auth';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

import KioskLoginComponent from '@components/web/src/templates/Login&Registration/KioskLoginPage/KioskLogin';

const AdminContainer: FC = () => {
  const recaptchaRef: any = React.createRef();
  const dispatch = useTypedDispatch();
  const [loginValues, setLoginValues] = useState({
    email: '',
    password: '',
  });
  const { isClientIpLoading, isCaptchaDisabled } = useTechnical();
  const { isRetailerLoading } = useRetailer();
  const isFetching = isRetailerLoading || isClientIpLoading;

  const handleLogin = captchaValue => {
    dispatch(actionLogin({ ...loginValues, captchaValue, isCaptchaDisabled }));
  };

  const onSubmit = values => {
    setLoginValues(values);
    if (recaptchaRef.current) {
      recaptchaRef.current.execute();
    } else {
      handleLogin(values);
    }
  };

  const sendProps = {
    handleLogin,
    isCaptchaDisabled,
    isFetching: isRetailerLoading || isClientIpLoading,
    onSubmit,
    recaptchaRef,
  };

  if (isFetching) {
    return null;
  }

  return <KioskLoginComponent {...sendProps} />;
};

export default AdminContainer;

import { css, styled } from 'styled-components';

export const CharacterScrollerContainer = styled.div`
  width: 100%;

  .swiper {
    width: 100%;
  }
`;

export const CharacterScrollerItemContainer = styled.div<{ $isActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 225px;

  img {
    position: relative;
    z-index: 1;
    height: ${({ $isActive }) => ($isActive ? '100%' : '180px')};

    transition:
      height 750ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 750ms cubic-bezier(0.4, 0, 0.2, 1),
      filter 750ms cubic-bezier(0.4, 0, 0.2, 1);

    ${({ $isActive }) =>
      !$isActive &&
      css`
        opacity: 0.75;
        filter: blur(2.5px);
      `};
  }
`;

export const BackgroundIcon = styled.div<{ $backgroundColor?: string; $isActive: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: ${({ $isActive }) => ($isActive ? '190px' : '150px')};
  width: ${({ $isActive }) => ($isActive ? '190px' : '150px')};
  border-radius: 50%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};

  transition:
    height 1500ms cubic-bezier(0.4, 0, 0.2, 1),
    width 1500ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 1500ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 1500ms cubic-bezier(0.4, 0, 0.2, 1);
`;

/**
 * Used for get client machine IP (we need it for geo-blocking by country)
 *
 * @returns {string} url for get client machine IP
 */
export const apiUrlLoginClientIP = '/users/get-user-ip/';

/**
 * Used for send information for external CRM functionality
 *
 * @returns {string} url for send information for external CRM functionality
 */

export const apiUrlCreateExternalCRM = '/users/external-crm/';

export const apiUrlCountriesList = '/users/countries/';

import styled from 'styled-components';

import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import { DEFAULT_COLOR_THEME, STATIC_COLORS } from '@components/web/src/foundations';

export const ProgressBarContainer = styled.div`
  position: relative;
  width: 100%;
  height: 8px;
  margin: 0 auto;
  background-color: rgb(35 32 33 / 10%);
  border-radius: 4px;
`;

export const WidgetCustomBackgroud = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  opacity: 0.2;
`;

export const ProgressBar = styled.div<{ $variant: string }>`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: 8px;
  border-radius: 4px;
  width: 0;
  transition: width 0.5s;
  background-color: ${({ $variant }) => {
    switch ($variant) {
      case VH_VARIANTS.PRIMARY:
        return `${DEFAULT_COLOR_THEME.primaryColor['-500']}`;
      case VH_VARIANTS.LIGHT:
        return `${STATIC_COLORS.base.white}`;
      default:
        return `${STATIC_COLORS.productColors[$variant][500]}`;
    }
  }};
`;

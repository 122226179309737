import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IRetailerSlice } from '@lib/core/retailers/slices/retailer';
import { retailerDataApiUrlCreator } from '@lib/core/retailers/slices/urls';
import { IRetailerDetail } from '@lib/core/retailers/types/retailerDetail';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';

export interface IServiceInstance {
  retailer: IRetailerSlice;
  serviceInstanceProductCategory: string;
  serviceInstanceRetailerLocationId: string;
  serviceInstanceRetailerSlug: string;
  serviceInstanceRetailerLocationSlug: string;
}

export const initialState: IServiceInstance = {
  retailer: {
    data: undefined,
    error: '',
    isLoading: false,
  },
  serviceInstanceProductCategory: '',
  serviceInstanceRetailerLocationId: '',
  serviceInstanceRetailerLocationSlug: '',
  serviceInstanceRetailerSlug: '',
};

export const actionGetServiceInstanceRetailerData = createTypedAsyncThunk(
  'serviceInstance/retailer/get',
  async ({
    serviceInstanceRetailerSlug,
  }: {
    serviceInstanceRetailerSlug: string;
    serviceInstanceProductCategory: string;
    serviceInstanceRetailerLocationId: string;
    serviceInstanceRetailerLocationSlug: string;
  }) => await request(retailerDataApiUrlCreator({ retailerSlug: serviceInstanceRetailerSlug })),
);

const serviceInstance = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetServiceInstanceRetailerData.pending, (state, { meta: { arg } }) => {
      const {
        serviceInstanceProductCategory,
        serviceInstanceRetailerLocationId,
        serviceInstanceRetailerSlug,
        serviceInstanceRetailerLocationSlug,
      } = arg;

      state.retailer.error = '';
      state.retailer.isLoading = true;
      state.serviceInstanceProductCategory = serviceInstanceProductCategory;
      state.serviceInstanceRetailerLocationId = serviceInstanceRetailerLocationId;
      state.serviceInstanceRetailerSlug = serviceInstanceRetailerSlug;
      state.serviceInstanceRetailerLocationSlug = serviceInstanceRetailerLocationSlug;
    });
    builder.addCase(actionGetServiceInstanceRetailerData.fulfilled, (state, action: PayloadAction<IRetailerDetail>) => {
      const { payload } = action;
      state.retailer.isLoading = false;
      state.retailer.data = payload;
    });
    builder.addCase(actionGetServiceInstanceRetailerData.rejected, (state, action) => {
      state.retailer.isLoading = false;
      state.retailer.error = action.payload?.message || action.error?.message;
    });
  },
  initialState,
  name: 'serviceInstance',
  reducers: {},
});

export default serviceInstance.reducer;

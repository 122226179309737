import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

const StackIcon = ({ className, variant }) => {
  const color = variant === VH_VARIANTS.PRIMARY ? '#FFF' : '#2C636D';

  return (
    <svg className={className} fill="none" height="20" viewBox="0 0 20 20" width="20">
      <g clipPath="url(#clip0_1798_6332)">
        <path
          d="M1.6665 14.1665L9.99984 18.3332L18.3332 14.1665M1.6665 9.99984L9.99984 14.1665L18.3332 9.99984M9.99984 1.6665L1.6665 5.83317L9.99984 9.99984L18.3332 5.83317L9.99984 1.6665Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.67"
        />
      </g>
      <defs>
        <clipPath id="clip0_1798_6332">
          <rect fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StackIcon;

import React, { FC, useEffect, useRef } from 'react';

import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import * as S from '@components/web/src/organisms/ProgressBar/styles';

export type IProgressBarVariant =
  | typeof VH_VARIANTS.PRIMARY
  | typeof VH_VARIANTS.LIGHT
  | typeof VH_VARIANTS.WINE
  | typeof VH_VARIANTS.BEER
  | typeof VH_VARIANTS.COFFEE;

interface Props {
  progressPercentage: number;
  variant?: IProgressBarVariant;
}

const ProgressBar: FC<Props> = ({ progressPercentage, variant = VH_VARIANTS.PRIMARY }) => {
  const progressBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (progressBarRef.current) {
      progressBarRef.current.style.width = `${progressPercentage}%`;
    }
  }, [progressPercentage]);
  return (
    <S.ProgressBarContainer>
      <S.ProgressBar ref={progressBarRef} $variant={variant} />
    </S.ProgressBarContainer>
  );
};

export default React.memo(ProgressBar);

import { TProductCategory } from '@lib/core/products/types';
import { isApplicationKiosk } from '@lib/core/service/consts';
import { getRequestCommonParameters } from '@lib/core/service/requests/api';
import { store } from '@lib/core/service/store';
import { selectFidelityCardId } from '@lib/core/users/selectors/fidelityCard';
/**
 * Used for get and update user data
 *
 * @returns {string} url for get user data based on retailer if ID is provided
 */
export const userDataApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/users/${retailerSlug}/${retailerLocationSlug}/refresh-user/`;
};

/**
 * Use to get and update user profile data
 *
 * @returns {string} url
 */
export const profileDataApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug, profileId } = getRequestCommonParameters();
  return `/${retailerSlug}/${retailerLocationSlug}/users/profile/${profileId}/`;
};

export const deleteFidelityCardUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  const { getState } = store;
  const state = getState();
  const fidelityCardID = selectFidelityCardId(state);
  return `/${retailerSlug}/${retailerLocationSlug}/users/fidelity-card/${fidelityCardID}`;
};

/**
 * Used to get all product lists
 * Create a new list: @param { list_name: '', product: '' }
 * Add a new item: @param { list_name: '', product: '', list_slug: '' }
 *
 * @returns {string} url
 */
export const productListApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/${retailerSlug}/${retailerLocationSlug}/users/product-list/`;
};

/**
 * Used to get specific product list data
 *
 * @returns {string} url
 */
export const productListProductApiUrlCreator = (listSlug: string) => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/${retailerSlug}/${retailerLocationSlug}/users/product-list/${listSlug}/product/`;
};

/**
 * Used to remove a product from the specific list
 *
 * @returns {string} url
 */
export const updateProductListProductApiUrlCreator = (listSlug: string, productId: string) => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();

  return `/${retailerSlug}/${retailerLocationSlug}/users/product-list/${listSlug}/product/${productId}/`;
};

/**
 * Used to get, post, remove location
 *
 * @returns {string} url
 */
export const locationListApiUrlCreator = (locationSlug?: string) => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();

  return `/${retailerSlug}/${retailerLocationSlug}/users/loc-list/${locationSlug ? `${locationSlug}/` : ''}`;
};

/**
 * Used to get, post, remove product feedback
 *
 * @returns {string} url
 */
export const productFeedbackDataApiUrlCreator = (productId?: string) => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();

  return `/${retailerSlug}/${retailerLocationSlug}/users/product-feedback/${productId ? `${productId}/` : ''}`;
};

/**
 * Used to retrieve products suggestion for the Taste Path feature
 *
 * @returns {string} url
 */
export const productsSuggestionApiUrlCreator = ({ productCategory = '' }: { productCategory: TProductCategory }) => {
  const { retailerSlug, retailerLocationSlug, userSessionId } = getRequestCommonParameters();

  return `/products/${retailerSlug}/${retailerLocationSlug}/suggestion/${productCategory}/${userSessionId}/`;
};

/**
 * Send email and password as auth method
 */
export const loginApiUrlCreator = () => {
  if (isApplicationKiosk) {
    return `/users/rest-auth/token/`;
  }

  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/users/${retailerSlug}/${retailerLocationSlug}/rest-auth/token/`;
};

/**
 * Send token obtained from react-oauth/google to our back-end as auth method
 */
export const googleLoginApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/users/${retailerSlug}/${retailerLocationSlug}/rest-auth/google/`;
};

/**
 * Send token obtained from react-oauth/google to our back-end as auth method
 */
export const facebookLoginApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/users/${retailerSlug}/${retailerLocationSlug}/rest-auth/facebook/`;
};

/**
 * Send auth data for register user
 */
export const registrationApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/users/${retailerSlug}/${retailerLocationSlug}/email-registration/`;
};

/**
 * Send email to start password reset process
 */
export const resetPasswordApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/users/${retailerSlug}/${retailerLocationSlug}/rest-auth/password/reset/`;
};

/**
 * Send email to set new password
 */
export const setNewPasswordApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/users/${retailerSlug}/${retailerLocationSlug}/rest-auth/password/reset/confirm/`;
};

/**
 * Used for upload user picture (avatar) and attach it to user profile
 *
 * @returns {string} url for upload user picture (avatar) and attach it to user profile
 */
export const uploadUserPictureApiUrlCreator = () => {
  const { userId } = getRequestCommonParameters();
  return `/users/${userId}/s3-picture-upload/`;
};

export const postUserFeedback = () => {
  return `/users/feedback/`;
};

export const fidelityCardApiUrlCreator = ({ fidelityCardCode = '' }) => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/${retailerSlug}/${retailerLocationSlug}/users/fidelity-card/${fidelityCardCode ? `${fidelityCardCode}/` : ''}`;
};

export const getProfileCharactersApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/${retailerSlug}/${retailerLocationSlug}/users/character/`;
};

/**
 * Uses for getting preference options and updating user preferences
 */
export const dietaryPreferenceApiUrlCreator = (preferenceSlug?: string) => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/${retailerSlug}/${retailerLocationSlug}/users/dietary-preference/${preferenceSlug ? `${preferenceSlug}/` : ''}`;
};

/**
 * Uses for removing multiple user preferences
 */
export const dietaryPreferenceMultipleRemoveApiUrlCreator = preferenceExposure => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/${retailerSlug}/${retailerLocationSlug}/users/dietary-preference/bulk/${preferenceExposure}/`;
};

/**
 * Uses for updating multiple user preferences
 */
export const dietaryPreferenceMultipleUpdateApiUrlCreator = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/${retailerSlug}/${retailerLocationSlug}/users/dietary-preference/bulk/`;
};

import { css, styled } from 'styled-components';

import { TBackButtonVariant } from '@components/web/src/atoms/Buttons/BackButton/BackButton';

export const BackButtonContainer = styled.button<{
  $variant: TBackButtonVariant;
}>`
  all: unset;
  display: flex;
  gap: 4px;
  align-items: center;
  width: 100%;
  padding: 8px 16px 16px;

  ${({ $variant }) =>
    $variant === 'long' &&
    css`
      gap: 8px;
      padding: 12px 0px;
      justify-content: center;
    `}
`;

import { createSelector } from 'reselect';

import { ICharactersSlice } from '@lib/core/characters/slices';
import { TCharacter } from '@lib/core/characters/types';

/**
 * @returns the characters slice state
 */
export const selectCharactersState = (state): ICharactersSlice => state.characters || {};

/**
 * @returns all characters array
 */
export const selectCharacters = createSelector([selectCharactersState], charactersState => charactersState.data || []);

/**
 * @returns all characters as JSON with identifiers as keys
 */
export const selectCharactersByIdentifiers = createSelector([selectCharacters], characters => {
  const charactersByIdentifiers: Record<string, TCharacter> = {};
  characters.forEach(character => {
    charactersByIdentifiers[character.identifier] = character;
  });
  return charactersByIdentifiers;
});

/**
 * @returns loading state for characters
 */
export const selectIsCharactersLoading = createSelector(
  [selectCharactersState],
  characters => characters.isLoading || false,
);

/**
 * @returns user characters for category
 */
export const selectCharactersByCategory = createSelector([selectCharacters], charactersArray => {
  const characters: Record<string, TCharacter[]> = {};
  charactersArray.forEach(characterData => {
    const { product_category: pc = '' } = characterData || {};
    if (characters[pc]) {
      characters[pc].push(characterData);
    } else {
      characters[pc] = [characterData];
    }
  });
  return characters || {};
});

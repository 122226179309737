import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IB2CExperience } from '@app/native/src/interfaces/experience';

import { TProductCategory } from '@lib/core/products/types';
import { PRODUCER_QUERY } from '@lib/core/service/consts';
import backendApiUrls from '@lib/core/service/requests/backend_api_urls';
import request from '@lib/core/service/requests/request';
import { PRODUCT_CATEGORY_QUERY } from '@lib/tools/shared/helpers/consts';

type IPostPayload = {
  productCategory: TProductCategory;
  producer?: string;
};

export interface IExperienceRequestData {
  count: number;
  results: IB2CExperience[];
}

export interface IExperienceCatalogState {
  experienceRequestError: string;
  experiencesData: IExperienceRequestData;
  experiencesTypes: any[];
  isExperienceRequestLoading: boolean;
}

export const initialExperienceCatalogState: IExperienceCatalogState = {
  experienceRequestError: '',
  experiencesData: {
    count: 0,
    results: [],
  },
  experiencesTypes: [],
  isExperienceRequestLoading: false,
};

export const fetchExperiencesLists = createAsyncThunk(
  'experienceCatalog/fetchExperiencesLists',
  async ({ productCategory, producer = '' }: IPostPayload) => {
    const apiUrl = backendApiUrls.apiUrlB2CExperiencesLists;
    const config = {
      params: {
        ...(productCategory && { [PRODUCT_CATEGORY_QUERY]: productCategory }),
        ...(producer && { [PRODUCER_QUERY]: producer }),
      },
    };
    const json = await request(apiUrl, config);
    const { results = [] } = json;
    const experiencesTypes = [
      ...new Map(
        results.map(experiences => [experiences.experience_type.identifier, experiences.experience_type]),
      ).values(),
    ];
    const sortedExperiencesTypes = experiencesTypes.sort((a: any, b: any) => a.identifier.localeCompare(b.identifier));

    return { experiencesTypes: sortedExperiencesTypes, results };
  },
);

export const experienceCatalogSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(fetchExperiencesLists.fulfilled, (state, action) => {
      state.isExperienceRequestLoading = false;
      state.experiencesTypes = action.payload?.experiencesTypes;
      state.experiencesData.results = action.payload?.results;
    });
    builder.addCase(fetchExperiencesLists.pending, state => {
      state.isExperienceRequestLoading = true;
      state.experiencesData.results = [];
    });
  },
  initialState: initialExperienceCatalogState,
  name: 'experienceCatalog',
  reducers: {},
});

export default experienceCatalogSlice.reducer;

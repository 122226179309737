import { TProductCategory } from '@lib/core/products/types';
import { selectUserQuizId } from '@lib/core/quizzes/selectors';
import {
  selectRemoteAccessCustomId,
  selectRemoteAccessExpiry,
  selectRemoteAccessKey,
  selectRemoteAccessRetries,
} from '@lib/core/retailers/selectors/remoteAccess';
import { selectRetailerSlug } from '@lib/core/retailers/selectors/retailer';
import {
  selectAssociatedRetailerLocation,
  selectRetailerLocationId,
} from '@lib/core/retailers/selectors/retailerLocation';
import {
  ACTION_PERFORMED_URL_PARAM,
  MIXPANEL_EVENT_SLUG_URL_PARAM,
  PRODUCT_CATEGORY_NONE,
  REDIRECT_URL_PARAM,
  isApplicationKiosk,
} from '@lib/core/service/consts';
import { selectLocale, selectServiceProductCategory } from '@lib/core/service/selectors';
import { selectRouteBasename } from '@lib/core/service/selectors/routes';
import {
  selectServiceInstanceProductCategory,
  selectServiceInstanceRetailerLocationId,
  selectServiceInstanceRetailerSlug,
} from '@lib/core/service/selectors/serviceInstance';
import { store } from '@lib/core/service/store';
import { prependBasename } from '@lib/core/service/utils';
import { selectUserSessionId } from '@lib/core/users/selectors/user';
import {
  selectIsUserKioskAdmin,
  selectKioskUserProductCategory,
  selectKioskUserRetailerLocationId,
  selectKioskUserRetailerSlug,
} from '@lib/core/users/selectors/userKiosk';
import { isAppInIframe } from '@lib/tools/comms/utils';
import { MixpanelActionPerformedContext } from '@lib/tools/dat/mixpanel/consts';
import { languages } from '@lib/tools/locale/utils/consts';
import {
  CHARACTERS_URL_PARAM,
  FIDELITY_ID_URL_PARAM,
  IS_FROM_KIOSK_QR_URL_PARAM,
  IS_REDIRECTED_FROM_WIDGET,
  VH_RETAILER_URL_SLUG_APP,
} from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

/**
 * * Toolkit for operations on URLs and browser navigation.
 * * Standard URL format: `/:locale/:retailerSlug/loc/:retailerLocationId/p/:productCategory/:page/`
 */
class RouteUtils {
  /**
   * Remote GT URL Search Params
   */
  public static remoteAccessParams = {
    accessKey: 'access_key',
    customId: 'custom_id',
    expiry: 'expiry',
    expiryHours: 'expiry_hours',
    retries: 'retries',
  };

  /**
   * @returns `true` if `:locale` is supported
   */
  private static hasLocale(): boolean {
    const { pathname } = window.location;
    const firstPathSegment = pathname.split('/')[1];

    return languages ? Object.values(languages).some(language => firstPathSegment === language) : false;
  }

  /**
   * @returns :locale from the basename
   */
  public static getLocale(): string {
    const { pathname } = window.location;
    const firstPathSegment = pathname.split('/')[1];

    if (this.hasLocale()) return Object.values(languages).filter(language => firstPathSegment === language)[0];
    return null;
  }

  /**
   * @returns :retailerSlug from the basename
   */
  public static getRetailerSlug(): string {
    const { pathname } = window.location;
    const firstPathSegment = pathname.split('/')[1];
    const secondPathSegment = pathname.split('/')[2];
    return this.getLocale() === firstPathSegment ? secondPathSegment : firstPathSegment;
  }

  /**
   * @returns :retailerLocationId from the basename
   */
  public static getRetailerLocationId(): string {
    const { pathname } = window.location;
    return pathname.includes('/loc/') ? pathname.split('/loc/')[1]?.split('/')[0] : '';
  }

  /**
   * @returns :productCategory from the basename
   */
  public static getProductCategory(url?: string): TProductCategory {
    const { pathname } = window.location;

    if (url) return url.includes('/p/') ? url.split('/p/')[1]?.split('/')[0] : '';
    return pathname.includes('/p/') ? pathname.split('/p/')[1]?.split('/')[0] : '';
  }

  /**
   * @returns the path after the basename
   */
  public static getPage(): string {
    const { pathname, search } = window.location;
    let page = pathname;

    const locale = this.getLocale();
    const retailerSlug = this.getRetailerSlug();
    const retailerLocationId = this.getRetailerLocationId();
    const productCategory = this.getProductCategory();

    if (!page.endsWith('/')) page += '/';
    if (locale) page = page.replace(`/${locale}/`, '/');
    if (retailerSlug) page = page.replace(`/${retailerSlug}/`, '/');
    if (retailerLocationId) page = page.replace(`/loc/${retailerLocationId}/`, '/');
    if (productCategory) page = page.replace(`/p/${productCategory}/`, '/');

    return page + search;
  }

  /**
   * Retrieves the search query string from the current window location.
   *
   * @returns The search query string.
   */
  public static getSearch(): string {
    const { search } = window.location;
    return search;
  }

  /**
   * * Generates the basename for the routers
   *
   * @returns `/:locale/:retailerSlug/loc/:retailerLocationId/p/:productCategory`
   */
  public static generateBasename(): string {
    const state = store.getState();
    const isUserKioskAdmin = selectIsUserKioskAdmin(state);

    const locale = selectLocale(state);
    let retailerSlug = selectRetailerSlug(state);
    let retailerLocationId = selectRetailerLocationId(state);
    let productCategory = selectServiceProductCategory(state);

    // * Lock kiosks to kiosk account settings
    if (isUserKioskAdmin) {
      retailerSlug = selectKioskUserRetailerSlug(state);
      retailerLocationId = selectKioskUserRetailerLocationId(state);
      productCategory = selectKioskUserProductCategory(state);
    }

    let routeBasename = '';

    if (retailerSlug && retailerLocationId) {
      routeBasename += `/${locale}/${retailerSlug}/loc/${retailerLocationId}`;

      // * Handle cases like TA product category page and vh-app
      if (productCategory !== PRODUCT_CATEGORY_NONE) {
        routeBasename += `/p/${productCategory}`;
      }
    }

    return routeBasename;
  }

  /**
   * Generates URL for navigation to mobile-kiosk Redirect Container
   * to track mixpanel events and apply @param payload values
   *
   * @param payload[REDIRECT_URL_PARAM] - /:page/ or URL
   *
   * @returns `URL`
   */
  public static generateRedirectURL(payload: {
    [REDIRECT_URL_PARAM]: string;
    [MIXPANEL_EVENT_SLUG_URL_PARAM]: string;
    [FIDELITY_ID_URL_PARAM]: string;
    [ACTION_PERFORMED_URL_PARAM]: MixpanelActionPerformedContext;
    [CHARACTERS_URL_PARAM]?: string;
    [IS_FROM_KIOSK_QR_URL_PARAM]?: string;
  }): string {
    const state = store.getState();
    let { origin } = window.location;
    origin = origin.replace('kiosk', 'app');

    const retailerLocationId = selectRetailerLocationId(state);
    const retailerLocationIdForReplacement = selectAssociatedRetailerLocation(state)?.identifier;
    const routeBasename = selectRouteBasename(state);
    const routeBasenameForKiosk = routeBasename.replace(retailerLocationId, retailerLocationIdForReplacement);

    const url = new URL(origin + (isApplicationKiosk ? routeBasenameForKiosk : routeBasename) + PAGES.vinhood.redirect);

    const queryString = Object.entries(payload)
      .map(([key, value]) => `${encodeURIComponent(key)}=${value}`)
      .join('&');

    return `${url}?${queryString}`;
  }

  /**
   * Generate remote guided trial links
   *
   * @returns `/:locale/:retailerSlug/loc/:retailerLocationId/p/:productCategory/remoteGt/?<rgt_params>`
   */
  public static generateRemoteAccessLink(): string {
    const state = store.getState();
    const accessKey = selectRemoteAccessKey(state);
    const customId = selectRemoteAccessCustomId(state);
    const expiry = selectRemoteAccessExpiry(state);
    const retries = selectRemoteAccessRetries(state);

    const searchParams = new URLSearchParams();
    searchParams.append(this.remoteAccessParams.accessKey, accessKey);
    searchParams.append(this.remoteAccessParams.customId, customId);
    searchParams.append(this.remoteAccessParams.expiry, expiry);
    searchParams.append(this.remoteAccessParams.retries, retries.toString());

    return prependBasename(`${PAGES.ta.landingRemote}?${searchParams.toString()}`);
  }

  /**
   * TA: Auth link generator page
   * @returns `true` if the current path is for admins
   */
  public static isPageRemoteGuidedTrialGenerator(): boolean {
    const { pathname } = window.location;
    return pathname.includes(PAGES.ta.linkGenerator);
  }

  /**
   * TA: Obtain remote access params for auth-link-generator or remoteGT routes.
   * @returns :productCategory from the basename
   */
  public static getRemoteAccessParams() {
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);

    return {
      accessKey: searchParams.get(this.remoteAccessParams.accessKey),
      customId: searchParams.get(this.remoteAccessParams.customId),
      expiry: searchParams.get(this.remoteAccessParams.expiry),
      expiryHours: searchParams.get(this.remoteAccessParams.expiryHours),
      retries: searchParams.get(this.remoteAccessParams.retries),
    };
  }

  /**
   * Redirects to an external character page.
   * @param url The URL of the retailer's character redirect page.
   */
  public static redirectExternalCharacterPage(url) {
    const state = store.getState();
    const userSessionId = selectUserSessionId(state);
    const userQuizId = selectUserQuizId(state);
    const characterRedirectUrl = new URL(url);
    const VH_EXP_TEST_ID = 'vh-exp-test-id';
    const VH_EXP_USER_SESSION = 'vh-exp-user-session';
    characterRedirectUrl.searchParams.append(VH_EXP_TEST_ID, userQuizId);
    characterRedirectUrl.searchParams.append(VH_EXP_USER_SESSION, userSessionId);

    // If the code is running within an iframe, redirect the parent window to the producer site page
    if (isAppInIframe) {
      window.top.location.href = characterRedirectUrl.toString();
    } else {
      // Otherwise, open the producer site page in a new tab
      window.open(characterRedirectUrl, '_blank');
    }
  }

  /**
   * Service Instance feature. Redirects user to Widget
   * @param page The widget page.
   */
  public static navigateToWidgetServiceInstance(page: string) {
    const state = store.getState();
    const locale = selectLocale(state);
    const serviceInstanceProductCategory = selectServiceInstanceProductCategory(state);
    const serviceInstanceRetailerLocationId = selectServiceInstanceRetailerLocationId(state);
    const serviceInstanceRetailerSlug = selectServiceInstanceRetailerSlug(state);

    const url = `${window.location.origin}/${locale}/${serviceInstanceRetailerSlug}/loc/${serviceInstanceRetailerLocationId}/p/${serviceInstanceProductCategory}${page}`;

    window.location.href = url;
  }

  /**
   * Service Instance feature. Redirects user to App
   * @param page The App page.
   */
  public static navigateToAppServiceInstance(page: string) {
    const state = store.getState();
    const locale = selectLocale(state);

    const url = `${window.location.origin}/${locale}/${VH_RETAILER_URL_SLUG_APP}${page}?${IS_REDIRECTED_FROM_WIDGET}=true`;

    window.location.href = url;
  }
}

export default RouteUtils;

import styled, { css } from 'styled-components';

import {
  AuthorizationType,
  VariantAuthButton,
} from '@components/web/src/atoms/Buttons/SocialButtons/AuthorizationButton/AuthorizationButton';
import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';

export type AuthButtonStyleProps = {
  $isHidden?: boolean;
  $variant?: VariantAuthButton;
  type?: AuthorizationType;
};

export const AuthButton = styled.button<AuthButtonStyleProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  align-items: center;
  background: white;
  border: 1px solid ${STATIC_COLORS.gray[300]};
  box-shadow: 0 1px 2px 0 rgba(16 24 40 / 5%);
  ${({ $variant }) =>
    $variant === 'primary' &&
    css`
      justify-content: center;
      width: 70px;
      height: 70px;
      padding: 10px;
      border-radius: 50%;
    `}

  ${({ $variant }) =>
    $variant === 'secondary' &&
    css`
      flex-direction: row;
      gap: 12px;
      justify-content: flex-start;
      width: 100%;
      height: fit-content;
      padding: 10px 16px;
      border-radius: 8px;
    `}
    &:hover {
    background-color: #e0e2e7;
    box-shadow: ${STATIC_SHADOWS.xs};
  }

  &:disabled,
  &:disabled:hover {
    cursor: not-allowed;
    border: 1px solid #979eac;
    opacity: 0.5;
  }
`;

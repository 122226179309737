import classNames from 'classnames';
import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  BANNER_POSITION_CATALOG,
  BANNER_POSITION_EDUTAINMENT,
  BANNER_POSITION_EXPERIENCES,
  BANNER_POSITION_HOME,
  BANNER_POSITION_TASTE_ID,
} from '@lib/core/banners/consts';
import { prependBasename } from '@lib/core/service/utils';
import { localeApp } from '@lib/tools/locale/source/web/app';
import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { PAGES } from '@lib/tools/views/urls';

import InactiveCatalogIcon from '@components/web/src/assets/icons/footer/icon_footer_cube.svg';
import ActiveCatalogIcon from '@components/web/src/assets/icons/footer/icon_footer_cube_active.svg';
import InactiveTrainingsIcon from '@components/web/src/assets/icons/footer/icon_footer_flag.svg';
import ActiveTrainingsIcon from '@components/web/src/assets/icons/footer/icon_footer_flag_active.svg';
import InactiveHomeIcon from '@components/web/src/assets/icons/footer/icon_footer_home.svg';
import ActiveHomeIcon from '@components/web/src/assets/icons/footer/icon_footer_home_active.svg';
import InactiveExperiencesIcon from '@components/web/src/assets/icons/footer/icon_footer_rocket.svg';
import ActiveExperiencesIcon from '@components/web/src/assets/icons/footer/icon_footer_rocket_active.svg';
import ActiveUserProfileIcon from '@components/web/src/assets/icons/footer/icon_footer_user_active.svg';
import InactiveUserProfileIcon from '@components/web/src/assets/icons/footer/icon_footer_user_inactive.svg';
import { Image } from '@components/web/src/foundations/Image/Image';

export interface IFooterAppLoggedProps {
  profileImageUrl?: string;
  isFooterDisabled?: boolean;
}

type IUrlKeys =
  | typeof BANNER_POSITION_HOME
  | typeof BANNER_POSITION_CATALOG
  | typeof BANNER_POSITION_EDUTAINMENT
  | typeof BANNER_POSITION_EXPERIENCES
  | typeof BANNER_POSITION_TASTE_ID;

type IFooterItem = {
  icon: string;
  urlKey: IUrlKeys;
  text: ILocaleText;
};

const FooterAppLogged: FC<IFooterAppLoggedProps> = ({ profileImageUrl, isFooterDisabled = false }) => {
  const { homeText, productsText, trainingsText, experiencesText, tasteIdText } = localeApp.footerApp;

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onIconClick = (urlKey: IUrlKeys) => {
    switch (urlKey) {
      case BANNER_POSITION_HOME:
        navigate(prependBasename(PAGES.vinhood.home));
        break;
      case BANNER_POSITION_CATALOG:
        navigate(prependBasename(PAGES.vinhood.catalog), { state: { from: pathname } });
        break;
      case BANNER_POSITION_EDUTAINMENT:
        navigate(prependBasename(PAGES.vinhood.edutainment));
        break;
      case BANNER_POSITION_EXPERIENCES:
        navigate(prependBasename(PAGES.vinhood.experiences), { state: { from: pathname } });
        break;

      case BANNER_POSITION_TASTE_ID:
        navigate(prependBasename(PAGES.vinhood.tasteId.tasteId));

        break;
      default:
        navigate(prependBasename(PAGES.vinhood.home));
    }
  };

  const getCurrentIcon = (urlKey: IUrlKeys): string => {
    let currentIcon;

    switch (urlKey) {
      case BANNER_POSITION_HOME:
        currentIcon = pathname.includes(urlKey) ? ActiveHomeIcon : InactiveHomeIcon;
        break;
      case BANNER_POSITION_CATALOG:
        currentIcon = pathname.includes(urlKey) ? ActiveCatalogIcon : InactiveCatalogIcon;
        break;
      case BANNER_POSITION_EDUTAINMENT:
        currentIcon = pathname.includes(urlKey) ? ActiveTrainingsIcon : InactiveTrainingsIcon;
        break;
      case BANNER_POSITION_EXPERIENCES:
        currentIcon = pathname.includes(urlKey) ? ActiveExperiencesIcon : InactiveExperiencesIcon;
        break;
      case BANNER_POSITION_TASTE_ID:
        currentIcon = profileImageUrl || (pathname.includes(urlKey) ? ActiveUserProfileIcon : InactiveUserProfileIcon);
        break;
      default:
    }

    return currentIcon;
  };

  const footerItemArray: IFooterItem[] = useMemo(
    () => [
      {
        icon: getCurrentIcon(BANNER_POSITION_HOME),
        text: homeText,
        urlKey: BANNER_POSITION_HOME,
      },
      {
        icon: getCurrentIcon(BANNER_POSITION_CATALOG),
        text: productsText,
        urlKey: BANNER_POSITION_CATALOG,
      },
      {
        icon: getCurrentIcon(BANNER_POSITION_EDUTAINMENT),
        text: trainingsText,
        urlKey: BANNER_POSITION_EDUTAINMENT,
      },
      {
        icon: getCurrentIcon(BANNER_POSITION_EXPERIENCES),
        text: experiencesText,
        urlKey: BANNER_POSITION_EXPERIENCES,
      },
      {
        icon: getCurrentIcon(BANNER_POSITION_TASTE_ID),
        text: tasteIdText,
        urlKey: BANNER_POSITION_TASTE_ID,
      },
    ],
    [pathname, profileImageUrl, homeText, productsText, experiencesText, trainingsText, tasteIdText],
  );

  return (
    <div className={classNames('footer-app-logged-container', { isFooterDisabled })}>
      <div className="footer-buttons-wrapper">
        {footerItemArray.map(item => {
          const { urlKey, text, icon } = item;
          return (
            <button
              key={urlKey}
              className={classNames('footer-button', { active: pathname.includes(urlKey) })}
              onClick={() => onIconClick(urlKey)}
            >
              <Image
                alt={urlKey}
                borderRadius={urlKey === BANNER_POSITION_TASTE_ID ? '50%' : ''}
                height="24px"
                objectFit={urlKey === BANNER_POSITION_TASTE_ID ? 'cover' : 'none'}
                src={icon}
                width="24px"
              />

              <span className="text">
                <LocaleFragment message={text} />
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default FooterAppLogged;

import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { getProductCategoryTasteIcon } from '@lib/tools/shared/helpers';
import { PRODUCT_CATEGORY_WINE, VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import HomeRecipeCard from '@components/web/src/kiosk/Cards/HomeRecipeCard/HomeRecipeCard';

export type Props = {
  productCategory: TProductCategory;
  navigateToTasteTest: () => void;
  navigateToSituationalTest: () => void;
};

const TasteTestCards: FC<Props> = ({
  productCategory = PRODUCT_CATEGORY_WINE,
  navigateToTasteTest,
  navigateToSituationalTest,
}) => {
  const { tasteTestCard } = localeKiosk.homePageCards;
  const { productCategories } = localeCommon;

  return (
    <div className="taste-test-cards-container">
      <div className="taste-card-container">
        <div className="taste-card-content">
          <p>
            <LocaleFragment
              message={tasteTestCard.description}
              options={{
                productCategory,
              }}
              variables={{
                productCategoryText: productCategories[productCategory],
              }}
            />
          </p>
          <img alt="taste icon" src={getProductCategoryTasteIcon(productCategory)} />
        </div>
        <Button
          size="lg"
          text={tasteTestCard.btn}
          variant={VH_VARIANTS.PRIMARY}
          handleClick={() => {
            MixpanelTracker.events.startTasteTestClick();
            navigateToTasteTest();
          }}
        />
      </div>

      <HomeRecipeCard
        productCategory={productCategory}
        handleClick={() => {
          MixpanelTracker.events.startSituationalTestClick();
          navigateToSituationalTest();
        }}
      />
    </div>
  );
};

export default TasteTestCards;

import { useDispatch, useSelector } from 'react-redux';

import { selectIsThirdPartyConnectModalShown } from '@lib/core/service/selectors';
import { setThirdPartyConnectModalOpenScenario } from '@lib/core/service/slices';
import { ThirdPartyConnectModalScenario } from '@lib/core/service/types/interface';
import {
  selectIsProductFeedbackLoading,
  selectLastUpdatedProductFeedbackId,
  selectProductFeedbackData,
} from '@lib/core/users/selectors/productFeedback';
import { TProductFeedbackValue, actionUpdateProductFeedback } from '@lib/core/users/slices/productFeedback';
import { useModals } from '@lib/tools/modals/hooks';
import { MODALS } from '@lib/tools/shared/helpers/consts';

export const useProductFeedback = () => {
  const dispatch = useDispatch();
  const { openModal } = useModals();

  const feedbackData = useSelector(selectProductFeedbackData);
  const isFeedbackLoading = useSelector(selectIsProductFeedbackLoading);
  const lastUpdatedFeedbackId = useSelector(selectLastUpdatedProductFeedbackId);
  const isThirdPartyConnectModalShown = useSelector(selectIsThirdPartyConnectModalShown);

  const handleUpdateFeedback = (feedback: TProductFeedbackValue, productId: string, productName = '') => {
    const shouldShowThirdPartyConnectModal =
      !(isThirdPartyConnectModalShown.rate5 || isThirdPartyConnectModalShown.rate4) &&
      (feedback === 4 || feedback === 5);

    if (shouldShowThirdPartyConnectModal) {
      dispatch(
        setThirdPartyConnectModalOpenScenario({
          productName,
          scenario: feedback === 4 ? ThirdPartyConnectModalScenario.RATE4 : ThirdPartyConnectModalScenario.RATE5,
        }),
      );
      openModal(MODALS.THIRD_PARTY_CONNECT_MODAL);
    }

    dispatch(actionUpdateProductFeedback({ feedback, productId }));
  };

  return { feedbackData, handleUpdateFeedback, isFeedbackLoading, lastUpdatedFeedbackId };
};

/* eslint-disable import/no-extraneous-dependencies */
import { useMemo } from 'react';

import { IFilterItem } from '@lib/tools/filterManager/slices/sublistFilterSlice';
import {
  FILTER_TYPE_CHARACTERISTICS,
  FILTER_TYPE_FORMAT,
  FILTER_TYPE_MOOD,
  FILTER_TYPE_ORIGIN,
  FILTER_TYPE_PRICE_RANGE,
  FILTER_TYPE_PRODUCT_CATEGORY,
  FILTER_TYPE_RATING,
  FILTER_TYPE_STYLE,
  FILTER_TYPE_WISHLIST,
} from '@lib/tools/shared/helpers/consts';
import { useTypedSelector } from '@lib/tools/views/hooks';

import wishlistFillIcon from '@components/web/src/assets/legacy/icons/wishlist_fill.svg';

export const useSearchTags = () => {
  const {
    showOnly: { isWishlistToggleActive },
    sublist,
    range: { userLowerPriceRangeValue, userUpperPriceRangeValue, appMaxPriceRangeValue, appMinPriceRangeValue },
  } = useTypedSelector(state => state.productFilters);

  const {
    [FILTER_TYPE_STYLE]: selectedStylesFilterObject = {},
    [FILTER_TYPE_FORMAT]: selectedFormatsFilterObject = {},
    [FILTER_TYPE_CHARACTERISTICS]: selectedCharacteristicsFilterObject = {},
    [FILTER_TYPE_ORIGIN]: selectedOriginsFilterObject = {},
    [FILTER_TYPE_MOOD]: selectedMoodsFilterObject = {},
    [FILTER_TYPE_PRODUCT_CATEGORY]: selectedProductCategoriesFilterObject = {},
    [FILTER_TYPE_RATING]: selectedRatedProductsFilterObject = {},
  } = sublist;

  const basicTags =
    (userUpperPriceRangeValue && userUpperPriceRangeValue !== appMaxPriceRangeValue) ||
    (userLowerPriceRangeValue && userLowerPriceRangeValue !== appMinPriceRangeValue)
      ? [
          {
            filterType: FILTER_TYPE_PRICE_RANGE,
            isActive: true,
            name: `${userLowerPriceRangeValue}-${userUpperPriceRangeValue}`,
            value: "min + '-' + max",
          },
        ]
      : [];
  const selectedWishlistFilterObject = useMemo(
    () => ({
      filterType: FILTER_TYPE_WISHLIST,
      icon: wishlistFillIcon,
      isActive: isWishlistToggleActive,
      name: '',
      value: '',
    }),
    [isWishlistToggleActive],
  );

  const selectedTags: IFilterItem[] = useMemo(() => {
    return [
      ...Object.values(selectedCharacteristicsFilterObject),
      ...Object.values(selectedOriginsFilterObject),
      ...Object.values(selectedFormatsFilterObject),
      ...Object.values(selectedStylesFilterObject),
      ...Object.values(selectedMoodsFilterObject),
      ...Object.values(selectedProductCategoriesFilterObject),
      ...Object.values(selectedRatedProductsFilterObject),
      selectedWishlistFilterObject,
    ].filter(({ isActive }) => isActive);
  }, [
    selectedOriginsFilterObject,
    selectedCharacteristicsFilterObject,
    selectedFormatsFilterObject,
    selectedStylesFilterObject,
    selectedMoodsFilterObject,
    selectedProductCategoriesFilterObject,
  ]);
  return [...basicTags, ...selectedTags];
};

import { styled } from 'styled-components';

import { DEFAULT_COLOR_THEME, STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const RatingFilterContainer = styled(Flexbox)`
  padding: 16px;
  background: ${STATIC_COLORS.base.white};
  border-bottom: ${({ theme }) =>
    `1px solid ${theme?.colors?.secondaryColor['-300'] || DEFAULT_COLOR_THEME?.secondaryColor['-300']}`};
`;

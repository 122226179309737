import { styled } from 'styled-components';

import KioskFooterWave from '@components/web/src/shared/Footer/FooterWave';

export const FooterKioskContainer = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 9998;
  width: 1080px;
`;

export const WaveIcon = styled(KioskFooterWave)`
  margin-bottom: -5px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 2.65px;
  padding: 7px 16px 12.35px;
  margin: 0 auto;
  background-color: var(--color-primary-800);
`;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 32px;
  justify-content: center;
`;

export const Link = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

import SplashScreenGIF from '@components/web/src/assets/gif/VH_Splash_Logo.gif';
import * as S from '@components/web/src/templates/SplashScreen/styles';

const SplashScreen = () => {
  return (
    <S.SplashScreenContainer>
      <img alt="splash" src={SplashScreenGIF} />
    </S.SplashScreenContainer>
  );
};

export default SplashScreen;

import styled from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 16px;
  width: 350px;
  margin-top: 48px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  padding: 10px;
  text-transform: uppercase;
  color: white;
  background: ${STATIC_COLORS.gray[800]};
  border: none;
  border-radius: 12px;
  box-shadow: ${STATIC_SHADOWS.xl};

  &:hover {
    background: ${STATIC_COLORS.gray[500]};
    border-color: ${STATIC_COLORS.gray[500]};
  }
`;

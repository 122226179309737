import styled, { css } from 'styled-components';

import Button from '@components/web/src/atoms/Buttons/Button';
import { SCREEN_SIZE, STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const ThirdPartyNewsletterContainer = styled(Flexbox)<{
  $isVariantSticky?: boolean;
  $withMarginTop?: boolean;
  $dynamicHeight?: number;
}>`
  width: 100%;
  max-width: ${SCREEN_SIZE.vhApp};

  ${({ $isVariantSticky, $dynamicHeight = 0, $withMarginTop }) => {
    const additionalMargin = $withMarginTop ? 80 : 0;
    const topValue = `${135 + $dynamicHeight + additionalMargin}px`;

    return (
      $isVariantSticky &&
      css`
        position: fixed;
        top: ${topValue};
        z-index: 999;
        @media screen and (max-width: ${SCREEN_SIZE.vhApp}) {
          top: calc(${55 + additionalMargin}px);
        }
      `
    );
  }}
`;

export const ThirdPartyNewsletterWrapper = styled(Flexbox)<{ $isVariantSticky?: boolean }>`
  padding: 32px 16px;
  background-color: ${STATIC_COLORS.teal['700']};
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2) inset;
  width: 100%;

  ${({ $isVariantSticky }) =>
    $isVariantSticky &&
    css`
      width: 96%;
      padding: 16px;
      border-radius: 8px;
      gap: 8px;
      box-shadow: none;
    `}
`;

export const TitleTextWrapper = styled.div<{ $isVariantSticky?: boolean }>`
  display: inline-block;
  text-align: ${({ $isVariantSticky }) => ($isVariantSticky ? 'left' : 'center')};
`;

export const AcceptBtn = styled(Button)<{ $isVariantSticky?: boolean }>`
  width: 100%;

  ${({ $isVariantSticky }) =>
    $isVariantSticky &&
    css`
      height: 40px;
    `}
`;

export const RejectBtn = styled(Button)<{ $isVariantSticky?: boolean }>`
  width: 100%;
  border: none;
  box-shadow: none;
  background-color: ${STATIC_COLORS.teal['700']};

  ${({ $isVariantSticky }) =>
    $isVariantSticky &&
    css`
      padding: 10px 0;
      height: 40px;
      white-space: nowrap;
    `}
`;

export const BtnWrapper = styled(Flexbox)<{ $isVariantSticky?: boolean }>`
  width: 100%;
  gap: 16px;
  flex-direction: column;
  ${({ $isVariantSticky }) =>
    $isVariantSticky &&
    css`
      flex-direction: row-reverse;
      border-top: 0.5px solid ${STATIC_COLORS.teal['500']};
      gap: 8px;
      padding: 11px 0;
    `}
`;
export const MoreText = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
  margin-left: 8px;
`;

import { css, keyframes, styled } from 'styled-components';

import { DEFAULT_COLOR_THEME, STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';

export const FeedbackButton = styled.button`
  position: relative;
  all: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;

  span {
    color: ${({ theme }) => theme?.colors?.primaryColor['-600'] || DEFAULT_COLOR_THEME.primaryColor['-600']};
  }
`;
export const RatingImage = styled(Image)`
  ${({ theme }) =>
    theme?.isKioskRootElementType
      ? css`
          width: 32px;
          height: 32px;
          margin-top: -4px;
        `
      : css`
          width: 24px;
          height: 24px;
          margin-top: -3px;
        `}
`;

const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

export const FeedbackTooltipContainer = styled.div<{ $isAnimated: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 161px;
  height: fit-content;
  text-align: center;
  padding: 8px 12px;
  border-radius: 8px;
  top: 135px;
  right: -5px;
  transition: all 0.3s ease;

  z-index: 999;
  background-color: ${STATIC_COLORS.green['600']};
  span {
    color: ${STATIC_COLORS.base.white};
  }

  animation: ${({ $isAnimated }) =>
    $isAnimated
      ? css`
          ${bounceAnimation} 2s infinite
        `
      : 'none'};
`;

export const Arrow = styled.div`
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid ${STATIC_COLORS.green['600']};
  position: absolute;
  top: -10px;
  left: calc(85% - 12px);
`;

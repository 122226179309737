import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useQuiz, useQuizView, useQuizzes, useUserQuiz } from '@lib/core/quizzes/hooks';
import {
  actionGenerateUserQuizId,
  actionGetQuiz,
  actionPatchUserQuizMetadata,
  actionPostUserQuiz,
  actionResetUserQuiz,
} from '@lib/core/quizzes/slices';
import {
  actionResetQuizView,
  actionResumeQuizView,
  actionTraverseQuizViewData,
} from '@lib/core/quizzes/slices/quizView';
import { TQuizQuestion } from '@lib/core/quizzes/types';
import QuizUtils from '@lib/core/quizzes/utils';
import { URL_QUIZ_TYPE_TASTE_REMOTE } from '@lib/core/quizzes/utils/consts';
import { useRemoteAccess } from '@lib/core/retailers/hooks/remoteAccess';
import { isApplicationPmi } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import { actionGetUserCharacters } from '@lib/core/users/slices/characters';
import { useComms } from '@lib/tools/comms/hooks';
import { broadcastQuizCompletionToHost } from '@lib/tools/comms/utils';
import { useEffectSkipFirst } from '@lib/tools/views/hooks';
import { useTypedDispatch as useDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

/**
 * * Quiz Launcher (HOC)
 *
 * Requires window pathname to contain the `:urlQuizType`.
 * Uses `:urlQuizType` to select the required `quiz`.
 *
 * 1. Fetches and populates `quizzes` state.
 * 2. Uses `:urlQuizType` to fetch and populate `quiz` state.
 * 2. Initializes and populates `userQuiz` state, after creating the `userQuiz` record.
 * 4. Loads view data for the first question.
 * 5. Manages `quizView` state, including pausing and resuming the `userQuiz`.
 *
 * @summary Designed to manage initialization, state, and navigation for quiz containers
 */
export default Component => {
  const QuizLauncher: FC<Element> = QuizContainer => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { quizType: urlQuizType } = useParams();

    const { isQuizzesFetching } = useQuizzes();
    const { quizData } = useQuiz();
    const { isQuizViewPaused, isQuizViewPostNewUserQuiz } = useQuizView();
    const { isUserQuizComplete, userQuizCharacters, isUserQuizTypeRecipe, isUserQuizTypeSituational } = useUserQuiz();

    const { productCategory } = useApp();
    const { isRemoteAccessValid } = useRemoteAccess();
    const { locale } = useApp();
    const { hostCustomId } = useComms();

    const userQuizCharacter = userQuizCharacters[0];

    /**
     * * Initialize with clean quiz states, or return to quiz if paused
     */
    useEffect(() => {
      if (isQuizViewPaused) {
        dispatch(actionResumeQuizView());
      } else {
        dispatch(actionResetUserQuiz());
        dispatch(actionResetQuizView());
      }
    }, [urlQuizType]);

    /**
     * * Cleanup closure
     */
    useEffectSkipFirst(() => {
      return () => {
        if (!isQuizViewPaused) {
          dispatch(actionResetUserQuiz());
          dispatch(actionResetQuizView());
        }
      };
    }, [isQuizViewPaused]);

    /**
     * * Select the quiz to load and create a new `userQuiz`
     */
    const quiz = QuizUtils.getQuiz(urlQuizType);

    useEffect(() => {
      if (!isQuizzesFetching && !isQuizViewPaused) {
        if (urlQuizType === URL_QUIZ_TYPE_TASTE_REMOTE && !isRemoteAccessValid) {
          navigate(prependBasename(PAGES.error.forbidden));
          return;
        }

        if (quiz?.slug) {
          dispatch(actionGetQuiz({ quizSlug: quiz.slug })).then(() => {
            dispatch(actionGenerateUserQuizId());
            dispatch(
              actionPostUserQuiz({
                quizSlug: quiz.slug,
                quizType: quiz.quiz_type?.slug,
              }),
            ).then(() => {
              if (hostCustomId) {
                const payload = { metadata: { custom_id: hostCustomId } };
                dispatch(actionPatchUserQuizMetadata(payload));
              }
            });

            const quizQuestions = Object.values(quizData);

            // Preload quiz answer images
            quizQuestions.forEach((question: TQuizQuestion) => {
              question.answers.forEach(answer => {
                new Image().src = answer[Object.keys(answer)[0]].image;
              });
            });

            // Load view data for the first question
            dispatch(actionTraverseQuizViewData({ steps: 0 }));
          });
        }
      }
    }, [isQuizzesFetching, urlQuizType, productCategory, locale, isRemoteAccessValid]);

    /**
     * * Post new `userQuiz` from within quiz views (Crafted)
     */
    useEffect(() => {
      if (isQuizViewPostNewUserQuiz) {
        dispatch(actionGenerateUserQuizId());
        dispatch(
          actionPostUserQuiz({
            quizSlug: quiz.slug,
            quizType: quiz.quiz_type?.slug,
          }),
        );
      }
    }, [isQuizViewPostNewUserQuiz]);

    useEffect(() => {
      if (!isUserQuizComplete || isUserQuizTypeRecipe || isUserQuizTypeSituational) {
        return;
      }

      if (userQuizCharacter) {
        dispatch(actionGetUserCharacters());
        if (!isApplicationPmi) {
          // ! Conform VH quiz completion payload schema to the TA schema
          broadcastQuizCompletionToHost(userQuizCharacter.identifier, userQuizCharacter.name);
        }
      } else {
        navigate(prependBasename(PAGES.error.notFound));
      }
    }, [userQuizCharacters]);

    return <Component {...QuizContainer} />;
  };

  return QuizLauncher;
};

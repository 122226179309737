import { styled } from 'styled-components';

import { STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const HeaderWidgetContainer = styled(Flexbox)<{ $isCenteredLogo: boolean; $isAppInIframe: boolean }>`
  position: sticky;
  top: 0;
  z-index: 9999;
  height: 48px;
  background: var(--color-secondary-100);
  align-items: center;
  box-shadow: ${STATIC_SHADOWS.xs};
  padding: ${({ $isAppInIframe, $isCenteredLogo }) => {
    if ($isAppInIframe) return $isCenteredLogo ? '0 48px' : '0 48px 0 16px';
    return $isCenteredLogo ? '0 16px' : '0 0 0 16px';
  }};
  justify-content: ${({ $isCenteredLogo }) => ($isCenteredLogo ? 'center' : 'space-between')};
`;

export const LogoWrapper = styled.button`
  all: unset;
  height: 18px;
`;

export const MenuIconWrapper = styled(Flexbox)`
  width: 45px;
  height: 100%;
`;

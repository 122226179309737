import { css, styled } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const SocialRegistrationPromptWrapper = styled(Flexbox)<{ $visible: boolean }>`
  position: fixed;
  bottom: -200px;
  left: 0;
  width: 100%;
  z-index: 99999;
  transition: bottom 0.5s ease-in-out;

  ${({ $visible }) =>
    $visible &&
    css`
      bottom: 0;
    `}

  @container rootElementExperience (min-width: 500px) {
    width: 100%;
    margin-inline: auto;

    ${({ $visible }) =>
      $visible &&
      css`
        bottom: 58px;
      `}
  }
`;

export const SocialRegistrationPromptContents = styled(Flexbox)`
  width: 100%;
  padding: 16px 16px 30px;
  border-radius: 16px 16px 0px 0px;
  background: ${STATIC_COLORS.warmGray['100']};
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.25);

  @container rootElementExperience (min-width: 500px) {
    width: 500px;
    margin-inline: auto;
  }
`;

export const Icon = styled(Flexbox)`
  width: 24px;
  height: 24px;
  margin-left: auto;
  cursor: pointer;

  svg {
    stroke: black;
  }
`;

import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { updateShowOnlyFilterValue } from '@lib/tools/filterManager/slices/showOnlyFilterSlice';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { FILTER_TYPE_WISHLIST } from '@lib/tools/shared/helpers/consts';
import { useTypedSelector } from '@lib/tools/views/hooks';

import { ReactComponent as WishlistIcon } from '@components/web/src/assets/legacy/icons/filter_wishlist_icon.svg';
import { ReactComponent as WishlistIconActive } from '@components/web/src/assets/legacy/icons/filter_wishlist_icon_active.svg';
import * as S from '@components/web/src/atoms/Filters/ShowOnlyFilter/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

interface IProps {
  title: ILocaleText;
  filterType: typeof FILTER_TYPE_WISHLIST;
}

const ShowOnlyFilter: FC<IProps> = ({ title, filterType }) => {
  const dispatch = useDispatch();
  const { isWishlistToggleActive } = useTypedSelector(state => state.productFilters.showOnly);

  const getCurrentIcon = iconName => {
    let currentIcon;

    switch (iconName) {
      case FILTER_TYPE_WISHLIST:
        currentIcon = isWishlistToggleActive ? <WishlistIconActive /> : <WishlistIcon />;
        break;
      default:
    }
    return currentIcon;
  };

  return (
    <S.ShowOnlyContainer
      $isActive={isWishlistToggleActive}
      type="button"
      onClick={() => dispatch(updateShowOnlyFilterValue(filterType))}
    >
      <S.IconWrapper $isSelected={isWishlistToggleActive}>{getCurrentIcon(filterType)}</S.IconWrapper>
      <Text
        color={isWishlistToggleActive ? STATIC_COLORS.teal['800'] : STATIC_COLORS.base.black}
        size="subtitle2"
        text={title}
        weight={isWishlistToggleActive ? 'semibold' : 'normal'}
      />
    </S.ShowOnlyContainer>
  );
};

export default ShowOnlyFilter;

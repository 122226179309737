import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useApp } from '@lib/core/service/hooks';
import { getMultipleUniqueRandomItemsFromArray, shuffleArray } from '@lib/core/service/utils';
import { selectDiscoveryQuizProductInstance, selectDiscoveryQuizProducts } from '@lib/tools/discoveryQuiz/selectors';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  DISCOVERY_QUIZ_RANDOM_PRODUCTS_COUNT,
  DISCOVER_QUIZ_AROMAS_ATTRIBUTE,
  DISCOVER_QUIZ_TASTE_ATTRIBUTE,
} from '@lib/tools/shared/helpers/consts';

import {
  IDiscoveryQuiz,
  IDiscoveryQuizData,
} from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

const useDiscoveryQuizData = () => {
  const { aromasQuizType, tasteQuizType, aromasQuizTitle, tasteQuizTitle, aromasQuizBtn, tasteQuizFinishBtn } =
    localeCommon.discoveryQuizModal;

  const { locale } = useApp();

  const discoveryQuizProducts = useSelector(selectDiscoveryQuizProducts);
  const discoveryQuizProductInstance = useSelector(selectDiscoveryQuizProductInstance);

  const randomProducts =
    useMemo(() => {
      return (
        discoveryQuizProducts &&
        discoveryQuizProductInstance &&
        getMultipleUniqueRandomItemsFromArray(
          discoveryQuizProducts.filter(product => product.identifier !== discoveryQuizProductInstance.identifier),
          DISCOVERY_QUIZ_RANDOM_PRODUCTS_COUNT,
        )
      );
    }, [discoveryQuizProducts, discoveryQuizProductInstance]) || [];

  const createQuizData = ({ quizAttribute, btnText, quizTitle, quizType, randomProduct }): IDiscoveryQuizData => {
    const getAttribute = (product, attributeKey) => product?.product?.attributes[`${attributeKey}_${locale}`];

    const answers = shuffleArray([
      { id: 1, isCorrectAnswer: true, text: getAttribute(discoveryQuizProductInstance, quizAttribute) },
      { id: 2, isCorrectAnswer: false, text: getAttribute(randomProduct, quizAttribute) },
    ]);

    return {
      answers,
      btnText,
      quizTitle,
      quizType,
    };
  };

  const discoveryQuizData = useMemo(
    () => [
      createQuizData({
        btnText: aromasQuizBtn,
        quizAttribute: DISCOVER_QUIZ_AROMAS_ATTRIBUTE,
        quizTitle: aromasQuizTitle,
        quizType: aromasQuizType,
        randomProduct: randomProducts[0],
      }),
      createQuizData({
        btnText: tasteQuizFinishBtn,
        quizAttribute: DISCOVER_QUIZ_TASTE_ATTRIBUTE,
        quizTitle: tasteQuizTitle,
        quizType: tasteQuizType,
        randomProduct: randomProducts[1],
      }),
    ],
    [discoveryQuizProductInstance, randomProducts],
  );

  const discoveryQuiz: IDiscoveryQuiz = {
    discoveryQuizData,
    discoveryQuizProductInstance,
  };

  return { discoveryQuiz };
};

export default useDiscoveryQuizData;

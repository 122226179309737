import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import QuizUtils from '@lib/core/quizzes/utils';
import {
  selectDietaryPreferenceListData,
  selectDietaryPreferenceListError,
  selectDietaryPreferenceListLoading,
  selectDietaryPreferenceMoodOptions,
  selectDietaryPreferenceOptionsData,
  selectDietaryPreferenceOptionsError,
  selectDietaryPreferenceOptionsLoading,
  selectDietaryPreferencePreferencesOptions,
  selectDietaryPreferenceTagsOptions,
  selectUserDietaryPreferencePreferences,
  selectUserDietaryPreferenceTags,
} from '@lib/core/users/selectors/dietaryPreference';
import { actionUpdateDietaryPreferenceListData } from '@lib/core/users/slices/dietaryPreference';
import { IUpdateDietaryPreferenceParams } from '@lib/core/users/types';
import { DIETARY_PREFERENCE_SLUG } from '@lib/tools/shared/helpers/consts';
import { QUIZ_SLUG_PREFERENCE } from '@lib/tools/shared/utils/quizzes/consts';
import { useAddons } from '@lib/tools/views/hooks';

import sketches_restrictions_dairy from '@components/web/src/assets/legacy/b2c/sketches_restrictions_dairy.png';
import sketches_restrictions_gluten from '@components/web/src/assets/legacy/b2c/sketches_restrictions_gluten.png';
import sketches_restrictions_meat from '@components/web/src/assets/legacy/b2c/sketches_restrictions_meat.png';
import sketches_restrictions_no_preferences from '@components/web/src/assets/legacy/b2c/sketches_restrictions_no.png';

export const useDietaryPreferences = () => {
  const dispatch = useDispatch();
  const { isDisableQuizNoGluten } = useAddons();

  // options
  const isDietaryPreferenceOptionsLoading = useSelector(selectDietaryPreferenceOptionsLoading);
  const dietaryPreferenceOptionsError = useSelector(selectDietaryPreferenceOptionsError);
  const dietaryPreferenceOptionsData = useSelector(selectDietaryPreferenceOptionsData);

  // list
  const isUserDietaryPreferenceDataLoading = useSelector(selectDietaryPreferenceListLoading);
  const userDietaryPreferenceDataError = useSelector(selectDietaryPreferenceListError);
  const userDietaryPreferenceData = useSelector(selectDietaryPreferenceListData);

  // filtered by exposure list data
  const userDietaryPreferencePreferences = useSelector(selectUserDietaryPreferencePreferences);
  const userDietaryPreferenceTags = useSelector(selectUserDietaryPreferenceTags);

  const userDietaryPreferencePreferencesSlugs = useMemo(
    () => userDietaryPreferencePreferences.map(pref => pref.dietary_preference.slug),
    [userDietaryPreferencePreferences],
  );

  const userDietaryPreferenceTagsSlugs = useMemo(
    () => userDietaryPreferenceTags.map(pref => pref.dietary_preference.slug),
    [userDietaryPreferenceTags],
  );

  const userDietaryPreferenceTagsSlugsString = useMemo(
    () =>
      userDietaryPreferenceTags
        .filter(({ dietary_preference: { slug } }) => slug !== DIETARY_PREFERENCE_SLUG.NO_PREFERENCES)
        .map(({ dietary_preference: { slug } }) => slug)
        .join(','),
    [userDietaryPreferenceData],
  );

  // filtered by exposure options data
  const dietaryPreferencePreferencesOptions = useSelector(selectDietaryPreferencePreferencesOptions);
  const dietaryPreferenceTagsOptions = useSelector(selectDietaryPreferenceTagsOptions);
  const dietaryPreferenceMoodOptions = useSelector(selectDietaryPreferenceMoodOptions);

  // toggle and answer options data
  const togglePreferenceOptionsData = useMemo(
    () =>
      dietaryPreferencePreferencesOptions
        .map(preference => ({
          ...preference,
          isEnabled: userDietaryPreferencePreferencesSlugs.includes(preference.slug),
        }))
        .sort((a, b) => {
          if (a.slug === DIETARY_PREFERENCE_SLUG.NO_FOOD_PREFERENCES) return 1;
          if (b.slug === DIETARY_PREFERENCE_SLUG.NO_FOOD_PREFERENCES) return -1;
          return 0;
        }),
    [dietaryPreferencePreferencesOptions, userDietaryPreferencePreferencesSlugs],
  );

  const toggleTagsOptionsData = useMemo(() => {
    return dietaryPreferenceTagsOptions
      .map(preference => ({
        ...preference,
        isEnabled: userDietaryPreferenceTagsSlugs.includes(preference.slug),
      }))
      .sort((a, b) => {
        if (a.slug === DIETARY_PREFERENCE_SLUG.NO_PREFERENCES) return 1;
        if (b.slug === DIETARY_PREFERENCE_SLUG.NO_PREFERENCES) return -1;
        return 0;
      });
  }, [dietaryPreferenceTagsOptions, userDietaryPreferenceTagsSlugs]);

  const answerPreferenceOptionsData = dietaryPreferencePreferencesOptions
    .map(({ name: text, slug }) => {
      if (slug === DIETARY_PREFERENCE_SLUG.NO_FOOD_PREFERENCES) {
        return {
          image: sketches_restrictions_no_preferences,
          isDisabled: [
            DIETARY_PREFERENCE_SLUG.NO_GLUTEN,
            DIETARY_PREFERENCE_SLUG.NO_MEAT,
            DIETARY_PREFERENCE_SLUG.NO_DAIRY,
          ].some(value => userDietaryPreferencePreferencesSlugs.includes(value)),
          isSelected: userDietaryPreferencePreferencesSlugs.includes(slug),
          isVisible: QuizUtils.isTasteQuizSpecificPreferencesAvailable(QUIZ_SLUG_PREFERENCE.NO_PREFERENCES),
          slug,
          text,
        };
      }
      if (slug === DIETARY_PREFERENCE_SLUG.NO_GLUTEN) {
        return {
          image: sketches_restrictions_gluten,
          isDisabled: userDietaryPreferencePreferencesSlugs.includes(DIETARY_PREFERENCE_SLUG.NO_FOOD_PREFERENCES),
          isSelected: userDietaryPreferencePreferencesSlugs.includes(slug),
          isVisible:
            (QuizUtils.isTasteQuizSpecificPreferencesAvailable(QUIZ_SLUG_PREFERENCE.NO_GLUTEN) ||
              QuizUtils.isTasteQuizSpecificPreferencesAvailable(QUIZ_SLUG_PREFERENCE.ALL_PREFERENCES)) &&
            !isDisableQuizNoGluten,
          slug,
          text,
        };
      }
      if (slug === DIETARY_PREFERENCE_SLUG.NO_MEAT) {
        return {
          image: sketches_restrictions_meat,
          isDisabled: userDietaryPreferencePreferencesSlugs.includes(DIETARY_PREFERENCE_SLUG.NO_FOOD_PREFERENCES),
          isSelected: userDietaryPreferencePreferencesSlugs.includes(slug),
          isVisible:
            QuizUtils.isTasteQuizSpecificPreferencesAvailable(QUIZ_SLUG_PREFERENCE.NO_MEAT) ||
            QuizUtils.isTasteQuizSpecificPreferencesAvailable(QUIZ_SLUG_PREFERENCE.ALL_PREFERENCES),
          slug,
          text,
        };
      }
      if (slug === DIETARY_PREFERENCE_SLUG.NO_DAIRY) {
        return {
          image: sketches_restrictions_dairy,
          isDisabled: userDietaryPreferencePreferencesSlugs.includes(DIETARY_PREFERENCE_SLUG.NO_FOOD_PREFERENCES),
          isSelected: userDietaryPreferencePreferencesSlugs.includes(slug),
          isVisible:
            QuizUtils.isTasteQuizSpecificPreferencesAvailable(QUIZ_SLUG_PREFERENCE.NO_DIARY) ||
            QuizUtils.isTasteQuizSpecificPreferencesAvailable(QUIZ_SLUG_PREFERENCE.ALL_PREFERENCES),
          slug,
          text,
        };
      }
      return null;
    })
    .filter(Boolean)
    .sort((a, b) => {
      if (a.slug === DIETARY_PREFERENCE_SLUG.NO_FOOD_PREFERENCES) return -1;
      if (b.slug === DIETARY_PREFERENCE_SLUG.NO_FOOD_PREFERENCES) return 1;
      return 0;
    });

  const handleUpdateToggleDietaryPreferenceOptionsData = ({
    slugToUpdate,
    exposureToUpdate,
  }: IUpdateDietaryPreferenceParams) =>
    dispatch(
      actionUpdateDietaryPreferenceListData({
        exposureToUpdate,
        slugToUpdate,
      }),
    );

  return {
    answerPreferenceOptionsData,
    dietaryPreferenceMoodOptions,
    dietaryPreferenceOptionsData,
    dietaryPreferenceOptionsError,
    dietaryPreferencePreferencesOptions,
    dietaryPreferenceTagsOptions,
    handleUpdateToggleDietaryPreferenceOptionsData,
    isDietaryPreferenceOptionsLoading,
    isUserDietaryPreferenceDataLoading,
    togglePreferenceOptionsData,
    toggleTagsOptionsData,
    userDietaryPreferenceData,
    userDietaryPreferenceDataError,
    userDietaryPreferencePreferences,
    userDietaryPreferencePreferencesSlugs,
    userDietaryPreferenceTags,
    userDietaryPreferenceTagsSlugs,
    userDietaryPreferenceTagsSlugsString,
  };
};

import { useSelector } from 'react-redux';

import {
  selectIsPromotionProductsAvailable,
  selectIsPromotionProductsLoading,
} from '@lib/tools/promotionProducts/selectors/index';

export const usePromotionProducts = () => ({
  isPromotionProductsAvailable: useSelector(selectIsPromotionProductsAvailable),
  isPromotionProductsLoading: useSelector(selectIsPromotionProductsLoading),
});

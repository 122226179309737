import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { REQUEST_METHODS } from '@lib/core/service/consts';
import request, { requestWithKeysAsOptions } from '@lib/core/service/requests/request';
import { selectWishlistProductInstanceIds, selectWishlistProductListData } from '@lib/core/users/selectors/productList';
import {
  productListApiUrlCreator,
  productListProductApiUrlCreator,
  updateProductListProductApiUrlCreator,
} from '@lib/core/users/slices/urls';
import {
  IGetProductListParams,
  IProductList,
  IProductListResponse,
  IUpdateProductListParams,
} from '@lib/core/users/types';
import { parseError } from '@lib/tools/shared/helpers';
import { PRODUCT_LIST_WISHLIST } from '@lib/tools/shared/helpers/consts';

export interface IProductListState {
  data: IProductList[][];
  error: string;
  isLoading: boolean;
}

export const initialState: IProductListState = {
  data: [],
  error: '',
  isLoading: false,
};

export const actionGetProductList = createAsyncThunk<IProductListResponse, IGetProductListParams>(
  'users/productList/actionGetProductList',
  async ({ listName }, { rejectWithValue }) => {
    try {
      const allUserProductLists = await request(productListApiUrlCreator());
      const productList = allUserProductLists.results.find(list => list?.list_name === listName);

      if (productList) {
        const response = await request(productListProductApiUrlCreator(productList.list_slug));

        return { listData: response.results, listName };
      }

      return { listData: null, listName };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  },
);

export const actionUpdateProductList = createAsyncThunk<IProductListResponse, IUpdateProductListParams>(
  'users/productList/actionUpdateProductList',
  async ({ productId, listName }, { getState, rejectWithValue }) => {
    const state: any = getState();
    const listData = {
      [PRODUCT_LIST_WISHLIST]: {
        data: selectWishlistProductListData(state),
        productIds: selectWishlistProductInstanceIds(state),
      },
    };

    const loadedListData = listData[listName].data.find(data => data.list_name === listName);

    const updateList = async (data: IProductList) => {
      const listSlug = data.list_slug || '';
      const isProductInList = listData[listName].productIds.includes(productId);

      const apiPath = isProductInList
        ? updateProductListProductApiUrlCreator(listSlug, productId)
        : productListApiUrlCreator();
      const config = { method: isProductInList ? REQUEST_METHODS.DELETE : REQUEST_METHODS.POST };
      const body = { list_name: listName, list_slug: listSlug, product: productId };

      return await requestWithKeysAsOptions({ apiPath, body, config }).then(() =>
        request(productListProductApiUrlCreator(listSlug)),
      );
    };

    const createList = async () =>
      await requestWithKeysAsOptions({
        apiPath: productListApiUrlCreator(),
        body: { list_name: listName, product: productId },
        config: { method: REQUEST_METHODS.POST },
      }).then(data => request(productListProductApiUrlCreator(data.list_slug)));

    try {
      let response;

      if (loadedListData) {
        response = await updateList(loadedListData);
      } else {
        const allUserProductLists = await request(productListApiUrlCreator());
        const createdListData: IProductList = allUserProductLists.results.find(list => list?.list_name === listName);

        if (!createdListData) {
          response = await createList();
        } else {
          response = await updateList(createdListData);
        }
      }

      return { listData: response.results, listName };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  },
);

const productListSlice = createSlice({
  extraReducers: builder => {
    builder.addMatcher(isAnyOf(actionGetProductList.pending, actionUpdateProductList.pending), state => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addMatcher(
      isAnyOf(actionGetProductList.fulfilled, actionUpdateProductList.fulfilled),
      (state, { payload }) => {
        state.isLoading = false;

        const { listData, listName } = payload;
        const isListExist = !!state.data.find(list => list?.length && list[0].list_name === listName);

        if (!isListExist && listData) {
          // add new list
          state.data.push(listData);
        } else if (isListExist) {
          // update lists and remove empty lists
          state.data = state.data
            .map(list => (list?.length > 0 && list[0].list_name === listName ? listData : list))
            .filter(list => list?.length);
        }
      },
    );
    builder.addMatcher(
      isAnyOf(actionGetProductList.rejected, actionUpdateProductList.rejected),
      (state, action: any) => {
        if (action.payload?.errorMessage) {
          state.error = action.payload.errorMessage;
        } else if (action.error?.message) {
          state.error = action.error.message;
        }
        state.isLoading = false;
      },
    );
  },
  initialState,
  name: 'productList',
  reducers: {
    actionResetProductList: () => initialState,
  },
});

export const { actionResetProductList } = productListSlice.actions;
export default productListSlice.reducer;

import classNames from 'classnames';
import { FC } from 'react';

import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

interface Props {
  title: ILocaleText;
  customTextColor?: string;
  isApplicationKiosk: boolean;
}

const Question: FC<Props> = ({ title = '', customTextColor, isApplicationKiosk }) => {
  return (
    <div className="mp-question-container">
      {title && (
        <p className={classNames('title', { isLargeVariant: isApplicationKiosk })} style={{ color: customTextColor }}>
          <LocaleFragment message={title} />
        </p>
      )}
    </div>
  );
};

export default Question;

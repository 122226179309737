import styled from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const DropdownMenuContainer = styled(Flexbox)`
  position: absolute;
  top: 48px;
  left: 0;
  z-index: 10001;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
  border-top: 0.5px solid ${STATIC_COLORS.gray['300']};
  box-shadow: ${STATIC_SHADOWS.lg};
  gap: 0;
  background: var(--color-secondary-100);
`;

export const BackgroundOverlay = styled.div`
  position: absolute;
  top: 48px;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: calc(100vh - 48px);
  background: rgba(52, 64, 84, 0.6);
`;

export const MenuItem = styled(Flexbox)`
  height: 48px;
  width: 100%;
  padding: 6px 0;
  gap: 0;
`;

export const MenuIcon = styled(Flexbox)`
  width: 48px;
  height: 100%;
`;

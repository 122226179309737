import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { retailerDataApiUrlCreator } from '@lib/core/retailers/slices/urls';
import { IRetailerDetail } from '@lib/core/retailers/types/retailerDetail';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';

export interface IRetailerSlice {
  isLoading: boolean;
  data: IRetailerDetail;
  error: string;
}
const initialState: IRetailerSlice = {
  data: undefined,
  error: '',
  isLoading: false,
};

export const actionGetRetailerDetail = createTypedAsyncThunk(
  'retailers/get',
  async (requestData: { retailerSlug: string }) => {
    return await request(retailerDataApiUrlCreator(requestData));
  },
);

export const actionSetRetailerDetail = createAsyncThunk('retailers/set', (retailerData: IRetailerDetail) => {
  return retailerData;
});

const retailerSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetRetailerDetail.pending, state => {
      state.error = '';
      state.isLoading = true;
    });
    builder.addCase(actionGetRetailerDetail.fulfilled, (state, action: PayloadAction<IRetailerDetail>) => {
      const { payload } = action;
      state.isLoading = false;
      state.data = payload;
    });
    builder.addCase(actionGetRetailerDetail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(actionSetRetailerDetail.fulfilled, (state, action: PayloadAction<IRetailerDetail>) => {
      const { payload } = action;
      state.data = payload;
      state.isLoading = false;
    });
  },
  initialState,
  name: 'retailer',
  reducers: {},
});

export default retailerSlice.reducer;

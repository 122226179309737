import { createSelector } from 'reselect';

import { IServiceTermsSlice } from '@lib/core/serviceTerms/slices';
import { IServiceTerm } from '@lib/core/serviceTerms/types';
import { selectAuthToken } from '@lib/core/users/selectors/auth';

/**
 * @returns Service Terms slice state
 */
export const selectServiceTermsState = (state): IServiceTermsSlice => state.serviceTerms || {};

/**
 * @returns Service Terms list
 */
export const selectServiceTermsList = createSelector(
  [selectServiceTermsState],
  serviceTermsSlice => serviceTermsSlice.list,
);

/**
 * @returns Service Terms options
 */
export const selectServiceTermsOptions = createSelector(
  [selectServiceTermsState],
  serviceTermsSlice => serviceTermsSlice.options,
);

/**
 * @returns all Service Terms includes platform and retailer, duplicates are excluded
 */
export const selectAllServiceTermsOptions = createSelector([selectServiceTermsOptions], serviceTermsOptions =>
  Object.values(
    [
      ...serviceTermsOptions.data.choices.service_terms.platform,
      ...serviceTermsOptions.data.choices.service_terms.retailer,
    ].reduce(
      (acc, item) => {
        acc[item.category] = item;
        return acc;
      },
      {} as Record<string, IServiceTerm>,
    ),
  ),
);

/**
 * @returns all User Service Terms (attached to x-profile)
 */
export const selectListData = createSelector(
  [selectServiceTermsList],
  serviceTermsList => serviceTermsList.data.results,
);

/**
 * @returns if user Service Terms are loading
 */
export const selectServiceTermListIsLoading = createSelector(
  [selectServiceTermsList],
  serviceTermsList => serviceTermsList.loading,
);

/**
 * @returns if User Service Terms was loading at least once
 */
export const selectServiceTermsWasLoaded = createSelector(
  [selectServiceTermsList],
  serviceTermsList => serviceTermsList.firstGetDataSuccessFlag,
);

/**
 * @returns if Service Terms are loading
 */
export const selectTermsOptionsLoading = createSelector(
  [selectServiceTermsOptions],
  serviceTermsOptions => serviceTermsOptions.loading,
);

/**
 * @returns if first requests to load required data to manage service terms are loading
 */
export const selectServiceTermsInitialDataLoading = createSelector(
  [selectTermsOptionsLoading, selectAllServiceTermsOptions, selectServiceTermsWasLoaded, selectAuthToken],
  (termsOptionsLoading, termsOptions, isListLoaded, isAuth) =>
    (termsOptionsLoading && !termsOptions.length) || (isAuth && !isListLoaded),
);

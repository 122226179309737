/* eslint-disable sort-keys-fix/sort-keys-fix */
import { Config } from 'mixpanel-browser';

import { ENV_CLOUDFRONT_APP_DOMAIN } from '@lib/core/service/consts';
import { stringToBoolean } from '@lib/core/service/utils';

export const MP_PROPERTIES = {
  // Retailer info
  retailerId: 'Retailer ID',
  retailerName: 'Retailer Name',
  retailerSlug: 'Retailer Slug',
  designSet: 'Design Set',
  retailerLocationID: 'Retailer Location ID',
  retailerLocationProductCategories: 'Retailer Location Product Categories',
  retailerLocationSlug: 'Retailer Location Slug',

  // User personal info
  email: '$email',
  firstName: '$first_name',
  lastName: '$last_name',
  gender: 'Gender',
  birthYear: 'Birth Year',
  registrationDate: 'Registration Date',
  homeCountry: 'Home Country',
  lastLogin: 'Last Login',
  fidelityCardID: 'Fidelity Card ID',

  // Privacy options
  enabledProfiling: 'Enabled Profiling',
  enabledMarketing: 'Enabled Marketing',
  enabledPartnerVinhood: 'Enabled Partner Vinhood',

  // User characters
  userWineCharacterID: 'User Wine Character ID',
  userCoffeeCharacterID: 'User Coffee Character ID',
  userBeerCharacterID: 'User Beer Character ID',

  // User preferences
  userFoodPreferences: 'User Food Preferences',
  userTagsActive: 'User Tags Active',
  userProductInterests: 'User Product Interests',
  userProductWishlist: 'User Product Wishlist',

  // Products
  priceCurrency: 'Price Currency',
  productCategory: 'Product Category',
  feedbackScore: 'Feedback Score',

  productID: 'Product ID',
  gprlID: 'GPRL ID',
  productName: 'Product Name',
  productCharacterID: 'Product Character ID',
  productProducerID: 'Product Producer ID',
  productProducerName: 'Product Producer Name',
  productProducerCountry: 'Product Producer Country',
  productPrice: 'Product Price',
  productDiscounted: 'Product Discounted',
  productDiscountedPrice: 'Product Discounted Price',
  productOriginsID: 'Product Origins ID',
  productOriginsName: 'Product Origins Name',
  productOriginsCountry: 'Product Origins Country',
  productFormatID: 'Product Format ID',
  productFormatName: 'Product Format Name',
  productCharacteristics: 'Product Characteristics',
  productPromotions: 'Product Promotions',
  productTags: 'Product Tags',
  productBookmarked: 'Product Bookmarked',
  productPosition: 'Product Position',
  productPositionIndex: 'Product Position Index',
  productStyles: 'Product Styles',
  productMaximumPrice: 'Product Maximum Price',
  productMinimumPrice: 'Product Minimum Price',
  showOnlySaved: 'Show Only Saved',
  followMyCharacters: 'Follow My Characters',

  // feedback scores
  userProductsScore1: 'User Products Score 1',
  userProductsScore2: 'User Products Score 2',
  userProductsScore3: 'User Products Score 3',
  userProductsScore4: 'User Products Score 4',
  userProductsScore5: 'User Products Score 5',

  // Scan actions
  productEAN: 'Product EAN',
  codeScanned: 'Code Scanned',

  // Experiences
  experienceID: 'Experience ID',
  experienceName: 'Experience Name',
  experiencePrice: 'Experience Price',
  experienceCategoryID: 'Experience Category ID',
  experienceCategoryName: 'Experience Category Name',
  experienceRegionID: 'Experience Region ID',
  experienceRegionName: 'Experience Region Name',
  experienceProducerID: 'Experience Producer ID',
  experienceProducerName: 'Experience Producer Name',
  experienceTags: 'Experience Tags',
  experienceBookmarked: 'Experience Bookmarked',

  // Test
  quizType: 'Test Type',
  userTestID: 'User Test ID',
  testName: 'Test Name',
  quizID: 'Quiz ID',
  testCharacters: 'Test Characters',
  testProductCategories: 'Test Product Categories',
  foodPreferences: 'Food Preferences',

  // Banner
  bannerID: 'Banner ID',
  bannerText: 'Banner Text',
  bannerPositionID: 'Banner Position ID',
  bannerPosition: 'Banner Position',
  bannerDestinationID: 'Banner Destination ID',
  bannerDestination: 'Banner Destination',

  // Recipe
  recipeID: 'Recipe ID',
  recipeCharactersID: 'Recipe Characters ID',
  recipeName: 'Recipe Name',
  recipeSlug: 'Recipe Slug',

  // Other
  entryPage: 'Entry Page',
  characterTypeID: 'Character Type ID',
  pageName: 'Page Name',

  // Discover quiz
  questionType: 'Question Type',
  correctAnswer: 'Correct Answer',

  // Action
  actionPerformed: 'Action Performed',

  // TasteMatch
  tasteMatchProductID: 'Taste Match Product ID',
  tasteMatchLevel: 'Taste Match Level',

  // Explore
  locationName: 'Location Name',
  locationId: 'Location ID',
  locationSlug: 'Location Slug',
  locationPosition: 'Location Position',
  locationPositionIndex: 'Location Position Index',
  listName: 'List Name',
  locationBookmarked: 'Location Bookmarked',

  // Promotions
  promotionDescription: 'Promotion Description',
  promotionId: 'Promotion ID',
  promotionSlug: 'Promotion Slug',
  promotionTypeSlug: 'Promotion Type Slug',
};

export const MP_EVENTS = {
  ENTRY_POINT: 'Entry Point',

  START_PAGE_VIEW: 'Start Page View',
  START_PAGE_ENTER_CLICK: 'Start Page Enter Click',
  USER_REGISTRATION_COMPLETED: 'User Registration Completed',
  LOGIN: 'Login',
  SIGN_OUT: 'Sign Out',
  PLAY_AS_ANONYMOUS: 'Play As Anonymous',
  SCAN_FIDELITY_CARD: 'Scan Fidelity Card',
  DETACH_FIDELITY_CARD: 'Detach Fidelity Card',

  PRODUCT_CLICK: 'Product Click',
  CONTACT_THE_PRODUCT_PRODUCER: 'Contact The Product Producer',
  FIND_ME: 'Find Me',
  PRODUCT_BOOKMARK: 'Product Bookmark',
  LOCATION_BOOKMARK: 'Location Bookmark',
  PRODUCT_CATALOG_CLICK: 'Product Catalog Click',
  PRODUCT_CATALOG_VIEW: 'Product Catalog View',
  SEE_ALL_PRODUCTS: 'See All Products',
  SEE_SIMILAR_PRODUCTS_CLICK: 'See Similar Products Click',
  PRODUCT_STORY_CLICK: 'Product Story Click',
  SCAN_PRODUCT: 'Scan Product',
  SCAN_FAILED: 'Scan Failed',
  PRODUCT_FEEDBACK: 'Product Feedback',
  CREATE_PRODUCT_PDF: 'Create Product PDF',

  VIEW_PRODUCER_EXPERIENCE_PAGE: 'View Producer Experience Page',
  EXPERIENCE_GENERIC_CATALOG_VIEW: 'Experience Generic Catalog View',
  EXPERIENCE_CATEGORY_CATALOG_VIEW: 'Experience Category Catalog View',

  TEST_STARTED: 'Test Started',
  TEST_COMPLETED: 'Test Completed',
  START_TASTE_TEST_CLICK: 'Start Taste Test Click',
  START_SITUATIONAL_TEST_CLICK: 'Start Situational Test Click',
  START_FOOD_PAIRING_CLICK: 'Start Food Pairing Click',
  RESULT_PAGE_VIEW: 'Result Page View',
  EXPLORE_SECTION_VIEW: 'Explore Section View',

  FOOTER_NAVIGATION_CLICK: 'Footer Navigation',
  BANNER_CLICK: 'Banner Click',
  TASTE_MATCH_CLICK: 'Taste Match Click',
  LOCATION_TASTE_MATCH_CLICK: 'Location Taste Match Click',
  RECIPE_CLICK: 'Recipe Click',
  SKIP_CHARACTER_TYPE: 'Skip Character Type',
  DISCOVER_QUIZ_START: 'Discover Quiz Start',
  DISCOVER_QUIZ_SUBMIT: 'Discover Quiz Submit',
  DISCOVER_QUIZ_SKIP: 'Discover Quiz Skip',
  PRODUCT_CATALOG_FILTER: 'Product Catalog Filter',
  SPECIAL_PROMO_DISPLAYED: { NAME: 'Special Promo Displayed', SLUG: 'special-promo-displayed' },
  PROMOTION_DESCRIPTION_CLICK: 'Promotion Description Click',
  CHARACTER_DESCRIPTION_CLICK: 'Character Description Click',
} as const;

export const MP_POSITION_CONTEXT = {
  SWIPER: 'Swiper',
  SCROLLABLE_CATALOG: 'Scrollable Catalog',
  PRODUCT_PAGE: 'Product Page',
  EXPLORE_PAGE: 'Explore Page',
  TASTE_PATH_PAGE: 'Taste Path Page',
  TEST_RESULT_PAGE: 'Test Result Page',
} as const;
export type MixpanelPositionContext = (typeof MP_POSITION_CONTEXT)[keyof typeof MP_POSITION_CONTEXT];

export const MP_ACTION_PERFORMED_CONTEXT = <const>{
  CLICK: 'Click',
  SCAN: 'Scan',
};
export type MixpanelActionPerformedContext =
  (typeof MP_ACTION_PERFORMED_CONTEXT)[keyof typeof MP_ACTION_PERFORMED_CONTEXT];

export const SUPER_PROP_PREFIX = 'SP - ';

const MIXPANEL_API_HOST = process.env.REACT_APP_MIXPANEL_API_HOST;
const MIXPANEL_DEBUG_ENABLED = stringToBoolean(process.env.REACT_APP_MIXPANEL_DEBUG_ENABLED);
const MIXPANEL_COOKIE_DOMAIN = ENV_CLOUDFRONT_APP_DOMAIN;
const MIXPANEL_CROSS_SUBDOMAIN_COOKIE = stringToBoolean(process.env.REACT_APP_MIXPANEL_CROSS_SUBDOMAIN_COOKIE);
const MIXPANEL_SECURE_COOKIE = stringToBoolean(process.env.REACT_APP_MIXPANEL_SECURE_COOKIE);
const MIXPANEL_IGNORE_DNT = stringToBoolean(process.env.REACT_APP_MIXPANEL_IGNORE_DNT);

export const MP_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
// WARN: make sure to check these configuration settings before running
// in production
export const MP_CONFIG: Partial<Config> = {
  // WARN: when module federation is ready we need to duplicate the domain keys
  api_host: MIXPANEL_API_HOST,
  cookie_domain: MIXPANEL_COOKIE_DOMAIN,
  cross_subdomain_cookie: MIXPANEL_CROSS_SUBDOMAIN_COOKIE,
  secure_cookie: MIXPANEL_SECURE_COOKIE,
  debug: MIXPANEL_DEBUG_ENABLED,
  ignore_dnt: MIXPANEL_IGNORE_DNT,
  skip_first_touch_marketing: true,
};

import React, { FC } from 'react';

import iconDefault from '@components/web/src/assets/legacy/icons/discovery_quiz_icon_default.svg';
import iconFalse from '@components/web/src/assets/legacy/icons/discovery_quiz_icon_false.svg';
import iconSelectedFalse from '@components/web/src/assets/legacy/icons/discovery_quiz_icon_selected_false.svg';
import iconSelectedTrue from '@components/web/src/assets/legacy/icons/discovery_quiz_icon_selected_true.svg';
import { IDiscoveryQuizAnswer } from '@components/web/src/components/DiscoveryQuiz/DiscoveryQuizAnswers/DiscoveryQuizAnswers';

interface IProps extends IDiscoveryQuizAnswer {
  index?: number;
  isSelectedAnswer: boolean;
  handleItemClick: (event: React.MouseEvent<HTMLButtonElement>, selectedAnswerId: number) => void;
}

const DiscoveryQuizAnswersItem: FC<IProps> = ({
  text,
  isCorrectAnswer,
  isSelectedAnswer = null,
  id,
  handleItemClick,
  index = 0,
}) => {
  let icon = iconDefault;
  let selectedAnswerSelector = '';
  let notSelectedAnswerSelector = '';

  if (isSelectedAnswer !== null) {
    if (isSelectedAnswer) {
      icon = isCorrectAnswer ? iconSelectedTrue : iconSelectedFalse;
      selectedAnswerSelector = isCorrectAnswer ? 'isSelectedTrue' : 'isSelectedFalse';
    } else {
      icon = isCorrectAnswer ? iconSelectedTrue : iconFalse;
      notSelectedAnswerSelector = 'isNotSelectedAnswer';
    }
  }

  return (
    <button
      className={`discovery-quiz-answers-item-container ${selectedAnswerSelector} ${notSelectedAnswerSelector} ${isCorrectAnswer}`}
      type="button"
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleItemClick(event, id)}
    >
      <img alt="icon" className="icon" src={icon} />
      <p className={`text discovery-quiz-answer-${index}`}>{text}</p>
    </button>
  );
};

export default DiscoveryQuizAnswersItem;

import { FC } from 'react';

import { localeApp } from '@lib/tools/locale/source/web/app';

import * as S from '@components/web/src/atoms/CloseComponent/styles';
import { Text } from '@components/web/src/foundations/Text/Text';

interface IProps {
  handleCloseClick: () => void;
  isVinhoodApp: boolean;
}

const CloseComponent: FC<IProps> = ({ handleCloseClick, isVinhoodApp }) => {
  const { closeComponent } = localeApp;

  return (
    <S.CloseCardContainer $isVinhoodApp={isVinhoodApp}>
      <S.ButtonWrapper onClick={handleCloseClick}>
        <Text fontFamily="Montserrat" size="body2" text={closeComponent.closeText} weight="medium" />
        <S.StyledCloseButton handleClick={handleCloseClick} type="bold" variant="light" />
      </S.ButtonWrapper>
    </S.CloseCardContainer>
  );
};

export default CloseComponent;

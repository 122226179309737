import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { getProductCategoryPairingIcon } from '@lib/tools/shared/helpers';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';

export type Props = {
  productCategory: TProductCategory;
  primaryProductColor?: string;
  handleClick: () => void;
};

const HomeRecipeCard: FC<Props> = ({ productCategory, primaryProductColor, handleClick }) => {
  const { recipeCard } = localeKiosk.homePageCards;

  return (
    <div className="home-recipe-card-container">
      <div className="recipe-container">
        <div className="recipe-content">
          <p>
            <LocaleFragment message={recipeCard.description} />
          </p>
          <img alt="recipe icon" src={getProductCategoryPairingIcon(productCategory)} />
        </div>
        <Button
          handleClick={handleClick}
          size="lg"
          text={recipeCard.btn}
          variant={VH_VARIANTS.PRIMARY}
          style={{
            backgroundColor: primaryProductColor,
          }}
        />
      </div>
    </div>
  );
};

export default HomeRecipeCard;

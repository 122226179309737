import { isProductInstanceInWishlistFilter } from '@lib/core/users/utils/filters';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';

import SwiperHOC from '@components/web/src/components/Swiper/SwiperHOC';
import * as S from '@components/web/src/kiosk/GroupedProducts/styles';
import ProductCard from '@components/web/src/organisms/Cards/ProductCard/ProductCard';

const GroupedProducts = ({
  products = [],
  isMobileResolution,
  isProductsLoading,
  isEnableLocationMapAddon,
  isEnableVusionAddon,
  isShowProductLocationAddon,
  locale,
  storeType,
  shouldHideComment,
  shouldHideFeedback,
  shouldHideWishlist,
  wishlistProductInstanceIds,
  isWishlistProductListLoading,
  handleUpdateWishlistProductList,
}) => (
  <S.GroupedProductsWrapper>
    {isMobileResolution ? (
      <SwiperHOC showPaginationDots>
        {isProductsLoading
          ? Array.from({ length: 3 }, (_, i) => (
              <ProductCard key={i + 1} isLoading={isProductsLoading} locale={locale} storeType={storeType} />
            ))
          : products?.map((productInstanceData, i) => (
              <ProductCard
                key={i}
                isResponsive
                handleUpdateWishlistProductList={handleUpdateWishlistProductList}
                isEnableLocationMapAddon={isEnableLocationMapAddon}
                isEnableVusionAddon={isEnableVusionAddon}
                isLoading={isProductsLoading}
                isShowProductLocationAddon={isShowProductLocationAddon}
                isWishlistProductListLoading={isWishlistProductListLoading}
                locale={locale}
                mixpanelIndex={i}
                mixpanelPositionContext={MP_POSITION_CONTEXT.SWIPER}
                productInstanceData={productInstanceData}
                shouldHideComment={shouldHideComment}
                shouldHideFeedback={shouldHideFeedback}
                shouldHideWishlist={shouldHideWishlist}
                storeType={storeType}
                isProductInstanceInWishlist={isProductInstanceInWishlistFilter(
                  wishlistProductInstanceIds,
                  productInstanceData,
                )}
              />
            ))}
      </SwiperHOC>
    ) : (
      <>
        {isProductsLoading
          ? Array.from({ length: 3 }, (_, i) => (
              <ProductCard key={i + 1} isLoading={isProductsLoading} locale={locale} storeType={storeType} />
            ))
          : products.map((productInstanceData, i) => (
              <ProductCard
                key={i}
                handleUpdateWishlistProductList={handleUpdateWishlistProductList}
                isEnableLocationMapAddon={isEnableLocationMapAddon}
                isEnableVusionAddon={isEnableVusionAddon}
                isLoading={isProductsLoading}
                isShowProductLocationAddon={isShowProductLocationAddon}
                locale={locale}
                mixpanelIndex={i}
                mixpanelPositionContext={MP_POSITION_CONTEXT.SWIPER}
                productInstanceData={productInstanceData}
                shouldHideComment={shouldHideComment}
                shouldHideFeedback={shouldHideFeedback}
                shouldHideWishlist={shouldHideWishlist}
                storeType={storeType}
                isProductInstanceInWishlist={isProductInstanceInWishlistFilter(
                  wishlistProductInstanceIds,
                  productInstanceData,
                )}
              />
            ))}
      </>
    )}
  </S.GroupedProductsWrapper>
);

export default GroupedProducts;

import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { DIETARY_PREFERENCE_QUERY, REQUEST_METHODS } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import { selectDietaryPreferenceListData } from '@lib/core/users/selectors/dietaryPreference';
import {
  dietaryPreferenceApiUrlCreator,
  dietaryPreferenceMultipleRemoveApiUrlCreator,
  dietaryPreferenceMultipleUpdateApiUrlCreator,
} from '@lib/core/users/slices/urls';
import {
  IUpdateDietaryPreferenceParams,
  IUpdateMultipleDietaryPreferenceParams,
  TDietaryPreference,
  TDietaryPreferenceOptionsResponse,
} from '@lib/core/users/types';
import { parseError } from '@lib/tools/shared/helpers';
import { DIETARY_PREFERENCE_SLUG } from '@lib/tools/shared/helpers/consts';

export interface IDietaryPreferenceState {
  list: {
    data: TDietaryPreference[];
    error: string;
    isLoading: boolean;
  };
  options: {
    data: TDietaryPreferenceOptionsResponse;
    error: string;
    isLoading: boolean;
  };
}

export const initialState: IDietaryPreferenceState = {
  list: {
    data: [],
    error: '',
    isLoading: false,
  },
  options: {
    data: { choices: { dietary_preferences: [] } },
    error: '',
    isLoading: false,
  },
};

export const actionGetDietaryPreferenceOptionsData = createTypedAsyncThunk<TDietaryPreferenceOptionsResponse>(
  'actionGetDietaryPreferenceOptionsData',
  async () =>
    await request(dietaryPreferenceApiUrlCreator(), {
      method: REQUEST_METHODS.OPTIONS,
    }),
);

export const actionGetDietaryPreferenceListData = createTypedAsyncThunk<TDietaryPreference[]>(
  'actionGetDietaryPreferenceListData',
  async () => {
    const dietaryPreferenceResponse = await request(dietaryPreferenceApiUrlCreator());
    return dietaryPreferenceResponse?.results ?? [];
  },
);

export const actionUpdateDietaryPreferenceListData = createTypedAsyncThunk<
  TDietaryPreference[],
  IUpdateDietaryPreferenceParams
>(
  'actionUpdateDietaryPreferenceListData',
  async ({ slugToUpdate, exposureToUpdate }, { getState, rejectWithValue }) => {
    try {
      const dietaryPreferenceListData = selectDietaryPreferenceListData(getState());

      const isNoPreferenceSlug = (slug: string) =>
        [DIETARY_PREFERENCE_SLUG.NO_FOOD_PREFERENCES, DIETARY_PREFERENCE_SLUG.NO_PREFERENCES].includes(slug);

      const removePreference = async (slug: string) =>
        await request(dietaryPreferenceApiUrlCreator(slug), { method: REQUEST_METHODS.DELETE });

      const addPreference = async (slug: string) =>
        await request(
          dietaryPreferenceApiUrlCreator(),
          { method: REQUEST_METHODS.POST },
          { [DIETARY_PREFERENCE_QUERY]: slug },
        );

      const isPreferenceToUpdateInList = dietaryPreferenceListData.some(
        ({ dietary_preference: { slug } }) => slug === slugToUpdate,
      );

      if (isNoPreferenceSlug(slugToUpdate)) {
        if (isPreferenceToUpdateInList) {
          // remove no-preference preference
          await removePreference(slugToUpdate);

          return dietaryPreferenceListData.filter(({ dietary_preference: { slug } }) => slug !== slugToUpdate);
        }

        const shouldDeleteAllPreferencesOfExposure = dietaryPreferenceListData.some(
          ({ dietary_preference: { exposure } }) => exposure === exposureToUpdate,
        );

        if (shouldDeleteAllPreferencesOfExposure) {
          // remove all preferences of the exposure
          await request(dietaryPreferenceMultipleRemoveApiUrlCreator(exposureToUpdate), {
            method: REQUEST_METHODS.DELETE,
          });
        }

        // post no-preference preference
        const newDietaryPreferenceData: TDietaryPreference = await addPreference(slugToUpdate);

        return dietaryPreferenceListData
          .filter(({ dietary_preference: { exposure } }) => exposure !== exposureToUpdate)
          .concat(newDietaryPreferenceData);
      }

      if (isPreferenceToUpdateInList) {
        // delete the preference
        await removePreference(slugToUpdate);

        return dietaryPreferenceListData.filter(({ dietary_preference: { slug } }) => slug !== slugToUpdate);
      }

      const noPreferenceSlugInList = dietaryPreferenceListData
        .filter(({ dietary_preference: { exposure } }) => exposure === exposureToUpdate)
        .find(({ dietary_preference: { slug } }) => isNoPreferenceSlug(slug))?.dietary_preference?.slug;

      if (noPreferenceSlugInList) {
        // delete no-preference preference
        await removePreference(noPreferenceSlugInList);
      }

      // post a new preference
      const newDietaryPreferenceData: TDietaryPreference = await addPreference(slugToUpdate);

      return dietaryPreferenceListData
        .filter(({ dietary_preference: { slug } }) => slug !== noPreferenceSlugInList)
        .concat(newDietaryPreferenceData);
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  },
);

export const actionUpdateMultipleDietaryPreferenceListData = createTypedAsyncThunk<
  TDietaryPreference[],
  IUpdateMultipleDietaryPreferenceParams
>(
  'actionUpdateMultipleDietaryPreferenceListData',
  async ({ slugsToUpdate, exposureToUpdate }, { getState, rejectWithValue }) => {
    try {
      const dietaryPreferenceListData = selectDietaryPreferenceListData(getState());

      const updatePreferences = async (slugs: string[]) => {
        const requestBody = slugs.map(slug => ({ [DIETARY_PREFERENCE_QUERY]: slug }));
        return await request(
          dietaryPreferenceMultipleUpdateApiUrlCreator(),
          { method: REQUEST_METHODS.POST },
          requestBody,
        );
      };

      const shouldDeleteAllPreferencesOfExposure = dietaryPreferenceListData.some(
        ({ dietary_preference: { exposure } }) => exposure === exposureToUpdate,
      );

      if (shouldDeleteAllPreferencesOfExposure) {
        await request(dietaryPreferenceMultipleRemoveApiUrlCreator(exposureToUpdate), {
          method: REQUEST_METHODS.DELETE,
        });
      }

      const newDietaryPreferenceData: TDietaryPreference = await updatePreferences(slugsToUpdate);

      return dietaryPreferenceListData
        .filter(({ dietary_preference }) => dietary_preference.exposure !== exposureToUpdate)
        .concat(newDietaryPreferenceData);
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  },
);

const dietaryPreferenceSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(actionGetDietaryPreferenceOptionsData.pending, state => {
        state.options.error = '';
        state.options.isLoading = true;
      })
      .addCase(actionGetDietaryPreferenceOptionsData.fulfilled, (state, action) => {
        state.options.data = action.payload;
        state.options.isLoading = false;
      })
      .addCase(actionGetDietaryPreferenceOptionsData.rejected, (state, action) => {
        state.options.error = action.payload.message;
        state.options.isLoading = false;
      })

      .addMatcher(
        isAnyOf(
          actionGetDietaryPreferenceListData.pending,
          actionUpdateDietaryPreferenceListData.pending,
          actionUpdateMultipleDietaryPreferenceListData.pending,
        ),
        state => {
          state.list.error = '';
          state.list.isLoading = true;
        },
      )
      .addMatcher(
        isAnyOf(
          actionGetDietaryPreferenceListData.fulfilled,
          actionUpdateDietaryPreferenceListData.fulfilled,
          actionUpdateMultipleDietaryPreferenceListData.fulfilled,
        ),
        (state, action) => {
          state.list.data = action.payload;
          state.list.isLoading = false;
        },
      )
      .addMatcher(
        isAnyOf(
          actionGetDietaryPreferenceListData.rejected,
          actionUpdateDietaryPreferenceListData.rejected,
          actionUpdateMultipleDietaryPreferenceListData.rejected,
        ),
        (state, action) => {
          state.list.error = action.payload?.message || action.error?.message;
          state.list.isLoading = false;
        },
      );
  },
  initialState,
  name: 'dietaryPreference',
  reducers: {
    actionResetDietaryPreferenceListData: state => {
      state.list.data = [];
    },
  },
});

export default dietaryPreferenceSlice.reducer;
export const { actionResetDietaryPreferenceListData } = dietaryPreferenceSlice.actions;

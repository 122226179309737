import { FC, ReactNode } from 'react';

import SocialRegistrationPromptContainer from '@app/web/src/containers/SocialRegistrationPromptContainer';

import { TLanguage } from '@lib/core/retailers/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { currentYear } from '@lib/tools/shared/helpers/utils';
import { TToastMessages } from '@lib/tools/toastMessage/types/interfaces';

import { FooterWidgetAnonymous, FooterWidgetLogged } from '@components/web/src/atoms/Footers';
import * as S from '@components/web/src/atoms/HeaderFooter/HeaderFooterWidget/styles';
import { HeaderWidget } from '@components/web/src/atoms/Headers/HeaderWidget/HeaderWidget';
import ToastMessage from '@components/web/src/atoms/Toast/ToastMessage';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import DownloadAppCard from '@components/web/src/organisms/Cards/DownloadAppCard/DownloadAppCard';
import FooterWave from '@components/web/src/shared/Footer/FooterWave';

interface IProps {
  children: ReactNode;
  isHideGearIcon: boolean;
  shouldShowHeaderDropDownMenu: boolean;
  locale: string;
  isEnableExitNavigation: boolean;
  isEnableLanguageSwitcher: boolean;
  isEnableSettingsNavigation: boolean;
  isRegistrationPromptShown: boolean;
  isHomePage: boolean;
  retailerLanguages: TLanguage[];
  profileImageUrl?: string;
  producerName?: string;
  retailerName: string;
  shouldHideHomeLink: boolean;
  isMultipleProducer?: boolean;
  shouldHideHeader?: boolean;
  shouldHideCatalogLink: boolean;
  shouldHideTasteIdLink: boolean;
  shouldHideExploreLink: boolean;
  shouldShowTasteIdGuide?: boolean;
  shouldShowDownloadAppCard: boolean;
  shouldHideAnonymousFooter: boolean;
  shouldHideLoggedFooter: boolean;
  isFixedBody: boolean;
  isHomePageLoading: boolean;
  toastMessages: TToastMessages;
  handleLogoClick: () => void;
  handleExitNavigation: () => void;
  handleSettingPageNavigation: () => void;
  handleClearToastMessage: () => void;
  handleSelectLanguage: (selectedLanguageCode: string) => void;
  handleSetTasteIdPageShown?: () => void;
  handleDownloadAppClick?: () => void;
  handleHideDownloadAppClick?: () => void;
}

const HeaderFooterWidget: FC<IProps> = ({
  children,
  isHideGearIcon,
  isHomePage,
  isEnableSettingsNavigation,
  shouldShowHeaderDropDownMenu,
  isEnableExitNavigation,
  isEnableLanguageSwitcher,
  isMultipleProducer,
  isRegistrationPromptShown,
  isHomePageLoading,
  locale,
  retailerLanguages,
  retailerName,
  profileImageUrl,
  producerName,
  shouldHideHeader = false,
  shouldHideCatalogLink,
  shouldHideTasteIdLink,
  shouldHideHomeLink,
  shouldShowDownloadAppCard,
  shouldHideExploreLink,
  shouldShowTasteIdGuide,
  shouldHideAnonymousFooter,
  shouldHideLoggedFooter,
  isFixedBody,
  toastMessages,
  handleLogoClick,
  handleExitNavigation,
  handleSettingPageNavigation,
  handleClearToastMessage,
  handleSelectLanguage,
  handleSetTasteIdPageShown,
  handleDownloadAppClick,
  handleHideDownloadAppClick,
}) => {
  const isShowFooterWave = isHomePage && !isHomePageLoading && !shouldHideAnonymousFooter;
  const { footerTitle, footerDescription, footerSubtitle } = localeWidget.homePage;
  const { copyrightText } = localeCommon.commonMessages;
  return (
    <S.HeaderFooterWidgetContainer>
      {toastMessages && (
        <ToastMessage
          handleClearToastMessage={handleClearToastMessage}
          message={toastMessages.message}
          retailerName={retailerName}
          title={toastMessages.title}
          type={toastMessages.type}
        />
      )}
      {shouldShowDownloadAppCard && (
        <DownloadAppCard handleClick={handleDownloadAppClick} handleCloseClick={handleHideDownloadAppClick} />
      )}
      {!shouldHideHeader && (
        <HeaderWidget
          handleExitNavigation={handleExitNavigation}
          handleLogoClick={handleLogoClick}
          handleSelectLanguage={handleSelectLanguage}
          handleSettingPageNavigation={handleSettingPageNavigation}
          isEnableExitNavigation={isEnableExitNavigation}
          isEnableLanguageSwitcher={isEnableLanguageSwitcher}
          isEnableSettingsNavigation={isEnableSettingsNavigation}
          locale={locale}
          producerName={producerName}
          retailerLanguages={retailerLanguages}
          shouldShowHeaderDropDownMenu={shouldShowHeaderDropDownMenu}
        />
      )}
      <S.BodyContainer
        $isFixedBody={isFixedBody}
        data-testid="BodyContainer"
        direction="column"
        id="root-scroll-element"
        justify="space-between"
      >
        <Flexbox>{children}</Flexbox>
        <S.FooterWaveContainer>
          {isShowFooterWave && (
            <S.FooterWaveWrapper isFullWidth data-testid="FooterWaveWrapper" direction="column" gap={0}>
              <FooterWave />
              <S.FooterContent direction="column" gap={16} padding="16px 32px 32px">
                <Text
                  color={STATIC_COLORS.base.white}
                  fontFamily="Fraunces"
                  size="subtitle1"
                  text={footerTitle}
                  weight="semibold"
                />
                <Text color={STATIC_COLORS.base.white} size="body2" text={footerDescription} />
                <Text color={STATIC_COLORS.base.white} size="body2" text={footerSubtitle} weight="semibold" />
                <Text
                  color={STATIC_COLORS.base.white}
                  localeOptions={{ currentYear }}
                  size="body3"
                  text={copyrightText}
                />
              </S.FooterContent>
            </S.FooterWaveWrapper>
          )}
          {!shouldHideAnonymousFooter && <FooterWidgetAnonymous isHideGearIcon={isHideGearIcon} />}
        </S.FooterWaveContainer>
      </S.BodyContainer>

      {isRegistrationPromptShown && <SocialRegistrationPromptContainer />}
      {!shouldHideLoggedFooter && (
        <FooterWidgetLogged
          handleSetTasteIdPageShown={handleSetTasteIdPageShown}
          isMultipleProducer={isMultipleProducer}
          profileImageUrl={profileImageUrl}
          shouldHideCatalogLink={shouldHideCatalogLink}
          shouldHideExploreLink={shouldHideExploreLink}
          shouldHideHomeLink={shouldHideHomeLink}
          shouldHideTasteIdLink={shouldHideTasteIdLink}
          shouldShowTasteIdGuide={shouldShowTasteIdGuide}
        />
      )}
    </S.HeaderFooterWidgetContainer>
  );
};

export default HeaderFooterWidget;

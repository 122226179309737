import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getProductData } from '@lib/core/products/utils';
import { useServiceInstance } from '@lib/core/service/hooks/useServiceInstance';
import { PRODUCT_ID_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { useTypedSelector } from '@lib/tools/views/hooks';

const useGetProductInstance = () => {
  const [searchParams] = useSearchParams();
  const productId = searchParams.get(PRODUCT_ID_URL_PARAM);

  const { isServiceInstanceFeatureEnabled, serviceInstanceRetailerSlug, serviceInstanceRetailerLocationSlug } =
    useServiceInstance();

  const storedProductsData = useTypedSelector(state => state.productCatalog.productsData.results);

  const [productInstanceData, setProductInstanceData] = useState(null);
  const [isProductInstanceDataLoading, setIsProductInstanceDataLoading] = useState(false);

  useEffect(() => {
    if (productId) {
      const storedProductInstanceData = storedProductsData.find(item => item.identifier === productId);

      if (storedProductInstanceData) {
        setProductInstanceData(storedProductInstanceData);
      } else if (!isProductInstanceDataLoading) {
        const fetchProductData = async () => {
          setIsProductInstanceDataLoading(true);
          const data = await getProductData({
            ...(isServiceInstanceFeatureEnabled && {
              customRetailerLocationSlug: serviceInstanceRetailerLocationSlug,
              customRetailerSlug: serviceInstanceRetailerSlug,
            }),
            productId,
          });
          setProductInstanceData(data);
          setIsProductInstanceDataLoading(false);
        };

        fetchProductData();
      }
    }
  }, [productId]);

  return { isProductInstanceDataLoading, productInstanceData } || {};
};

export default useGetProductInstance;

import classNames from 'classnames';
import { FC, ReactNode, useContext } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { SwiperContext } from '@app/web/src/containers/app/OnBoardingContainer';

import 'swiper/css';
import 'swiper/css/pagination';
import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

type Props = {
  title?: ILocaleText;
  children: ReactNode[];
  showPaginationDots?: boolean;
  slidesPerView?: number | 'auto';
  className?: string;
  spaceBetween?: number;
  currentDesignSet?: string;
  storedType?: string;
};

const SwiperHOC: FC<Props> = ({
  title,
  children,
  showPaginationDots = true,
  slidesPerView = 'auto',
  className,
  spaceBetween = 13,
}) => {
  const swiperRef = useContext(SwiperContext);
  return (
    <div className={classNames('vh-swiper-container', className)}>
      {title && (
        <div className={classNames('swiper-title')}>
          <p>
            <LocaleFragment message={title} />
          </p>
        </div>
      )}
      <Swiper
        ref={swiperRef}
        modules={[Pagination]}
        pagination={showPaginationDots ? { clickable: true } : false}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
      >
        {children?.map((item, idx, orginalArr) =>
          Array.isArray(item) ? (
            item.map((subItem, subItemIdx) => <SwiperSlide key={subItemIdx.toString()}>{subItem}</SwiperSlide>)
          ) : (
            <SwiperSlide key={Array.isArray(orginalArr[0]) ? (orginalArr[0].length + 1).toString() : idx.toString()}>
              {item}
            </SwiperSlide>
          ),
        )}
        {showPaginationDots && <div className="hidden-pagination-block" />}
      </Swiper>
    </div>
  );
};

export default SwiperHOC;

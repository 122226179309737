import { css, styled } from 'styled-components';

import { TStyledBadgeContainer } from '@components/web/src/atoms/Badge/Badge';
import { STATIC_COLORS, styleMatcher } from '@components/web/src/foundations';

const getSizeStyles = (size = 'sm') =>
  styleMatcher(size, {
    lg: () => css`
      padding: 4px 12px;
    `,
    md: () => css`
      padding: 2px 10px;
    `,
    sm: () => css`
      padding: 2px 8px;
    `,
    xl: () => css`
      padding: 4px 12px;
    `,
  });

const getVariantStyles = (variant = 'primary', theme, productCategory) =>
  styleMatcher(variant, {
    primary: () => css`
      background-color: ${productCategory
        ? STATIC_COLORS.productColors[productCategory]['50']
        : theme?.colors?.primaryColor['-50']};
      color: ${theme?.colors?.primaryColor['-900']};
    `,
    secondary: () => css`
      background-color: ${theme?.colors?.secondaryColor['-100']};
      color: ${theme?.colors?.secondaryColor['-900']};
    `,
  });

export const BadgeContainer = styled.button<TStyledBadgeContainer>`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  border-radius: 16px;

  ${({ $size }) => getSizeStyles($size)}
  ${({ $isProductCategoryType, $productCategory }) =>
    $isProductCategoryType &&
    css`
      background-color: ${STATIC_COLORS.productColors[$productCategory]['50'] ||
      STATIC_COLORS.productColors.wine['50']} !important;
      color: ${STATIC_COLORS.gray['800']} !important;
      padding: 8px 16px;
      border-radius: 66px;
      gap: 8px;
    `}

  ${({ theme, $variant, $productCategory }) => getVariantStyles($variant, theme, $productCategory)}
`;

export const Icon = styled.span<{ $variant: string; $isProductCategoryType?: boolean }>`
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  cursor: pointer;

  svg {
    ${({ $variant, theme }) =>
      $variant === 'primary'
        ? css`
            stroke: ${theme?.colors?.primaryColor['-600']};
          `
        : css`
            stroke: ${theme?.colors?.secondaryColor['-600']};
          `}
  }

  ${({ $isProductCategoryType }) =>
    $isProductCategoryType &&
    css`
      width: 24px;
      height: 24px;
    `}
`;

import styled from 'styled-components';

import { Text } from '@components/web/src/foundations/Text/Text';

export const ManageModalContainer = styled.div`
  flex: 0;
  padding: 16px;
`;
export const Title = styled(Text)`
  margin: 16px 0 8px;
`;
export const Description = styled(Text)`
  margin-bottom: 24px;
`;
export const ModalItems = styled.div`
  margin-bottom: 40px;
`;
export const ModalActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

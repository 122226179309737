import { combineReducers } from 'redux';

import auth from '@lib/core/users/slices/auth';
import characters from '@lib/core/users/slices/characters';
import dietaryPreference from '@lib/core/users/slices/dietaryPreference';
import scanner from '@lib/core/users/slices/fidelityCard';
import locationList from '@lib/core/users/slices/locationList';
import productFeedback from '@lib/core/users/slices/productFeedback';
import productList from '@lib/core/users/slices/productList';
import profile from '@lib/core/users/slices/profile';
import userInstance from '@lib/core/users/slices/user';

export const users = combineReducers({
  auth,
  characters,
  dietaryPreference,
  locationList,
  productFeedback,
  productList,
  profile,
  scanner,
  userInstance,
});

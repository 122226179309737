import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ENV_RECAPTCHA_SITE_KEY, isApplicationPmi } from '@lib/core/service/consts';
import { useTechnical } from '@lib/core/service/hooks/useTechnical';
import { actionLogin, actionResetAuthSlice } from '@lib/core/users/slices/auth';
import { Button } from '@lib/tools/devtools/components/inputs/Button';
import { useTypedSelector } from '@lib/tools/views/hooks';
import ReCaptchaContainer from '@lib/tools/views/web/containers/reCaptchaContainer';

function LoginForm() {
  const dispatch = useDispatch();
  const recaptchaRef: any = useRef();
  const [, setIsBtnDisabled] = useState(ENV_RECAPTCHA_SITE_KEY && !!ENV_RECAPTCHA_SITE_KEY && !!isApplicationPmi);
  const { isCaptchaDisabled: isGoogleCaptchaDisabled } = useTechnical();
  const isCaptchaDisabled = isGoogleCaptchaDisabled || !!isApplicationPmi;
  const { error } = useTypedSelector(state => state.users.auth);
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();

  const activateRecaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }

    recaptchaRef.current.execute();
  };
  const handleLogin = async (siteKey: string) => {
    // ! Captcha is disabled on the PMI backend environment in v0.8
    await dispatch(actionResetAuthSlice());

    if (!error) {
      await dispatch(
        actionLogin({
          captchaValue: siteKey,
          email,
          isCaptchaDisabled: !!isApplicationPmi,
          password,
        }),
      );
    } else {
      // setSnackbarMessage(JSON.stringify(error));
    }
  };

  return (
    <>
      <ReCaptchaContainer
        handleValue={handleLogin}
        isDisabled={!!ENV_RECAPTCHA_SITE_KEY && !isCaptchaDisabled}
        recaptchaRef={recaptchaRef}
        setIsBtnDisabled={setIsBtnDisabled}
      />
      <div className="panel">
        <input className="m-2" placeholder="Email" type="email" onChange={e => setEmail(e.target.value)} />
        <input className="m-2" placeholder="Password" type="password" onChange={e => setPassword(e.target.value)} />

        <Button onClick={!isApplicationPmi ? activateRecaptcha : () => handleLogin('')}>Login</Button>
      </div>
    </>
  );
}

export default LoginForm;

import { createSelector } from '@reduxjs/toolkit';

import { IProductCatalogState } from '@lib/tools/productCatalog/types/types';

/**
 * Selects the entire product catalog state from the Redux store.
 */
export const selectProductCatalogState = (state): IProductCatalogState => state.productCatalog || {};

/**
 * Selects the products data from the product catalog state.
 */
export const selectProductsData = createSelector(
  [selectProductCatalogState],
  productCatalog => productCatalog.productsData,
);

/**
 * Selects the list of products from the products data.
 */
export const selectProductsDataResults = createSelector([selectProductsData], productsData => productsData.results);

/**
 * Selects the total count of products in the current query.
 */
export const selectProductsDataCount = createSelector([selectProductsData], productsData => productsData.count);

/**
 * Selects the limited products data from the product catalog state.
 */
export const selectProductsDataLimited = createSelector(
  [selectProductCatalogState],
  productCatalog => productCatalog.productsDataLimited,
);

/**
 * Checks if any limited products data from the product catalog state exist.
 */
export const selectIsAnyProductsDataLimitedAvailable = createSelector([selectProductCatalogState], productCatalog => {
  const productsDataLimited = productCatalog.productsDataLimited || {};

  return Object.values(productsDataLimited).some(
    priorityArray => Array.isArray(priorityArray) && priorityArray.length > 0,
  );
});

/**
 * Selects the first priority products from the limited products data.
 */
export const selectProductsDataLimitedFirstPriority = createSelector(
  [selectProductsDataLimited],
  productsDataLimited => productsDataLimited.first_priority || [],
);

/**
 * Selects the second priority products from the limited products data.
 */
export const selectProductsDataLimitedSecondPriority = createSelector(
  [selectProductsDataLimited],
  productsDataLimited => productsDataLimited.second_priority || [],
);

/**
 * Selects the third priority products from the limited products data.
 */
export const selectProductsDataLimitedThirdPriority = createSelector(
  [selectProductsDataLimited],
  productsDataLimited => productsDataLimited.third_priority || [],
);

/**
 * Selects the filters data from the product catalog state.
 */
export const selectFiltersData = createSelector(
  [selectProductCatalogState],
  productCatalog => productCatalog.filtersData,
);

/**
 * Selects the featured products from the product catalog state.
 */
export const selectFeaturedProducts = createSelector(
  [selectProductCatalogState],
  productCatalog => productCatalog.featuredProducts,
);

/**
 * Selects the static matched products from the product catalog state.
 */
export const selectStaticMatchedProducts = createSelector(
  [selectProductCatalogState],
  productCatalog => productCatalog.staticMatchedProducts,
);

/**
 * Selects the last time the featured products were loaded.
 */
export const selectLastFeaturedProductsLoadedTime = createSelector(
  [selectProductCatalogState],
  productCatalog => productCatalog.lastFeaturedProductsLoadedTime,
);

/**
 * Selects the last time any products data was loaded.
 */
export const selectLastDataLoadedTime = createSelector(
  [selectProductCatalogState],
  productCatalog => productCatalog.lastDataLoadedTime,
);

/**
 * Selects a boolean indicating whether the products request is currently loading.
 */
export const selectIsProductsRequestLoading = createSelector(
  [selectProductCatalogState],
  productCatalog => productCatalog.isProductsRequestLoading,
);

/**
 * Selects a boolean indicating whether the featured products are currently loading.
 */
export const selectIsFeaturedProductsLoading = createSelector(
  [selectProductCatalogState],
  productCatalog => productCatalog.isFeaturedProductsLoading,
);

import { createSelector } from 'reselect';

import { IWellnessState } from '@lib/core/productAttributes/slices/wellness';
import { TWellness } from '@lib/core/productAttributes/types';
import { RootState } from '@lib/core/service/types/appStateType';

/**
 * @returns wellness slice state
 */
const selectWellnessState = (state: RootState): IWellnessState => state?.productAttributes?.wellness;

/**
 * @returns wellness data array
 */
export const selectWellnessData: (state) => TWellness[] = createSelector(
  [selectWellnessState],
  wellness => wellness?.data || [],
);

/**
 * @returns wellness loading state
 */
export const selectIsWellnessLoading: (state) => boolean = createSelector(
  [selectWellnessState],
  wellness => wellness?.isLoading || false,
);

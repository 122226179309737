import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { isApplicationKiosk } from '@lib/core/service/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  LEGAL_PAGE_COOKIE_POLICY_PAGE,
  LEGAL_PAGE_PRIVACY_POLICY_PAGE,
  LEGAL_PAGE_TERMS_AND_CONDITIONS_PAGE,
} from '@lib/tools/shared/helpers/consts';

import Tabs from '@components/web/src/atoms/Tabs/Tabs/Tabs';
import { B2B_MOBILE_RESOLUTION_MAX_VALUE } from '@components/web/src/kiosk/consts';
import CookiePolicyPage from '@components/web/src/templates/LegalPages/CookiePolicyPage/CookiePolicyPage';
import * as S from '@components/web/src/templates/LegalPages/LegalTabsPage/styles';
import PrivacyPolicyPage from '@components/web/src/templates/LegalPages/PrivacyPolicyPage/PrivacyPolicyPage';
import TermsConditionsPage from '@components/web/src/templates/LegalPages/TermsConditionsPage/TermsConditionsPage';

interface IProps {
  handleGoBack: () => void;
  onTabClick: (tabCode: string) => void;
  defaultTab: string;
}

const LegalTabsPage: FC<IProps> = ({ handleGoBack, onTabClick, defaultTab = LEGAL_PAGE_TERMS_AND_CONDITIONS_PAGE }) => {
  const { commonMessages } = localeCommon;

  const isMobileResolution = useMediaQuery({ maxWidth: B2B_MOBILE_RESOLUTION_MAX_VALUE });

  const tabsData = [
    {
      children: <TermsConditionsPage />,
      isHiddenTab: false,
      tabCode: LEGAL_PAGE_TERMS_AND_CONDITIONS_PAGE,
      tabText: commonMessages.termsAndConditionsTitle,
    },
    {
      children: <PrivacyPolicyPage />,
      isHiddenTab: false,
      tabCode: LEGAL_PAGE_PRIVACY_POLICY_PAGE,
      tabText: commonMessages.privacyPolicyTitle,
    },
    {
      children: <CookiePolicyPage />,
      isHiddenTab: !isMobileResolution && isApplicationKiosk,
      tabCode: LEGAL_PAGE_COOKIE_POLICY_PAGE,
      tabText: commonMessages.cookiePolicyTitle,
    },
  ];
  return (
    <S.LegalPageContainer>
      <S.StyledBackButton handleClick={handleGoBack} style={{ marginTop: '10px' }} text="Back" variant="short" />
      <Tabs
        active={defaultTab}
        gradientVariant={isApplicationKiosk ? 'default-down' : 'custom-down'}
        tabs={tabsData}
        onTabClick={onTabClick}
      />
    </S.LegalPageContainer>
  );
};

export default LegalTabsPage;

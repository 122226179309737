import { styled } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const PriceRangeFilterContainer = styled(Flexbox)`
  padding: 16px;
  border-bottom: 1px solid ${STATIC_COLORS.warmGray['300']};
  background: ${STATIC_COLORS.base.white};
`;

export const PriceText = styled(Text)`
  text-align: center;
`;

export const RangeWrapper = styled(Flexbox)`
  position: relative;
  width: 100%;
  height: 28px;
  padding-inline: 14px;
  .range-slider-vinhood {
    width: 100%;
    height: 8px;
    padding: 0;
    .rc-slider-handle {
      width: 28px;
      height: 28px;
      background-color: var(--color-primary-700) !important;
      border-radius: 50%;
    }
    .rc-slider-track {
      background-color: var(--color-primary-400) !important;
    }
    .slider-tooltip-content,
    .rc-slider-mark {
      display: none;
    }
  }
`;

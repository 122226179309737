import { IParsedCharacter } from '@lib/core/characters/types/views';

export const parseCharacterDetails = ({ character }): IParsedCharacter => {
  const {
    product_category: characterProductCategory,
    name,
    character_caption: characterCaption,
    related_essences: relatedCharacterEssences = [],
    character_description: characterDescription = '',
    image: characterImage,
    technical_caption: technicalCaption,
    technical_description: technicalDescription,
    identifier,
  } = character || {};
  const sortedRelatedCharacterEssences = [...relatedCharacterEssences]?.sort((a, b) => a.position - b.position) || [];

  return {
    characterCaption,
    characterDescription,
    characterId: identifier,
    characterImage,
    characterName: name,
    characterProductCategory,
    sortedRelatedCharacterEssences,
    technicalCaption,
    technicalDescription,
  };
};

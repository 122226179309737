import styled from 'styled-components';

interface StyledImageWrapperProps {
  $width?: string;
  $height?: string;
  $minWidth?: string;
  $maxWidth?: string;
  $minHeight?: string;
  $maxHeight?: string;
  $borderRadius?: string;
}

interface StyledImageProps {
  $objectFit: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
}

export const StyledImageWrapper = styled.div<StyledImageWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  min-width: ${({ $minWidth }) => $minWidth};
  max-width: ${({ $maxWidth }) => $maxWidth};
  min-height: ${({ $minHeight }) => $minHeight};
  max-height: ${({ $maxHeight }) => $maxHeight};
  border-radius: ${({ $borderRadius }) => $borderRadius};
`;

export const StyledImage = styled.img<StyledImageProps>`
  width: 100%;
  height: 100%;
  object-fit: ${({ $objectFit }) => $objectFit};
  border-radius: inherit;
`;

import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import StyleIcon from '@mui/icons-material/Style';
import TimelineIcon from '@mui/icons-material/Timeline';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useProducts } from '@lib/core/products/hooks/products';
import { useQuizView, useQuizzes } from '@lib/core/quizzes/hooks';
import { actionResetQuizView } from '@lib/core/quizzes/slices/quizView';
import { TQuiz } from '@lib/core/quizzes/types';
import { isQuizForProductCategory } from '@lib/core/quizzes/utils/filters';
import { prependBasename } from '@lib/core/service/utils';
import { history } from '@lib/core/service/utils/Navigator';
import { useUser } from '@lib/core/users/hooks';
import { actionResetAuthSlice } from '@lib/core/users/slices/auth';
import StyledTooltip from '@lib/tools/devtools/components/feedback/Tooltip';
import LoginForm from '@lib/tools/devtools/components/forms/LoginForm';
import { Button } from '@lib/tools/devtools/components/inputs/Button';
import { DropdownSelect } from '@lib/tools/devtools/components/inputs/Select';
import { themeDevTools } from '@lib/tools/devtools/components/theme';
import { Snowball } from '@lib/tools/devtools/panels/sensory/charts/Snowball';
import { QuizAnswerTagsTab } from '@lib/tools/devtools/panels/sensory/tabs/QuizAnswerTagsTab';
import { ScoreMatrixTab } from '@lib/tools/devtools/panels/sensory/tabs/ScoreMatrixTab';
import { PREFERENCES_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { recommendProducts } from '@lib/tools/shared/pmi/products/recommender';
import { QUIZ_SLUG_PREFERENCE } from '@lib/tools/shared/utils/quizzes/consts';
import { useEffectSkipFirst } from '@lib/tools/views/hooks';

export const SensoryPanel = () => {
  const dispatch = useDispatch();

  const { productsByCharacterCorrelation } = useProducts();
  const { quizzes: allQuizzes } = useQuizzes();
  const { quizViewDepth, quizViewQuestionId, quizViewPreviousQuestionId, quizViewUserPath, quizViewPausedQuestionId } =
    useQuizView();
  const { userGroups, isUserAuthenticated } = useUser();

  const quizzes: TQuiz[] = allQuizzes?.filter(isQuizForProductCategory) || [];

  type ISensoryToggleOptions = 'tags' | 'scorematrix' | 'snowball';

  const [selectedQuizIndex, setSelectedQuizIndex] = useState(0);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [sensoryToggles, setSensoryToggle] = useState<ISensoryToggleOptions>('tags');
  const isUserSensory = userGroups?.some(userGroup => userGroup?.name === 'Sensory');
  useEffect(() => {
    if (productsByCharacterCorrelation.length) {
      setRecommendedProducts(recommendProducts(productsByCharacterCorrelation));
    }
  }, [productsByCharacterCorrelation]);

  /**
   * @todo complete quiz options for food preferences
   */
  useEffectSkipFirst(() => {
    const quizType = quizzes[selectedQuizIndex].quiz_type?.slug;
    const quizSlug = quizzes[selectedQuizIndex].slug;

    let preferencesParam = `?${PREFERENCES_URL_PARAM}=`;

    switch (true) {
      case quizSlug.includes(QUIZ_SLUG_PREFERENCE.NO_PREFERENCES):
        preferencesParam += QUIZ_SLUG_PREFERENCE.NO_PREFERENCES;
        break;
      case quizSlug.includes(QUIZ_SLUG_PREFERENCE.NO_MEAT_NO_GLUTEN):
        preferencesParam += QUIZ_SLUG_PREFERENCE.NO_MEAT_NO_GLUTEN;
        break;
      case quizSlug.includes(QUIZ_SLUG_PREFERENCE.NO_DIARY):
        preferencesParam += QUIZ_SLUG_PREFERENCE.NO_DIARY;
        break;
      case quizSlug.includes(QUIZ_SLUG_PREFERENCE.ALL_PREFERENCES):
        preferencesParam += QUIZ_SLUG_PREFERENCE.ALL_PREFERENCES;
        break;
      default:
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        preferencesParam = null;
        break;
    }

    history.navigate(prependBasename(`/quiz/${quizType}/`));
  }, [selectedQuizIndex]);

  const listProductsByCharacterCorrelation = [];
  const optionsQuizzes = [
    <option key="" disabled>
      select quiz
    </option>,
  ];

  recommendedProducts.forEach(productLocation => {
    if (productLocation) {
      listProductsByCharacterCorrelation.push(
        <span key={productLocation?.identifier}>
          <div>
            {productLocation?.product?.name} - {productLocation?.identifier}
          </div>
          <div>{productLocation?.product?.associated_quiz_answer_tags}</div>
        </span>,
      );
    }
  });

  const beautifyUserPath = userPath => {
    const printNode = (node, path) => {
      const depth = path.length;
      const isLast = depth === path.length - 1;
      // eslint-disable-next-line no-nested-ternary
      const prefix = depth === 0 ? '' : isLast ? '└─ ' : '├─ ';

      const nodeString = `${'  '.repeat(depth)}${prefix}${node}${quizViewPausedQuestionId === node ? '*' : ''}`;

      if (path.length > 0) {
        const nextNode = path[0];
        const nextPath = path.slice(1);
        return `${printNode(nextNode, nextPath)}\n${nodeString}`;
      }

      return nodeString;
    };

    const userPathKeys = Object.keys(userPath);
    let result = '';
    // Reverse the order of processing userPathKeys
    for (let i = userPathKeys.length - 1; i >= 0; i -= 1) {
      const key = userPathKeys[i];
      const answerIds = Object.keys(userPath[key])[0];
      const questionId = userPath[key][answerIds][0];
      result += `${printNode(questionId, [answerIds])}\n`;
    }

    return result.trim();
  };

  return (
    <>
      <div className="panel collapsible" role="presentation">
        Sensory Tools
      </div>
      <Box sx={{ margin: themeDevTools.spacing.medium }}>
        <div className="panel">
          <DropdownSelect
            options={optionsQuizzes}
            title="List"
            onChange={e => setSelectedQuizIndex(parseInt(e.target.value, 10))}
          />
          <div className="title">Depth {quizViewDepth}</div>

          <div className="title">Question {quizViewQuestionId}</div>

          <div className="title">Prev Question {quizViewPreviousQuestionId}</div>

          <Button onClick={() => dispatch(actionResetQuizView())}>Reset</Button>

          {Object.keys(quizViewUserPath).length !== 0 && (
            <div className="panel" style={{ whiteSpace: 'pre-wrap' }}>
              <span className="title">Path</span> {quizViewPreviousQuestionId}
              <br />
              <br />
              <div className="code">{beautifyUserPath(quizViewUserPath)}</div>
            </div>
          )}

          {!!listProductsByCharacterCorrelation.length && (
            <div className="panel">
              <span className="title">Products</span>

              {listProductsByCharacterCorrelation}
            </div>
          )}
        </div>
        {!isUserAuthenticated && <LoginForm />}

        {isUserSensory && isUserAuthenticated && (
          <>
            <div className="panel">
              <ToggleButtonGroup
                exclusive
                aria-label="sensory-toggles"
                onChange={(_, option) => {
                  setSensoryToggle(option);
                }}
              >
                <StyledTooltip placement="bottom" title="Tags">
                  <ToggleButton
                    aria-label="tags"
                    value="tags"
                    sx={{
                      '&.Mui-selected, &.Mui-selected:hover': {
                        background: themeDevTools.color.green,
                      },
                      '&:hover': { background: themeDevTools.color.green },
                    }}
                  >
                    <StyleIcon
                      sx={{
                        '&.Mui-selected, &.Mui-selected:hover': {
                          color: themeDevTools.color.black,
                        },
                        color: themeDevTools.color.white,
                        height: themeDevTools.spacing.large,
                        width: themeDevTools.spacing.large,
                      }}
                    />
                  </ToggleButton>
                </StyledTooltip>

                <StyledTooltip placement="bottom" title="Score Matrix">
                  <ToggleButton
                    aria-label="scorematrix"
                    value="scorematrix"
                    sx={{
                      '&.Mui-selected, &.Mui-selected:hover': {
                        background: themeDevTools.color.green,
                      },
                      '&:hover': {
                        background: themeDevTools.color.green,
                      },
                    }}
                  >
                    <ScoreboardIcon
                      sx={{
                        '&.Mui-selected, &.Mui-selected:hover': {
                          color: themeDevTools.color.black,
                        },
                        color: themeDevTools.color.white,
                        height: themeDevTools.spacing.large,
                        width: themeDevTools.spacing.large,
                      }}
                    />
                  </ToggleButton>
                </StyledTooltip>

                <StyledTooltip placement="bottom" title="Snowball">
                  <ToggleButton
                    aria-label="snowball"
                    value="snowball"
                    sx={{
                      '&.Mui-selected, &.Mui-selected:hover': {
                        background: themeDevTools.color.green,
                      },
                      '&:hover': { background: themeDevTools.color.green },
                    }}
                  >
                    <TimelineIcon
                      sx={{
                        '&.Mui-selected, &.Mui-selected:hover': {
                          color: themeDevTools.color.black,
                        },
                        color: themeDevTools.color.white,
                        height: themeDevTools.spacing.large,
                        width: themeDevTools.spacing.large,
                      }}
                    />
                  </ToggleButton>
                </StyledTooltip>
              </ToggleButtonGroup>
            </div>

            {sensoryToggles === 'tags' && <QuizAnswerTagsTab />}

            {sensoryToggles === 'scorematrix' && <ScoreMatrixTab />}

            {sensoryToggles === 'snowball' && (
              <>
                <div className="panel collapsible" role="presentation">
                  <span className="title">Snowball</span>
                </div>

                <div className="panel">
                  <Snowball />
                </div>
              </>
            )}
            <div className="panel">
              <Button onClick={() => dispatch(actionResetAuthSlice())}>Logout</Button>
            </div>
          </>
        )}
      </Box>
    </>
  );
};

import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { LEGAL_TAB_SLUG_REPLACEMENT } from '@lib/core/service/consts';
import { prependBasename } from '@lib/core/service/utils';
import { PAGES } from '@lib/tools/views/urls';

import LegalTabsPage from '@components/web/src/templates/LegalPages/LegalTabsPage/LegalTabsPage';

const LegalTabsContainer: FC = () => {
  const navigate = useNavigate();
  const { legalTabSlug } = useParams();
  const onBackButtonClick = () => navigate(prependBasename(PAGES.vinhood.home));

  const onTabClick = (tabCode: string) => {
    if (tabCode !== legalTabSlug) {
      navigate(prependBasename(PAGES.vinhood.legal.tabs.replace(LEGAL_TAB_SLUG_REPLACEMENT, tabCode)));
      window.scrollTo({ behavior: 'smooth', top: 0 });
    }
  };

  return <LegalTabsPage defaultTab={legalTabSlug} handleGoBack={onBackButtonClick} onTabClick={onTabClick} />;
};

export default LegalTabsContainer;

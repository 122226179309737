import React, { useEffect, useState } from 'react';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { ToastMessageTypes } from '@lib/tools/toastMessage/utils/consts';

import { ReactComponent as ToastCloseIcon } from '@components/web/src/assets/icons/icon_close.svg';
import DangerIcon from '@components/web/src/assets/icons/icon_danger_toast.png';
import InfoIcon from '@components/web/src/assets/icons/icon_info.png';
import { ReactComponent as RateIcon } from '@components/web/src/assets/icons/icon_rate.svg';
import SuccessIcon from '@components/web/src/assets/icons/icon_sucess_toast.png';
import WarningIcon from '@components/web/src/assets/icons/icon_warning_toast.png';
import * as S from '@components/web/src/atoms/Toast/styles';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';

export type IToastType = keyof typeof ToastMessageTypes;

export type IToastPlacement = 'top' | 'bottom';
export type ICustomIcon = 'rate';

export interface IToastMessageProps {
  type?: IToastType;
  title?: ILocaleText;
  message?: ILocaleText;
  retailerName?: string;
  closeTime?: number | false;
  customIcon?: ICustomIcon;
  autoClose?: boolean;
  position?: IToastPlacement;
  handleClearToastMessage: () => void;
}

const customIconVariants: Record<ICustomIcon, JSX.Element> = {
  rate: <RateIcon />,
};

const defaultIcons: Record<IToastType, string> = {
  error: DangerIcon,
  info: InfoIcon,
  success: SuccessIcon,
  warning: WarningIcon,
};

const ToastMessage: React.FC<IToastMessageProps> = ({
  type = 'success',
  title,
  message,
  retailerName,
  closeTime = 5000,
  customIcon,
  autoClose = true,
  position = 'top',
  handleClearToastMessage,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (autoClose && closeTime) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        handleClearToastMessage();
      }, closeTime);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [closeTime, autoClose, handleClearToastMessage]);

  const handleCloseClick = () => {
    setIsVisible(false);
    handleClearToastMessage();
  };

  if (!isVisible) return null;
  const toastProps = { autoClose, duration: closeTime, position, type };
  const showProgressBar = closeTime && autoClose;

  return (
    <S.ToastContainer {...toastProps}>
      <S.ToastContent {...toastProps}>
        <S.IconContainer>
          {customIcon ? customIconVariants[customIcon] : <Image alt={`${type}-logo`} src={defaultIcons[type]} />}
        </S.IconContainer>
        <S.TextContainer {...toastProps}>
          {title && <Text localeIndex={{ retailerName }} size="body2" text={title} weight="bold" />}
          {message && <Text size={!title ? 'body1' : 'body2'} text={message} weight="medium" />}
        </S.TextContainer>

        <Flexbox>
          <S.CloseButton onClick={handleCloseClick}>
            <ToastCloseIcon />
          </S.CloseButton>
        </Flexbox>
      </S.ToastContent>
      {showProgressBar && <S.ProgressBar {...toastProps} />}
    </S.ToastContainer>
  );
};

export default ToastMessage;

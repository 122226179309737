import { FC } from 'react';

interface IProps {
  isLargeVariant?: boolean;
}

const VinhoodLogo: FC<IProps> = ({ isLargeVariant = false }) => {
  const fillColor = '#232021';

  return isLargeVariant ? (
    <svg
      fill="none"
      height="30.8"
      style={{ cursor: 'pointer' }}
      viewBox="0 0 205 33"
      width="205"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.4044 32.5951V1.50304C33.4044 0.75035 32.8056 0.12793 32.0501 0.12793C31.3088 0.12793 30.6958 0.735875 30.6958 1.50304V32.5951H33.4044Z"
        fill={fillColor}
      />
      <path
        d="M97.7117 32.5951V1.50304C97.7117 0.75035 97.113 0.12793 96.3575 0.12793C95.6162 0.12793 95.0032 0.735875 95.0032 1.50304L94.9747 18.9598H76.7274V1.50304C76.7274 0.75035 76.1286 0.12793 75.3731 0.12793C74.6318 0.12793 74.0188 0.735875 74.0188 1.50304V32.5951H76.7131V21.2757H94.9889V32.5951H97.7117Z"
        fill={fillColor}
      />
      <path
        d="M65.893 32.5951V1.50304C65.893 0.75035 65.2943 0.12793 64.5388 0.12793C63.7975 0.12793 63.1845 0.735875 63.1845 1.50304C63.1845 1.54647 63.213 27.9342 63.213 27.9342L44.8089 0.12793H42.0861V31.22C42.0861 31.9727 42.6848 32.5951 43.4403 32.5951C44.1816 32.5951 44.7946 31.9872 44.7946 31.22L44.8232 4.80332L63.213 32.5951H65.893Z"
        fill={fillColor}
      />
      <path
        d="M24.3662 0.214209C23.6535 -0.0608136 22.8551 0.315531 22.5843 1.03928L12.8334 28.585L12.6338 29.2798L12.4342 28.585L2.68336 1.03928C2.4125 0.315531 1.61418 -0.0608136 0.901395 0.214209C0.188611 0.489232 -0.182038 1.29983 0.0888195 2.02357L11.2938 32.5945H13.9311L25.1788 2.02357C25.4354 1.29983 25.079 0.489232 24.3662 0.214209Z"
        fill={fillColor}
      />
      <path
        d="M202.32 17.6715C202.32 21.5363 201.308 24.605 199.269 26.8775C197.231 29.1501 194.536 30.2791 191.172 30.2791H185.113V2.44391H191.4C194.679 2.45839 197.316 3.58743 199.326 5.83103C201.336 8.07464 202.334 11.0709 202.334 14.8055V17.6715H202.32ZM205 17.8597V14.8778C205 11.9973 204.415 9.42081 203.261 7.16272C202.092 4.90464 200.481 3.16766 198.4 1.95177C196.318 0.735876 193.966 0.12793 191.343 0.12793H183.759C183.018 0.12793 182.405 0.735875 182.405 1.50304V32.5951H191.143C193.866 32.5951 196.276 31.9872 198.385 30.7713C200.481 29.5554 202.12 27.8184 203.275 25.5748C204.43 23.3312 205 20.7546 205 17.8597Z"
        fill={fillColor}
      />
      <path
        d="M125.182 0.677912C123.301 0.156816 120.107 -0.0747823 117.627 0.31604C113.464 0.952935 111.397 3.13864 108.703 5.6428C105.752 8.39303 103.699 11.1433 103.557 16.2963C103.514 17.903 103.656 19.7124 104.027 21.9705C104.369 24.0983 106.764 27.4854 108.047 28.6434C113.293 33.3477 124.27 33.8978 130.842 30.0764C134.834 27.7604 136.587 23.3022 137.186 17.3964C137.756 11.8525 135.931 8.14695 133.066 5.26645C131.498 3.68869 127.72 1.38718 125.182 0.677912ZM134.064 18.5689C133.365 23.3745 128.433 29.2513 125.254 30.1343C120.948 31.3357 114.833 30.0475 111.397 28.1223C108.86 26.6893 106.222 23.085 105.738 20.4072C105.068 16.7161 106.351 11.0275 107.905 8.59567C110.014 5.2954 113.564 3.23997 117.912 2.83467C123.386 2.31358 128.19 3.79002 131.099 6.88764C133.736 9.69577 134.762 13.7487 134.064 18.5689ZM154.806 0.287092C149.816 0.822663 145.839 3.31234 144.071 6.72842C142.802 9.17467 141.149 15.9634 142.532 21.1744C143.886 26.255 149.93 31.7265 156.331 32.2911C165.64 34.2162 174.507 26.2695 175.605 18.9452C177.201 4.70193 168.89 -0.914323 154.806 0.287092ZM166.153 28.2815C157.115 31.7844 146.937 26.2406 145.212 18.9307C143.872 13.2132 146.609 6.87317 148.704 5.00591C151.199 2.77677 154.578 1.87933 159.581 1.47403C167.279 0.85161 172.155 5.7586 173.124 12.9092C174.136 20.2624 171.414 26.2406 166.153 28.2815Z"
        fill="url(#paint0_linear_3891_44203)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3891_44203"
          x1="103.548"
          x2="127.841"
          y1="32.6"
          y2="-21.4395"
        >
          <stop stopColor="#3D80C1" />
          <stop offset="1" stopColor="#24A360" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      fill="none"
      height="18"
      style={{ cursor: 'pointer' }}
      viewBox="0 0 110 18"
      width="110"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9243 17.5795V1.14857C17.9243 0.750799 17.603 0.421875 17.1976 0.421875C16.7998 0.421875 16.4709 0.743149 16.4709 1.14857V17.5795H17.9243Z"
        fill={fillColor}
      />
      <path
        d="M52.4307 17.5795V1.14857C52.4307 0.750799 52.1094 0.421875 51.704 0.421875C51.3062 0.421875 50.9773 0.743149 50.9773 1.14857L50.962 10.3737H41.1708V1.14857C41.1708 0.750799 40.8495 0.421875 40.4441 0.421875C40.0463 0.421875 39.7174 0.743149 39.7174 1.14857V17.5795H41.1631V11.5976H50.9697V17.5795H52.4307Z"
        fill={fillColor}
      />
      <path
        d="M35.3572 17.5795V1.14857C35.3572 0.750799 35.036 0.421875 34.6306 0.421875C34.2328 0.421875 33.9039 0.743149 33.9039 1.14857C33.9039 1.17152 33.9192 15.1164 33.9192 15.1164L24.0438 0.421875H22.5828V16.8528C22.5828 17.2505 22.904 17.5795 23.3095 17.5795C23.7072 17.5795 24.0361 17.2582 24.0361 16.8528L24.0514 2.89263L33.9192 17.5795H35.3572Z"
        fill={fillColor}
      />
      <path
        d="M13.0746 0.467703C12.6921 0.322365 12.2637 0.521248 12.1184 0.903717L6.88621 15.4605L6.77912 15.8277L6.67203 15.4605L1.43985 0.903717C1.29451 0.521248 0.866145 0.322365 0.483675 0.467703C0.101206 0.613042 -0.0976792 1.04141 0.0476592 1.42388L6.06008 17.5794H7.47522L13.5106 1.42388C13.6483 1.04141 13.457 0.613042 13.0746 0.467703Z"
        fill={fillColor}
      />
      <path
        d="M108.562 9.69294C108.562 11.7353 108.019 13.357 106.925 14.5579C105.831 15.7589 104.385 16.3556 102.58 16.3556H99.3291V1.64578H102.702C104.462 1.65343 105.877 2.25008 106.956 3.43573C108.034 4.62139 108.57 6.20481 108.57 8.17836V9.69294H108.562ZM110 9.79238V8.2166C110 6.69437 109.686 5.33278 109.067 4.13948C108.44 2.94617 107.575 2.02825 106.458 1.3857C105.342 0.74315 104.079 0.421875 102.672 0.421875H98.6024C98.2047 0.421875 97.8757 0.743149 97.8757 1.14857V17.5795H102.565C104.026 17.5795 105.319 17.2582 106.451 16.6156C107.575 15.9731 108.455 15.0552 109.074 13.8695C109.694 12.6838 110 11.3223 110 9.79238Z"
        fill={fillColor}
      />
      <path
        d="M67.1711 0.712496C66.1613 0.437118 64.4479 0.314728 63.1169 0.521262C60.8833 0.857835 59.7741 2.01289 58.3284 3.33624C56.7449 4.78962 55.6434 6.24301 55.5669 8.96619C55.544 9.81527 55.6205 10.7714 55.8194 11.9648C56.0029 13.0892 57.288 14.8792 57.9765 15.4911C60.7915 17.9772 66.6815 18.2678 70.2079 16.2484C72.3497 15.0245 73.2906 12.6685 73.6118 9.54754C73.9178 6.61783 72.9387 4.65958 71.4012 3.13735C70.5597 2.30357 68.5326 1.08732 67.1711 0.712496ZM71.9366 10.1671C71.5618 12.7067 68.9151 15.8124 67.2093 16.279C64.8992 16.9139 61.6176 16.2331 59.7741 15.2157C58.4125 14.4585 56.9974 12.5538 56.7373 11.1386C56.3778 9.18802 57.0662 6.18181 57.9 4.89671C59.0321 3.15265 60.9368 2.06644 63.2699 1.85226C66.2072 1.57688 68.7851 2.35712 70.3455 3.99409C71.7607 5.47807 72.3114 7.6199 71.9366 10.1671ZM83.0665 0.505964C80.3892 0.788992 78.255 2.10469 77.3065 3.90994C76.6257 5.20269 75.7384 8.79025 76.4804 11.544C77.207 14.229 80.4504 17.1204 83.885 17.4188C88.88 18.4361 93.6379 14.2366 94.2269 10.366C95.0837 2.83903 90.6241 -0.128935 83.0665 0.505964ZM89.1554 15.2999C84.3057 17.151 78.844 14.2213 77.9184 10.3584C77.1994 7.33687 78.6681 3.98644 79.7925 2.99967C81.1312 1.82166 82.9441 1.3474 85.629 1.13321C89.7597 0.804289 92.3758 3.39743 92.896 7.17623C93.4391 11.0621 91.978 14.2213 89.1554 15.2999Z"
        fill="url(#paint0_linear_12458_39701)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_12458_39701"
          x1="55.5624"
          x2="68.2702"
          y1="17.582"
          y2="-11.1207"
        >
          <stop stopColor="#0099AB" />
          <stop offset="1" stopColor="#24A260" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default VinhoodLogo;

import { keyframes, styled } from 'styled-components';

import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--color-secondary-100);
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
  margin-bottom: 16px;

  img {
    position: absolute;
    top: 25px;
    width: 225px;
    height: 225px;
  }
`;

export const BackgroundIcon = styled.div<{ $backgroundColor?: string }>`
  width: 225px;
  height: 225px;
  border-radius: 50%;
  background-color: ${({ $backgroundColor }) => $backgroundColor || STATIC_COLORS.teal[50]};
`;

export const HeaderWrapper = styled(Flexbox)<{ $withTopPadding?: boolean }>`
  text-align: center;
  padding: ${({ $withTopPadding }) => ($withTopPadding ? '16px' : '0 16px 16px')};
`;

export const ButtonsWrapper = styled(Flexbox)<{ $withTopPadding?: boolean }>`
  padding: ${({ $withTopPadding }) => ($withTopPadding ? '32px 16px 48px' : '16px 16px 48px')};
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const CenteredText = styled(Text)`
  text-align: center;
  animation: ${fadeIn} 750ms cubic-bezier(0.4, 0, 0.2, 1);
`;

export const LinkButton = styled(Button)<{ $isCentered?: boolean; $isBold?: boolean }>`
  padding-left: 0;
  padding-right: 0;
  justify-content: ${({ $isCentered }) => ($isCentered ? 'center' : 'left')};

  span {
    color: ${STATIC_COLORS.base.black};

    strong {
      text-decoration: underline;
      font-weight: ${({ $isBold }) => ($isBold ? '700' : '400')};
    }
  }
`;

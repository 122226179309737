import { useSelector } from 'react-redux';

import { selectCharacters, selectIsCharactersLoading } from '@lib/core/characters/selectors';
import { TCharacter } from '@lib/core/characters/types';

export const useCharacters = (): {
  characters: TCharacter[];
  isCharactersLoading: boolean;
} => ({
  characters: useSelector(selectCharacters),
  isCharactersLoading: useSelector(selectIsCharactersLoading),
});

import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { prependBasename } from '@lib/core/service/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { PAGES } from '@lib/tools/views/urls';

import { ReactComponent as BrowseIcon } from '@components/web/src/assets/icons/footer/icon_footer_cube_active.svg';
import { ReactComponent as ExploreIcon } from '@components/web/src/assets/icons/footer/icon_footer_explore_active.svg';
import { ReactComponent as HomeIcon } from '@components/web/src/assets/icons/footer/icon_footer_home_active.svg';
import { ReactComponent as LocationPinIcon } from '@components/web/src/assets/icons/footer/icon_footer_location_pin.svg';
import { ReactComponent as ProfileIcon } from '@components/web/src/assets/icons/footer/icon_footer_user_active.svg';
import * as S from '@components/web/src/atoms/Footers/FooterWidgetLogged/styles';
import TooltipComponent from '@components/web/src/atoms/Tooltips/Tooltip';
import { Text } from '@components/web/src/foundations/Text/Text';

interface IProps {
  profileImageUrl?: string;
  shouldHideHomeLink: boolean;
  shouldHideExploreLink: boolean;
  shouldHideCatalogLink: boolean;
  shouldHideTasteIdLink: boolean;
  shouldShowTasteIdGuide: boolean;
  isMultipleProducer?: boolean;
  handleSetTasteIdPageShown: () => void;
}

const FooterWidgetLogged: FC<IProps> = ({
  profileImageUrl = '',
  isMultipleProducer,
  shouldHideHomeLink,
  shouldHideExploreLink,
  shouldHideCatalogLink,
  shouldHideTasteIdLink,
  shouldShowTasteIdGuide,
  handleSetTasteIdPageShown,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { footerHome, footerExplore, footerLocation, footerBrowse, footerTasteId, footerTooltipTextToTasteId } =
    localeCommon.commonMessages;

  const onLinkClick = (pageSlug, pageName) => {
    MixpanelTracker.events.footerNavigationClick({ pageName });

    switch (pageSlug) {
      case PAGES.vinhood.home:
        navigate(prependBasename(PAGES.vinhood.home));
        break;
      case PAGES.vinhood.explore:
        navigate(prependBasename(PAGES.vinhood.explore));
        break;
      case PAGES.vinhood.catalog:
        navigate(prependBasename(PAGES.vinhood.catalog), { state: { from: pathname } });
        break;
      case PAGES.vinhood.tasteId.tasteId:
        handleSetTasteIdPageShown();
        navigate(prependBasename(PAGES.vinhood.tasteId.tasteId));
        break;
      default:
        navigate(prependBasename(PAGES.vinhood.home));
    }
  };

  const footerMenu = useMemo(
    () => [
      {
        icon: <HomeIcon />,
        isHidden: shouldHideHomeLink,
        pageName: 'Home',
        pageSlug: PAGES.vinhood.home,
        text: footerHome,
      },
      {
        icon: isMultipleProducer ? <ExploreIcon /> : <LocationPinIcon />,
        isHidden: shouldHideExploreLink,
        pageName: 'Explore',
        pageSlug: PAGES.vinhood.explore,
        text: isMultipleProducer ? footerExplore : footerLocation,
      },
      {
        icon: <BrowseIcon />,
        isHidden: shouldHideCatalogLink,
        pageName: 'Browse',
        pageSlug: PAGES.vinhood.catalog,
        text: footerBrowse,
      },
      {
        icon: <ProfileIcon />,
        isHidden: shouldHideTasteIdLink,
        pageName: 'Profile ID',
        pageSlug: PAGES.vinhood.tasteId.tasteId,
        text: footerTasteId,
        tooltipText: footerTooltipTextToTasteId,
      },
    ],
    [pathname, profileImageUrl, shouldHideHomeLink, shouldHideTasteIdLink, shouldHideCatalogLink, isMultipleProducer],
  );

  return (
    <S.FooterWidgetLoggedContainer>
      <S.LinksWrapper>
        {footerMenu.map(({ pageSlug, text, icon, isHidden, pageName, tooltipText }, idx) => {
          const isActive = pathname.includes(pageSlug);
          const isProfileIcon = pageSlug === PAGES.vinhood.tasteId.tasteId && !!profileImageUrl;

          const linkComponent = (
            <S.Link key={idx} $isActive={isActive} $isHidden={isHidden} onClick={() => onLinkClick(pageSlug, pageName)}>
              {isProfileIcon ? <img alt={pageSlug} src={profileImageUrl} /> : icon}
              <Text size="body3" text={text} weight={isActive ? 'semibold' : 'medium'} />
            </S.Link>
          );

          if (shouldShowTasteIdGuide && tooltipText) {
            return (
              <TooltipComponent
                key={pageSlug}
                isAlwaysOpen
                contentClassName="footer-tooltip-content"
                placement="top-end"
                title={tooltipText}
                trigger={linkComponent}
                variant="green"
              />
            );
          }

          return linkComponent;
        })}
      </S.LinksWrapper>
    </S.FooterWidgetLoggedContainer>
  );
};

export default FooterWidgetLogged;

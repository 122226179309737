import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@lib/core/service/types/appStateType';
import { IFilterItem } from '@lib/tools/filterManager/slices/sublistFilterSlice';
import {
  FILTER_TYPE_CHARACTERISTICS,
  FILTER_TYPE_FORMAT,
  FILTER_TYPE_MOOD,
  FILTER_TYPE_ORIGIN,
  FILTER_TYPE_PRODUCT_CATEGORY,
  FILTER_TYPE_RATING,
  FILTER_TYPE_STYLE,
  ORDERING_TYPE_RANK,
} from '@lib/tools/shared/helpers/consts';

const selectProductFilters = (state: RootState) => state?.productFilters;

const selectIsFilterOpened = createSelector(
  [selectProductFilters],
  (productFilters): boolean => productFilters?.filterManager.isFilterOpened || false,
);

const selectOrdering = createSelector(
  [selectProductFilters],
  (productFilters): string => productFilters?.filterManager.ordering || ORDERING_TYPE_RANK,
);

const selectSearchTextValue = createSelector(
  [selectProductFilters],
  (productFilters): string => productFilters?.searchText.searchTextValue || '',
);

const selectShowOnlyIsWishlistToggleActive = createSelector(
  [selectProductFilters],
  (productFilters): boolean => productFilters?.showOnly.isWishlistToggleActive || false,
);

/**
 * Selects the minimum price that was set by user inside filter
 */
const selectUserLowerPriceRangeValue = createSelector(
  [selectProductFilters],
  (productFilters): number => productFilters?.range.userLowerPriceRangeValue || 0,
);

/**
 * Selects the maximum price that was set by user inside filter
 */
const selectUserUpperPriceRangeValue = createSelector(
  [selectProductFilters],
  (productFilters): number => productFilters?.range.userUpperPriceRangeValue || 0,
);

/**
 * Selects the minimum price returned from API for current filter
 */
const selectAppMinPriceRangeValue = createSelector(
  [selectProductFilters],
  (productFilters): number => productFilters?.range.appMinPriceRangeValue || 0,
);

/**
 * Selects the max price returned from API for current filter
 */
const selectAppMaxPriceRangeValue = createSelector(
  [selectProductFilters],
  (productFilters): number => productFilters?.range.appMaxPriceRangeValue || 0,
);

const selectLastPriceRangeChangeWasManual = createSelector(
  [selectProductFilters],
  productFilters => productFilters?.range.lastChangeWasManual,
);

const selectToggleIsCharacterToggleActive = createSelector(
  [selectProductFilters],
  (productFilters): boolean => !!productFilters?.toggle.isCharacterToggleActive,
);

const selectToggleIsPromotionToggleActive = createSelector(
  [selectProductFilters],
  (productFilters): boolean => !!productFilters?.toggle.isPromotionToggleActive,
);

const selectSublistStyle = createSelector(
  [selectProductFilters],
  (productFilters): Record<string, IFilterItem> => productFilters?.sublist?.[FILTER_TYPE_STYLE] || {},
);

const selectSublistFormats = createSelector(
  [selectProductFilters],
  (productFilters): Record<string, IFilterItem> => productFilters?.sublist?.[FILTER_TYPE_FORMAT] || {},
);

const selectSublistCharacteristics = createSelector(
  [selectProductFilters],
  (productFilters): Record<string, IFilterItem> => productFilters?.sublist?.[FILTER_TYPE_CHARACTERISTICS] || {},
);

const selectSublistOrigin = createSelector(
  [selectProductFilters],
  (productFilters): Record<string, IFilterItem> => productFilters?.sublist?.[FILTER_TYPE_ORIGIN] || {},
);

const selectSublistMood = createSelector(
  [selectProductFilters],
  (productFilters): Record<string, IFilterItem> => productFilters?.sublist?.[FILTER_TYPE_MOOD] || {},
);

const selectSublistRating = createSelector(
  [selectProductFilters],
  (productFilters): Record<string, IFilterItem> => productFilters?.sublist?.[FILTER_TYPE_RATING] || {},
);

const selectSublistProductCategory = createSelector(
  [selectProductFilters],
  (productFilters): Record<string, IFilterItem> => productFilters?.sublist?.[FILTER_TYPE_PRODUCT_CATEGORY] || {},
);

export {
  selectIsFilterOpened,
  selectOrdering,
  selectSearchTextValue,
  selectShowOnlyIsWishlistToggleActive,
  selectUserLowerPriceRangeValue,
  selectUserUpperPriceRangeValue,
  selectToggleIsCharacterToggleActive,
  selectToggleIsPromotionToggleActive,
  selectSublistStyle,
  selectSublistFormats,
  selectSublistCharacteristics,
  selectSublistOrigin,
  selectSublistMood,
  selectSublistRating,
  selectSublistProductCategory,
  selectLastPriceRangeChangeWasManual,
  selectAppMaxPriceRangeValue,
  selectAppMinPriceRangeValue,
};

import styled, { css } from 'styled-components';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import Button from '@components/web/src/atoms/Buttons/Button';
import { SCREEN_SIZE } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const FoodHabitsContainer = styled(Flexbox)<{ $background?: string }>`
  width: 100%;
  padding: 16px 16px 20px;

  ${({ $background }) =>
    $background
      ? css`
          background-color: ${$background};
        `
      : css`
          background-color: var(--color-secondary-100);
        `}

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      gap: 60px;
      padding: 200px 90px;
    `}
`;

export const AnswersContainer = styled(Flexbox)`
  flex-flow: row wrap;
  gap: 24px 16px;
  max-width: 343px;
  margin: 0 auto;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      gap: 140px 24px;
      max-width: ${SCREEN_SIZE.desktopKiosk};
    `}
`;

export const StyledButton = styled(Button)`
  min-width: 110px;
  margin: 12px auto;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      margin: 40px auto 0;
    `}
`;

export const GoBackButton = styled(BackButton)`
  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      margin: 112px auto 0;
    `}
`;

import { TProductFeedbackValue } from '@lib/core/users/slices/productFeedback';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import feedback1Active from '@components/web/src/assets/icons/feedback/feedback_1_active.svg';
import feedback1Inactive from '@components/web/src/assets/icons/feedback/feedback_1_inactive.svg';
import feedback2Active from '@components/web/src/assets/icons/feedback/feedback_2_active.svg';
import feedback2Inactive from '@components/web/src/assets/icons/feedback/feedback_2_inactive.svg';
import feedback3Active from '@components/web/src/assets/icons/feedback/feedback_3_active.svg';
import feedback3Inactive from '@components/web/src/assets/icons/feedback/feedback_3_inactive.svg';
import feedback4Active from '@components/web/src/assets/icons/feedback/feedback_4_active.svg';
import feedback4Inactive from '@components/web/src/assets/icons/feedback/feedback_4_inactive.svg';
import feedback5Active from '@components/web/src/assets/icons/feedback/feedback_5_active.svg';
import feedback5Inactive from '@components/web/src/assets/icons/feedback/feedback_5_inactive.svg';

// ! Refactor below
// Dev tools
export const URL_PARAM_TOOLS = 'tools';

// Unit test variable
export const RTL_SLUG_FOR_TESTS = 'RTL_SLUG_FOR_TESTS';

// ! Quizzes and Routes - use quizTypes in a route map

// Retailer const
export const STORE = 'store-character';
export const EAN_CODE_REPLACEMENT = 'ean-code-replacement';
export const RETAILER_SLUG_FOR_REPLACEMENT = ':retailer_slug';
export const UID_REPLACEMENT = ':uid';
export const TOKEN_REPLACEMENT = ':token';
export const EMAIL_HASH_REPLACEMENT = ':email_hash';
export const RETAILER_LOCATION_SLUG_FOR_REPLACEMENT = ':retailerLocationSlug';
export const EXPERIENCE_TYPE_REPLACEMENT = ':experienceType';
export const EDUTAINMENT_FORM_LINK_REPLACEMENT = ':typeformLink';
export const PRODUCT_CATEGORY_REPLACEMENT = ':productCategory';

// Flash label constants - Vusion API
export const STORE_ID_REPLACEMENT = ':store_id';
export const ITEM_ID_REPLACEMENT = ':item_id';

// Quiz constants
export const QUIZ_SESSION_ID_REPLACEMENT = ':quiz_session_id';

// Filter types
export const CHARACTERS = 'characters';
export const CHARACTERISTICS = 'characteristics';

export const isApplicationKiosk = import.meta.env.REACT_APP_IS_KIOSK === 'true';
export const isApplicationPmi = import.meta.env.REACT_APP_IS_PMI === 'true';

export const ENV_IS_PRODUCTION = process.env.REACT_APP_IS_PRODUCTION === 'true';
export const ENV_IS_TESTING = process.env.MOCK_STORE === 'true';
export const ENV_IS_BUILD = process.env.NODE_ENV === 'production';

export const ENV_APPLE_APP_ID = process.env.REACT_APP_APPLE_CLIENT_ID;
export const ENV_APPLE_REDIRECT_URL = process.env.REACT_APP_APPLE_REDIRECT_URL;
export const ENV_FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const ENV_RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const ENV_DEVTOOLS_TOKEN = process.env.REACT_APP_DEVTOOLS_TOKEN;
export const ENV_GOOGLE_APP_ID = process.env.REACT_APP_GOOGLE_APP_ID;
export const ENV_HOTJAR_SITE_ID = process.env.REACT_APP_HOTJAR_SITE_ID;
export const ENV_FACEBOOK_PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID;
export const ENV_TRACKING_BLACKLIST = process.env.REACT_APP_TRACKING_BLACKLIST;
export const ENV_AWS_S3_BUCKET_NAME = process.env.REACT_APP_AWS_S3_BUCKET_NAME;
export const ENV_AWS_S3_BUCKET_REGION = process.env.REACT_APP_AWS_S3_BUCKET_REGION;
export const ENV_AWS_CF_DOMAIN_FA = process.env.REACT_APP_AWS_CF_DOMAIN_FA;

const ENV_VINHOOD_CLOUDFRONT_DIST_ID = isApplicationKiosk
  ? process.env.REACT_APP_AWS_CF_DIST_ID_KIOSK
  : process.env.REACT_APP_AWS_CF_DIST_ID_WEB;

const ENV_VINHOOD_CF_DOMAIN = isApplicationKiosk
  ? process.env.REACT_APP_AWS_CF_DOMAIN_KIOSK
  : process.env.REACT_APP_AWS_CF_DOMAIN_WEB;

export const ENV_CLOUDFRONT_DIST_ID = isApplicationPmi
  ? process.env.REACT_APP_AWS_CF_DIST_ID_FA
  : ENV_VINHOOD_CLOUDFRONT_DIST_ID;

export const ENV_CLOUDFRONT_APP_DOMAIN = isApplicationPmi
  ? process.env.REACT_APP_AWS_CF_DOMAIN_FA
  : ENV_VINHOOD_CF_DOMAIN;

// Localization env consts
export const ENV_LOCALE_S3_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
export const ENV_LOCALE_S3_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

export const ENV_FIREBASE_I18N_API_KEY = process.env.REACT_APP_FIREBASE_I18N_API_KEY;
export const ENV_FIREBASE_I18N_APP_ID = process.env.REACT_APP_FIREBASE_I18N_APP_ID;
export const ENV_FIREBASE_I18N_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_I18N_AUTH_DOMAIN;
export const ENV_FIREBASE_I18N_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_I18N_MESSAGING_SENDER_ID;
export const ENV_FIREBASE_I18N_PROJECT_ID = process.env.REACT_APP_FIREBASE_I18N_PROJECT_ID;
export const ENV_FIREBASE_I18N_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_I18N_STORAGE_BUCKET;

export const ENV_API_DOMAIN = process.env.API_DOMAIN ? `https://${process.env.API_DOMAIN}` : '';

export const ENV_BACKEND_DOMAIN =
  ENV_API_DOMAIN ||
  `https://api${ENV_IS_PRODUCTION ? '' : '-staging'}.${isApplicationPmi ? 'taste-advisor-vh' : 'vinhood'}.com`;

export const ENV_MEDIA_DOMAIN = `https://media${ENV_IS_PRODUCTION ? '' : '-staging'}.${
  isApplicationPmi ? 'taste-advisor-vh' : 'vinhood'
}.com`;

// * Design Sets
export const DESIGN_SET_PMI_V2 = 'taste-advisor-v2';
export const DESIGN_SET_PMI_CRAFTED = 'taste-advisor-crafted';
export const DESIGN_SET_WINE_EXPERIENCE = 'wine-experience';
export const DESIGN_SET_VINHOOD_APP = 'vinhood-app';
export const DESIGN_SET_VINHOOD_EXPERIENCE = 'vinhood-experience';
export const DESIGN_SET_VINHOOD_KIOSK = 'vinhood-kiosk';

// local storage
export const STATE = 'state';

// request errors
export const REQUEST_ERROR_FORBIDDEN_MESSAGE = 'Your IP is not allowed for this API.';

// Console warnings
export const ERROR_UNRECOGNIZED_POST_MESSAGE_EVENT = 'WarningUnrecognizedPostMessageEvent';

// for B2C tabs in profile page
export const PROFILE_TAB_SLUG_REPLACEMENT = ':profileSlug?';
// for Widget tabs in legal page
export const LEGAL_TAB_SLUG_REPLACEMENT = ':legalTabSlug?';

// b2c modals
export const B2C_MODALS = {
  FOOD_PREFERENCES_MODAL: 'foodPreferencesModal',
  INTERESTS_MODAL: 'interestsModal',
  MANAGE_MODAL: 'manageModal',
} as const;

// B2C SearchTypes
export const B2C_SEARCH_TYPE_PRODUCTS = 'products';
export const B2C_SEARCH_TYPE_ARTICLES = 'articles';
export const B2C_SEARCH_TYPE_EXPERIENCES = 'experiences';

export const GET_PRODUCTS_REQUEST_LIMIT = 20;
export const B2C_GET_EXPERIENCES_PAGE_SIZE = 20;

export const PRODUCT_SLUG_REPLACEMENT = 'product-slug';

// Experiences Catalog
export const EXPERIENCES_TYPES = {
  courses: 'EXT0004',
  events: 'EXT0006',
  experiences: 'EXT0002',
  onlineActivities: 'EXT0003',
  onlineClasses: 'EXT0005',
  overnightStays: 'EXT0007',
  tastings: 'EXT0001',
};

// HTTP Request methods
export const REQUEST_METHODS = {
  DELETE: 'DELETE',
  GET: 'GET',
  OPTIONS: 'OPTIONS',
  PATCH: 'PATCH',
  POST: 'POST',
  PUT: 'PUT',
};

export const QA_URL_PARAM_CURRENT_DATE = 'QACurrentDateDay';

// URL params
export const ENABLE_PROMOTION_TOGGLE_URL_PARAM = 'enablePromotionToggle';
export const ENABLE_WISHLIST_FILTER_URL_PARAM = 'enableWishlistFilter';
export const DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM = 'disableUserCharacterToggle';
export const IS_FROM_SITUATIONAL_TEST_URL_PARAM = 'isFromSituationalTest';
export const PRODUCT_PREFERENCES_URL_PARAM = 'situationalPreferences';
export const IS_FROM_PRODUCT_DETAILS_PAGE = 'isFromProductDetails';
export const IS_FROM_EXPLORE_PAGE = 'isFromExplorePage';
export const REDIRECT_URL_PARAM = 'redirectUrl';
export const MIXPANEL_EVENT_SLUG_URL_PARAM = 'mixpanelEventSlug';
export const ACTION_PERFORMED_URL_PARAM = 'actionPerformed';

// Open-search API queries
export const PRODUCT_CHARACTERISTICS_QUERY = 'product_characteristics_identifier';
export const PRODUCT_NAME_QUERY = 'query';
export const ORIGINS_QUERY = 'product_region_identifier';
export const ASSOCIATED_RETAILER_SLUG_QUERY = 'associated_retailer_location_slug';
export const STYLE_QUERY = 'character_type_identifier';
export const FORMAT_QUERY = 'product_format_identifier';
export const MOOD_QUERY = 'user_product_preferences';
export const PRODUCTS_MIN_PRICE_QUERY = 'price_gt';
export const PRODUCTS_MAX_PRICE_QUERY = 'price_lt';
export const PREFERENCES_QUERY = 'preferences_slug';
export const PRODUCER_QUERY = 'producer';
export const PRODUCER_IDENTIFIER_QUERY = 'producer_identifier';
export const GPRL_CHARACTER_QUERY = 'character_identifier';
export const GPRL_PROMOTIONS_QUERY = 'promotions_typeSlug';
export const SEARCH_BY_NAME_FIELDS_QUERY = 'fields';
export const RETAILER_LOCATION_QUERY = 'retailer_location';
export const DIETARY_PREFERENCE_QUERY = 'dietary_preference';

export const HARDCODED_FEEDBACK_DATA: Record<
  TProductFeedbackValue,
  { icon: { activeIcon: string; inactiveIcon: string }; text: ILocaleText; value: TProductFeedbackValue }
> = {
  1: {
    icon: { activeIcon: feedback1Active, inactiveIcon: feedback1Inactive },
    text: localeCommon.ratingModal.ratingVeryBad,
    value: 1,
  },
  2: {
    icon: { activeIcon: feedback2Active, inactiveIcon: feedback2Inactive },
    text: localeCommon.ratingModal.ratingBad,
    value: 2,
  },
  3: {
    icon: { activeIcon: feedback3Active, inactiveIcon: feedback3Inactive },
    text: localeCommon.ratingModal.ratingNeutral,
    value: 3,
  },
  4: {
    icon: { activeIcon: feedback4Active, inactiveIcon: feedback4Inactive },
    text: localeCommon.ratingModal.ratingGood,
    value: 4,
  },
  5: {
    icon: { activeIcon: feedback5Active, inactiveIcon: feedback5Inactive },
    text: localeCommon.ratingModal.ratingVeryGood,
    value: 5,
  },
};

export const ModalTypes = {
  FindMeModal: 'findMeModal',
  InformationModal: 'informationModal',
  LocationMapModal: 'locationMapModal',
  ProductScanFound: 'productScanFound',
  ProductScanNotFound: 'productScanNotFound',
  PromoQrModal: 'promoQrModal',
} as const;

export const PRODUCT_CATEGORY_NONE = 'none';

export const PRODUCT_REQUEST_FOR_HOME_PAGE = 'homePage';
export const PRODUCT_REQUEST_FOR_RESULT_PAGE = 'resultPage';

// Taste Match
export const TASTE_MATCH_LEVELS = {
  FOUR: 'levelFour',
  NONE: 'levelNone',
  ONE: 'levelOne',
  THREE: 'levelThree',
  TWO: 'levelTwo',
} as const;

export const TASTE_MATCH_LEVEL_VALUES = {
  [TASTE_MATCH_LEVELS.ONE]: { max: 1, min: 0.96 },
  [TASTE_MATCH_LEVELS.TWO]: { max: 0.95, min: 0.8 },
  [TASTE_MATCH_LEVELS.THREE]: { max: 0.79, min: 0.6 },
  [TASTE_MATCH_LEVELS.FOUR]: { max: 0.59, min: 0 },
  [TASTE_MATCH_LEVELS.NONE]: { max: null, min: null },
};

export type TTasteMatchLevels = (typeof TASTE_MATCH_LEVELS)[keyof typeof TASTE_MATCH_LEVELS];

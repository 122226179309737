/* eslint-disable sort-keys-fix/sort-keys-fix */
import { defineMessages } from '@formatjs/intl';

export const localeWidget = {
  homePage: defineMessages({
    modalLogoutDeleteData: {
      defaultMessage: 'Even the best love stories end: you are going to delete all your profile data.',
      id: 'vh.vinhood_experience.start_page.modal_logout.modalLogoutDeleteData',
    },
    modalLogoutDeleteDataBtn: {
      defaultMessage: 'Delete all my data',
      id: 'vh.vinhood_experience.start_page.modal_logout.modalLogoutDeleteDataBtn',
    },
    trainYourTasteAt: {
      defaultMessage: 'Train your Taste at ||{ retailerLocationName }!',
      id: 'vh.vinhood_experience.start_page.trainYourTasteAt',
    },
    trainYourTaste: {
      defaultMessage: 'Train your Taste!',
      id: 'vh.vinhood_experience.start_page.trainYourTaste',
    },
    anonTitle: {
      defaultMessage: 'Welcome back!',
      id: 'vh.vinhood_experience.start_page.anonTitle',
    },
    authTitle: {
      defaultMessage: 'Welcome, { userFirstName }!',
      id: 'vh.vinhood_experience.start_page.authTitle',
    },
    characterSubtitle: {
      defaultMessage:
        'The perfect { productCategory, select, other {{ productCategoryPluralText }}} for your taste have a ||**#{ characterName } character**.',
      id: 'vh.vinhood_experience.start_page.characterSubtitle',
    },
    tasteChanged: {
      defaultMessage: 'Has your taste changed? **Try the test again!**',
      id: 'vh.vinhood_experience.start_page.tasteChanged',
    },
    alreadyRegistered: {
      defaultMessage: 'Have a Vinhood profile already? **Log in**',
      id: 'vh.vinhood_experience.start_page.alreadyRegistered',
    },
    startTest: {
      defaultMessage: 'Start the Taste Test',
      id: 'vh.vinhood_experience.start_page.startTest',
    },
    typeRecipe: {
      defaultMessage: `Do a Food Pairing Test`,
      id: 'vh.vinhood_experience.start_page.typeRecipe',
    },
    deleteYourData: {
      defaultMessage: `Do you want to **delete your data?**`,
      id: 'vh.vinhood_experience.start_page.deleteYourData',
    },
    dontWorry: {
      defaultMessage: `Don't worry: friends as before.`,
      id: 'vh.vinhood_experience.start_page.dontWorry',
    },
    seeMy: {
      defaultMessage: '{ productCategory, select, other { See my suggested { productCategoryPluralText }}}',
      id: 'vh.vinhood_experience.start_page.seeMy',
    },
    footerTitle: {
      defaultMessage: 'Hello, we are Vinhood!',
      id: 'vinhood.vinhood_experience.start_page.footerTitle',
    },
    footerDescription: {
      defaultMessage:
        'Founded in 2016 in Italy, the country of taste, we guide people worldwide to better understand and enjoy flavors based on their sensory preferences and personal values, making the world of flavors accessible.',
      id: 'vinhood.vinhood_experience.start_page.footerDescription',
    },
    footerSubtitle: {
      defaultMessage: 'The more you know, the more you enjoy!',
      id: 'vinhood.vinhood_experience.start_page.footerSubtitle',
    },
  }),
  startPage: defineMessages({
    title: {
      defaultMessage:
        'Discover your taste profile, {productCategory, select, wine {your perfect {productCategoryPluralText} and how to pair them with your recipes} beer {your perfect {productCategoryPluralText} and how to pair them with your recipes} bread {your perfect {productCategoryPluralText} and how to pair them with your recipes} coffee {your perfect {productCategoryPluralText} and how to pair them with your recipes} other{}}.',
      id: 'vh.vinhood_experience.start_page.title',
    },
    subTitle: {
      defaultMessage:
        'VINHOOD will guide you through the world of {productCategory, select, wine {{productCategoryPluralText}} beer {{productCategoryPluralText}} bread {{productCategoryPluralText}} coffee {{productCategoryPluralText}} other {}} with advice and recommendations that are always just a click away. We’ll save your profile at the end of the test, even if you don’t register, so that you can use it again next time!',
      id: 'vh.vinhood_experience.start_page.subTitle',
    },
    checkOutCookie: {
      defaultMessage: 'Check out our [linkTo=cookie-page]cookie policy[linkTo=cookie-page] to learn more.',
      id: 'vh.vinhood_experience.start_page.checkOutCookie',
    },
    btnText: {
      defaultMessage: 'Let’s get started!',
      id: 'vh.vinhood_experience.start_page.btnText',
    },
  }),
  productCatalog: defineMessages({
    pourCoffee: {
      defaultMessage: 'Pour your coffee',
      id: 'vh.vinhood_experience.product_catalog_page.pourCoffee',
    },
    pourBeer: {
      defaultMessage: 'Pour your beer',
      id: 'vh.vinhood_experience.product_catalog_page.pourBeer',
    },
    uncorkTheWineShort: {
      defaultMessage: 'Uncork the wine',
      id: 'vh.vinhood_experience.product_catalog_page.uncorkTheWineShort',
    },
    yourBestMatchIs: {
      defaultMessage: 'Your best match is:',
      id: 'vh.vinhood_experience.product_catalog_page.yourBestMatchIs',
    },
    allProductsByProximity: {
      defaultMessage:
        'Here are all {productCategory, select, beer {beers} wine {wines} coffee {coffees} other {}} sorted by proximity to your taste criteria.',
      id: 'vh.vinhood_experience.product_catalog_page.allWinesByProximity',
    },
    productsByProximity: {
      defaultMessage:
        'Here are {productCategory, select, beer {beers} wine {wines} coffee {coffees} other {}} sorted by proximity to your taste criteria.',
      id: 'vh.vinhood_experience.product_catalog_page.winesByProximity',
    },

    soManyProducts: {
      defaultMessage: 'So many products! Use filters to simplify your choice.',
      id: 'vh.vinhood_experience.product_catalog_page.soManyProducts',
    },
    seeOtherProducts: {
      defaultMessage: 'Other products perfect for you',
      id: 'vh.vinhood_experience.product_catalog_page.seeOtherProducts',
    },
  }),
  cookiePolicyPage: defineMessages({
    mainTitle: {
      defaultMessage: 'Cookie policy',
      id: 'vinhood.vinhood_experience.cookie_policy_page.mainTitle',
    },
    mainDescription: {
      defaultMessage:
        '**TASTE S.r.l.** is a company incorporated and operating under Italian law, with registered office in Milan, Piazza della Repubblica 32, Vinhood (the **“Controller”**, **“Us”** or **“VINHOOD”**), may be contacted at the e-mail address: privacy@vinhood.com. As data Controller, it provides you with the following information regarding the use of cookies on the widget that you will find on the VINHOOD Platform or at third parties’ websites (referred as **“Widget”**).',
      id: 'vinhood.vinhood_experience.cookie_policy_page.mainDescription',
    },
    whatAreCookiesTitle: {
      defaultMessage: '1. What are cookies and what cookie categories we use',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesTitle',
    },
    whatAreCookiesParagraph1: {
      defaultMessage:
        'Cookies are small text strings that the Platform you visit sends to your computer, smartphone tablet, etc., where they are stored and then resent to the Platform on your next visit.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph1',
    },
    whatAreCookiesParagraph2: {
      defaultMessage: 'Cookies used on this Platform may be identified using the following guidelines:',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph2',
    },
    whatAreCookiesParagraph3: {
      defaultMessage: '**a) Based on who manages the cookies:**',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph3',
    },
    whatAreCookiesParagraph4: {
      defaultMessage:
        '(i) Proprietary or first-party cookies: these are cookies directly installed by Us through the Widget hosted in the site;',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph4',
    },
    whatAreCookiesParagraph5: {
      defaultMessage:
        '(ii) Third-party cookies: these are cookies installed through the Widget on third parties’ site other than Us.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph5',
    },
    whatAreCookiesParagraph6: {
      defaultMessage: '**b) Based on the time they persist and remain active:**',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph6',
    },
    whatAreCookiesParagraph7: {
      defaultMessage:
        '(i) Session cookies: these cookies store the information required during a browsing session, and they are deleted immediately after the session is closed, or after the browser is closed.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph7',
    },
    whatAreCookiesParagraph8: {
      defaultMessage:
        '(ii) Persistent cookies: these cookies are stored on your hard drive and the widget reads them each time you visit the widget again. A persistent cookie has a specific expiration date, after which it will stop working.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph8',
    },
    whatAreCookiesParagraph9: {
      defaultMessage: '**c) Based of the purposes:**',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph9',
    },
    whatAreCookiesParagraph10: {
      defaultMessage:
        'Some cookies are necessary to allow the user to browse the Widget and use its features (so-called technical cookies). Others are used to obtain statistical information, in aggregate or not, on the number of users accessing the site and how the widget is used (so-called analytics cookies). Finally, others are used to track your profile in order to display advertisements that may be in line with your interests, based on your taste and consumption habits (so-called profiling cookies).',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph10',
    },
    whatAreCookiesParagraph11: {
      defaultMessage: 'Our Widget uses technical cookies, analytics cookies and profiling cookies.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph11',
    },
    whatAreCookiesParagraph12: {
      defaultMessage:
        'By changing the settings on the browser you use, you can decide at any time to block cookie installation, in whole or in part. The configuration of each browser is different and is described in the browser “Guide” menu. If you want to learn about cookie settings, you can consult the instructions provided by the most common browsers:',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesParagraph12',
    },
    whatAreCookiesGoogleChrome: {
      defaultMessage: '**Google Chrome:** https://support.google.com/chrome/answer/95647?hl=it',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesGoogleChrome',
    },
    whatAreCookiesMozillaFirefox: {
      defaultMessage:
        '**Mozilla Firefox:** https://support.mozilla.org/it/kb/protezione-antitracciamento-avanzata-firefox-desktop?redirectslug=Attivare+e+disattivare+i+cookie&redirectlocale=it',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesMozillaFirefox',
    },
    whatAreCookiesInternetExplorer: {
      defaultMessage:
        '**Internet Explorer:** https://support.microsoft.com/it-it/topic/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesInternetExplorer',
    },
    whatAreCookiesOpera: {
      defaultMessage: '**Opera:** https://help.opera.com/en/latest/web-preferences/#cookies',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesOpera',
    },
    whatAreCookiesSafari: {
      defaultMessage: '**Safari:** https://support.apple.com/it-it/guide/safari/sfri11471/mac',
      id: 'vinhood.vinhood_experience.cookie_policy_page.whatAreCookiesSafari',
    },
    cookiesUsedTitle: {
      defaultMessage: '2. Cookies used by VINHOOD',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedTitle',
    },
    cookiesUsedParagraph1: {
      defaultMessage: '**Technical cookies**',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph1',
    },
    cookiesUsedParagraph2: {
      defaultMessage:
        'Technical cookies are those that Vinhood uses for the sole purpose of allowing you to use the Widget and be able to enjoy it. They can be further divided into:',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph2',
    },
    cookiesUsedParagraph3: {
      defaultMessage:
        '(i) Navigation cookies: they guarantee the normal navigation and usage of the Widget, allowing you, for example, to make a purchase or authenticate to access reserved areas.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph3',
    },
    cookiesUsedParagraph4: {
      defaultMessage:
        '(ii) Functionality cookies: allow you to browse, based on a series of selected features (for example, language or country of origin) for enhancing the service provided on the Platform. These are persistent cookies that are stored on the computer you use also after closing the web browser until the expiry date set for each cookie (normally from two months to two years) is reached or until you decide to remove them.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph4',
    },
    cookiesUsedParagraph5: {
      defaultMessage: 'Technical cookies are not used for purposes other than those described above.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph5',
    },
    cookiesUsedParagraph6: {
      defaultMessage: 'The following technical cookies are currently used on the Platform:',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph6',
    },
    cookiesUsedParagraph7: {
      defaultMessage:
        'You can disable proprietary and third-party technical cookies by changing your browser settings. However, you should be aware that by removing or blocking these cookies, several of the websites you visit (or the relevant features) may not work properly or efficiently.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph7',
    },
    cookiesUsedParagraph8: {
      defaultMessage: '**Cookie Analytics**',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph8',
    },
    cookiesUsedParagraph9: {
      defaultMessage:
        'Widget’s analytics cookies are not installed directly by Us, but by third parties and are used to collect statistical information, aggregated or non-aggregate form, on the number of users accessing the Widget and/or the site that hosts it and how they visit it.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph9',
    },
    cookiesUsedParagraph10: {
      defaultMessage:
        'The following third-party cookie analytics are installed on the Platform without your prior consent, because in they are less invasive, in that they and anonymized, preventing third parties from accessing unbundled analytics at IP address level (in other words, when using these cookies, third parties cannot cross-check data for tracing a user’s identity) and the third parties cannot cross-check data obtained using cookies with other data they already hold:',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph10',
    },
    thirdPartyCookiesAnalytics: {
      defaultMessage: 'Third-party Cookie Analytics',
      id: 'vinhood.vinhood_experience.cookie_policy_page.thirdPartyCookiesAnalytics',
    },
    serviceName: {
      defaultMessage: 'Service name',
      id: 'vinhood.vinhood_experience.cookie_policy_page.serviceName',
    },
    purpose: {
      defaultMessage: 'Purpose',
      id: 'vinhood.vinhood_experience.cookie_policy_page.purpose',
    },
    persistence: {
      defaultMessage: 'Persistence',
      id: 'vinhood.vinhood_experience.cookie_policy_page.persistence',
    },
    purposeGoogleAnalytics: {
      defaultMessage:
        'The service allows Us to understand how you interact with the Platform. This service, with no active advertising features and by hiding your IP address, uses a set of cookies for collecting information and generating statistics on Platform use.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.purposeGoogleAnalytics',
    },
    persistenceGoogleAnalytics: {
      defaultMessage: '14 months',
      id: 'vinhood.vinhood_experience.cookie_policy_page.persistenceGoogleAnalytics',
    },
    cookiesUsedParagraph11: {
      defaultMessage:
        'You can decide to block the installation of cookie analytics at any time using the functionality on the site that hosts the Widget. For more information, please read the third party cookie analytics information carefully by following the links in the table above.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph11',
    },
    cookiesUsedParagraph12: {
      defaultMessage: '**Profiling cookies**',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph12',
    },
    cookiesUsedParagraph13: {
      defaultMessage:
        'Profiling cookies are used to create your own profile, based on your preferences and interests, as manifested while browsing the Internet, so that you can view personalized content. As required by current privacy legislations, your prior consent is required for the installation of such cookies. For this reason, when you access the site that hosts our Widget a special banner is displayed, informing you that (i) profiling cookies are used and that (ii) you can give your consent to the installation of cookies by clicking a designated button inside the banner itself.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph13',
    },
    cookiesUsedParagraph14: {
      defaultMessage:
        'You are free to block the installation of profiling cookies at any time using the functionality on the site that hosts the Widget.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph14',
    },
    cookiesUsedParagraph15: {
      defaultMessage:
        'All profiling cookies are installed by third parties, acting as autonomous data controllers (third-party cookies). Here is a list of the third party profiling cookies currently used by the Widget, showing the link to the information pages created by their developers:',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph15',
    },
    purposeGoogleAds1: {
      defaultMessage:
        'Use of cookies for making advertising campaigns more efficient and for displaying more relevant ads, based on their affinity with you, thanks to the record of Google partner websites that you have visited.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.purposeGoogleAds1',
    },
    purposeGoogleAds2: {
      defaultMessage:
        'Though these data, it is possible to monitor the performance of advertising campaigns and calculate conversion rates.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.purposeGoogleAds2',
    },
    persistenceGoogleAds: {
      defaultMessage: '540 days',
      id: 'vinhood.vinhood_experience.cookie_policy_page.persistenceGoogleAds',
    },
    purposeBrainLead: {
      defaultMessage:
        'Use of cookies to make you experience more engaging. A number of common cookie uses include content selection, based on what interests you, improving campaign performance reporting and the option of sending you customized content.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.purposeBrainLead',
    },
    persistenceBrainLead: {
      defaultMessage: '13 months',
      id: 'vinhood.vinhood_experience.cookie_policy_page.persistenceBrainLead',
    },
    purposeFacebook1: {
      defaultMessage:
        'Use of cookies for showing ads to people that could be interested in the products and for measuring advertising campaign performance.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.purposeFacebook1',
    },
    purposeFacebook2: {
      defaultMessage:
        'In addition, these data allow statistical data to be provided on people that use Facebook products, on people that interact with ads, advertiser websites and apps, and on companies that use Facebook products.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.purposeFacebook2',
    },
    persistenceFacebook: {
      defaultMessage: '90 days',
      id: 'vinhood.vinhood_experience.cookie_policy_page.persistenceFacebook',
    },
    cookiesUsedParagraph16: {
      defaultMessage:
        'The Platform also features "social buttons", i.e. plugins for interacting with social networks and that allow you to share your information and content with social networks, to identify users and store information about the use of the Platform on their profiles. You can review the information regarding social media data management, using the social buttons which will take you to the following links:',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedParagraph16',
    },
    cookiesUsedInstagram: {
      defaultMessage: '**Instagram** https://help.instagram.com/155833707900388',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedInstagram',
    },
    cookiesUsedLinkedin: {
      defaultMessage: '**Linkedin** https://www.linkedin.com/legal/cookie-policy',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedLinkedin',
    },
    cookiesUsedFacebook: {
      defaultMessage: '**Facebook** https://m.facebook.com/policies/cookies/',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedFacebook',
    },
    cookiesUsedYoutube: {
      defaultMessage: '**Youtube** https://policies.google.com/technologies/ ||cookies?hl=it',
      id: 'vinhood.vinhood_experience.cookie_policy_page.cookiesUsedYoutube',
    },
    amendmentsUpdatesTitle: {
      defaultMessage: '3. Amendments and Updates',
      id: 'vinhood.vinhood_experience.cookie_policy_page.amendmentsUpdatesTitle',
    },
    amendmentsUpdatesParagraph1: {
      defaultMessage:
        'This Cookie Policy is valid from the effective date. The Controller may amend and/or supplement this cookie Policy, also as a result of amended and/or supplemented legislation.',
      id: 'vinhood.vinhood_experience.cookie_policy_page.amendmentsUpdatesParagraph1',
    },
  }),
  privacyPolicyPage: defineMessages({
    mainTitle: {
      defaultMessage: 'Privacy policy',
      id: 'vinhood.vinhood_experience.privacy_policy_page.mainTitle',
    },
    mainDescription: {
      defaultMessage:
        "TASTE S.r.l., is an Italian company with registered office in Milan, Piazza della Repubblica 32, (hereinafter referred to as 'VINHOOD'), owner, inter alia, of a widget accessible through browsers and mobile devices, such as those available at VINHOOD kiosks (hereinafter referred to as 'VINHOOD Platform').",
      id: 'vinhood.vinhood_experience.privacy_policy_page.mainDescription',
    },

    privacyImportantTitle: {
      defaultMessage: 'YOUR PRIVACY IS OUR PRIORITY',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantTitle',
    },
    privacyImportantParagraph1: {
      defaultMessage:
        'We understand the trust you place in us when you use our services and entrust us with your data. Protecting your data is our top priority.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantParagraph1',
    },
    privacyImportantParagraph2: {
      defaultMessage:
        'We invite you to read our Privacy Policy, which applies when you access and browse the VINHOOD Platform and when you use our services. This policy helps you understand what data we collect, why we collect it, and how you can update, manage, export, and delete the information you provide to us.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantParagraph2',
    },
    privacyImportantParagraph3: {
      defaultMessage:
        'To give you a better understanding of our purpose and the way we process your data, let us first tell you who we are and what the purpose of VINHOOD, an innovative reality in the world of taste, is.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantParagraph3',
    },
    privacyImportantParagraph4: {
      defaultMessage:
        '"VINHOOD" helps you to have fun, to learn more and deepen your relationship with the world of taste. How do we do this? We help you to create your Taste passport, which can be useful whenever you interact with the world of taste. You will then be able to use your Taste profile whenever you need it both on websites and on VINHOOD\'s third party partner platforms..',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantParagraph4',
    },
    privacyImportantParagraph5: {
      defaultMessage:
        'As a user of VINHOOD you have the right to manage the processing of your data and to check the information we collect about you and how we use it to ensure that you enjoy our services in various ways.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantParagraph5',
    },
    privacyImportantParagraph6: {
      defaultMessage: 'For example you can:',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantParagraph6',
    },

    privacyImportantItem1: {
      defaultMessage:
        'You will be able to carry out different types of tests, based on algorithms developed with professionals, to recognize your taste profile on different product categories as a starting point for new experiences in the world of taste.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantItem1',
    },
    privacyImportantItem2: {
      defaultMessage:
        "We identify the #characteristics of your taste profile to create your 'taste passport' and suggest products and services in line with your profile.",
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantItem2',
    },
    privacyImportantItem3: {
      defaultMessage:
        'You can use different channels (such as widgets and devices on our kiosks) third party websites or in third party physical stores to develop and enrich your innovative and customized experience.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantItem3',
    },
    privacyImportantItem4: {
      defaultMessage:
        'We will provide you with exclusive content and you will be able to participate in customized events suggested by us based on your #taste profile, your knowledge the products, your behavior and your interests.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantItem4',
    },

    privacyImportantItem5: {
      defaultMessage:
        'Take our taste tests, discover your #character and register on the "VINHOOD Platform" by creating a profile associated with your #character and use our services. By creating a profile associated with your #character, we will be able to recognize you and recommend services and products in line with your #character, depending on your environment and experience (e.g. testing).',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantItem5',
    },
    privacyImportantItem6: {
      defaultMessage:
        'Run our tests through the VINHOOD widget on third party platforms and receive personalized recommendations on products and services without logging into your account and therefore anonymously.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyImportantItem6',
    },
    dataControllerTitle: {
      defaultMessage: 'DATA CONTROLLER',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataControllerTitle',
    },
    dataControllerParagraph1: {
      defaultMessage:
        "VINHOOD is the data controller of personal data **('Controller')** in the person of its legal representative pro tempore with registered office in Milan, Piazza della Repubblica 32.",
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataControllerParagraph1',
    },
    dataControllerParagraph2: {
      defaultMessage:
        'In carrying out its activity, VINHOOD, in its capacity as "Data Controller", processes personal data in compliance with the legal provisions in force, (Art. 13 of EU Regulation 2016/679, hereinafter referred to as the "GDPR") and the applicable Privacy legislation, in a manner based on the principles of correctness, lawfulness and transparency, protecting your confidentiality and your rights, according to the criteria set out in this document.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataControllerParagraph2',
    },
    dataProcessMainTitle: {
      defaultMessage: 'WHAT DATA WE PROCESS',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessMainTitle',
    },

    dataProcessItem1: {
      defaultMessage:
        '**Personal and contact details to create your taste profile, become part of the VINHOOD world and take advantage of our tools and services:** At the end of a test, we send you, if you request it in the appropriate section of the VINHOOD Platform, the result by email. This is the beginning of the creation of your taste profile. The data you provide us with to create this profile are: first name, last name, gender, date of birth and e-mail. You may also create your profile using social networks. Depending on the permissions granted to the social network you use, we may have access to personal data in addition to that required for registration on the VINHOOD platform.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessItem1',
    },
    dataProcessItem2: {
      defaultMessage:
        '**Data relating to your preferences (e.g. which foods you like to combine with a certain type of product; which fruit or vegetables you prefer, etc.):**',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessItem2',
    },
    dataProcessSubItem1: {
      defaultMessage:
        'When you perform a test on our VINHOOD Platform (taste, expert, or pairing with other products), and receive a #character that matches your taste profile, we collect your answers.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessSubItem1',
    },
    dataProcessSubItem2: {
      defaultMessage:
        'To get to know you better, we will occasionally ask you for some additional information as part of our initiatives. We may, for example, ask you if you are a beginner or an expert on a specific product, comments and feedback on our tests, products and events, if you are interested in other products.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessSubItem2',
    },
    dataProcessParagraph1: {
      defaultMessage: 'This data is only processed if you register on the Platform and create a VINHOOD profile.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessParagraph1',
    },
    dataProcessParagraph2: {
      defaultMessage: 'We may also process your data in aggregate and anonymous form.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessParagraph2',
    },
    dataCollectTitle: {
      defaultMessage: 'DATA WE AUTOMATICALLY COLLECT WHEN YOU USE OUR SERVICES',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataCollectTitle',
    },
    dataCollectParagraph1: {
      defaultMessage:
        '**We use so-called cookies to collect certain data.** To find out more, to learn about the different types of cookies and to manage your preferences, please read the relevant cookie policy https://www.vinhood.com/cookie-policy/.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataCollectParagraph1',
    },

    dataUseForTitle: {
      defaultMessage: 'WHAT WE USE YOUR DATA FOR AND HOW LONG WE KEEP THEM',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataUseForTitle',
    },
    dataUseForParagraph1: {
      defaultMessage: 'We collect your data for the following purposes:',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataUseForParagraph1',
    },
    dataUseForParagraph2: {
      defaultMessage:
        'In any case, we undertake to base our data processing on the principles of accuracy and data minimization (we only process the data that we really need to achieve the purposes of the processing that we have explained to you above), and to review annually the necessity of retaining the data for no longer than is necessary to achieve the purposes for which the data was collected and processed.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataUseForParagraph2',
    },
    dataUseForParagraph3: {
      defaultMessage:
        'We may retain your data in order to comply with regulatory obligations, or to establish, exercise or defend a legal claim against us.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataUseForParagraph3',
    },
    dataUseForParagraph4: {
      defaultMessage:
        'Once the purposes for which your personal data was collected and processed have been fulfilled, we will take appropriate steps to make your data anonymous so that it cannot be identified.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataUseForParagraph4',
    },
    dataUseForItem1: {
      defaultMessage:
        '**Improve your experience when using our services:** We use your data so that you can take advantage of our services offered through the VINHOOD Platform. Our services use permanent cookies (see https://www.vinhood.com/cookie-policy/) which allow us to store your #character when you test. This function is not available if you deactivate the storage of such cookies in your browser settings.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataUseForItem1',
    },
    dataUseForItem2: {
      defaultMessage:
        '**Creating your personal profile on our Platform:** We use your data so that you can create your personal profile via the Platform, our services or through the use of an existing social network account. The legal basis for the processing is Art. 6 para. 1, b) of the GDPR, the performance of a contract to which the data subject is party. The provision is necessary in order to process the request. The Data will be kept as long as the account is active. For technical reasons, the data may be kept for up to 30 days following the deletion of the account.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataUseForItem2',
    },
    dataUseForItem3: {
      defaultMessage:
        '**Sending you commercial/promotional communications and invitations to our events/workshops:** your personal data will be processed, where you have given your consent, to send you invitations and notifications of our events, to send you promotional communications of our activities (including new studies, surveys, etc.) to recommend products that are similar to your #character and to send you market research and statistical surveys. The legal basis for the processing is Art. 6, para. 1, a) of the GDPR, i.e. the consent. The provision is optional, in case you fail to provide it, the Controller will not be able to regularly update you on its news and events. You may withdraw your consent at any time by clicking on the "unsubscribe" link included in the marketing e-mail you receive or by writing to the following e-mail address privacy@vinhood.com. The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal. The data shall be retained as long as the data subject is subscribed to the newsletter service, unless the consent is withdrawn. The Controller will regularly check your interest in receiving the newsletter and will proceed to delete your data both in the event of withdrawal of consent and in the event of non-interaction for an extended period of not less than 24 months.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataUseForItem3',
    },
    dataUseForItem4: {
      defaultMessage:
        '**Create your personalized profile based on your #character and/or business category (e.g. manufacturers or retailers) and the preferences you show when interacting with us in order to suggest products in line with your preferences, including by means of personalized email communications:** Profiling activity may also be carried out using proprietary and third-party cookies (Cookie Policy linkable). The legal basis for the processing is Art. 6, para. 1, a) of the GDPR, i.e. the consent. The provision is optional, in case of failure to provide it, the Controller will not be able to suggest products in line with the preferences shown and update you with personalized commercial communications. You may withdraw the consent given at any time by clicking on the "unsubscribe" link included in the marketing e-mail received or by writing to the following e-mail address privacy@vinhood.com. The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal. The data will be retained as long as the data subject is subscribed to the VINHOOD service, unless consent is withdrawn.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataUseForItem4',
    },
    dataUseForItem5: {
      defaultMessage:
        '**Communication of data to third party partners:** We may communicate the data you provide with third parties, private individuals or companies, who collaborate with VINHOOD from time to time identified, belonging to the following categories GDO (large-scale retail trade); wine cellars; producers of alcoholic beverages; coffee producers for commercial and marketing purposes only if you have given your explicit consent to the transfer. The Partner or category of partners to whom the data will be disclosed will be identified on a case-by-case basis prior to the request for consent. The legal basis for such processing is Article 6(1)(a) of the GDPR, i.e. the consent. The provision is optional; in the event of failure to provide Personal Data, the Data Controller will not be able to regularly update the data subject on news and events to third-party partners. The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataUseForItem5',
    },

    dataProcessingMethodTitle: {
      defaultMessage: 'HOW YOUR DATA IS PROCESSED',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessingMethodTitle',
    },
    dataProcessingMethodParagraph1: {
      defaultMessage:
        'The processing of your Data will be carried out both on paper and by digital means, with the aid of computer systems, and manually, solely by authorized persons expressly appointed for this purpose. The processing will take place using logics and forms of organization of the data strictly related to the obligations, tasks or purposes that we have explained to you in detail in the preceding points.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessingMethodParagraph1',
    },
    dataProcessingMethodParagraph2: {
      defaultMessage:
        'We use precise technical and organizational measures to protect your data against possible manipulation, loss, destruction and against access by unauthorized persons. These security measures are constantly reviewed and improved in line with technological developments.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataProcessingMethodParagraph2',
    },

    dataContextShareTitle: {
      defaultMessage: 'WITH WHOM AND IN WHAT AREAS WE SHARE YOUR DATA',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataContextShareTitle',
    },
    dataContextShareParagraph1: {
      defaultMessage:
        'Personal data will be processed by employees of the Data Controller, who are duly authorised to do so, for all the above purposes, on the basis of instructions and after taking appropriate measures to protect Personal Data.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataContextShareParagraph1',
    },
    dataContextShareParagraph2: {
      defaultMessage:
        'The following parties may become aware of personal data in relation to the processing purposes set out in this privacy policy and may process personal data both as autonomous data controllers and as data processors duly appointed by the Data Controller:',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataContextShareParagraph2',
    },
    dataContextShareParagraph3: {
      defaultMessage:
        "The updated list of all Data Processors is available at VINHOOD's office and can be requested at the following e-mail address: privacy@vinhood.com. This list may subsequently be supplemented and/or updated from time to time.",
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataContextShareParagraph3',
    },
    dataContextShareParagraph4: {
      defaultMessage:
        'In addition, we may disclose to third party partners anonymous and aggregated data relating, for example, to the age and/or gender of users of the Platform. This information will not include any personal data.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataContextShareParagraph4',
    },
    dataContextShareForItem1: {
      defaultMessage: 'Parties carrying out activities functional to the achievement of the above purposes, including:',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataContextShareForItem1',
    },
    dataContextShareForItem2: {
      defaultMessage: 'companies that provide data analysis and development services,',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataContextShareForItem2',
    },
    dataContextShareForItem3: {
      defaultMessage:
        'IT service providers for the management of contact and e-mail databases, digital service providers and IT consultants;',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataContextShareForItem3',
    },
    dataContextShareForItem4: {
      defaultMessage: 'accountants, legal advisers;',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataContextShareForItem4',
    },
    dataContextShareForItem5: {
      defaultMessage: 'Judicial and Public Security Authorities.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.dataContextShareForItem5',
    },
    automatedProcessTitle: {
      defaultMessage: 'WE USE AUTOMATED DECISION-MAKING PROCESSES',
      id: 'vinhood.vinhood_experience.privacy_policy_page.automatedProcessTitle',
    },
    automatedProcessParagraph1: {
      defaultMessage:
        'As mentioned above, we define a low-level profiling of our users to provide you with a personalized service.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.automatedProcessParagraph1',
    },
    automatedProcessParagraph2: {
      defaultMessage:
        'The profiling is based on parameters such as #taste characteristics, product knowledge, the actions you take on our platform, your business category.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.automatedProcessParagraph2',
    },
    automatedProcessParagraph3: {
      defaultMessage:
        'Taste profiling is done through our algorithms that use predictive capabilities to identify, for example, your #taste character with regard to a certain product category (e.g. wine, coffee, beer, bitter, ...) and be able to give you recommendations in line with your preferences on a given product.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.automatedProcessParagraph3',
    },
    automatedProcessParagraph4: {
      defaultMessage:
        "As a result of these analyses, you will be able to take advantage of the Platform's services personalized for you and also receive personalized communications from us that we consider to be in line with your tastes and preferences (#character).",
      id: 'vinhood.vinhood_experience.privacy_policy_page.automatedProcessParagraph4',
    },
    automatedProcessParagraph5: {
      defaultMessage:
        'We have positively conducted a balancing test in order to determine that the processing related to your categorization is conducted on the legal basis of the legitimate interest pursued by the Controller to achieve maximum efficiency in the creation of a unique "taste passport" and marketing activities.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.automatedProcessParagraph5',
    },
    automatedProcessParagraph6: {
      defaultMessage:
        'Our test and the resulting profiling have no legal effect and does not affect you in the same way.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.automatedProcessParagraph6',
    },
    transferDataTitle: {
      defaultMessage: 'TRANSFER OF PERSONAL DATA TO THIRD COUNTRIES',
      id: 'vinhood.vinhood_experience.privacy_policy_page.transferDataTitle',
    },
    transferDataParagraph1: {
      defaultMessage:
        'The entire processing of your data takes place in Italy and in countries within the European Union. Should there be a need to transfer data to Third Countries, we undertake to:',
      id: 'vinhood.vinhood_experience.privacy_policy_page.transferDataParagraph1',
    },
    transferDataItem1: {
      defaultMessage:
        'Ensure that the country to which the Data provided by you will be sent guarantees an adequate level of protection, as required by Article 45 of the GDPR;',
      id: 'vinhood.vinhood_experience.privacy_policy_page.transferDataItem1',
    },
    transferDataItem2: {
      defaultMessage:
        'Check and carry out the transfer only if we or the data controller provide adequate safeguards providing you with enforceable rights and effective remedies (pursuant to Article 46 of the GDPR).',
      id: 'vinhood.vinhood_experience.privacy_policy_page.transferDataItem2',
    },
    yourRightsTitle: {
      defaultMessage: 'YOUR RIGHTS: YOU CAN KNOW AND MANAGE YOUR DATA AT ANY TIME',
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsTitle',
    },
    yourRightsParagraph1: {
      defaultMessage:
        'You may at any time exercise the following rights under the conditions and within the limits provided for in Articles 12-22 of the GDPR by sending an email to privacy@vinhood.com:',
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsParagraph1',
    },
    yourRightsItem1: {
      defaultMessage:
        '**Right of access:** the data subject has the right to obtain from the data controller confirmation as to whether or not personal data concerning him or her is being processed and, if so, to obtain access to the personal data (Article 15 GDPR)',
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsItem1',
    },
    yourRightsItem2: {
      defaultMessage:
        '**Right to rectification** of inaccurate personal data and to obtain supplementation of incomplete personal data (Article 16 GDPR);',
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsItem2',
    },
    yourRightsItem3: {
      defaultMessage:
        '**Right to erasure of personal data:** the data subject may request that his or her data be erased if it is no longer necessary for the purposes mentioned above, in case of withdrawal of consent or his or her objection to the processing, in case of unlawful processing, or if there is a legal obligation to erase (Article 17 GDPR);',
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsItem3',
    },
    yourRightsItem4: {
      defaultMessage:
        "**Right to restriction of processing:** The data subject has the right to obtain the restriction of processing when one of the following cases occurs: 1. The data subject disputes the accuracy of the personal data, for the period necessary for the Controller to verify the accuracy of such personal data; 2. The processing is unlawful, and the data subject opposes the erasure of the personal data and instead requests that its use be restricted, although the Controller no longer needs the personal data for the purposes of the processing; 3. The personal data are necessary for the data subject to establish, exercise, or defend a right in court; 4. The data subject has objected to the processing, pending verification as to whether the Controller's legitimate reasons prevail over those of the data subject (Article 18 GDPR).",
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsItem4',
    },

    yourRightsItem5: {
      defaultMessage:
        '**Right to object to the processing:** the data subject may object to the processing of his or her data at any time, unless the data Controller demonstrates the existence of compelling legitimate grounds for processing which override the interests, rights and freedoms of the data subject or for the establishment, exercise or defense of legal claims, pursuant to Article 6(1)(e) or (f) of the GDPR, including profiling (Article 21 GDPR);',
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsItem5',
    },
    yourRightsItem6: {
      defaultMessage:
        '**Right to portability:** the data subject has the right to receive in a structured, commonly used and machine-readable format personal data concerning him or her provided to a data controller and has the right to transmit such data to another data controller without hindrance from the data controller to whom he or she has provided it where: the processing is based on consent, or on a contract (Article 20 GDPR);',
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsItem6',
    },
    yourRightsItem7: {
      defaultMessage: '**Right to lodge a complaint** with the supervisory authority (Article 77 GDPR).',
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsItem7',
    },
    yourRightsParagraph2: {
      defaultMessage:
        'In the event that the data subject considers that the processing of personal data carried out by the Controller is in breach of the provisions of Regulation (EU) 2016/679, the data subject has the right to lodge a complaint with the Supervisory Authority, in particular in the Member State where he or she normally resides or works, or in the place where the alleged breach of the Regulation occurred (in Italy, the Garante per la Protezione dei Dati Personali https://www.garanteprivacy.it/ ), or to take the matter to the appropriate judicial instances.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.yourRightsParagraph2',
    },
    exerciseRightsTitle: {
      defaultMessage: 'HOW YOU CAN EXERCISE YOUR RIGHTS',
      id: 'vinhood.vinhood_experience.privacy_policy_page.exerciseRightsTitle',
    },
    exerciseRightsParagraph1: {
      defaultMessage:
        "To exercise the aforementioned rights, you may send a communication to the e-mail address: privacy@vinhood.com, indicating 'Privacy - exercise of rights' in the subject line.",
      id: 'vinhood.vinhood_experience.privacy_policy_page.exerciseRightsParagraph1',
    },

    privacyPolicyParagraph1: {
      defaultMessage:
        'This Privacy Policy was published on September 2024. Any updates will always be published on this page.',
      id: 'vinhood.vinhood_experience.privacy_policy_page.privacyPolicyParagraph1',
    },
  }),
  termsConditionsPage: defineMessages({
    mainTitle: {
      defaultMessage: 'Terms and Conditions',
      id: 'vinhood.vinhood_experience.terms_conditions_page.mainTitle',
    },
    mainDescription: {
      defaultMessage:
        'These standard terms and conditions (“Standard Terms and Conditions”) govern access to and use of the Vinhood website, www.vinhood.com, including the App and the widget (“Platform”), supplied by TASTE s.r.l., with registered office in Milan, Piazza della Repubblica 32, tax code IT09388380967 ("VINHOOD") via the Platform.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.mainDescription',
    },
    conditionTitle1: {
      defaultMessage: 'GENERAL INFORMATION AND SCOPE OF APPLICATION',
      id: 'vinhood.vinhood_experience.terms_conditions_page.conditionTitle1',
    },
    condition1Paragraph1: {
      defaultMessage: 'These Standard Terms and conditions apply to all services offered by VINHOOD via the Platform.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition1Paragraph1',
    },
    condition1Paragraph2: {
      defaultMessage:
        'The Standard Terms and Conditions may be changed without notice. Any amendments and/or new terms and conditions will be in force from the time they are published on the Platform. For this purpose, we invite all users to regularly access the Platform and check the most recent Standard Terms and Conditions.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition1Paragraph2',
    },
    condition1Paragraph3: {
      defaultMessage: 'Applicable Standard Terms and Conditions are those in force at the time the service is used.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition1Paragraph3',
    },
    platformDescription: {
      defaultMessage:
        '“VINHOOD” is a Platform that helps you have fun and learn about and deepen your relationship with the Taste World. How do we do this? We make a range of tools and customized experiences available to you on our Platform, such as tests, games and other content (“Services”) which, based on a number of features, such as, type of product, the #character of your Taste profile, behavior, your degree of product knowledge and your interests are used to help you explore and interact with the Taste world in an enjoyable, knowledgeable and customized way and, above all, within everybody’s reach. We help you create your Taste profile passport that you will find useful each time you interact with the Taste world. Therefore, you may use your Taste profile each time you need to, both on websites and on third-party VINHOOD partner platforms.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.platformDescription',
    },
    condition1Paragraph4: {
      defaultMessage:
        'Whenever the user does not intend to accept the changes to the Standard Terms and Conditions, he/she is entitled to withdraw, giving notice by sending a recorded mail letter with return receipt or e-mail providing 14 days advance notice.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition1Paragraph4',
    },
    platformDescriptionParagraph1: {
      defaultMessage:
        'You may take different types of tests, based on scientific algorithms, for identifying your Taste profile on various product categories, as a starting point leading to new experiences we offer you in the Taste world.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.platformDescriptionParagraph1',
    },
    condition1Paragraph5: {
      defaultMessage:
        'Continued use of the service after the term indicated in the previous paragraph has expired will be considered as an expression of the wish to accept the Standard Terms and Conditions.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition1Paragraph5',
    },
    platformDescriptionParagraph2: {
      defaultMessage:
        'We identify the #characters of your Taste profile to create your “taste passport” and to provide you with suggestions and services matching your profile.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.platformDescriptionParagraph2',
    },
    condition1Paragraph6: {
      defaultMessage:
        'These Standard Terms and Conditions do not govern the sale of goods and/or the provision of services by parties other than VINHOOD that appear on the Platform via links, banners or any other hypertext link. Before entering into commercial transactions with said parties, the user is strongly advised to check their terms and conditions of sale. VINHOOD is not liable in any way whatsoever for the provision of services and/or sale of goods by such parties. VINHOOD does not check and/or monitor websites which can be consulted via said links. Therefore, VINHOOD is not liable for the content of said websites or for any errors and/or omissions and/or breaches of laws by such websites.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition1Paragraph6',
    },
    platformDescriptionParagraph3: {
      defaultMessage:
        'You may use various channels (apps, websites, widgets and kiosks, etc.) to enrich your innovative and customized experience.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.platformDescriptionParagraph3',
    },
    condition2Paragraph1: {
      defaultMessage:
        'You can decide to create a personal profile for registering your #character and creating your taste portfolio. In this case, you need to register on the Platform, by creating a VINHOOD account. Failure to accept the Standard Terms and Conditions will mean that it is not possible to register on the Platform.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition2Paragraph1',
    },
    platformDescriptionParagraph4: {
      defaultMessage:
        'We will provide you with exclusive content and you may take part in customized events that we will suggest to you based on the #character of your Taste profile, the level of knowledge you have about products, and your behavior and interests.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.platformDescriptionParagraph4',
    },
    condition2Paragraph2: {
      defaultMessage:
        'Registering on the Platform is free. To register, the user must complete the registration form, entering name, last name, e-mail address and a password (“Registration Credentials”). Registration is confirmed via an e-mail sent to the e-mil address provided by the user. You can also create a profile by using several social networks (Facebook, Instagram, etc.) or your Google Account.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition2Paragraph2',
    },
    platformDescriptionParagraph5: {
      defaultMessage:
        'You may suggest content to us linked to the Taste world and tell us what you think about our products or our services.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.platformDescriptionParagraph5',
    },
    condition2Paragraph3: {
      defaultMessage:
        'Registration Credentials must be used exclusively by the user and they may not be transferred to others. The user is required to promptly notify VINHOOD if undue use of Registration Credentials is suspected. Registration Credentials may be changed by the user at any time, by accessing the Platform and the user profile section.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition2Paragraph3',
    },
    platformDescriptionPrint: {
      defaultMessage: 'Please print a copy of the Standard Terms and Conditions and/or store them on a durable medium.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.platformDescriptionPrint',
    },
    condition2Paragraph4: {
      defaultMessage:
        'The user warrants that the Registration Credentials provided during the registration procedure on the Platform are complete, correct and true. The user accepts to indemnify and hold VINHOOD harmless from any and all claims for damages or fines arising out of and/or in any way in connection with a breach by the user of the Platform registration rules. The user is solely responsible for access to the Platform using the Registration Credentials and responds directly for any and all loss or harm caused to VINHOOD or to third parties due to improper use, loss and misappropriation by others or failure to adequately protect the non-disclosure of Registration Credentials. All transactions made using the Registration Credentials are considered made by the user to whose registration Credentials they refer.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition2Paragraph4',
    },
    condition2Paragraph5: {
      defaultMessage: 'Services are delivered in Italian and English.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition2Paragraph5',
    },
    condition2Paragraph6: {
      defaultMessage: 'Only one registration is permitted per user. Multiple registrations will be deleted by VINHOOD.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition2Paragraph6',
    },
    condition2Paragraph7: {
      defaultMessage:
        'VINHOOD reserves the right, at its discretion, to refuse the registration of any user. Furthermore, VINHOOD reserves the right to block the account of a user that acts in breach of the Standard Terms and Conditions or applicable law.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition2Paragraph7',
    },
    condition2Paragraph8: {
      defaultMessage:
        'The user may at any time delete his/her VINHOOD registration by sending an e-mail to the address: info@VINHOOD.com.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition2Paragraph8',
    },
    condition3Paragraph1: {
      defaultMessage:
        'VINHOOD is entitled to temporarily suspend the service, without any prior notice and delivery of the Platform and the services for the time strictly required for technical work necessary and/or opportune for improving the quality of such services.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition3Paragraph1',
    },
    condition3Paragraph2: {
      defaultMessage:
        'VINHOOD may, at any time, interrupt the provision of service, for justified security reasons or breaches of data protection, notifying the user accordingly.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition3Paragraph2',
    },
    conditionTitle2: {
      defaultMessage: 'REGISTRATION',
      id: 'vinhood.vinhood_experience.terms_conditions_page.conditionTitle2',
    },
    condition4Paragraph1: {
      defaultMessage:
        'The Platform content, including, but not limited to, the #characters, the tests, the works, the audio tracks and videos, the images, the photographs, the music, the documents, the drawings, the figures, the logos and any and all other material, in whatever form, published on the Platform, including the menus, the web pages, the graphics, the colors, the formats, the tools, the characters and the Platform web design, the diagrams, the layout, the methods, the processes, the features and the software are protected by copyright and by any and all other VINHOOD and other holder intellectual property rights Reproducing, changing, duplicating, copying, distributing, selling and, however, using the images and any other content on the Platform, if not authorized in advance in writing by VINHOOD, is prohibited. Likewise, any and all use of the Platform content for commercial and/or advertising purposes is prohibited.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition4Paragraph1',
    },
    condition4Paragraph2: {
      defaultMessage:
        'All the distinctive signs that mark the products/services published on the Platform are the registered trademarks of their respective owners and they are used by VINHOOD for the sole purpose of marking, describing and advertising the products/services published/on sale on the Platform.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition4Paragraph2',
    },
    condition4Paragraph3: {
      defaultMessage:
        'Any use whatsoever of the aforesaid distinctive signs that is not compliant with law and, as such, is not authorized, is prohibited. It is not permitted in any way whatsoever to use any distinctive sign appearing on the Platform for exploiting the distinctive character for profit or unlawful gain, or reputation of such signs, o, in any way causing harm to such signs and their owners.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition4Paragraph3',
    },
    condition4Paragraph4: {
      defaultMessage:
        'In no case may the user manipulate, change, modify or adapt the Platform or the material made available by VINHOOD. The Platform is offered as is. Commercial use of the software, the Platform and/or any other content is strictly prohibited. The User may not bypass the technical limitations in the software; translate, decode, decompile or unbundle the software, nor attempt to trace the Software source code or create derivative works based on the Software and publish the software for allowing its duplication by others, rent, sub-license or lend the Software.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition4Paragraph4',
    },
    condition5Paragraph1: {
      defaultMessage:
        'The Platform is provided as is with no warranty provided as to the result. VINHOOD is in no way liable for any interruptions in the provision of the Platform and/or service due to causes not directly attributable to it and/or that are outside its technical control, such as data transmission or telephone network outages.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition5Paragraph1',
    },
    condition5Paragraph2: {
      defaultMessage:
        'VINHOOD is entitled to change, suspend and/or interrupt delivery of the Platform, at any time, in whole or in part, at its sole discretion, also definitively, without prior notice and without such action giving rise to any claim whatsoever for damages and/or reimbursement claimed from VINHOOD.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition5Paragraph2',
    },
    condition5Paragraph3: {
      defaultMessage:
        'VINHOOD will not recognize any warranty other than that granted under these Standard Terms and Conditions: it is understood that the user uses the Platform under his/her full and sole liability and that use of the Platform is required to comply with all instructions given by VINHOOD.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition5Paragraph3',
    },
    condition5Paragraph4: {
      defaultMessage:
        'The User is authorized to use the Platform for personal use only and not collectively or for profit, and he/she accepts full liability for any and all non-authorized use.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition5Paragraph4',
    },
    conditionTitle3: {
      defaultMessage: 'SERVICE SUSPENSION',
      id: 'vinhood.vinhood_experience.terms_conditions_page.conditionTitle3',
    },
    condition5Paragraph5: {
      defaultMessage:
        'VINHOOD provides no warranty as to the accuracy and/or actuality of the Platform content. VINHOOD may not be considered liable for any loss to Users that may be caused to Users and their use of information and the content included on the Platform.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition5Paragraph5',
    },
    conditionTitle4: {
      defaultMessage: 'PLATFORM CONTENT AND INTELLECTUAL PROPERTY RIGHTS',
      id: 'vinhood.vinhood_experience.terms_conditions_page.conditionTitle4',
    },
    condition5Paragraph6: {
      defaultMessage:
        'VINHOOD accepts no liability for any abuse committed by Users, or in cases where they may be a victim of said abuse, as a result of third-party conduct, whether put in place or not in breach of the Standard Terms and Conditions.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition5Paragraph6',
    },
    condition6Paragraph1: {
      defaultMessage: 'The Standard Terms and Conditions are governed by Italian law.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition6Paragraph1',
    },
    condition6Paragraph2: {
      defaultMessage:
        'Any dispute that may arise in relation to the Standard Terms and Conditions will be referred for adjudication to the Court and venue indicated by the User/consumer as his/her residence or domicile, if located within the territory of the Italian State.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition6Paragraph2',
    },
    condition6Paragraph3: {
      defaultMessage: 'Whenever users are non-consumers, the Courts of Milan will have sole jurisdiction.',
      id: 'vinhood.vinhood_experience.terms_conditions_page.condition6Paragraph3',
    },
    conditionTitle5: {
      defaultMessage: 'EXCLUSION OF LIABILITY',
      id: 'vinhood.vinhood_experience.terms_conditions_page.conditionTitle5',
    },
    conditionTitle6: {
      defaultMessage: 'FINAL PROVISIONS, APPLICABLE LAW AND VENUE WITH JURISDICTION',
      id: 'vinhood.vinhood_experience.terms_conditions_page.conditionTitle6',
    },
  }),
  expertQuizPage: defineMessages({
    title: {
      defaultMessage: 'Do you want to use the Taste Test to discover the perfect product for you?',
      id: 'vh.vinhood_experience.expert_quiz_page.title',
    },
    youGuideMe: {
      defaultMessage: `Absolutely, let's get started!`,
      id: 'vh.vinhood_experience.expert_quiz_page.youGuideMe',
    },
    answerYes: {
      defaultMessage: `No, come on: I'll tell you what I like!`,
      id: 'vh.vinhood_experience.expert_quiz_page.answerYes',
    },
  }),
  registerPage: defineMessages({
    title: {
      defaultMessage: 'High five: ||gift unlocked!',
      id: 'vh.vinhood_experience.registerPage.title',
    },
    subTitle: {
      defaultMessage: `Receive immediately **a personalized report** based on your taste by sharing your email with us. ||But the surprises aren't over yet...`,
      id: 'vh.vinhood_experience.registerPage.subTitle',
    },
    socialConnection: {
      defaultMessage: 'Connect via one of your social media accounts',
      id: 'vh.vinhood_experience.registerPage.socialConnection',
    },
    fillForm: {
      defaultMessage: 'Or fill out the form below',
      id: 'vh.vinhood_experience.registerPage.fillForm',
    },
    nameField: {
      defaultMessage: 'Name',
      id: 'vinhood.vinhood_experience.registerPage.nameField',
    },
    nameFieldPlaceholder: {
      defaultMessage: 'Enter your name',
      id: 'vinhood.vinhood_experience.registerPage.nameFieldPlaceholder',
    },
    emailField: {
      defaultMessage: 'Email',
      id: 'vinhood.vinhood_experience.registerPage.emailField',
    },
    emailFieldPlaceholder: {
      defaultMessage: 'Enter your email',
      id: 'vinhood.vinhood_experience.registerPage.emailFieldPlaceholder',
    },
    passwordField: {
      defaultMessage: 'Password',
      id: 'vinhood.vinhood_experience.registerPage.passwordField',
    },
    passwordFieldPlaceholder: {
      defaultMessage: 'Create a password',
      id: 'vinhood.vinhood_experience.registerPage.passwordFieldPlaceholder',
    },
    submitBtn: {
      defaultMessage: 'Submit',
      id: 'vinhood.vinhood_experience.registerPage.submitBtn',
    },
    emailValidation: {
      defaultMessage: 'Email is required',
      id: 'vinhood.vinhood_experience.registerPage.emailValidation',
    },
    validEmailValidation: {
      defaultMessage: 'You cannot use this email to register',
      id: 'vinhood.vinhood_experience.registerPage.validEmailValidation',
    },
    nameValidation: {
      defaultMessage: 'First Name should be at least two characters.',
      id: 'vinhood.vinhood_experience.registerPage.nameValidation',
    },
    passwordValidation: {
      defaultMessage: 'Between 8 and 30 characters, at least one uppercase, one lowercase letters and one number.',
      id: 'vinhood.vinhood_experience.registerPage.passwordValidation',
    },
    passwordTooShortValidation: {
      defaultMessage: 'Must be at least 8 characters.',
      id: 'vinhood.vinhood_experience.registerPage.passwordTooShortValidation',
    },
    passwordTooLongValidation: {
      defaultMessage: 'The password must not exceed 30 characters.',
      id: 'vinhood.vinhood_experience.registerPage.passwordTooLongValidation',
    },
    passwordUpperLowerNumberValidation: {
      defaultMessage: 'Passwords should contain at least one capital letter, one lowercase letter and one number',
      id: 'vinhood.vinhood_experience.registerPage.passwordUpperLowerNumber',
    },
    promotionalMessage: {
      defaultMessage: `You'll receive promotional messages tailored for you! Magic? More or less. Our data management has something to do with it: read our Privacy Policy and Legal.`,
      id: 'vinhood.vinhood_experience.registerPage.promotionalMessage',
    },
    loginRedirection: {
      defaultMessage: `Already registered?[linkTo=login-page]Enable your Vinhood taste profile.[linkTo=login-page]`,
      id: 'vinhood.vinhood_experience.registerPage.loginRedirection',
    },
    catalogRedirection: {
      defaultMessage: `Perhaps next time?[linkTo=catalog-page]Skip and go to products.[linkTo=catalog-page]`,
      id: 'vinhood.vinhood_experience.registerPage.catalogRedirection',
    },
  }),
  tabs: defineMessages({
    yourTastePathTab: {
      defaultMessage: 'Your taste path',
      id: 'vinhood.vinhood_experience.tabs.yourTastePathTab',
    },
    allProductsTab: {
      defaultMessage: 'All products',
      id: 'vinhood.vinhood_experience.tabs.allProductsTab',
    },
  }),
  tastePathPage: defineMessages({
    title: {
      defaultMessage: 'Your taste path',
      id: 'vinhood.vinhood_experience.tastePathPage.title',
    },
    descriptionCT0001wine1: {
      defaultMessage: 'The bubble that drives you wild.',
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0001wine1',
    },
    descriptionCT0001wine2: {
      defaultMessage: 'The bubble that teases you.',
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0001wine2',
    },
    descriptionCT0001wine3: {
      defaultMessage: `The one that's never enough.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0001wine3',
    },
    descriptionCT0003wine1: {
      defaultMessage: 'The white that seduces you.',
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0003wine1',
    },
    descriptionCT0003wine2: {
      defaultMessage: `The one you'd never say no to.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0003wine2',
    },
    descriptionCT0003wine3: {
      defaultMessage: `The white that intrigues you.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0003wine3',
    },
    descriptionCT0005wine1: {
      defaultMessage: 'Something that enchants you.',
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0005wine1',
    },
    descriptionCT0005wine2: {
      defaultMessage: `The one that whisks you away from routine.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0005wine2',
    },
    descriptionCT0005wine3: {
      defaultMessage: `The one that tempts you.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0005wine3',
    },
    descriptionCT0002wine1: {
      defaultMessage: 'The red that makes you fall in love.',
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0002wine1',
    },
    descriptionCT0002wine2: {
      defaultMessage: `The one that's always the right answer.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0002wine2',
    },
    descriptionCT0002wine3: {
      defaultMessage: `The red that captivates you.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0002wine3',
    },
    descriptionCT0004wine1: {
      defaultMessage: 'The sweet that makes you dream.',
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0004wine1',
    },
    descriptionCT0004wine2: {
      defaultMessage: `The one that pampers you.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0004wine2',
    },
    descriptionCT0004wine3: {
      defaultMessage: `The sweet that enchants you.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0004wine3',
    },
    descriptionCT0010beer1: {
      defaultMessage: `A stroll on the clouds.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0010beer1',
    },
    descriptionCT0010beer2: {
      defaultMessage: `A carefree breeze.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0010beer2',
    },
    descriptionCT0010beer3: {
      defaultMessage: `Dancing effervescence.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0010beer3',
    },
    descriptionCT0002beer1: {
      defaultMessage: `A biting hop.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0002beer1',
    },
    descriptionCT0002beer2: {
      defaultMessage: `A dizzying tale.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0002beer2',
    },
    descriptionCT0002beer3: {
      defaultMessage: `An enveloping bitter temptation.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0002beer3',
    },
    descriptionCT0011beer1: {
      defaultMessage: `A caress of toasted malt.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0011beer1',
    },
    descriptionCT0011beer2: {
      defaultMessage: `A hug of toasted malt.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0011beer2',
    },
    descriptionCT0011beer3: {
      defaultMessage: `A story of fire and passion.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0011beer3',
    },
    descriptionCT0007beer1: {
      defaultMessage: `An explosion of flavors.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0007beer1',
    },
    descriptionCT0007beer2: {
      defaultMessage: `A bold symphony.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0007beer2',
    },
    descriptionCT0007beer3: {
      defaultMessage: `A whirlwind of intensity.`,
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionCT0007beer3',
    },
    btnText: {
      defaultMessage: 'Skip to ',
      id: 'vinhood.vinhood_experience.tastePathPage.btnText',
    },
    alreadyTasted: {
      defaultMessage: 'Already tasted',
      id: 'vinhood.vinhood_experience.tastePathPage.alreadyTasted',
    },
    titleEnd: {
      defaultMessage: 'Made it, mate!',
      id: 'vinhood.vinhood_experience.tastePathPage.titleEnd',
    },
    descriptionEnd: {
      defaultMessage:
        'Your guts and curiosity just wrapped up this delicious journey. ||Maybe your thirst played a role too. ||Catch you on the flip side!',
      id: 'vinhood.vinhood_experience.tastePathPage.descriptionEnd',
    },
    tooltipModalTitle: {
      defaultMessage: 'Taste it, enjoy it, and rate it!',
      id: 'vinhood.vinhood_experience.tastePathPage.tooltipModalTitle',
    },
    tooltipModalSubTitle: {
      defaultMessage: `To unlock more, play with the "rate it" emoji!`,
      id: 'vinhood.vinhood_experience.tastePathPage.tooltipModalSubTitle',
    },
  }),
  tastingPathFeedbackLoadingPage: defineMessages({
    titleText1: {
      defaultMessage: 'Hey there, Indiana Jones! Ready to embark on a new leg of your journey?',
      id: 'vinhood.vinhood_experience.tastingPathFeedbackLoadingPage.titleText1',
    },
    titleText2: {
      defaultMessage: `You're about to become a Flavor Adventurer...`,
      id: 'vinhood.vinhood_experience.tastingPathFeedbackLoadingPage.titleText2',
    },
    titleText3: {
      defaultMessage: `Culinary Explorer Transformation in progress...`,
      id: 'vinhood.vinhood_experience.tastingPathFeedbackLoadingPage.titleText3',
    },
    titleText4: {
      defaultMessage: `Just one step away from becoming the Flavor Conqueror...`,
      id: 'vinhood.vinhood_experience.tastingPathFeedbackLoadingPage.titleText4',
    },
    titleText5: {
      defaultMessage: `Gastronomic Guru Wannabe`,
      id: 'vinhood.vinhood_experience.tastingPathFeedbackLoadingPage.titleText5',
    },
    titleText6: {
      defaultMessage: `Evolving into a Delicacy Hunter`,
      id: 'vinhood.vinhood_experience.tastingPathFeedbackLoadingPage.titleText6',
    },
    titleText7: {
      defaultMessage: `New level unlocked: Divine Taster`,
      id: 'vinhood.vinhood_experience.tastingPathFeedbackLoadingPage.titleText7',
    },
    titleText8: {
      defaultMessage: `You: the Wise Gourmet`,
      id: 'vinhood.vinhood_experience.tastingPathFeedbackLoadingPage.titleText8',
    },
  }),
  toastMessage: defineMessages({
    titleText: {
      defaultMessage: 'Taste it, savour it, and rate it!',
      id: 'vinhood.vinhood_experience.toastMessage.titleText',
    },
    bodyText1: {
      defaultMessage: 'To unlock more, choose the emoji that best matches your mood.',
      id: 'vinhood.vinhood_experience.toastMessage.bodyText1',
    },
    bodyText2: {
      defaultMessage: 'Follow your path and click on "?" to get instructions',
      id: 'vinhood.vinhood_experience.toastMessage.bodyText2',
    },
  }),
  noAvailableProductsCatalog: defineMessages({
    titleText: {
      defaultMessage: 'Whoops...No products now, but joy coming soon!',
      id: 'vinhood.vinhood_experience.noAvailableProductsCatalog.titleText',
    },
  }),
  explorePage: defineMessages({
    titleText: {
      defaultMessage:
        'This {productCategory, select, wine {{ productCategoryText }} beer {{ productCategoryText }} coffee {{ productCategoryText }} other {}} might tickle ||your taste buds',
      id: 'vinhood.vinhood_experience.explorePage.titleText',
    },
    experiencesText: {
      defaultMessage: 'Experiences',
      id: 'vinhood.vinhood_experience.explorePage.experiencesText',
    },
    placeCardSubtitle: {
      defaultMessage: 'You are here!',
      id: 'vinhood.vinhood_experience.explorePage.placeCardSubtitle',
    },
    listTitle: {
      defaultMessage: 'Prepare for your next',
      id: 'vinhood.vinhood_experience.explorePage.listTitle',
    },
    listSubtitle: {
      defaultMessage: ' { productCategory, select, other {{ productCategoryText }}} Adventure! 🚀',
      id: 'vinhood.vinhood_experience.explorePage.listSubtitle',
    },
    listBtn: {
      defaultMessage: 'See all { retailerName } locations',
      id: 'vinhood.vinhood_experience.explorePage.listBtn',
    },
    searchPlaces: {
      defaultMessage: 'Search places',
      id: 'vinhood.vinhood_experience.explorePage.searchPlaces',
    },
  }),
  ageAndGenderPages: defineMessages({
    genderTitle: {
      defaultMessage: 'How do you identify yourself?',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.genderTitle',
    },
    genderSubtitle: {
      defaultMessage: `Gender and taste are connected!`,
      id: 'vinhood.vinhood_experience.ageAndGenderPages.genderSubtitle',
    },
    continueBtn: {
      defaultMessage: 'Continue',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.continueBtn',
    },
    skipBtn: {
      defaultMessage: 'Skip',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.skipBtn',
    },
    agePreTitle: {
      defaultMessage: 'Taste changes as we mature!',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.agePreTitle',
    },
    ageTitle: {
      defaultMessage: 'What is your birth year?',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.ageTitle',
    },
    tastingTipTitle: {
      defaultMessage: '#TastingTip',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.tastingTipTitle',
    },
    randomGenderTastingTip1: {
      defaultMessage:
        'Females are more sensitive than males regarding odor detection, identification, discrimination, and memory.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomGenderTastingTip1',
    },
    randomGenderTastingTip2: {
      defaultMessage:
        'Taste preferences and detection abilities differ between genders due to varying estrogen levels, which can modulate the detectability and preference of taste.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomGenderTastingTip2',
    },
    randomGenderTastingTip3: {
      defaultMessage:
        'Hormonal variation affects bitter taste; bitterness varies with the menstrual cycle, rises in early pregnancy and falls after menopause.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomGenderTastingTip3',
    },
    randomGenderTastingTip4: {
      defaultMessage:
        'Females are more sensitive in the recognition of all tastes because they have higher papillae density than males.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomGenderTastingTip4',
    },
    randomGenderTastingTip5: {
      defaultMessage:
        'A regular reduction of papillae density, which means a reduction in taste perception, is observed with age, an effect more evident in males than in females.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomGenderTastingTip5',
    },
    randomGenderTastingTip6: {
      defaultMessage: 'Women consume wine and beer 2 times less than men.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomGenderTastingTip6',
    },
    randomAgeTastingTip1: {
      defaultMessage:
        'Young brains react negatively to veggies, while loving the orange juice. Our taste buds mature, appreciating the full flavor spectrum as we grow older.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomAgeTastingTip1',
    },
    randomAgeTastingTip2: {
      defaultMessage:
        'With age it becomes difficult to perceive bitterness, while the sweet taste is kept even in advanced age, with a consequent liking of sweet and high-calorie meals.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomAgeTastingTip2',
    },
    randomAgeTastingTip3: {
      defaultMessage:
        'After turning 60 years old, the decreased amount and changed composition of saliva may reduce the perception of bitter  and sour tastes.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomAgeTastingTip3',
    },
    randomAgeTastingTip4: {
      defaultMessage:
        'Adults tested for their responses to bitter stimuli demonstrated an aversion that decreased with age.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomAgeTastingTip4',
    },
    randomAgeTastingTip5: {
      defaultMessage: 'Ratings for high-fat foods rose with age for both sexes.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomAgeTastingTip5',
    },
    randomAgeTastingTip6: {
      defaultMessage:
        'Although there was decreased preference for higher concentrations of sweeteners from childhood through adulthood, there was evidence for a change in direction among older adults.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomAgeTastingTip6',
    },
    randomAgeTastingTip7: {
      defaultMessage:
        'Older people associate alcohol with relaxation and may link its consumption more to a symbolic ritual, e.g., having a drink to demarcate the end of a working day, while young consumers may perceive alcohol more as a way to socialize and get out of routine.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomAgeTastingTip7',
    },
    randomAgeTastingTip8: {
      defaultMessage:
        '“Mild-drink lovers” were more likely to be women and young, “spirit lovers” were mainly men aged 30–45, while “beer/wine lovers” were the oldest group with no significant difference by gender.',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.randomAgeTastingTip8',
    },
    ageError: {
      defaultMessage: 'Please enter a 4-digit number between 1920 and { maxValidBirthYear }',
      id: 'vinhood.vinhood_experience.ageAndGenderPages.ageError',
    },
  }),
  testResultPage: defineMessages({
    characterAdjectiveNone: {
      defaultMessage: 'None',
      id: 'vinhood.vinhood_experience.test_result_page.characterAdjectiveNone',
    },
    characterAdjectivePoor: {
      defaultMessage: 'Poor',
      id: 'vinhood.vinhood_experience.test_result_page.characterAdjectivePoor',
    },
    characterAdjectiveFair: {
      defaultMessage: 'Fair',
      id: 'vinhood.vinhood_experience.test_result_page.characterAdjectiveFair',
    },
    characterAdjectiveAverage: {
      defaultMessage: 'Average',
      id: 'vinhood.vinhood_experience.test_result_page.characterAdjectiveAverage',
    },
    characterAdjectiveGood: {
      defaultMessage: 'Good',
      id: 'vinhood.vinhood_experience.test_result_page.characterAdjectiveGood',
    },
    characterAdjectiveExcellent: {
      defaultMessage: 'Excellent',
      id: 'vinhood.vinhood_experience.test_result_page.characterAdjectiveExcellent',
    },
    characterTitleText: {
      defaultMessage:
        'Your {productCategory, select, wine {{ productCategoryText }} beer {{ productCategoryText }} coffee {{ productCategoryText }} other {}} #Character is',
      id: 'vinhood.vinhood_experience.test_result_page.characterTitleText',
    },
    authenticatedCharacterTitleText: {
      defaultMessage:
        'My {productCategory, select, wine {{ productCategoryText }} beer {{ productCategoryText }} coffee {{ productCategoryText }} other {}} #Character is',
      id: 'vinhood.vinhood_experience.test_result_page.authenticatedCharacterTitleText',
    },
    titleText: {
      defaultMessage:
        '#{characterName} {productCategory, select, wine {{ productCategoryPluralText }} beer {{ productCategoryPluralText }} coffee {{ productCategoryPluralText }} other {}} are',
      id: 'vinhood.vinhood_experience.test_result_page.titleText',
    },
    btnText: {
      defaultMessage: 'Learn more',
      id: 'vinhood.vinhood_experience.test_result_page.btnText',
    },
    likeProText: {
      defaultMessage: 'Taste like a pro!',
      id: 'vinhood.vinhood_experience.test_result_page.likeProText',
    },
    discoverText: {
      defaultMessage: 'Discover your perfect products',
      id: 'vinhood.vinhood_experience.test_result_page.discoverText',
    },
    honeText: {
      defaultMessage: 'Hone your taste with fun quizzes and games',
      id: 'vinhood.vinhood_experience.test_result_page.honeText',
    },
    diveText: {
      defaultMessage: 'Dive into the world of wine, beer, coffee, and beyond!',
      id: 'vinhood.vinhood_experience.test_result_page.diveText',
    },
    quizTitleText: {
      defaultMessage: 'Challenge your knowledge about',
      id: 'vinhood.vinhood_experience.test_result_page.quizTitleText',
    },
    quizTrueText: {
      defaultMessage: 'True',
      id: 'vinhood.vinhood_experience.test_result_page.quizTrueText',
    },
    quizFalseText: {
      defaultMessage: 'False',
      id: 'vinhood.vinhood_experience.test_result_page.quizFalseText',
    },
    yesRight: {
      defaultMessage: `You're right!`,
      id: 'vinhood.vinhood_experience.test_result_page.yesRight',
    },
    ohNo: {
      defaultMessage: 'Oh no...',
      id: 'vinhood.vinhood_experience.test_result_page.ohNo',
    },
    productSectionTitle: {
      defaultMessage: '{productCategory, select, other {Ideal {retailerLocationName} {productCategoryText}}} for you:',
      id: 'vinhood.vinhood_experience.test_result_page.productSectionTitle',
    },
    productSectionModalTitle: {
      defaultMessage:
        '{productCategory, select, wine {Save your {productCategoryPluralText}} beer {Save your {productCategoryPluralText}} coffee {Save your {productCategoryPluralText}} other {}}',
      id: 'vinhood.vinhood_experience.test_result_page.productSectionModalTitle',
    },
    productSectionDescription: {
      defaultMessage:
        '{productCategory, select, wine {You just tried your soulmate {productCategoryText}? ||Start saving the record of all your best {productCategoryPluralText}} beer {You just tried your soulmate {productCategoryText}? ||Start saving the record of all your best {productCategoryPluralText}} coffee {You just tried your soulmate {productCategoryText}? ||Start saving the record of all your best {productCategoryPluralText}} other {}}',
      id: 'vinhood.vinhood_experience.test_result_page.productSectionDescription',
    },
    productSectionModalExtraDescription: {
      defaultMessage: 'And learn about them!',
      id: 'vinhood.vinhood_experience.test_result_page.productSectionModalExtraDescription',
    },
  }),
  registrationBanner: defineMessages({
    registrationBannerTitle: {
      defaultMessage: 'Know and explore more:',
      id: 'vinhood.vinhood_experience.registrationBanner.registrationBannerTitle',
    },
    registrationBannerBtn: {
      defaultMessage: 'Create a free account!',
      id: 'vinhood.vinhood_experience.registrationBanner.registrationBannerBtn',
    },
  }),
  registrationResultPage: defineMessages({
    title: {
      defaultMessage: '{productCategory, select, other { Your ideal {productCategoryText} has the character: }}',
      id: 'vinhood.vinhood_experience.registrationResultPage.title',
    },
    prefectCategoryForYou: {
      defaultMessage: `{productCategory, select, other { We've found the perfect {productCategoryText} for you... }}`,
      id: 'vinhood.vinhood_experience.registrationResultPage.prefectCategoryForYou',
    },
    discoverTitle: {
      defaultMessage: 'Discover it now!',
      id: 'vinhood.vinhood_experience.registrationResultPage.discoverTitle',
    },
    googleBtn: {
      defaultMessage: 'Continue with Google',
      id: 'vinhood.vinhood_experience.registrationResultPage.googleBtn',
    },
    emailBtn: {
      defaultMessage: 'Continue with Email',
      id: 'vinhood.vinhood_experience.registrationResultPage.emailBtn',
    },
  }),
};

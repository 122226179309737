import { styled } from 'styled-components';

export const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;

  & > span {
    flex: 1 0 0;
  }

  & > div {
    position: relative;
    width: 90px;
    height: 100px;

    img {
      position: absolute;
      bottom: 23px;
      left: 50%;
      width: 126px;
      height: 126px;
      filter: drop-shadow(4px 4px 8px rgb(0 0 0 / 25%));
      transform: translateX(-50%) rotate(270deg);
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;

  button {
    @extend .tp-body1-medium;
    width: 100%;
  }
`;

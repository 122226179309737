import { FC } from 'react';

import * as S from '@components/web/src/components/Skeleton/ProductCardSkeleton/styles';
import Skeleton from '@components/web/src/components/Skeleton/Skeleton';

interface IProps {
  isResponsive?: boolean;
}

const ProductCardSkeleton: FC<IProps> = ({ isResponsive = false }) => (
  <S.ProductCardSkeletonContainer $isResponsive={isResponsive}>
    <S.ImageWrapper>
      <Skeleton isRound className="circle" />
      <Skeleton isRound className="circle" />
    </S.ImageWrapper>
    <S.ProductWrapper>
      <Skeleton className="character" />
      <Skeleton className="name" />
      <Skeleton className="producer" />
      <Skeleton className="description" />
      <S.PriceWrapper>
        <Skeleton className="format" />
        <Skeleton className="price" />
      </S.PriceWrapper>
    </S.ProductWrapper>
    <Skeleton className="cta" />
  </S.ProductCardSkeletonContainer>
);

export default ProductCardSkeleton;

// eslint-disable-next-line import/no-extraneous-dependencies
import styled, { css } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations/index';

export const SliderWrapper = styled.button<{ $isToggled?: boolean; $toggleColor?: string; disabled?: boolean }>`
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  padding: 0;
  border: none;
  border-radius: 12px;

  &:disabled {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: not-allowed;
  }

  &:disabled:hover {
    background-color: transparent;
    border: none;
  }

  ${({ disabled, theme }) => {
    return (
      disabled &&
      css`
        & input {
          &:checked,
          &:focus {
            & + span {
              outline: none;
              background-color: ${theme?.colors?.secondaryColor['-300']} !important;
            }
          }
        }
        & span {
          background-color: ${theme?.colors?.secondaryColor['-300']} !important;
          cursor: not-allowed !important;

          &::before {
            background-color: ${theme?.colors?.secondaryColor['-100']};
            filter: drop-shadow(0 1px 2px rgb(16 24 40 / 6%)) drop-shadow(0 1px 3px rgb(16 24 40 / 10%));
          }
        }
      `
    );
  }}

  ${({ $isToggled, theme, $toggleColor }) => {
    const toggleStateColor = $toggleColor || theme?.colors?.primaryColor['-600'];
    return css`
      &:focus {
        box-shadow: ${`0 0 0 4px ${STATIC_COLORS.green['25']}`};
      }

      input {
        width: 0;
        height: 0;
        opacity: 0;

        &:checked {
          & + span::before {
            transform: translateX(20px);
          }
        }
      }

      span {
        position: absolute;
        inset: 0;
        cursor: pointer;
        border-radius: 12px;
        transition: 0.4s;
        background-color: ${$isToggled ? toggleStateColor : theme?.colors?.secondaryColor['-300']};
        &::before {
          position: absolute;
          bottom: 2px;
          left: 2px;
          width: 20px;
          height: 20px;
          content: '';
          background-color: ${STATIC_COLORS.base.white};
          filter: drop-shadow(0 1px 2px rgb(16 24 40 / 6%)) drop-shadow(0 1px 3px rgb(16 24 40 / 10%));
          border-radius: 50%;
          transition: 0.4s;
        }
      }
    `;
  }}
`;

export const TextWrapper = styled.div<{ $position?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ $position }) => {
    const isPositionRight = $position === 'right';
    return `order: ${isPositionRight ? '2' : '-2'}`;
  }};
`;

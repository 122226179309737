import { createSelector } from 'reselect';

import { IRecyclingState } from '@lib/core/productAttributes/slices/recycling';
import { TRecycling } from '@lib/core/productAttributes/types';
import { RootState } from '@lib/core/service/types/appStateType';

/**
 * @returns recycling slice state
 */
const selectRecyclingState = (state: RootState): IRecyclingState => state?.productAttributes?.recycling;

/**
 * @returns recycling data array
 */
export const selectRecyclingData: (state) => TRecycling[] = createSelector(
  [selectRecyclingState],
  recycling => recycling?.data || [],
);

/**
 * @returns recycling loading state
 */
export const selectIsRecyclingLoading: (state) => boolean = createSelector(
  [selectRecyclingState],
  recycling => recycling?.isLoading || false,
);

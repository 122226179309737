import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { selectRetailerLocationsList } from '@lib/core/retailers/selectors/retailerLocation';
import { actionGetRetailerLocations } from '@lib/core/retailers/slices';
import { isApplicationPmi } from '@lib/core/service/consts';
import { useUser } from '@lib/core/users/hooks';
import { useUserKiosk } from '@lib/core/users/hooks/useUserKiosk';
import { useLogging } from '@lib/tools/dat/hooks';
import { MixpanelExtras } from '@lib/tools/dat/mixpanel';
import { TableComponent } from '@lib/tools/devtools/components/table';
import { themeDevTools } from '@lib/tools/devtools/components/theme';
import { useEffectSkipFirst } from '@lib/tools/views/hooks';

/**
 * * Ghost Inspector assertions for MixPanel and logging
 */
export const LoggingPanel = () => {
  const dispatch = useDispatch();

  const { retailerSlug } = useRetailer();
  const { userGroups } = useUser();
  const { isUserKioskAdmin } = useUserKiosk();
  const { logHistory, isLoggingEnabled } = useLogging();

  const retailerLocations = useSelector(selectRetailerLocationsList);

  const [isMixpanelEnabled, toggleMixpanelEvents] = useState(false);

  useEffectSkipFirst(() => {
    MixpanelExtras.switchTrackingOnOff(!isMixpanelEnabled, userGroups);
  }, [isMixpanelEnabled]);

  useEffect(() => {
    if (!retailerLocations.length && isLoggingEnabled && retailerSlug && !isUserKioskAdmin) {
      dispatch(actionGetRetailerLocations({ retailerSlug }));
    }
  }, [isLoggingEnabled, retailerSlug]);

  const logTableList = [];

  logHistory.forEach(log => {
    logTableList.push([log.type, log.eventName, JSON.stringify(log.args)]);
  });

  return (
    <>
      <div className="panel collapsible">Logs</div>

      <Box sx={{ margin: themeDevTools.spacing.medium }}>
        {!!logTableList.length && (
          <>
            {!isApplicationPmi && (
              <div className="panel">
                <div>
                  <input
                    checked={isMixpanelEnabled}
                    className="mr-1 mt-2"
                    type="checkbox"
                    onChange={e => {
                      e.stopPropagation();
                      toggleMixpanelEvents(prev => !prev);
                    }}
                  />{' '}
                  Send mixpanel events
                </div>
              </div>
            )}

            <div className="panel">
              {/* // ! Provide filters for the type of event  */}
              {/* // ? Type can be MixPanel, Google Analytics, or Service */}
              <TableComponent
                defaultRowsPerPage={-1}
                rowData={logTableList}
                rowType="LoggingRow"
                tableHeadInfo={['Type', 'Event', 'Args']}
              />
            </div>
          </>
        )}
      </Box>
    </>
  );
};

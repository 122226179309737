import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';

import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { StyledText } from '@components/web/src/foundations/Text/Text.styles';
import { IFontFamilies, IStyleWeight, ITypographyVariants } from '@components/web/src/foundations/Text/types';

export interface ITextProps extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  text?: ILocaleText;
  color?: string;
  shouldRenderEmptySelector?: boolean;
  size?: ITypographyVariants;
  weight?: IStyleWeight;
  disableMarkdown?: boolean;
  fontFamily?: IFontFamilies;
  localeOptions?: Record<string, string>;
  localeIndex?: Record<string, string>;
  localeVariables?: Record<string, { readonly id: string; readonly defaultMessage: string }>;
  transformText?: 'default' | 'none';
  linesLimit?: number;
  textDecoration?: 'underline' | 'none';
  children?: ReactNode;
  handleClick?: (e) => void;
}

export const Text = ({
  text,
  fontFamily = 'Montserrat',
  size = 'body3',
  weight = 'normal',
  color,
  shouldRenderEmptySelector = true,
  localeOptions,
  localeIndex,
  localeVariables,
  transformText = 'default',
  linesLimit,
  textDecoration,
  children,
  handleClick,
  ...rest
}: ITextProps) => {
  if (!shouldRenderEmptySelector) return null;
  return (
    <StyledText
      $color={color}
      $fontFamily={fontFamily}
      $linesLimit={linesLimit}
      $size={size}
      $textDecoration={textDecoration}
      $weight={weight}
      tabIndex={handleClick && 0}
      onClick={handleClick && handleClick}
      onKeyUp={handleClick && handleClick}
      {...rest}
    >
      {!!text && (
        <LocaleFragment
          index={localeIndex}
          message={text}
          options={localeOptions}
          transformText={transformText}
          variables={localeVariables}
        />
      )}
      {children}
    </StyledText>
  );
};

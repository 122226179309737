import { FC } from 'react';

import Badge from '@components/web/src/atoms/Badge/Badge';
import BadgesTooltip from '@components/web/src/organisms/CardFooter/BadgesTooltip';

interface IProps {
  badges?: string[];
}

const CardFooter: FC<IProps> = ({ badges }) => {
  let badgeCount = 0;
  if (badges?.length > 0) {
    badgeCount = badges.length - 1;
  }
  const onCardFooterClick = (e: any) => e.stopPropagation();

  return (
    <div aria-hidden className="card-footer-container" onClick={onCardFooterClick}>
      <div className="card-footer-wrapper">
        {badges?.length > 0 && <Badge text={badges[0]} />}
        {badgeCount > 0 && (
          <BadgesTooltip content={badges}>
            <Badge text={`+${badgeCount}`} />
          </BadgesTooltip>
        )}
      </div>
    </div>
  );
};

export default CardFooter;

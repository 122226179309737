import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@lib/core/service/types/appStateType';

const selectProductFeedbackState = (state: RootState) => state.users.productFeedback;

const selectProductFeedbackData = createSelector([selectProductFeedbackState], state => state.data);

const selectIsProductFeedbackLoading = createSelector([selectProductFeedbackState], state => state.isLoading);

const selectLastUpdatedProductFeedbackId = createSelector(
  [selectProductFeedbackState],
  state => state.lastUpdatedFeedbackId,
);

export { selectProductFeedbackData, selectLastUpdatedProductFeedbackId, selectIsProductFeedbackLoading };

import styled, { css, keyframes } from 'styled-components';

import { TASTE_MATCH_LEVELS, TTasteMatchLevels } from '@lib/core/service/consts';

import TasteMatchGIF from '@components/web/src/assets/gif/taste_match.gif';
import { TTasteMatchVariants } from '@components/web/src/atoms/TasteMatchButton/TasteMatchButton';
import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';

export const TasteMatchButton = styled.div<{ $variant: TTasteMatchVariants; $isLevelFour: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 8px 12px;
  background-color: ${STATIC_COLORS.teal['-25']};
  border-radius: 20px;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    background: ${STATIC_GRADIENTS.primaryGradient};
    padding: 1px;
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  & > span:not(.icon) {
    flex-grow: 1;
  }

  svg {
    width: 16px;
    height: 16px;
    min-width: 16px !important;
    path {
      stroke: ${STATIC_COLORS.base.black};
    }
  }
  ${({ theme, $variant }) =>
    theme?.isKioskRootElementType &&
    $variant === 'default' &&
    css`
      padding: 10px 16px 10px 20px;
      background-color: ${theme.colors.primaryColor['-50']};
      border-radius: 25px;

      svg {
        width: 24px;
        height: 24px;
        min-width: 24px !important;
      }

      &:before {
        border-radius: 25px;
        padding: 2px;
      }
    `}

  ${({ $variant, $isLevelFour }) =>
    $variant === 'catalog' &&
    css`
      border-radius: 26px;

      ${!$isLevelFour &&
      css`
        gap: 16px;
        padding: 12px 16px 6px;

        svg {
          margin-top: -4px;
        }
      `}
    `}
`;

export const TasteMatchContent = styled.div<{ $variant: TTasteMatchVariants }>`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;

  ${({ $variant }) =>
    $variant === 'catalog' &&
    css`
      gap: 6px;
      flex-direction: column;
      align-items: flex-start;
    `}

  ${({ theme }) =>
    !theme.isKioskRootElementType &&
    css`
      max-width: 279px;
    `}
`;

export const TextWrapper = styled.div`
  display: flex;
  text-align: center;
  gap: 4px;
`;

export const MatchBarSlide = styled.div<{ $variant: TTasteMatchVariants }>`
  max-width: 100px;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  position: relative;
  background-color: ${STATIC_COLORS.gray[200]};
  ${({ theme, $variant }) =>
    theme?.isKioskRootElementType &&
    $variant === 'default' &&
    css`
      max-width: 150px;
    `}

  ${({ $variant }) =>
    $variant === 'catalog' &&
    css`
      max-width: 279px;
    `}
`;

const slideAnimation = (width: number) => keyframes`
from {width: 0%}
to {width: ${width}%}
`;

export const MatchBar = styled.div<{ $tasteMatchLevel: TTasteMatchLevels }>`
  position: absolute;
  left: 0;
  top: -1px;
  height: 6px;
  border-radius: 1px;
  background: ${STATIC_GRADIENTS.primaryGradient};
  background-image: url('${TasteMatchGIF}');
  background-repeat: no-repeat;
  background-size: cover;

  ${({ $tasteMatchLevel }) => {
    if ($tasteMatchLevel === TASTE_MATCH_LEVELS.NONE) {
      return css`
        width: 0;
      `;
    }
    if ($tasteMatchLevel === TASTE_MATCH_LEVELS.THREE) {
      return css`
        animation: ${slideAnimation(65)} 1s ease-in-out forwards;
      `;
    }
    if ($tasteMatchLevel === TASTE_MATCH_LEVELS.TWO) {
      return css`
        animation: ${slideAnimation(80)} 1s ease-in-out forwards;
      `;
    }
    return css`
      animation: ${slideAnimation(100)} 1s ease-in-out forwards;
    `;
  }}
`;

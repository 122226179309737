import styled, { css, keyframes } from 'styled-components';

import { ReactComponent as SplashAnimationIcon } from '@components/web/src/assets/legacy/b2c/icons/QuizAnswerIcon.svg';
import TestAnswerIcon from '@components/web/src/atoms/TestAnswer/TestAnswerAnimationIcon/TestAnswerAnimationIcon';
import { STATIC_COLORS } from '@components/web/src/foundations';

const rotateShrink = keyframes`
  0% {
    opacity: 1;
    transform: scale(1) rotate(-10deg);
  }
  25% {
    opacity: 1;
    transform: scale(1) rotate(0deg);
  }
  50% {
    opacity: 1;
    transform: scale(1) rotate(360deg);
  }
  75% {
    border: 1.5px solid ${STATIC_COLORS.base.white};
    opacity: 1;
    transform: scale(0.2);
  }
  100% {
    visibility: hidden;
    border: 1.5px solid ${STATIC_COLORS.base.white};
    transform: scale(0.42);
  }
`;

const changeColor = keyframes`
  50% {
    background-color: ${STATIC_COLORS.base.black};
  }
  75% {
    background-color: ${STATIC_COLORS.base.white};
  }
  100% {
    background-color: ${STATIC_COLORS.base.white};
  }
`;

const expandSplash = keyframes`
  0% {
    visibility: visible;
    transform: scale(0.5);
  }
  50% {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
  100% {
    visibility: hidden;
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeIn = keyframes`
  to {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
`;

export const QuizAnimationContainer = styled.div<{ scale: number }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  overflow: visible;
  cursor: pointer;
  transform: ${({ scale }) => `scale(${scale})`};
`;

export const RotateAndShrinkIcon = styled(TestAnswerIcon)<{ $isActive: boolean }>`
  position: absolute;
  transform-origin: center;

  ${({ $isActive }) =>
    $isActive &&
    css`
      animation: ${rotateShrink} calc(1.5s * var(--scale-factor, 1)) linear forwards;

      &::before,
      &::after {
        animation-name: ${changeColor};
        animation-duration: calc(1s * var(--scale-factor, 1));
        animation-timing-function: linear;
        animation-fill-mode: forwards;
      }
    `}
`;

export const SplashIcon = styled(SplashAnimationIcon)<{ $isActive: boolean }>`
  position: absolute;
  top: 4%;
  visibility: hidden;

  ${({ $isActive }) =>
    $isActive &&
    css`
      visibility: visible;
      width: 100%;
      height: 100%;
      opacity: 0;
      transform-origin: center;
      animation: ${expandSplash} calc(0.3s * var(--scale-factor, 1)) linear forwards;
      animation-delay: calc(1.3s * var(--scale-factor, 1));
    `}
`;

export const TealIcon = styled(TestAnswerIcon)<{ $isActive: boolean }>`
  position: absolute;
  visibility: hidden;

  ${({ $isActive }) =>
    $isActive &&
    css`
      visibility: visible;
      width: 24px;
      height: 24px;
      opacity: 0;
      transform-origin: center;
      animation: ${fadeIn} calc(0.2s * var(--scale-factor, 1)) linear forwards;
      animation-delay: calc(1.5s * var(--scale-factor, 1));
    `}
`;

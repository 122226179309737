import { useState } from 'react';

const HEIGHT = 1080;
const WIDTH = 1060;

const VideoPlaylist = ({ videos, height = HEIGHT, width = WIDTH }) => {
  const [currentMediaIndex, setCurrentMediaIndex] = useState<number>(0);

  const handleVideoEnd = () => {
    setTimeout(() => {
      const nextIndex = (currentMediaIndex + 1) % videos.length;
      setCurrentMediaIndex(nextIndex);
    }, 5);
  };

  const isSingleMedia = videos.length === 1;

  return (
    <video
      key={currentMediaIndex}
      autoPlay
      muted
      className="welcome-video"
      height={height}
      loop={isSingleMedia}
      width={width}
      onEnded={handleVideoEnd}
    >
      <source src={videos[currentMediaIndex]} type="video/mp4" />
    </video>
  );
};

export default VideoPlaylist;

import styled from 'styled-components';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';

export const LegalPageContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const StyledBackButton = styled(BackButton)`
  margin-top: 10px;
`;

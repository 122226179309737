import { useSelector } from 'react-redux';

import {
  selectCurrentProductTipData,
  selectUsersProductTipsStorage,
  selectisCurrentProductTipLoading,
} from '@lib/tools/productTip/selectors';

export const useProductTip = () => ({
  currentProductTipData: useSelector(selectCurrentProductTipData),
  isCurrentProductTipLoading: useSelector(selectisCurrentProductTipLoading),
  usersProductTipsStorage: useSelector(selectUsersProductTipsStorage),
});

import { selectRetailerLocationProductCategories } from '@lib/core/retailers/selectors/retailerLocation';
import { requestWithKeysAsOptions } from '@lib/core/service/requests/request';
import { store } from '@lib/core/service/store';
import { selectUserCharacters } from '@lib/core/users/selectors/characters';
import { deleteFidelityCardUrlCreator } from '@lib/core/users/slices/urls';
import { TUser } from '@lib/core/users/types';

/**
 * Utility class for working with user data.
 */

type IUserInformationCompleteType = {
  userData: TUser | Record<string, never>;
  userDietaryPreferencePreferencesSlugs?: string[];
  userDietaryPreferenceTagsSlugs?: string[];
};

type IReturnType = {
  isProfileCompleted: boolean;
  profilePercent: number;
  isSettingsDataCompleted: boolean;
  isTagsDataCompleted: boolean;
};
interface DeleteFidelityCardResponse {
  success?: boolean;
  error?: any;
}

class UserUtils {
  /**
   * Checks if the user has characters for each retailer location product categories.
   * @returns {boolean}
   */

  public static isUserHasCharactersForEachRetailerLocationCategories() {
    const state = store.getState();
    const userCharacters = selectUserCharacters(state);
    const retailerLocationProductCategories = selectRetailerLocationProductCategories(state);

    const userCharactersProductCategories = userCharacters.map(characterData => characterData?.product_category || '');
    return retailerLocationProductCategories.every(productCategory =>
      userCharactersProductCategories.includes(productCategory),
    );
  }

  /**
   * Checks if the user's information is complete.
   * @param {Object} userData - The user's data.
   * @param {Object} userDietaryPreferencePreferencesSlugs - preference slugs
   * @param {Object} userDietaryPreferenceTagsSlugs - tags slugs
   * @returns {Object} An object containing the completeness status and profile percentage.
   */
  public static isUserInformationComplete = ({
    userData,
    userDietaryPreferencePreferencesSlugs,
    userDietaryPreferenceTagsSlugs,
  }: IUserInformationCompleteType): IReturnType => {
    const isEmptyString = (value: string): boolean => !!(!value || !value.trim());
    let profilePercent = 100;
    let isSettingsDataCompleted = true;
    let isTagsDataCompleted = true;

    const {
      first_name: firstName = '',
      last_name: lastName = '',
      country = '',
      profile_image: displayPicture = '',
    } = userData;

    if (isEmptyString(firstName)) profilePercent -= 14;
    if (isEmptyString(lastName)) profilePercent -= 14;
    if (isEmptyString(country)) profilePercent -= 14;
    if (isEmptyString(displayPicture)) profilePercent -= 14;

    if (userDietaryPreferencePreferencesSlugs && userDietaryPreferenceTagsSlugs) {
      if (!userDietaryPreferencePreferencesSlugs?.length) {
        profilePercent -= 22;
      }
      if (profilePercent < 100) isSettingsDataCompleted = false;

      if (!userDietaryPreferenceTagsSlugs?.length) {
        isTagsDataCompleted = false;
        profilePercent -= 22;
      }
    }

    return {
      isProfileCompleted: profilePercent === 100,
      isSettingsDataCompleted,
      isTagsDataCompleted,
      profilePercent,
    };
  };

  public static deleteUserFidelityCardInfo = async (): Promise<DeleteFidelityCardResponse> => {
    try {
      await requestWithKeysAsOptions({
        apiPath: deleteFidelityCardUrlCreator(),
        config: { method: 'DELETE' },
        withErrorReturn: true,
      });
      return { success: true };
    } catch (error) {
      return error;
    }
  };
}

export default UserUtils;

import { styled } from 'styled-components';

import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';

export const LoadingSpinnerContainer = styled.div`
  position: absolute;
  inset: 0;
  z-index: 99999999;
  width: 100%;
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
  margin: auto;
  background: ${STATIC_GRADIENTS.primaryGradient};

  img {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  max-width: 311px;
  color: ${STATIC_COLORS.base.whiteGray};

  & > * {
    text-align: center;
  }
`;

import { useEffect, useState } from 'react';

import { useEffectSkipFirst } from '@lib/tools/views/hooks/useEffectSkipFirst';

export const useDebounceEffect = (effect, deps, delay) => {
  // ToDo behavior is incorrect, check useDebounceEffectSkipFirst approach
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
};

export const useDebounceEffectSkipFirst = (effect, deps, delay) => {
  const [lastTimeout, setLastTimeout] = useState(null);
  useEffectSkipFirst(() => {
    if (lastTimeout) {
      clearTimeout(lastTimeout);
      setLastTimeout(null);
    }
    setLastTimeout(setTimeout(() => effect(), delay));

    return () => clearTimeout(lastTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
};

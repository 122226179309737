import styled from 'styled-components';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';

export const CookiePolicyPageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 16px 16px 20px;
  color: var(--vh-black);

  p,
  li,
  ul {
    padding: 0;
    margin: 0;
  }

  ul {
    padding-left: 15px;
    list-style-type: disc;
  }
`;

export const MainTitle = styled.p`
  margin-bottom: 4px;
`;

export const MainDescription = styled.p`
  margin: 0;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
`;

export const SectionTitle = styled.div`
  /* Consider adding styles for the section title */
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 16px 0;
`;

export const Table = styled.div`
  margin: 0;
  border: 0.5px solid var(--color-secondary-500);
`;

export const TableMainTitle = styled.div`
  padding: 8px;
  background-color: var(--color-secondary-200);
`;

export const TableTitle = styled.div`
  padding: 4px 8px;
  background-color: var(--color-secondary-200);
`;

export const TableDescription = styled.div`
  padding: 4px 8px 16px;
  span {
    display: block;
    margin-top: 15px;
  }
`;

export const PreviousCta = styled.div`
  justify-content: left;
  padding: 8px 0 16px;
  margin-top: 20px;
`;

export const StyledBackButton = styled(BackButton)`
  padding: 8px 16px;
`;

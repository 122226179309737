import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_BREAD,
  PRODUCT_CATEGORY_CANDY,
  PRODUCT_CATEGORY_CHOCOLATE,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_DIGESTIF,
  PRODUCT_CATEGORY_OIL,
  PRODUCT_CATEGORY_TEA,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import { IStaticColors, IStaticGradients, IStaticShadows } from '@components/web/src/foundations/types';

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const DEFAULT_COLOR_THEME = {
  primaryColor: {
    '-25': '#f7fbfb',
    '-50': '#d9e9ec',
    '-100': '#BAD7DD',
    '-200': '#9CC6CE',
    '-300': '#7DB4BE',
    '-400': '#5FA3AF',
    '-500': '#4091A0',
    '-600': '#367A86',
    '-700': '#2C636D',
    '-800': '#214B53',
    '-900': '#17343A',
  },
  secondaryColor: {
    '-25': '#fefefe',
    '-50': '#f9f9f8',
    '-100': '#F4F5F2',
    '-200': '#EFF0EC',
    '-300': '#EAEBE6',
    '-400': '#E5E7E0',
    '-500': '#E0E2DA',
    '-600': '#BCBEB7',
    '-700': '#989A94',
    '-800': '#747671',
    '-900': '#51514E',
  },
};

export const DEFAULT_STYLED_THEME = {
  colors: {
    ...DEFAULT_COLOR_THEME,
  },
};

export const SCREEN_SIZE = {
  desktopKiosk: '1080px',
  desktopKioskLarge: '1920px',
  medium: '768px',
  mobileSmall: '420px',
  vhApp: '500px',
};

export const deviceSize = {
  desktopKioskLarge: `(max-width: ${SCREEN_SIZE.desktopKioskLarge})`,
  lg: `(max-width: ${SCREEN_SIZE.desktopKiosk})`,
  md: `(max-width: ${SCREEN_SIZE.medium})`,
  mobileSmall: `(max-width: ${SCREEN_SIZE.mobileSmall})`,
  vhApp: `(max-width: ${SCREEN_SIZE.vhApp})`,
};

export const STATIC_COLORS: IStaticColors = {
  green: {
    '25': '#F2F9F5',
    '50': '#D3EDDF',
    '100': '#B0DEC6',
    '200': '#8DCFAD',
    '300': '#6AC093',
    '400': '#47B17A',
    '500': '#24A360',
    '600': '#1E8851',
    '700': '#186F41',
    '800': '#135432',
    '900': '#0D3B23',
  },
  azure: {
    '25': '#F7FAFD',
    '50': '#D8E6F3',
    '100': '#B9D1E9',
    '200': '#9ABDDF',
    '300': '#7BA9D5',
    '400': '#5C94CB',
    '500': '#3D80C1',
    '600': '#336CA2',
    '700': '#295783',
    '800': '#204364',
    '900': '#162E45',
  },
  teal: {
    '25': '#F7FBFB',
    '50': '#D9E9EC',
    '100': '#BAD7DD',
    '200': '#9CC6CE',
    '300': '#7DB4BE',
    '400': '#5FA3AF',
    '500': '#4091A0',
    '600': '#367A86',
    '700': '#2C636D',
    '800': '#214B53',
    '900': '#17343A',
  },
  gray: {
    '25': '#f9f9fa',
    '50': '#e0e2e7',
    '100': '#c8ccd3',
    '200': '#b0b5c0',
    '300': '#979eac',
    '400': '#7f8798',
    '500': '#667085',
    '600': '#565e70',
    '700': '#454C5A',
    '800': '#353a45',
    '900': '#252830',
  },
  warmGray: {
    '25': '#fefefe',
    '50': '#f9f9f8',
    '100': '#f4f5f2',
    '200': '#eff0ec',
    '300': '#e1e0d9',
    '400': '#e5e6e0',
    '500': '#e0e2da',
    '600': '#bcbeb7',
    '700': '#989a94',
    '800': '#747571',
    '900': '#51514e',
  },
  productColors: {
    [PRODUCT_CATEGORY_BEER]: { '50': '#FBE5CC', '200': '#F5BC7B', '500': '#EB7F00' },
    [PRODUCT_CATEGORY_COFFEE]: { '50': '#D0CDCC', '200': '#867C7B', '500': '#150400' },
    [PRODUCT_CATEGORY_WINE]: { '50': '#ecccdf', '200': '#CD7BAB', '500': '#9E005D' },
    [PRODUCT_CATEGORY_BREAD]: { '200': '#F0DF7B', '500': '#E2C100' },
    [PRODUCT_CATEGORY_TEA]: { '200': '#A9E8D7', '500': '#59D2B1' },
    [PRODUCT_CATEGORY_OIL]: { '200': '#DCE47A', '500': '#BBCB00' },
    [PRODUCT_CATEGORY_DIGESTIF]: { '200': '#B48E92', '500': '#6E252E' },
    [PRODUCT_CATEGORY_CHOCOLATE]: { '200': '#DAA688', '500': '#B8531A' },
    [PRODUCT_CATEGORY_CANDY]: { '200': '#DAA688', '500': '#B8531A' },
  },
  base: {
    black: '#232021',
    white: '#fff',
    whiteGray: '#efefef',
    red: '#f04438',
    lightRed: '#fda29b',
    green: '#12b76a',
  },
  error: {
    '50': '#fef3f2',
    '100': '#fee4e2',
    '400': '#F97066',
    '600': '#D92D20',
  },
  warning: {
    '400': '#FDB022',
  },
};

export const customGradient = (startColor: string, endColor: string) =>
  `linear-gradient(180deg, ${startColor} 0%, ${endColor} 100%)`;

export const STATIC_GRADIENTS: IStaticGradients = {
  warmGradient() {
    return customGradient(STATIC_COLORS.warmGray[100], STATIC_COLORS.base.white);
  },
  warmGradientReversed() {
    return customGradient(STATIC_COLORS.base.white, STATIC_COLORS.warmGray[100]);
  },
  primaryGradient: 'linear-gradient(45deg, #3d80c1 0%, #24a360 100%)',
  warmGradientForSkeleton: 'linear-gradient(90deg, #c8c6bb 0.69%, rgb(225 224 217 / 0%) 100.69%)',
  characterGradients: {
    [PRODUCT_CATEGORY_WINE]: 'linear-gradient(180deg, #fbf5f9 0%, #ecccdf 100%)',
    [PRODUCT_CATEGORY_BEER]: 'linear-gradient(0deg, #fbe5cc 0%, #fefaf5 97.59%)',
    [PRODUCT_CATEGORY_COFFEE]: 'linear-gradient(0deg, #aba5a3 0%, #f6f5f5 97.59%)',
  },
  productGradients: {
    [PRODUCT_CATEGORY_WINE]: 'radial-gradient(98% 98% at 51.6% 100%, #ce80ae 0%, #9e005d 99.58%)',
    [PRODUCT_CATEGORY_BEER]: 'radial-gradient(98% 98% at 51.6% 100%, #f5bc7b 0%, #eb7f00 99.58%)',
    [PRODUCT_CATEGORY_COFFEE]: 'radial-gradient(98% 98% at 51.6% 100%, #8a8180 0%, #150400 99.58%)',
  },
};

export const STATIC_SHADOWS: IStaticShadows = {
  xs: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  sm: '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
  md: '0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
  lg: '0px 12px 16px -4px rgba(16, 24, 40, 0.10), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)',
  xl: '0px 20px 24px -4px rgba(16, 24, 40, 0.10), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)',
  '2xl': '0px 24px 48px -12px rgba(16, 24, 40, 0.25)',
  '3xl': '0px 32px 64px -12px rgba(16, 24, 40, 0.20)',
};

export const styledTheme = {
  breakpoints: { ...deviceSize },
  colors: {
    staticColors: { ...STATIC_COLORS },
  },
};

export function styleMatcher<T extends string | number | symbol, V>(value: T, handlers: { [key in T]: () => V }): V {
  const handler = handlers[value];

  return handler();
}

import { FC } from 'react';

import { localeApp } from '@lib/tools/locale/source/web/app';

import CloseButton from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/Modals/InterestsModal/styles';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

interface IProps {
  isModalOpen: boolean;
  hideModal: () => void;
}

const InterestsModal: FC<IProps> = ({ isModalOpen, hideModal }) => {
  const {
    interestsModal: { heading, description },
  } = localeApp;

  return (
    <ModalWrapper maxContentHeight hideModal={hideModal} isOpen={isModalOpen}>
      <S.InterestModalContainer>
        <CloseButton handleClick={hideModal} />
        <S.Title color={STATIC_COLORS.base.black} fontFamily="Fraunces" size="h5" text={heading} weight="semibold" />
        <Text color={STATIC_COLORS.base.black} size="body1" text={description} />
      </S.InterestModalContainer>
    </ModalWrapper>
  );
};

export default InterestsModal;

const CircleColorKiosk = ({ productCategoryColor }) => {
  return (
    <svg fill="none" height="250" viewBox="0 0 265 250" width="265">
      <path
        d="M100.843 1.22257C210.998 -8.07213 275.938 35.2251 263.478 145.034C254.963 201.337 185.61 262.566 112.824 247.755C62.7952 243.375 15.5416 201.226 4.96527 162.152C-5.88967 122.009 7.08277 69.718 16.9458 50.8723C30.7541 24.5262 61.8813 5.39069 100.843 1.22257Z"
        fill={productCategoryColor || '#EAE9E3'}
      />
      <path
        d="M138.289 10.3722C198.459 5.62459 236.574 43.4052 244.186 98.3821C252.021 155.008 230.734 201.092 189.633 216.795C119.02 243.787 39.4022 201.125 25.9728 144.789C15.4745 100.767 36.8502 51.9532 53.244 37.5765C72.7918 20.4248 99.1713 13.4593 138.289 10.3722Z"
        fill="white"
      />
    </svg>
  );
};

export default CircleColorKiosk;

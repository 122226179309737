import { FC, useMemo, useState } from 'react';

import { TProductInstance } from '@lib/core/products/types';
import { TASTE_MATCH_LEVELS, TTasteMatchLevels } from '@lib/core/service/consts';
import { getMultipleUniqueRandomItemsFromArray } from '@lib/core/service/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import { ReactComponent as QuestionMarkIcon } from '@components/web/src/assets/icons/question-circle.svg';
import * as S from '@components/web/src/atoms/TasteMatchButton/styles';
import { Text } from '@components/web/src/foundations/Text/Text';
import TasteMatchModal from '@components/web/src/templates/Modals/TasteMatchModal/TasteMatchModal';

/**
 * @param tasteMatchValue - Raw taste match value between 0 and 1
 * @param tasteMatchLevel - Parsed tasteMatchValue to {@link TTasteMatchLevels}
 *
 * @param isLocationTasteMatchEvent uses for mixpanel events:
 * if true we pass locationName, locationId, locationName
 * if false we pass productInstanceData
 */

export type TTasteMatchVariants = 'default' | 'catalog';

interface IProps {
  tasteMatchValue: number | null;
  tasteMatchLevel: TTasteMatchLevels;
  variant?: TTasteMatchVariants;
  isLocationTasteMatchEvent?: boolean;
  retailerLocationName?: string;
  retailerLocationId?: string;
  retailerLocationSlug?: string;
  productInstanceData?: TProductInstance;
  navigateToQuizSelectionPage: () => void;
  hideTooltip: () => void;
}

const TasteMatchButton: FC<IProps> = ({
  tasteMatchValue,
  tasteMatchLevel,
  variant = 'default',
  isLocationTasteMatchEvent,
  retailerLocationName,
  retailerLocationId,
  retailerLocationSlug,
  productInstanceData,
  navigateToQuizSelectionPage,
  hideTooltip,
}) => {
  const { none, levelOne, levelTwo, levelThree, catalogTasteMatch, random1, random2, random3, random4, random5 } =
    localeCommon.tasteMatchLevel;

  const [isTasteMatchModalOpen, setTasteMatchModalOpen] = useState(false);

  const isLevelNone = tasteMatchLevel === TASTE_MATCH_LEVELS.NONE;
  const isLevelOne = tasteMatchLevel === TASTE_MATCH_LEVELS.ONE;
  const isLevelTwo = tasteMatchLevel === TASTE_MATCH_LEVELS.TWO;
  const isLevelThree = tasteMatchLevel === TASTE_MATCH_LEVELS.THREE;
  const isLevelFour = tasteMatchLevel === TASTE_MATCH_LEVELS.FOUR;

  const hideCatalogTasteMatchText = isLevelFour || isLevelNone;
  const isCatalogVariant = variant === 'catalog';

  const text = useMemo(() => {
    if (isLevelNone) return none;
    if (isLevelOne) return levelOne;
    if (isLevelTwo) return levelTwo;
    if (isLevelThree) return levelThree;

    return getMultipleUniqueRandomItemsFromArray([random1, random2, random3, random4, random5], 1)[0];
  }, [tasteMatchLevel]);

  return (
    <>
      <S.TasteMatchButton
        $isLevelFour={isLevelFour}
        $variant={variant}
        onClick={() => {
          if (isLocationTasteMatchEvent) {
            MixpanelTracker.events.locationTasteMatchClick({
              locationId: retailerLocationId,
              locationName: retailerLocationName,
              locationSlug: retailerLocationSlug,
              tasteMatchLevel: tasteMatchValue,
            });
          } else MixpanelTracker.events.tasteMatchClick(productInstanceData);
          hideTooltip();
          setTasteMatchModalOpen(true);
        }}
      >
        <S.TasteMatchContent $variant={variant}>
          {!isLevelFour && (
            <S.MatchBarSlide $variant={variant}>
              <S.MatchBar $tasteMatchLevel={tasteMatchLevel} />
            </S.MatchBarSlide>
          )}
          {isCatalogVariant ? (
            <S.TextWrapper>
              {!hideCatalogTasteMatchText && <Text size="body1" text={catalogTasteMatch} weight="semibold" />}
              <Text size="body1" text={text} weight={hideCatalogTasteMatchText ? 'semibold' : 'normal'} />
            </S.TextWrapper>
          ) : (
            <Text size="subtitle2" text={text} weight="medium" />
          )}
        </S.TasteMatchContent>
        <QuestionMarkIcon />
      </S.TasteMatchButton>
      {isTasteMatchModalOpen && (
        <TasteMatchModal
          handleBtnClick={navigateToQuizSelectionPage}
          hideModal={() => setTasteMatchModalOpen(false)}
          isOpen={isTasteMatchModalOpen}
          shouldDisplayButton={isLevelNone}
        />
      )}
    </>
  );
};

export default TasteMatchButton;

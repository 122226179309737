import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@lib/core/service/types/appStateType';
import { DIETARY_PREFERENCE_EXPOSURE } from '@lib/tools/shared/helpers/consts';

/**
 * @returns the dietaryPreference options state
 */
const selectDietaryPreferenceState = (state: RootState) => state.users.dietaryPreference;

/**
 * @returns status of the loading dietaryPreference options state
 */
export const selectDietaryPreferenceOptionsLoading = createSelector(
  [selectDietaryPreferenceState],
  state => state.options.isLoading,
);

/**
 * @returns error of the loading dietaryPreference options state
 */
export const selectDietaryPreferenceOptionsError = createSelector(
  [selectDietaryPreferenceState],
  state => state.options.error,
);

/**
 * @returns data of the dietaryPreference options state
 */
export const selectDietaryPreferenceOptionsData = createSelector(
  [selectDietaryPreferenceState],
  state => state.options.data?.choices?.dietary_preferences || [],
);

/**
 * @returns status of the loading dietaryPreference list state
 */
export const selectDietaryPreferenceListLoading = createSelector(
  [selectDietaryPreferenceState],
  state => state.list.isLoading,
);

/**
 * @returns error of the loading dietaryPreference list state
 */
export const selectDietaryPreferenceListError = createSelector(
  [selectDietaryPreferenceState],
  state => state.list.error,
);

/**
 * @returns data of the dietaryPreference list state
 */
export const selectDietaryPreferenceListData = createSelector([selectDietaryPreferenceState], state => state.list.data);

/**
 * @returns filtered by preference exposure list data
 */
export const selectUserDietaryPreferencePreferences = createSelector([selectDietaryPreferenceState], state =>
  state.list.data.filter(
    ({ dietary_preference: { exposure } }) => exposure === DIETARY_PREFERENCE_EXPOSURE.PREFERENCES,
  ),
);

/**
 * @returns filtered by tags exposure list data
 */
export const selectUserDietaryPreferenceTags = createSelector([selectDietaryPreferenceState], state =>
  state.list.data.filter(({ dietary_preference: { exposure } }) => exposure === DIETARY_PREFERENCE_EXPOSURE.TAGS),
);

/**
 * @returns filtered by preference exposure options data
 */
export const selectDietaryPreferencePreferencesOptions = createSelector([selectDietaryPreferenceState], state =>
  state.options.data?.choices?.dietary_preferences?.filter(
    ({ exposure }) => exposure === DIETARY_PREFERENCE_EXPOSURE.PREFERENCES,
  ),
);

/**
 * @returns filtered by tags exposure options data
 */
export const selectDietaryPreferenceTagsOptions = createSelector([selectDietaryPreferenceState], state =>
  state.options.data?.choices?.dietary_preferences?.filter(
    ({ exposure }) => exposure === DIETARY_PREFERENCE_EXPOSURE.TAGS,
  ),
);

/**
 * @returns filtered by mood exposure options data
 */
export const selectDietaryPreferenceMoodOptions = createSelector([selectDietaryPreferenceState], state =>
  state.options.data?.choices?.dietary_preferences?.filter(
    ({ exposure }) => exposure === DIETARY_PREFERENCE_EXPOSURE.MOOD,
  ),
);

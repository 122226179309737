import { useSelector } from 'react-redux';

import { selectIsWellnessLoading, selectWellnessData } from '@lib/core/productAttributes/selectors';
import { TWellness } from '@lib/core/productAttributes/types';

interface IProps {
  isWellnessLoading: boolean;
  wellnessData: TWellness[];
}

export const useWellness = (): IProps => ({
  isWellnessLoading: useSelector(selectIsWellnessLoading),
  wellnessData: useSelector(selectWellnessData),
});

import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const scrollToTop = targetContainer => {
  const scrollOptions: ScrollToOptions = {
    behavior: 'smooth',
    left: 0,
    top: 0,
  };

  if (targetContainer) {
    targetContainer.scrollTo(scrollOptions);
  } else {
    window.scrollTo(scrollOptions);
  }
};

export const useScrollToTop = (additionalDependency?: any) => {
  const location = useLocation();
  const targetContainer = document.querySelector('#root-scroll-element');

  useLayoutEffect(() => {
    scrollToTop(targetContainer);
  }, [location.pathname, additionalDependency]);
};

import { FC } from 'react';

import { TCharacter } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import { LocaleUtils } from '@lib/tools/locale/utils';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { getPrimaryProductCategoryColor } from '@lib/tools/shared/helpers';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
  VH_VARIANTS,
} from '@lib/tools/shared/helpers/consts';

import beerCharactersSetIcon from '@components/web/src/assets/legacy/b2b/kiosk/beer_characters_set.png';
import coffeeCharactersSetIcon from '@components/web/src/assets/legacy/b2b/kiosk/coffee_characters_set.png';
import wineCharactersSetIcon from '@components/web/src/assets/legacy/b2b/kiosk/wine_characters_set.png';
import Button from '@components/web/src/atoms/Buttons/Button';

export type Props = {
  productCategory: TProductCategory;
  characterData: TCharacter;
  navigateToTasteTest: () => void;
  navigateToProductCatalogPage: (isEnableWishlistFilter?: boolean) => void;
};

const CharacterExperienceCard: FC<Props> = ({
  productCategory = PRODUCT_CATEGORY_WINE,
  characterData,
  navigateToProductCatalogPage,
  navigateToTasteTest,
}) => {
  const characterImage = characterData?.image || '';
  const characterName = characterData?.name || '';
  const characterCaption = characterData?.technical_caption || '';

  const { characterExperienceCard } = localeKiosk.homePageCards;
  const { productCategoriesPlural, productCategories } = localeCommon;

  const { publishedTerms } = LocaleUtils;

  const productCategoryText = publishedTerms[productCategories[productCategory].id];
  const productCategoryPluralText = publishedTerms[productCategoriesPlural[productCategory].id];
  const testAgainTest = (
    <LocaleFragment
      message={characterExperienceCard.testAgain}
      insertIntlJSXPartOptions={{
        className: 'cta-test',
        linkId: 'test-page',
        linkRender: text => (
          <span aria-hidden className="decorated-text" onClick={navigateToTasteTest}>
            {text}
          </span>
        ),
      }}
    />
  );
  const primaryProductColor = getPrimaryProductCategoryColor(productCategory);

  const getProductCategoryBackgroundImage = () => {
    switch (productCategory) {
      case PRODUCT_CATEGORY_WINE:
        return wineCharactersSetIcon;
      case PRODUCT_CATEGORY_BEER:
        return beerCharactersSetIcon;
      case PRODUCT_CATEGORY_COFFEE:
        return coffeeCharactersSetIcon;
      default:
        return wineCharactersSetIcon;
    }
  };

  return (
    <div
      className="character-taste-card-container"
      style={{
        backgroundColor: primaryProductColor,
      }}
    >
      {characterName && (
        <div
          className="character-background"
          style={{
            backgroundImage: `url(${characterImage})`,
          }}
        />
      )}
      <div className="character-card-content-wrapper">
        {characterName ? (
          <>
            <p className="title">
              <LocaleFragment
                message={characterExperienceCard.title}
                index={{
                  productCategory,
                }}
                options={{
                  characterName,
                  productCategoryText,
                }}
              />
            </p>
            <p className="character-description">{characterCaption}</p>
            <p className="description">
              <LocaleFragment message={characterExperienceCard.description} />
            </p>
            {testAgainTest}
          </>
        ) : (
          <>
            <img alt="characters" className="missed-character-image" src={getProductCategoryBackgroundImage()} />
            <p className="missed-character-title">
              <LocaleFragment
                message={characterExperienceCard.missedCharacterTitle}
                index={{
                  productCategory,
                }}
                options={{
                  productCategoryPluralText,
                }}
              />
            </p>
            <p className="missed-character-description">
              <LocaleFragment message={characterExperienceCard.missedCharacterDescription} />
            </p>
          </>
        )}
      </div>
      <Button
        handleClick={characterName ? () => navigateToProductCatalogPage() : navigateToTasteTest}
        size="lg"
        text={characterName ? characterExperienceCard.btn : characterExperienceCard.missedCharacterBtn}
        variant={VH_VARIANTS.LIGHT}
        style={{
          color: primaryProductColor,
        }}
      />
    </div>
  );
};

export default CharacterExperienceCard;

import styled from 'styled-components';

export const ConfettiContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const ImageWrapper = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

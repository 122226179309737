import { FC, SyntheticEvent, useState } from 'react';

import { TProductInstance } from '@lib/core/products/types';
import { HARDCODED_FEEDBACK_DATA } from '@lib/core/service/consts';
import { TProductFeedbackValue } from '@lib/core/users/slices/productFeedback';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import startIcon from '@components/web/src/assets/icons/feedback/feedback_start_icon.svg';
import FeedbackTooltip from '@components/web/src/atoms/Feedback/Feedback/FeedbackTooltip';
import * as S from '@components/web/src/atoms/Feedback/Feedback/styles';
import { Text } from '@components/web/src/foundations/Text/Text';
import DiscoveryQuizModal, {
  IDiscoveryQuiz,
} from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import FeedbackModal from '@components/web/src/templates/Modals/FeedbackModal/FeedbackModal';

interface IProps {
  feedback: TProductFeedbackValue;
  productInstanceData: TProductInstance;
  discoveryQuiz: IDiscoveryQuiz;
  isFeedbackModalDisabled?: boolean;
  shouldShowFeedbackTooltip?: boolean;
  handleUpdateFeedback: (feedback: TProductFeedbackValue, productId: string, productName?: string) => void;
  handleRedirectToRegistrationPage?: () => void;
  handleAuthFeatureClick?: () => void;
}

const Feedback: FC<IProps> = ({
  feedback,
  productInstanceData,
  discoveryQuiz,
  isFeedbackModalDisabled = false,
  shouldShowFeedbackTooltip,
  handleUpdateFeedback,
  handleAuthFeatureClick,
}) => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isDiscoveryQuizModalOpen, setIsDiscoveryQuizModalOpen] = useState(false);

  const { productCard, tasteId } = localeCommon;
  const icon = feedback ? HARDCODED_FEEDBACK_DATA[feedback].icon.activeIcon : startIcon;

  const handleButtonClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (handleAuthFeatureClick) {
      handleAuthFeatureClick();
    } else {
      setIsFeedbackModalOpen(true);
    }
  };
  const isShowFeedbackTooltip = shouldShowFeedbackTooltip && !feedback;

  return (
    <>
      <S.FeedbackButton onClick={handleButtonClick}>
        <S.RatingImage alt="feedback icon" src={icon} />
        {!feedback && <Text size="body3" text={productCard.rateIt} weight="medium" />}
        <FeedbackTooltip isAnimated infoText={tasteId.tasteIdTooltipText} isShowTooltip={isShowFeedbackTooltip} />
      </S.FeedbackButton>

      {isFeedbackModalOpen && productInstanceData && !isFeedbackModalDisabled && (
        <FeedbackModal
          feedback={feedback}
          handleOpenDiscoveryQuizModal={() => setIsDiscoveryQuizModalOpen(true)}
          handleUpdateFeedback={handleUpdateFeedback}
          hideModal={() => setIsFeedbackModalOpen(false)}
          isModalOpen={isFeedbackModalOpen}
          productInstanceData={productInstanceData}
        />
      )}
      {isDiscoveryQuizModalOpen && discoveryQuiz && (
        <DiscoveryQuizModal
          discoveryQuiz={discoveryQuiz}
          hideModal={() => setIsDiscoveryQuizModalOpen(false)}
          isModalOpen={isDiscoveryQuizModalOpen}
        />
      )}
    </>
  );
};

export default Feedback;

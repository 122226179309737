const KioskHome = () => {
  const cardStyles = [
    { borderRadius: ' 40px 10px 0px 0px', height: '500px', width: '503px' },
    { borderRadius: '10px 40px 0px 0px', height: '500px', width: '503px' },
    { borderRadius: '0px 0px 10px 40px', height: '542px', width: '503px' },
    { borderRadius: '0px 0px 40px 10px', height: '542px', width: '503px' },
  ];

  return (
    <div className="skeleton-kiosk-home-container">
      {cardStyles.map((style, index) => (
        <div key={index} className="skeleton-square-tile-container">
          <div className="square-tile" style={style} />
        </div>
      ))}
    </div>
  );
};

export default KioskHome;

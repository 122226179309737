import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { prependBasename } from '@lib/core/service/utils';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { currentYear } from '@lib/tools/shared/helpers/utils';
import { PAGES } from '@lib/tools/views/urls';

import { ReactComponent as GearIcon } from '@components/web/src/assets/legacy/b2c/icons/icon_green50_gear.svg';
import { ReactComponent as WavyImage } from '@components/web/src/assets/legacy/b2c/images/footer_waves.svg';
import { ModalsHOC } from '@components/web/src/templates/Modals/ModalsHOC';

interface IFooterLink {
  id: string;
  content: string | ReactNode;
  onClick: () => void;
}

interface IProps {
  showGearIcon?: boolean;
}

const FooterAppAnonymous: FC<IProps> = ({ showGearIcon = true }) => {
  const navigate = useNavigate();

  const { termsAndConditionsTitle, privacyPolicyTitle, cookiePolicyTitle, copyrightText } = localeCommon.commonMessages;

  const footerLinks: IFooterLink[] = [
    {
      content: <LocaleFragment message={termsAndConditionsTitle} />,
      id: '1',
      onClick: () => navigate(prependBasename(PAGES.vinhood.legal.termsConditions)),
    },
    {
      content: <LocaleFragment message={privacyPolicyTitle} />,
      id: '2',
      onClick: () => navigate(prependBasename(PAGES.vinhood.legal.privacy)),
    },
    {
      content: <LocaleFragment message={cookiePolicyTitle} />,
      id: '3',
      onClick: () => navigate(prependBasename(PAGES.vinhood.legal.cookies)),
    },
    {
      content: <GearIcon />,
      id: '4',
      onClick: () => navigate(prependBasename(PAGES.vinhood.legal.generalServiceTerms)),
    },
  ];

  if (!showGearIcon) delete footerLinks[3];

  return (
    <ModalsHOC>
      <div className="footer-app-anonymous-container">
        <div className="wavyimage-container">
          <WavyImage className="wavyimage-element" />
        </div>
        <div className="footer-content">
          <span className="copyright-text">
            <LocaleFragment message={copyrightText} options={{ currentYear }} />
          </span>
          <div className="content-link-container">
            {footerLinks.map(contentItem => (
              <button key={contentItem.id} className="link" onClick={contentItem.onClick}>
                {contentItem.content}
              </button>
            ))}
          </div>
        </div>
      </div>
    </ModalsHOC>
  );
};

export default FooterAppAnonymous;

import React from 'react';

import { useTheme } from '@lib/tools/views/hooks/useTheme';

import { B2B_CIRCULAR_COLORS } from '@components/web/src/widget/consts';

type FooterWaveProps = {
  className?: string;
};

const FooterWave: React.FC<FooterWaveProps> = ({ className }) => {
  const defaultColor = B2B_CIRCULAR_COLORS.neutral.internal;
  const { primary } = useTheme();
  const primaryColor = primary['-800'];
  const fillColor = primaryColor || defaultColor;

  return (
    <svg
      className={className}
      fill="none"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 834 93"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M138.115 36.8964C75.2194 36.8964 12.749 30.4777 0 20.8496V92.736H834V30.4777H755.381C723.508 30.4777 619.391 41.7104 549.271 41.7104C479.152 41.7104 386.721 20.8496 325.101 20.8496C263.48 20.8496 216.734 36.8964 138.115 36.8964Z"
        fill={fillColor}
        opacity="0.3"
      />
      <path
        d="M221.558 35.3005C175.499 24.0483 66.7055 1.54395 0 1.54395V92.6662H834V27.9891C801.339 28.8811 763.223 30.8692 721.849 35.3005C646.805 43.3377 537.218 62.6272 450.262 62.6272C380.698 62.6272 268.807 44.4094 221.558 35.3005Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default FooterWave;

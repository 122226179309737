import styled from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const ResultEssenceContainer = styled(Flexbox)`
  width: 100%;
`;

export const ResultEssenceTitle = styled(Text)`
  width: 100px;
`;

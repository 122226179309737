import { FC } from 'react';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { APPLE, EMAIL, FACEBOOK, GOOGLE } from '@lib/tools/shared/helpers/consts';

import { ReactComponent as AppleDisabled } from '@components/web/src/assets/legacy/icons/apple_disabled.svg';
import { ReactComponent as AppleIcon } from '@components/web/src/assets/legacy/icons/apple_icon.svg';
import { ReactComponent as EmailIcon } from '@components/web/src/assets/legacy/icons/email_icon.svg';
import { ReactComponent as FacebookDisabled } from '@components/web/src/assets/legacy/icons/facebook_disabled.svg';
import { ReactComponent as FacebookIcon } from '@components/web/src/assets/legacy/icons/facebook_icon.svg';
import { ReactComponent as GoogleDisabled } from '@components/web/src/assets/legacy/icons/google_disabled.svg';
import { ReactComponent as GoogleIcon } from '@components/web/src/assets/legacy/icons/google_icon.svg';
import * as S from '@components/web/src/atoms/Buttons/SocialButtons/AuthorizationButton/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';

export type AuthorizationType = typeof GOOGLE | typeof FACEBOOK | typeof APPLE | typeof EMAIL;
export type VariantAuthButton = 'primary' | 'secondary';

export interface Props {
  isDisabled?: boolean;
  isHidden?: boolean;
  type: AuthorizationType;
  text?: ILocaleText;
  variant?: VariantAuthButton;
  handleClick: () => void;
}

const AuthorizationButton: FC<Props> = ({
  handleClick,
  type,
  isDisabled = false,
  isHidden = false,
  text,
  variant = 'primary',
}) => {
  const renderIcon = () => {
    switch (type) {
      case APPLE:
        return isDisabled ? <AppleDisabled /> : <AppleIcon />;
      case FACEBOOK:
        return isDisabled ? <FacebookDisabled /> : <FacebookIcon />;
      case GOOGLE:
        return isDisabled ? <GoogleDisabled /> : <GoogleIcon />;
      case EMAIL:
        return <EmailIcon />;
      default:
        return null;
    }
  };

  return (
    <S.AuthButton $isHidden={isHidden} $variant={variant} disabled={isDisabled} type={type} onClick={handleClick}>
      {renderIcon()}
      {variant === 'secondary' && (
        <Text color={STATIC_COLORS.base.black} size="subtitle2" text={text} weight="normal" />
      )}
    </S.AuthButton>
  );
};

export default AuthorizationButton;
